import React from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { getStatusLabel } from "src/Utils/Combined";
import { makeStyles } from "@mui/styles";
import { imgUrl } from "src/Helpers/utils/Constant";
import { useSearchParams } from "react-router-dom";
import { ISingleCompanyDocsInfo } from "src/Types/module/UpdateRequest/RequestCompanyDocsInfo";

const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
const CompanyDocsAppReject = ({
  requestCompanyDocsInfo,
}: {
  requestCompanyDocsInfo: ISingleCompanyDocsInfo;
}) => {
  const classes = useStyles();
  const [query] = useSearchParams();
  const status = query.get("status");
  return (
    <Container>
      <Card>
        <CardHeader
          title={
            status === "approved"
              ? "Approved information"
              : "Rejected information"
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.TableCell}>Status</TableCell>
                <TableCell align="right">
                  {getStatusLabel(requestCompanyDocsInfo.status)}
                </TableCell>
              </TableRow>

              {requestCompanyDocsInfo.reject_reason && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Reject reason
                  </TableCell>
                  <TableCell align="right">
                    {requestCompanyDocsInfo.reject_reason}
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_office_agreement && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Office agreement
                  </TableCell>
                  <TableCell align="right">
                    {requestCompanyDocsInfo.company_office_agreement}
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_agreement_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Agreement file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestCompanyDocsInfo.company_agreement_file}`}
                    >
                      {requestCompanyDocsInfo.company_agreement_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_incorporation_certificate && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Incorporation certificate
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestCompanyDocsInfo.company_incorporation_certificate}`}
                    >
                      {requestCompanyDocsInfo.company_incorporation_certificate}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_memorandum_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Memorandum file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestCompanyDocsInfo.company_memorandum_file}`}
                    >
                      {requestCompanyDocsInfo.company_memorandum_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_one_staff_certificate && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    One staff certificate
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestCompanyDocsInfo.company_one_staff_certificate}`}
                    >
                      {requestCompanyDocsInfo.company_one_staff_certificate}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_ownership_status && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Ownership status
                  </TableCell>
                  <TableCell align="right">
                    {requestCompanyDocsInfo.company_ownership_status}
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_partnership_deed_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Partnership deed file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestCompanyDocsInfo.company_partnership_deed_file}`}
                    >
                      {requestCompanyDocsInfo.company_partnership_deed_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}
              {requestCompanyDocsInfo.company_staff_list_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Staff list file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestCompanyDocsInfo.company_staff_list_file}`}
                    >
                      {requestCompanyDocsInfo.company_staff_list_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestCompanyDocsInfo.company_office_photographs && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Office photographs
                  </TableCell>
                  <TableCell align="right">
                    {requestCompanyDocsInfo.company_office_photographs}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default CompanyDocsAppReject;
