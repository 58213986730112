import { useState, MouseEvent } from 'react';
import {
  Button,
  IconButton,
  Tooltip,
  Popover,
  Typography,
  Box,
} from '@mui/material';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import PopoverComponent from 'src/common/PopoverComponent';

const VerifiedMoneyReceiptStatus = ({
  voucher_id,
  status,
  refetchData,
}: {
  voucher_id: number;
  status: number;
  refetchData: () => void;
}) => {
  const { user } = useAuthContext();
  const { response, loading, updateData, success } = UpdateDataApi<any>(
    `/api/atab/money-receipt/${voucher_id}`
  );

  if (success) {
    refetchData();
  }
  const handleConfirm = () => {
    if (voucher_id) {
      updateData({});
    }
  };
  return (
    <IconButton>
      {status ? (
        <Button size='small' variant='outlined'>
          Payment Verified
        </Button>
      ) : user?.user_admin_role === 'Secretary' ? (
        <Box>
          <PopoverComponent handleConfirm={handleConfirm}>
            <Button size='small' variant='outlined' color='error'>
              Payment Verified Pending
            </Button>
          </PopoverComponent>
        </Box>
      ) : (
        <Button size='small' variant='outlined' color='error'>
          Payment Verified Pending
        </Button>
      )}
    </IconButton>
  );
};

export default VerifiedMoneyReceiptStatus;
