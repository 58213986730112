import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
  ListItem,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { Divider } from "@mui/material";
import { IPrevCompanyDocsInfo } from "src/Types/module/UpdateRequest/RequestCompanyDocsInfo";

const CurrentCompanyDocsInfo = ({ preCompanyDocsInfo }: any) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Current information
          </Typography>
        }
      />

      <TableContainer sx={{ mb: 5 }}>
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Company office agreement
              </TableCell>
              <TableCell align="right">
                {" "}
                {preCompanyDocsInfo.company_office_agreement}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company agreement file
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_agreement_file ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCompanyDocsInfo.company_agreement_file}`}
                  >
                    {preCompanyDocsInfo.company_agreement_file}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company incorporation certificate
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_incorporation_certificate ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCompanyDocsInfo.company_incorporation_certificate}`}
                  >
                    {preCompanyDocsInfo.company_incorporation_certificate}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company memorandum file
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_memorandum_file ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCompanyDocsInfo.company_memorandum_file}`}
                  >
                    {preCompanyDocsInfo.company_memorandum_file}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Company one staff certificate
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_one_staff_certificate ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCompanyDocsInfo.company_one_staff_certificate}`}
                  >
                    {preCompanyDocsInfo.company_one_staff_certificate}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company ownership status
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_ownership_status}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                company partnership deed file
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_partnership_deed_file ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCompanyDocsInfo.company_partnership_deed_file}`}
                  >
                    {preCompanyDocsInfo.company_partnership_deed_file}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company staff list file
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_staff_list_file ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCompanyDocsInfo.company_staff_list_file}`}
                  >
                    {preCompanyDocsInfo.company_staff_list_file}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company office photographs
              </TableCell>
              <TableCell align="right">
                {preCompanyDocsInfo.company_office_photographs?.map((sImg) => (
                  <a
                    key={sImg}
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${sImg}`}
                  >
                    {sImg}
                  </a>
                ))}{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CurrentCompanyDocsInfo;
