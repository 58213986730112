import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
  InputLabel,
  Card,
  CardHeader,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { TOKEN_KEY, url } from 'src/Helpers/utils/Constant';
import { Container } from '@mui/system';
import Toaster from 'src/Toaster/Toaster';
import FreshMemberList from './UnverifiedMember/UnverifiedMemberList';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';

const forLimited = ['Managing Director', 'Director', 'Chairman', 'CEO'];
const forPartnerShip = ['Partner', 'Managing Partner', 'CEO'];
const forProprietor = ['Proprietor'];
const CreateMember = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  console.log(errors);
  const token = localStorage.getItem(TOKEN_KEY);
  const onSubmit = async (inputs: any) => {
    const { is_new_member, ...removeData } = removeUndefinedAndNull(inputs);
    const formData = new FormData();
    if (is_new_member) {
      formData.append('new_member', String(0));
    } else {
      formData.append('new_member', String(1));
    }
    Object.keys(removeData).forEach((item) => {
      if (item === 'company_owner_photo' && removeData[item][0]) {
        formData.append(item, removeData[item][0]);
      } else if (item === 'user_member_deed_file' && removeData[item][0]) {
        formData.append(item, removeData[item][0]);
      } else {
        formData.append(item, removeData[item]);
      }
    });

    try {
      setLoading(true);
      const response = await fetch(`${url}/api/haab/member`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token ? token : ''}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: 'Successfully created member',
        });
        reset();
        setLoading(false);
      } else {
        setLoading(false);
        Toaster().fire({
          icon: 'error',
          title: data.message,
        });
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
    // console.table(Object.fromEntries(formData));
  };
  const companyType: 'Proprietor' | 'Limited' | 'Partnership' = watch(
    'member_company_type'
  );
  useEffect(() => {
    if (companyType === 'Proprietor') {
      setValue('company_owner_designation', 'Proprietor');
    } else {
      setValue('company_owner_designation', undefined);
    }
  }, [companyType]);
  const zone = watch('user_member_zone');

  return (
    <Container maxWidth='xl'>
      <Card>
        <CardHeader title='Create Member' />
        <Helmet>
          <title>HAAB - Create Member</title>
        </Helmet>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mx: 4,
          }}
        >
          <Grid container columnSpacing={2} alignItems={'center'}>
            <Grid item xs={12} md={3} xl={3}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='name'
                label='Enter company name'
                placeholder='Enter company name'
                {...register('user_member_company_name')}
                type='text'
                autoFocus
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3} mt={1}>
              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>
                  Organization Type
                </InputLabel>
                <Select
                  required
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Organization Type'
                  {...register('member_company_type')}
                >
                  <MenuItem value={'Proprietor'}>Proprietor</MenuItem>
                  <MenuItem value={'Partnership'}>Partnership</MenuItem>
                  <MenuItem value={'Limited'}>Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <TextField
                margin='normal'
                fullWidth
                id='Hajj-license'
                required
                label='Hajj license no'
                placeholder='Hajj license no'
                {...register('user_member_hajj_license', {
                  required: 'Hajj license number is required',
                  onChange: (e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      setError('user_member_hajj_license', {
                        type: 'manual',
                        message: 'Only Number Allows',
                      });
                    }

                    if (value.length > 4) {
                      setError('user_member_hajj_license', {
                        type: 'manual',
                        message: 'must be exactly 4 digits',
                      });
                    } else if (value.length === 4 && /^\d{4}$/.test(value)) {
                      clearErrors('user_member_hajj_license');
                    }
                  },
                })}
                type='text'
                autoFocus
                size='small'
                error={!!errors.user_member_hajj_license}
                helperText={errors.user_member_hajj_license?.message as string}
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <TextField
                required
                margin='normal'
                fullWidth
                id='phone'
                label='Enter Owner name'
                placeholder='Enter Owner name'
                {...register('company_owner_name')}
                type='text'
                autoFocus
                size='small'
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <Controller
                name='company_owner_designation'
                control={control}
                defaultValue={
                  companyType === 'Proprietor' ? forProprietor[0] : ''
                }
                render={({ field: { onChange, value, ...fieldProps } }) => (
                  <Autocomplete
                    freeSolo
                    options={
                      companyType === 'Proprietor'
                        ? forProprietor
                        : companyType === 'Limited'
                        ? forLimited
                        : forPartnerShip
                    }
                    value={value}
                    onChange={(event, newValue) => {
                      onChange(newValue); // Update form value
                      setValue('company_owner_designation', newValue); // Ensure form is aware of the change
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Enter Owner designation'
                        required
                        size='small'
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      setValue('company_owner_designation', newValue);
                    }}
                  />
                )}
              />
            </Grid>
            {companyType === 'Partnership' && (
              <Grid item xs={12} md={3} xl={3}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='Hajj-license'
                  label='Upload partnership deed file'
                  placeholder='Upload partnership deed file'
                  {...register('user_member_deed_file')}
                  type='file'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  size='small'
                />
              </Grid>
            )}

            <Grid item xs={12} md={3} xl={3}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='phone'
                label='Enter Owner  email address'
                placeholder='Enter Owner email address'
                {...register('user_member_email')}
                type='text'
                autoFocus
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='phone'
                label='Enter Owner phone number'
                placeholder='Enter Owner phone number'
                {...register('user_member_phone', {
                  required: true,
                })}
                type='number'
                autoFocus
                size='small'
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              xl={3}
              sx={{
                mt: 1,
              }}
            >
              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>
                  Select zone
                </InputLabel>
                <Select
                  required
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={zone}
                  label='Select zone'
                  {...register('user_member_zone_id')}
                >
                  <MenuItem value={1}>Dhaka</MenuItem>
                  <MenuItem value={2}>Chittagong</MenuItem>
                  <MenuItem value={3}>Sylhet</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <TextField
                label='Upload Owner photo'
                margin='normal'
                InputLabelProps={{ shrink: true }}
                fullWidth
                {...register('company_owner_photo')}
                type='file'
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <FormControlLabel
                control={
                  <Checkbox {...register('is_new_member')} color='primary' />
                }
                label='Is this old member?'
              />
            </Grid>
          </Grid>
          <Box textAlign={'center'}>
            {loading ? (
              <Button
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                  width: '40%',
                }}
              >
                Loading ...
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                  width: {
                    xs: '100%',
                    sm: '40%',
                  },
                }}
                size='small'
              >
                Create Member
              </Button>
            )}
          </Box>
        </Box>
      </Card>
      <FreshMemberList />
    </Container>
  );
};

export default CreateMember;
