import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
} from '@mui/material';
import { ICompanyProposer } from 'src/Types/module/member/CompanyInformation';
import { getStatusLabel } from 'src/Utils/Combined';
import moment from 'moment';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

interface IProps {
  sProposer: ICompanyProposer;
  setUpdateModal: (value: boolean) => void;
  setSingleProposer: Function;
}
const ShowProposer = ({
  sProposer,
  setUpdateModal,
  setSingleProposer,
}: IProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer component={Paper} sx={{ mb: 5 }}>
        <Table aria-label='custom pagination table'>
          <TableBody key={sProposer.proposer_request_id}>
            <TableRow>
              <TableCell component='th' scope='row'>
                Proposer company name
              </TableCell>
              <TableCell align='right'>
                {sProposer.proposer_request_to_company_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Proposer Company Owner name
              </TableCell>
              <TableCell align='right'>
                {sProposer.company_owner_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Proposer status
              </TableCell>
              <TableCell align='right'>
                {getStatusLabel(sProposer.proposer_request_status)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Proposer request date
              </TableCell>
              <TableCell align='right'>
                {moment(sProposer.proposer_request_update_date).format(
                  'Do MMM YYYY'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='right'>
                <Box
                // sx={{
                //   position: 'absolute',
                //   top: -30,
                //   right: 0,
                // }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    startIcon={<ModeEditOutlineOutlinedIcon fontSize='small' />}
                    onClick={() => {
                      setUpdateModal(true);
                      setSingleProposer(sProposer);
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShowProposer;
