import { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Avatar,
  Drawer,
  Divider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);

function ComplainTopBar({}: {}) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const { memberId } = useParams();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [singleSuppportConversations, setSingleSupportConversations] =
    useState<{
      user_member_company_name: string;
      user_member_representative_name: string;
      user_member_representative_photo: string;
      user_member_id: number;
    }>(null);
  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get<any>(
          `${url}/api/atab/user-member/get/a/member/for-admin/${memberId}`
        );
        if (data.success) {
          setSingleSupportConversations(data?.data);
        }
      } catch (error) {}
    })();
  }, []);
  return (
    <>
      <RootWrapper>
        <Box display='flex' alignItems='center'>
          <img
            height={40}
            width={40}
            style={{ borderRadius: '100%' }}
            src={
              singleSuppportConversations?.user_member_representative_photo
                ? `${imgUrl}/${singleSuppportConversations?.user_member_representative_photo}`
                : '/static/images/avatars/avatar2.png'
            }
          />

          <Box ml={1}>
            <Typography variant='h4'>
              {singleSuppportConversations?.user_member_representative_name}{' '}
              <br />
              <Typography variant='h5'>
                {singleSuppportConversations?.user_member_company_name}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', lg: 'flex' },
          }}
        >
          <Tooltip placement='bottom' title='Conversation information'>
            <IconButton color='primary' onClick={handleDrawerToggle}>
              <InfoTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </RootWrapper>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
        variant='temporary'
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        elevation={9}
      >
        <Box
          sx={{
            minWidth: 360,
          }}
          p={2}
        >
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Avatar
              sx={{
                mx: 'auto',
                my: 2,
                width: theme.spacing(12),
                height: theme.spacing(12),
              }}
              variant='rounded'
              alt={singleSuppportConversations?.user_member_representative_name}
              src={`${imgUrl}/${singleSuppportConversations?.user_member_representative_photo}`}
            />
            <Typography variant='h4'>
              <Link
                to={`/member-list/${singleSuppportConversations?.user_member_id}`}
              >
                View details
              </Link>
            </Typography>
          </Box>
          <Divider
            sx={{
              my: 3,
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default ComplainTopBar;
