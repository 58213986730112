import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Box } from '@mui/material';

const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Box sx={{ mt: 5 }}>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js'>
        <div
          style={{
            height: '600px',
          }}
        >
          <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
        </div>
      </Worker>

      <Box>
        <a href={pdfUrl} target='_blank' rel='noopener noreferrer'>
          Download
        </a>
      </Box>
    </Box>
  );
};

export default PdfViewer;
