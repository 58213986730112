import React from "react";
import CommonMemberTable from "../commonModule/CommonMemberTable";

const BlockedMemberTable = () => {
  return (
    <div>
      <CommonMemberTable status="blocked" title=" Penalty Members" />
    </div>
  );
};

export default BlockedMemberTable;
