import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateCompanyOwnerInformation from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyOwnerInformation';
import {
  ICompanyOwnerInfo,
  ICompanyOwnerInfoResponse,
} from 'src/Types/module/member/CompanyInformation';
import moment from 'moment';
import OrganizationInfo from '../../../CompanyAddInfo/CompanyAddInfoComponents/OrganizationInfo';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { HttpClient } from 'src/Helpers/http/http';
import PdfAndImageView from 'src/common/PdfAndImageView';
function CompanyInformation() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [ownerInformation, setOwnerInformation] = useState<ICompanyOwnerInfo>(
    {} as ICompanyOwnerInfo
  );
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const data: ICompanyOwnerInfoResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/company/${memberId}`
        );
        if (data.success) {
          setOwnerInformation(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    member_company_name,
    member_company_type,
    member_company_stablished_date,
    member_company_status,
    member_company_verified_by,
    member_company_verified_date,
    member_company_created_at,
    member_company_website,
    member_company_logo,
    phones,
    cellphone,
    email,
    addressline1,
    postalcode,
    police_station,
    zone_name,
    area,
    thana,
    district,
    division,
  } = ownerInformation || {};

  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Company information'
            actionButtonTitle={member_company_name ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {member_company_name ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company name:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_name
                              ? member_company_name
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company status:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_status
                              ? member_company_status
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company type:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_type
                              ? member_company_type
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        {phones?.length &&
                          phones &&
                          phones?.map((phone, index) => (
                            <TableRow>
                              <TableCell component='th' scope='row'>
                                Company phone number {`${index + 1}`}:
                              </TableCell>
                              <TableCell align='right'>
                                {phone ? phone : 'Not provided'}
                              </TableCell>
                            </TableRow>
                          ))}

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company cell phone number:
                          </TableCell>
                          <TableCell align='right'>
                            {cellphone ? cellphone : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company email:
                          </TableCell>
                          <TableCell align='right'>
                            {email ? email : 'Not provided'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company address:
                          </TableCell>
                          <TableCell align='right'>
                            {addressline1 ? addressline1 : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company website:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_website ? (
                              <a href={member_company_website} target='_blank'>
                                {member_company_website}
                              </a>
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company logo:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_logo ? (
                              <PdfAndImageView file={member_company_logo} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company created date:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_created_at
                              ? moment(member_company_created_at).format(
                                  'Do MMM YYYY'
                                )
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company stablish date :
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_stablished_date
                              ? moment(member_company_stablished_date).format(
                                  'Do MMM YYYY'
                                )
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company verify by:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_verified_by
                              ? member_company_verified_by
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company verify date:
                          </TableCell>
                          <TableCell align='right'>
                            {member_company_verified_date
                              ? moment(member_company_verified_date).format(
                                  'Do MMM YYYY'
                                )
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company police station:
                          </TableCell>
                          <TableCell align='right'>
                            {police_station ? police_station : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company post code:
                          </TableCell>
                          <TableCell align='right'>
                            {postalcode ? postalcode : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company zone name:
                          </TableCell>
                          <TableCell align='right'>
                            {zone_name ? zone_name : 'Not provided'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company division:
                          </TableCell>
                          <TableCell align='right'>
                            {division ? division : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company district:
                          </TableCell>
                          <TableCell align='right'>
                            {district ? district : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company thana:
                          </TableCell>
                          <TableCell align='right'>
                            {thana ? thana : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company area:
                          </TableCell>
                          <TableCell align='right'>
                            {area ? area : 'Not provided'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
        <Box textAlign={'end'}>
          {' '}
          <PageTitle
            actionButtonTitle={member_company_name ? 'Update' : ''}
            setAction={setOpen}
          />
        </Box>
      </>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: member_company_name
            ? 'Update company information'
            : 'Add company information',
        }}
      >
        {member_company_name ? (
          <UpdateCompanyOwnerInformation
            ownerInformation={ownerInformation}
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <OrganizationInfo
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>
    </>
  );
}

export default CompanyInformation;
