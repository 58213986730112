import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IVoucherType } from 'src/content/modules/Members/subModules/invoice/type/voucherType';

const SpeceficOrAllWiseVoucher = ({
  register,
  setValue,
  watch,
  memberId,
}: {
  register?: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  memberId: number;
}) => {
  const voucherType = watch('specific_type');
  const invoice_ids = watch('invoice_ids');
  const { response: speceficMemberWiseVoucher, getData } = GetDataApi<
    IVoucherType[]
  >(`/api/atab/invoice/get/all/by/user-member/status/${memberId}/unpaid`, true);

  useEffect(() => {
    if (voucherType === 'all') {
      let allAmountOfVoucher =
        speceficMemberWiseVoucher?.length > 0
          ? speceficMemberWiseVoucher?.reduce((acc, item) => {
              return (acc += Number(item.due));
            }, 0)
          : 0;
      setValue('amount', allAmountOfVoucher);
    } else if (voucherType === 'specific') {
      setValue('invoice_ids', undefined);
      setValue('amount', 0);
    }
  }, [voucherType]);
  useEffect(() => {
    if (invoice_ids?.length > 0 && invoice_ids) {
      const spe = speceficMemberWiseVoucher?.filter(
        (i) => i.atab_payment_invoice_id === invoice_ids[0]
      );
      setValue('amount', Number(spe[0]?.due));
    }
  }, [invoice_ids]);
  useEffect(() => {
    if (memberId) {
      getData();
      setValue('amount', 0);
      setValue('specific_type', undefined);
    }
  }, [memberId]);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth size='small'>
          <InputLabel id='demo-simple-select-label'>
            Payment to specific voucher
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={voucherType || ''}
            label='Payment to specific voucher'
            placeholder='Select Voucher Type'
            {...register('specific_type')}
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={'specific'}>Specific</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {voucherType === 'specific' && (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-simple-select-label'>
                Select Voucher
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Select Voucher'
                placeholder='Select Voucher'
                onChange={(e) => setValue('invoice_ids', [e.target.value])}
                MenuProps={{
                  style: {
                    maxHeight: 300,
                  },
                }}
              >
                {speceficMemberWiseVoucher?.length > 0 ? (
                  speceficMemberWiseVoucher?.map((item) => (
                    <MenuItem
                      key={item.atab_payment_invoice_id}
                      value={item.atab_payment_invoice_id}
                    >
                      {item.voucher_no}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled key={'no'} value={'no'}>
                    No Voucher available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          size='small'
          fullWidth
          disabled={voucherType === 'all'}
          id='Amount'
          label='Amount'
          defaultValue={0}
          {...register('amount')}
          type='number'
          variant='outlined'
        />
      </Grid>
    </>
  );
};

export default SpeceficOrAllWiseVoucher;
