import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Toaster from 'src/Toaster/Toaster';
import { ICompanyOwnerInfo } from 'src/Types/module/member/CompanyInformation';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import AddressSelect from '../../subModules/memberList/singleMember/AddressSelect/AddressSelect';
import MultiplePhoneNumbersModal from './MultiplePhoneNumbers';

interface Props {
  ownerInformation: ICompanyOwnerInfo;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}

const UpdateCompanyOwnerInformation = ({
  ownerInformation,
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: Props) => {
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({
    member_company_name: '',
    member_company_type: '',
    police_station: '',
    postalcode: '',
    cellphone: '',
    phone: '',
    phones: '',
    addressline1: '',
    email: '',
    member_company_website: '',
    member_company_stablished_date: '',
    member_company_zone: ownerInformation.zone_id,
    member_company_logo: '',
    member_company_form17: '',
    member_company_form12: '',
    area_id: 0,
  });
  const onSubmit = async (data: {
    division_id: number;
    district_id: number;
    thana_id: number;
    area_id: number;
  }) => {
    const formData = new FormData();
    Object.keys(info).forEach((key) => {
      if (info[key]) {
        formData.append(
          key,
          key === 'phones' ? JSON.stringify(info[key]) : info[key]
        );
      }
    });

    try {
      setIsLoading(true);
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/company/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
        setInfo({
          member_company_name: '',
          member_company_type: '',
          police_station: '',
          postalcode: '',
          cellphone: '',
          phone: '',
          phones: '',
          addressline1: '',
          email: '',
          member_company_website: '',
          member_company_stablished_date: '',
          member_company_zone: ownerInformation.zone_id,
          member_company_logo: '',
          member_company_form17: '',
          member_company_form12: '',
          area_id: 0,
        });
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box p={2}>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <Box>
                <TextField
                  size='small'
                  id='outlined-textarea'
                  label='Name of the organization'
                  placeholder='Name of the organization'
                  defaultValue={ownerInformation?.member_company_name}
                  onChange={(e) =>
                    setInfo({ ...info, member_company_name: e.target.value })
                  }
                  fullWidth
                  type='text'
                />
              </Box>
              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  label='Enter organization type'
                  placeholder='Enter organization type'
                  defaultValue={ownerInformation?.member_company_type}
                  onChange={(e) =>
                    setInfo({ ...info, member_company_type: e.target.value })
                  }
                  type='text'
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <TextField
                  size='small'
                  id='date'
                  label='Organization established date'
                  placeholder='Organization established date'
                  type='date'
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      member_company_stablished_date: e.target.value,
                    });
                  }}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  defaultValue={ownerInformation.police_station}
                  label='Organization police station'
                  placeholder='Organization police station'
                  type='text'
                  onChange={(e) =>
                    setInfo({ ...info, police_station: e.target.value })
                  }
                  fullWidth
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Box>
                <FormControl fullWidth size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Select zone{' '}
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={info.member_company_zone}
                    label='Select zone'
                    onChange={(e: any) =>
                      setInfo({ ...info, member_company_zone: e.target.value })
                    }
                  >
                    <MenuItem value={1}>Dhaka</MenuItem>
                    <MenuItem value={2}>Chittagong</MenuItem>
                    <MenuItem value={3}>Sylhet</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  label='Organization cellphone'
                  placeholder='Organization cellphone'
                  variant='outlined'
                  type='text'
                  defaultValue={ownerInformation.cellphone}
                  fullWidth
                  onChange={(e) =>
                    setInfo({ ...info, cellphone: e.target.value })
                  }
                />
              </Box>

              <Box mt={2}>
                <MultiplePhoneNumbersModal
                  info={info}
                  setInfo={setInfo}
                  companyData={ownerInformation}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  label='Organization full address'
                  placeholder='Organization full address'
                  type='text'
                  defaultValue={ownerInformation.addressline1}
                  fullWidth
                  onChange={(e) =>
                    setInfo({ ...info, addressline1: e.target.value })
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Box>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  label='Organization logo'
                  placeholder='Organization logo'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e: any) =>
                    setInfo({ ...info, member_company_logo: e.target.files[0] })
                  }
                />
              </Box>

              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  defaultValue={ownerInformation.email}
                  placeholder='Organization email address'
                  label='Organization email address'
                  type='email'
                  fullWidth
                  disabled
                  onChange={(e) => setInfo({ ...info, email: e.target.value })}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  defaultValue={ownerInformation.member_company_website}
                  label='Enter organization website'
                  type='text'
                  fullWidth
                  onChange={(e) =>
                    setInfo({ ...info, member_company_website: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  defaultValue={ownerInformation.postalcode}
                  label='Organization postal code'
                  placeholder='Organization postal code'
                  type='text'
                  fullWidth
                  onChange={(e) =>
                    setInfo({ ...info, postalcode: e.target.value })
                  }
                />
              </Box>
            </Box>
          </Grid>
          {/* address */}
          <AddressSelect
            setInfo={setInfo}
            info={info}
            ownerInformation={ownerInformation}
            register={register}
          />
        </Grid>
        <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
          {isLoading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              loading..
            </Button>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Update
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateCompanyOwnerInformation;
