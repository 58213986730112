import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container } from "@mui/system";
import SingleSMS from "./SingleSMS";
import MultiSMS from "./MultiSMS";
import { Helmet } from "react-helmet-async";
import { Card, CardHeader, Divider } from "@mui/material";

export default function SMS() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ p: 5 }} maxWidth="lg">
      <Helmet>
        <title>Message Panel</title>
      </Helmet>
      <Card>
        <CardHeader title="Massage Panel" />
        <Divider />
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Message to single member" value="1" />
                <Tab label="Message to multi member" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SingleSMS />
            </TabPanel>
            <TabPanel value="2">
              <MultiSMS />
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </Container>
  );
}
