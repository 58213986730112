import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { url } from 'src/Helpers/utils/Constant';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ICompanyProposer } from 'src/Types/module/member/CompanyInformation';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateCompanyProposer from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyProposer';
import ProposerInput from '../../../CompanyAddInfo/CompanyAddInfoComponents/ProposerInput';
import ShowProposer from './ShowProposer';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';

function ProposerInformation() {
  const { memberId } = useParams();
  const [proposer, setProposer] = useState<ICompanyProposer[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [singleProposer, setSingleProposer] = useState<ICompanyProposer>(
    {} as ICompanyProposer
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/atab/member-company/get/proposer/for/requester/status/${memberId}/all`
        );

        if (data.success) {
          setProposer(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Company proposer information'
            actionButtonTitle={proposer.length ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>

        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {proposer.length ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                {proposer?.map((sProposer) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={proposer.length > 1 ? 6 : 12}
                      key={sProposer.proposer_request_id}
                    >
                      <ShowProposer
                        setSingleProposer={setSingleProposer}
                        sProposer={sProposer}
                        setUpdateModal={setUpdateModal}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: 'Add company proposer information',
        }}
      >
        <ProposerInput
          memberId={memberId}
          setOpen={setOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </ModalsWrapper>

      {/* update proposer  */}
      <ModalsWrapper
        setShowModal={setUpdateModal}
        showModal={updateModal}
        modalData={{
          title: 'Update Company Proposer',
        }}
      >
        <UpdateCompanyProposer
          singleProposer={singleProposer}
          memberId={memberId}
          setRefresh={setRefresh}
          setUpdateModal={setUpdateModal}
          refresh={refresh}
        />
      </ModalsWrapper>
    </>
  );
}

export default ProposerInformation;
