import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import { Imembers } from "src/Types/module/member/memberTypes";
import { IDate } from "src/Types/module/serviceRequest/serviceRequestTypes";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { CommonDatePicker } from "src/common/CommonDatePicker";
import CommonPdfHeader from "../components/CommonPdfHeader";
import AccountReportExcelGenerator from "../components/AccountReportExcelGenerator";
import AccountReportPdfGenerator from "../components/AccountReportPdfGenerator";

const TrialBalance = () => {
  const [date, setDate] = useState<IDate>({
    startDate: null,
    endDate: null,
  });

  const getDownloadData: () => Promise<{
    total: number;
    data: Imembers[];
  }> = async () => {
    const token = localStorage.getItem("haab_admin");

    try {
      const { data } = await axios.get(
        `${url}/api/atab/user-member/download/member/data/for-admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  const data = [];

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid item xs={12} mb={2}>
        <Card sx={{ p: 2 }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid item>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={10}
                  label="Company"
                  onChange={(e) => console.log(e)}
                >
                  <MenuItem value={10}>HAAB Head Office</MenuItem>
                  <MenuItem value={30}>ATTI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                fontWeight={"bold"}
                variant="h6"
                component="h6"
                gutterBottom
              >
                From
              </Typography>

              <CommonDatePicker
                onChange={(e: any) => setDate({ ...date, startDate: e })}
                label={"Start Date"}
                value={date.startDate}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                fontWeight={"bold"}
                variant="h6"
                component="h6"
                gutterBottom
              >
                To
              </Typography>
              <CommonDatePicker
                onChange={(e: any) => setDate({ ...date, endDate: e })}
                label={"End Date"}
                value={date.endDate}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Trial Balance"
              action={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <AccountReportExcelGenerator
                    excelName={"Trial_balance"}
                    excelTableHead={[
                      "Account Head",
                      "Head Code",
                      "Debit",
                      "Credit",
                    ]}
                    excelData={data}
                  />

                  <AccountReportPdfGenerator
                    getDownloadData={getDownloadData}
                    pdfTitle="HAAB HEAD OFFICE"
                    columns={[
                      {
                        label: "Membership No",
                        key: "user_member_id_card_number",
                      },
                      {
                        label: "Agency Name & Address",
                        key: "user_member_company_name",
                        width: 120,
                      },
                      {
                        label: "Name of Representative & Designation",
                        key: "user_member_representative_name",
                        width: 80,
                      },
                      { label: "TIN NO", key: "company_tin_number" },
                      {
                        label: "Contact No & Email",
                        key: "user_member_phone",
                        width: 100,
                      },
                    ]}
                    rowsPerPage={25}
                  />
                </Box>
              }
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Account Group</TableCell>
                      <TableCell>Account Subgroup</TableCell>
                      <TableCell>Account Head Group</TableCell>
                      <TableCell>Account Head Subgroup</TableCell>
                      <TableCell>Particulars</TableCell>
                      <TableCell>Account Code</TableCell>
                      <TableCell>Debit (BDT)</TableCell>
                      <TableCell>Credit (BDT)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <InvoiceTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box> */}
            </CardContent>

            {/* Modal */}

            {/* manual invoice  */}
            {/* <ModalsWrapper
              modalData={{ title: "Create manual invoice" }}
              setShowModal={setOpenManual}
              showModal={openManual}
            >
              <CreateManualInvoice handleClose={() => setOpenManual(false)} />
            </ModalsWrapper> */}
          </Card>
        </Grid>
      </Grid>

      <CommonPdfHeader pdfHead="adfggfhaj" />
    </Container>
  );
};

export default TrialBalance;
