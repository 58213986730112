import { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IAccountType } from '../type/voucherType';
import AccountTable from './AccountTable';
import CreateAccount from './CreateAccount';
import EditAccount from './EditAccount';
const AccountsList = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [accountEditData, setEditData] = useState<IAccountType>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<{
    page: string | number;
    listType: string | number;
    limit: string | number;
  }>({
    listType: 'all',
    page: 1,
    limit: 30,
  });
  const { response, loading, total, getData } = GetDataApi<IAccountType[]>(
    filter.listType === 'all'
      ? `/api/atab/account/?limit=${filter.limit}&page=${filter.page}`
      : `/api/atab/account?account_type=${filter.listType}&limit=${filter.limit}&page=${filter.page}`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter]);
  console.log(response?.length);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    width: '300px',
                  }}
                >
                  <Grid item xs={6}>
                    <Select
                      size='small'
                      fullWidth
                      variant='outlined'
                      placeholder='Payment type'
                      defaultValue={'all'}
                      sx={{ padding: 0, height: 35 }}
                      onChange={(e) =>
                        setFilter({ ...filter, listType: e.target.value })
                      }
                    >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='Cash'>Cash</MenuItem>
                      <MenuItem value='Bank'>Bank</MenuItem>
                      <MenuItem value='Mobile Banking'>Mobile Banking</MenuItem>
                    </Select>
                  </Grid>
                  <Button
                    onClick={() => setOpen(true)}
                    variant='contained'
                    size='small'
                  >
                    Add Beneficiary
                  </Button>
                </Box>
              }
              title='Beneficiary list'
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SL</TableCell>
                      <TableCell>Account Name</TableCell>
                      {filter.listType === 'Bank' && (
                        <TableCell>Bank Name</TableCell>
                      )}

                      <TableCell>Account Type</TableCell>
                      {filter.listType === 'Mobile Banking' && (
                        <>
                          <TableCell>Wallet Type</TableCell>
                          <TableCell>Mobile Operator Name</TableCell>
                          <TableCell>Wallet Account Type</TableCell>
                          <TableCell>Mobile No</TableCell>
                        </>
                      )}

                      <TableCell>Amount</TableCell>
                      {filter.listType !== 'Cash' && (
                        <TableCell>Status</TableCell>
                      )}

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : response?.length ? (
                      <>
                        {response?.map((sInvoice, index) => (
                          <AccountTable
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                            index={index}
                            refetchData={getData}
                            setEditData={(e) => {
                              setEditData(e);
                              setEditModalOpen(true);
                            }}
                            filter={filter}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total as number}
                  onPageChange={(_event: any, newPage: number) =>
                    setFilter({ ...filter, page: newPage })
                  }
                  onRowsPerPageChange={(event) =>
                    // setLimit(parseInt(event.target.value))
                    setFilter({ ...filter, limit: event.target.value })
                  }
                  page={Number(filter.page) && Number(filter.page)}
                  rowsPerPage={Number(filter.limit)}
                  rowsPerPageOptions={[30, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: 'Create Beneficiary' }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateAccount refetch={getData} closeModal={setOpen} />
            </ModalsWrapper>
            {/*edit------- Modal */}
            <ModalsWrapper
              modalData={{ title: 'Edit Account' }}
              setShowModal={setEditModalOpen}
              showModal={editModalOpen}
            >
              <EditAccount
                key={accountEditData?.id}
                refetch={getData}
                closeModal={setEditModalOpen}
                accountEditData={accountEditData}
              />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountsList;
