import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { ITrainingInvoiceItem } from "src/Utils/type";

type IProps = {
  details: ITrainingInvoiceItem;
  handleClose: Function;
};

function TrainingInvoiceUpdateForm({ details, handleClose }: IProps) {
  const Toast = Toaster();
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [bdtAmount, setBDTAmount] = useState<number>(null);
  const [usdAmount, setUSDAmount] = useState<number>(null);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (user.user_admin_id && details.training_paymentable_item_id) {
      try {
        const { data } = await axios.put(
          `${url}/api/training/payment/update/paymentable/item/${details.training_paymentable_item_id}?user_admin_id=${user.user_admin_id}`,
          {
            training_paymentable_item_name:
              name || details.training_paymentable_item_name,
            training_paymentable_item_bdt_amount:
              bdtAmount || details.training_paymentable_item_bdt_amount,
            training_paymentable_item_usd_amount:
              usdAmount || details.training_paymentable_item_usd_amount,
          }
        );
        if (data.success) {
          setIsLoading(false);
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          handleClose();
        }
      } catch (err) {
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: err.response.data.message,
        });
      }
    }
  };
  return (
    <Box
      component="form"
      onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        type="text"
        id="name"
        defaultValue={details?.training_paymentable_item_name}
        onChange={(e: any) => setName(e.target.value)}
        variant="outlined"
        label="Item name"
        sx={{ paddingBottom: "10px" }}
      />
      <FormControl sx={{ paddingBottom: "20px" }} fullWidth variant="filled">
        <InputLabel htmlFor="filled-adornment-amount">
          Item BDT amount
        </InputLabel>
        <FilledInput
          id="filled-adornment-amount"
          type="number"
          required
          defaultValue={details?.training_paymentable_item_bdt_amount}
          onChange={(e: any) => setBDTAmount(e.target.value)}
          startAdornment={<InputAdornment position="start">৳</InputAdornment>}
        />
      </FormControl>
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="filled-adornment-amount">
          Item USD amount
        </InputLabel>
        <FilledInput
          id="filled-adornment-amount"
          type="number"
          required
          defaultValue={details?.training_paymentable_item_usd_amount}
          onChange={(e: any) => setUSDAmount(e.target.value)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
      {isLoading ? (
        <LoadingButton
          loading={isLoading}
          fullWidth
          color="secondary"
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          loadingPosition="end"
          variant="contained"
        >
          Updating...
        </LoadingButton>
      ) : (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Update item
        </Button>
      )}
    </Box>
  );
}

export default TrainingInvoiceUpdateForm;
