import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
  ButtonGroup,
  Chip,
} from '@mui/material';
import { imgUrl } from 'src/Helpers/utils/Constant';
import GetDataApi from './FetchData/GetDataApi';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';

interface ICommentType {
  admin_id: number;
  comments: string;
  created_at: string;
  id: number;
  member_id: number;
  role_id: number;
  self: true;
  signature: string;
  status: number;
  type: string;
  user_admin_name: string;
  forward_to: string;
  is_forward_me: 'true' | 'false';
}

interface ISelfComments {
  id: number;
  comments: string;
  created_at: string;
  forward_to: string;
  signature: string;
}

const ViewAllNotes = () => {
  const { memberId } = useParams();
  const { user } = useAuthContext();
  const { response } = GetDataApi<{
    comments: ICommentType[];
    selfComments: ISelfComments[];
  }>(`/api/atab/user-member/get/comments/${memberId}`);

  // State to manage the toggle between "Incoming Comments" and "Outgoing Comments"
  const [view, setView] = useState<'incoming' | 'outgoing'>('incoming');

  console.log(user);
  return (
    <Box sx={{ padding: 2 }}>
      {/* ButtonGroup for toggling between Incoming and Outgoing Comments */}
      <ButtonGroup sx={{ marginBottom: 4 }}>
        <Button
          variant={view === 'incoming' ? 'contained' : 'outlined'}
          onClick={() => setView('incoming')}
        >
          Incoming Note
        </Button>
        <Button
          variant={view === 'outgoing' ? 'contained' : 'outlined'}
          onClick={() => setView('outgoing')}
        >
          Outgoing Note
        </Button>
      </ButtonGroup>

      {view === 'incoming' && (
        <TableContainer component={Paper}>
          <Typography
            variant='h6'
            fontWeight='bold'
            color='primary'
            sx={{ padding: 2 }}
          >
            Incoming Note
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='none'>
                  <Typography fontWeight='bold' sx={{ ml: 1 }}>
                    Date
                  </Typography>
                </TableCell>
                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Forward From</Typography>
                </TableCell>
                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Forward To</Typography>
                </TableCell>
                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Note</Typography>
                </TableCell>

                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Signature</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.comments
                ?.filter((item) => item.admin_id !== user?.user_admin_id)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell padding='none'>
                      <Typography sx={{ ml: 1 }}>
                        {moment(row.created_at).format('DD-MMM-YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography sx={{ ml: 1 }}>
                        {row.user_admin_name}
                      </Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography sx={{ ml: 1 }}>
                        {row.forward_to}{' '}
                        {row.is_forward_me === 'true' ? (
                          <Chip
                            size='small'
                            label='me'
                            color='primary'
                            variant='outlined'
                          />
                        ) : (
                          <Chip
                            size='small'
                            label='others'
                            color='success'
                            variant='outlined'
                          />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography sx={{ ml: 1 }}>{row.comments}</Typography>
                    </TableCell>

                    <TableCell padding='none'>
                      <img
                        src={`${imgUrl}/${row.signature}`}
                        alt='Signature'
                        width={100}
                        height={50}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {view === 'outgoing' && (
        <TableContainer component={Paper}>
          <Typography
            variant='h6'
            fontWeight='bold'
            color='primary'
            sx={{ padding: 2, width: 700 }}
          >
            Outgoing Note
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='none'>
                  <Typography fontWeight='bold' sx={{ ml: 1 }}>
                    Date
                  </Typography>
                </TableCell>
                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Forward To</Typography>
                </TableCell>
                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Note</Typography>
                </TableCell>

                <TableCell padding='none'>
                  <Typography fontWeight='bold'>Signature</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.selfComments?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ ml: 1 }}>
                    {moment(row.created_at).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell padding='none'>{row.forward_to}</TableCell>
                  <TableCell padding='none'>{row.comments}</TableCell>
                  <TableCell padding='none'>
                    <img
                      src={`${imgUrl}/${row.signature}`}
                      alt='Signature'
                      width={100}
                      height={50}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ViewAllNotes;
