import { useContext } from 'react';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ListSubheader,
  List,
  Button,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { NavLink as RouterLink, useSearchParams } from 'react-router-dom';
import { SidebarContext } from 'src/components/Context/SidebarContext';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { menuWrapper, submenuWrapper } from 'src/Utils/DashboardUtils';

const MenuWrapper = menuWrapper();
const SubMenuWrapper = submenuWrapper();

function SidebarMenus() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = React.useState<string | false>(
    searchParams.get('tabValue')
  );
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useAuthContext();
  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <MenuWrapper>
        <List component='div'>
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/dashboard'
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {/* member management */}
        {(user?.permissions?.subModules?.includes('Active Member') ||
          user?.permissions?.subModules?.includes('InActive Member') ||
          user?.permissions?.subModules?.includes('Cancelled Member') ||
          user?.permissions?.subModules?.includes('Create Member') ||
          user?.permissions?.subModules?.includes(
            'New Member Application'
          )) && (
          <List component={'div'}>
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'memberManagement'}
                  onChange={handleChange('memberManagement')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Member Management
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: 0 }}>
                    {user?.permissions?.subModules?.includes(
                      'Active Member'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={() => {
                            closeSidebar;
                          }}
                          to='/member-list/active?tabValue=memberManagement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Active Member
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'InActive Member'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/member-list/inactive?tabValue=memberManagement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          InActive Member
                        </Button>
                      </ListItem>
                    )}
                    {user?.permissions?.subModules?.includes(
                      'Cancelled Member'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/member-list/rejected?tabValue=memberManagement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Cancelled Member
                        </Button>
                      </ListItem>
                    )}
                    {user?.permissions?.subModules?.includes(
                      'Create Member'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/member-list/create-member?tabValue=memberManagement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Create Member
                        </Button>
                      </ListItem>
                    )}
                    {user?.permissions?.subModules?.includes(
                      'New Member Application'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/member-list/pending?tabValue=memberManagement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          New Member Application
                        </Button>
                      </ListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* application management-------------- */}
        {(user?.permissions?.subModules?.includes('ID Card') ||
          user?.permissions?.subModules?.includes('Renewal') ||
          user?.permissions?.subModules?.includes('Address Change') ||
          user?.permissions?.subModules?.includes('Ownership Change') ||
          user?.permissions?.subModules?.includes(
            'Duplicate HAAB certificate'
          ) ||
          user?.permissions?.subModules?.includes(
            'Agency information update'
          )) && (
          <List component={'div'}>
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                {
                  <Accordion
                    expanded={expanded === 'application'}
                    onChange={handleChange('application')}
                    className='accordion'
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Applications
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      {user?.permissions?.subModules?.includes('ID Card') && (
                        <ListItem>
                          <Button
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/update-applications/id-card?tabValue=application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            ID Card
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes('Renewal') && (
                        <ListItem component='div'>
                          <Button
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/update-applications/renewal-application?tabValue=application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Renewal
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes(
                        'Address Change'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/update-applications/address-change-application?tabValue=application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Address Change
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes(
                        'Ownership Change'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/update-applications/ownership-change-application?tabValue=application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Ownership Change
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes(
                        'Duplicate HAAB certificate'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/update-applications/duplicate-certificate?tabValue=application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Duplicate HAAB certificate
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes(
                        'Agency information update'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/update-applications/agency-info-update?tabValue=application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Agency information update
                          </Button>
                        </ListItem>
                      )}
                    </AccordionDetails>
                  </Accordion>
                }
              </List>
            </SubMenuWrapper>
          </List>
        )}
        {/* --------accounts management---------------- */}

        {(user?.permissions?.subModules?.includes('Add Beneficiary') ||
          user?.permissions?.subModules?.includes('Create Voucher') ||
          user?.permissions?.subModules?.includes('Create Money Receipt') ||
          user?.permissions?.subModules?.includes('Payment Items')) && (
          <List component={'div'}>
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  expanded={expanded === 'accounts'}
                  onChange={handleChange('accounts')}
                  className='accordion'
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Accounts
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {user?.user_admin_role === 'Accounts' && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/invoices/pending?tabValue=accounts'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          New Member Application
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Add Beneficiary'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/invoices/account?tabValue=accounts'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Add Beneficiary
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Create Voucher'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/invoices/create?tabValue=accounts'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Create Voucher
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Create Money Receipt'
                    ) && (
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/invoices/transactions?tabValue=accounts'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Create Money Receipt
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Payment Items'
                    ) && (
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/invoices/payment-list?tabValue=accounts'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Payment Items
                        </Button>
                      </ListItem>
                    )}
                    {
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/invoices/budget-list?tabValue=accounts'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Budget Management
                        </Button>
                      </ListItem>
                    }
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {(user?.permissions?.subModules?.includes('Create Notice') ||
          user?.permissions?.subModules?.includes('Create Notice Groups')) && (
          <List component={'div'}>
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  expanded={expanded === 'internalNotice'}
                  onChange={handleChange('internalNotice')}
                  className='accordion'
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Internal Notice
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {user?.permissions?.subModules?.includes(
                      'Create Notice'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/internal-notice/notice?tabValue=internalNotice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Create Notice
                        </Button>
                      </ListItem>
                    )}
                    {user?.permissions?.subModules?.includes(
                      'Create Notice'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/internal-notice/email?tabValue=internalNotice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Create Email
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Create Notice Groups'
                    ) && (
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/internal-notice/notice-group?tabValue=internalNotice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Create Groups
                        </Button>
                      </ListItem>
                    )}

                    {/* <ListItem component='div'>
               <Button
                 className='submodule-name'
                 disableRipple
                 component={RouterLink}
                 onClick={closeSidebar}
                 to='/update-applications/address-change-application'
                 startIcon={<TableChartTwoToneIcon />}
               >
                 All Notices
               </Button>
             </ListItem> */}
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* ---public annoucement------------ */}
        {(user?.permissions?.subModules?.includes('Public Notice') ||
          user?.permissions?.subModules?.includes('Meeting Announcement') ||
          user?.permissions?.subModules?.includes('Event Announcement') ||
          user?.permissions?.subModules?.includes(
            'Public Announcement Reports'
          )) && (
          <List component={'div'}>
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  expanded={expanded === 'publicAnnouncement'}
                  onChange={handleChange('publicAnnouncement')}
                  className='accordion'
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Public Announcements
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {user?.permissions?.subModules?.includes(
                      'Public Notice'
                    ) && (
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/public-announcements/id-card?tabValue=publicAnnouncement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Public Notice
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Meeting Announcement'
                    ) && (
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/public-announcements/renewal-application?tabValue=publicAnnouncement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Meeting Announcement
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Event Announcement'
                    ) && (
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/public-announcements/address-change-application?tabValue=publicAnnouncement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event Announcement
                        </Button>
                      </ListItem>
                    )}

                    {user?.permissions?.subModules?.includes(
                      'Public Announcement Reports'
                    ) && (
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/public-announcements/address-change-application?tabValue=publicAnnouncement'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Public Announcement Reports
                        </Button>
                      </ListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}
        {/* training---------------- */}

        {(user?.permissions?.subModules?.includes('Trainee Application') ||
          user?.permissions?.subModules?.includes('Trainee List') ||
          user?.permissions?.subModules?.includes('Batch List') ||
          user?.permissions?.subModules?.includes('Notices') ||
          user?.permissions?.subModules?.includes('Materials') ||
          user?.permissions?.subModules?.includes('Active Courses') ||
          user?.permissions?.subModules?.includes('Proposed Courses') ||
          user?.permissions?.subModules?.includes('Inactive Courses') ||
          user?.permissions?.subModules?.includes('Course Report') ||
          user?.permissions?.subModules?.includes('Active Teachers') ||
          user?.permissions?.subModules?.includes('InactiveTeachers') ||
          user?.permissions?.subModules?.includes('Teacher list') ||
          user?.permissions?.subModules?.includes('Teacher Report')) && (
          <List
            component='div'
            subheader={
              <ListSubheader component='div' disableSticky>
                Training
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'trainee'}
                  onChange={handleChange('trainee')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Trainee
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-trainee/applications?tabValue=trainee'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Trainee Applications
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-trainee/list?tabValue=trainee'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Trainee List
                      </Button>
                    </ListItem>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className='accordion'
                  expanded={expanded === 'traineeBatches'}
                  onChange={handleChange('traineeBatches')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Trainee Batches
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-batch/list?tabValue=traineeBatches'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Batch List
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-batch/notice-list?tabValue=traineeBatches'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Notices
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-batch/material-list?tabValue=traineeBatches'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Materials
                      </Button>
                    </ListItem>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className='accordion'
                  expanded={expanded === 'course'}
                  onChange={handleChange('course')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name' sx={{}}>
                      Course
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-course/list?tabValue=course'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Active Courses
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-course/proposed-course?tabValue=course'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Proposed Courses
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-course/inactive-course?tabValue=course'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Inactive Courses
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-course/course-report?tabValue=course'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Course Report
                      </Button>
                    </ListItem>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className='accordion'
                  expanded={expanded === 'teacher'}
                  onChange={handleChange('teacher')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Teacher
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      <ListItem component='div'>
                        <Button
                          sx={{ paddingLeft: '18px!important' }}
                          disableRipple
                          component={RouterLink}
                          className='submodule-name'
                          onClick={closeSidebar}
                          to='/training-teacher/active-teacher?tabValue=teacher'
                        >
                          Active Teachers
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          sx={{ paddingLeft: '18px!important' }}
                          disableRipple
                          component={RouterLink}
                          className='submodule-name'
                          onClick={closeSidebar}
                          to='/training-teacher/inactive-teacher?tabValue=teacher'
                        >
                          Inactive Teachers
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          sx={{ paddingLeft: '18px!important' }}
                          disableRipple
                          component={RouterLink}
                          className='submodule-name'
                          onClick={closeSidebar}
                          to='/training-teacher/list?tabValue=teacher'
                        >
                          Teacher list
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          sx={{ paddingLeft: '18px!important' }}
                          disableRipple
                          component={RouterLink}
                          className='submodule-name'
                          onClick={closeSidebar}
                          to='/training-teacher/teacher-report?tabValue=teacher'
                        >
                          Teacher Report
                        </Button>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className='accordion'
                  expanded={expanded === 'trainingInvoice'}
                  onChange={handleChange('trainingInvoice')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name' sx={{}}>
                      Training Invoice
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-invoice/list?tabValue=trainingInvoice'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Create Voucher
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-invoice/create-money-receipt?tabValue=trainingInvoice'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Create Moeny Receipt
                      </Button>
                    </ListItem>

                    <ListItem component='div'>
                      <Button
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/training-invoice-item/payement-items?tabValue=trainingInvoice'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        payment Items
                      </Button>
                    </ListItem>

                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/training-invoice/payment-report?tabValue=trainingInvoice'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Payment Reports
                      </Button>
                    </ListItem>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'panel34'}
                  onChange={handleChange('panel34')}
                  className='accordion'
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Service Requests
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/service-request/issue-certificate'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Issue Certificate
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/service-request/books'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Issue Books
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/service-request/trainee-duplicate-certificate'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Issue Duplicate certificate
                      </Button>
                    </ListItem>

                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/service-request/issue-duplicate-id'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Issue Duplicate ID Cards
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/service-request/inactive-duplicate-id'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Inactive ID Cards
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/service-request/service-request-duplicate-id'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Service Reports
                      </Button>
                    </ListItem>
                  </AccordionDetails>
                </Accordion>

                {/* {user?.permissions?.subModules?.includes('trainee-report') && (
          <Accordion
            expanded={expanded === 'panel35'}
            onChange={handleChange('panel35')}
            className='accordion'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='expand-icon' />}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
            >
              <Typography className='accordion-module-name'>
                Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListItem>
                <Button
                  className='submodule-name'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/training-report/list'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Trainee Report
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  className='submodule-name'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/training-report/agency-wise'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Agency Wise Report
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  className='submodule-name'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/training-report/batch-wise'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Batch Wise Report
                </Button>
              </ListItem>
            </AccordionDetails>
          </Accordion>
        )} */}
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* fair module */}

        {(user?.permissions?.subModules?.includes('Exhibitor') ||
          user?.permissions?.subModules?.includes('Fair') ||
          user?.permissions?.subModules?.includes(
            'Inbound Stall Applications'
          ) ||
          user?.permissions?.subModules?.includes('Visitors') ||
          user?.permissions?.subModules?.includes('Events') ||
          user?.permissions?.subModules?.includes('Invitations')) && (
          <List
            component='div'
            subheader={
              <ListSubheader component='div' disableSticky>
                Fair
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                {user?.permissions?.subModules?.includes('Exhibitor') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'exhibitor'}
                    onChange={handleChange('exhibitor')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Exhibitor
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/exhibitor/list?tabValue=exhibitor'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Exhibitor list
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/exhibitor/invoice-list?tabValue=exhibitor'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Exhibitor invoice list
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user?.permissions?.subModules?.includes('Fair') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'fair'}
                    onChange={handleChange('fair')}
                  >
                    {
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Fair
                        </Typography>
                      </AccordionSummary>
                    }

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/fair/list?tabValue=fair'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Fair list
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/fair/stall-list?tabValue=fair'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Stall list
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user?.permissions?.subModules?.includes(
                  'Inbound Stall Applications'
                ) && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'inboundStall'}
                    onChange={handleChange('inboundStall')}
                  >
                    {
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Inbound Stall Applications
                        </Typography>
                      </AccordionSummary>
                    }

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/fair/stall-application?tabValue=inboundStall'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            New Application
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/fair/stall-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Approved Application
                          </Button>
                        </ListItem>

                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/fair/stall-application?tabValue=inboundStall'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Rejected Application
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/fair/stall-application?tabValue=inboundStall'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Application Report
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user?.permissions?.subModules?.includes('Visitors') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel25'}
                    onChange={handleChange('panel25')}
                  >
                    {
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Visitors
                        </Typography>
                      </AccordionSummary>
                    }

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/visitor/list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All visitor list/Live Visitors
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/visitor/list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Visitor Registration
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/visitor/visitor-invoice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Visitor invoices
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/visitor/visitor-invoice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Visitor Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* event  */}
                {user?.permissions?.subModules?.includes('Events') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel26'}
                    onChange={handleChange('panel26')}
                  >
                    {
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Events
                        </Typography>
                      </AccordionSummary>
                    }

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/fair-event/all-event-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event List
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/fair-event/all-event-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event Invoices
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/fair-event/all-event-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* Guest and Speaker */}
                {user?.permissions?.subModules?.includes('Invitations') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel27'}
                    onChange={handleChange('panel27')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Invitations
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/guest-speaker/guest-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Guest Registration
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/guest-speaker/speaker-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Speaker Registration
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/guest-speaker/guest-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Honorarium Invoices
                          </Button>
                        </ListItem>
                      </List>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/guest-speaker/guest-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Guest & Speaker Report
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* Report  */}

        <List component='div'>
          <SubMenuWrapper>
            <List className='accordion-submodule' component='div'>
              <Accordion
                className='accordion'
                expanded={expanded === 'report'}
                onChange={handleChange('report')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Report
                  </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: 0 }}>
                  <List component='div'>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/invoices/transaction-history?tabValue=report'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Transaction History
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/invoices/budget-report?tabValue=report'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Budget Report
                      </Button>
                    </ListItem>

                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/invoices/total-due-advance?tabValue=report'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Total Due Report
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/invoices/profit-loss-report?tabValue=report'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Profit Loss Report
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
          </SubMenuWrapper>
        </List>

        {/* support  */}
        {(user?.permissions?.subModules?.includes('All Tickets') ||
          user?.permissions?.subModules?.includes('Conversations')) && (
          <List
            component='div'
            // subheader={
            //   <ListSubheader component='div' disableSticky>
            //     Support
            //   </ListSubheader>
            // }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'support'}
                  onChange={handleChange('support')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Support
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      {user?.permissions?.subModules?.includes(
                        'All Tickets'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/support/all-ticket?tabValue=support'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            All Tickets
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes(
                        'Conversations'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/support/conversations?tabValue=support'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Conversations
                          </Button>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {
          <List
            component='div'
            // subheader={
            //   <ListSubheader component='div' disableSticky>
            //     Setting
            //   </ListSubheader>
            // }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'settings'}
                  onChange={handleChange('settings')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Settings
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      <ListItem component='div'>
                        <Button
                          sx={{ paddingLeft: '18px!important' }}
                          disableRipple
                          component={RouterLink}
                          className='submodule-name'
                          onClick={closeSidebar}
                          to='/profile/details?tabValue=settings'
                          startIcon={<AccountCircleTwoToneIcon />}
                        >
                          Edit Profile
                        </Button>
                      </ListItem>

                      {/* {user?.permissions?.subModules?.includes('sms') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/address'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Managge Address
                          </Button>
                        </ListItem>
                      )} */}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        }

        {(user?.permissions?.subModules?.includes('Audit Trail') ||
          user?.permissions?.subModules?.includes('Work Log') ||
          user?.permissions?.subModules?.includes('Account Log')) && (
          <List
            component='div'
            // subheader={
            //   <ListSubheader component='div' disableSticky>
            //     Logs
            //   </ListSubheader>
            // }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'logs'}
                  onChange={handleChange('logs')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Logs
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      {user?.permissions?.subModules?.includes(
                        'Audit Trail'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/logs/audit-trail?tabValue=logs'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Access Logs (Audit Trail)
                          </Button>
                        </ListItem>
                      )}

                      {user?.permissions?.subModules?.includes('Work Log') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/logs/work-log?tabValue=logs'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Work Log
                          </Button>
                        </ListItem>
                      )}
                      {user?.permissions?.subModules?.includes(
                        'Account Log'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/logs/account-log?tabValue=logs'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Account Log
                          </Button>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* -------------------admin setting----- */}
        {(user?.permissions?.subModules?.includes('User list') ||
          user?.permissions?.subModules?.includes('Roles') ||
          user?.permissions?.subModules?.includes('Permissions')) && (
          <List
            component='div'
            // subheader={
            //   <ListSubheader component='div' disableSticky>
            //     Admin Settings
            //   </ListSubheader>
            // }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'adminsetting'}
                  onChange={handleChange('adminsetting')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='expand-icon' />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                  >
                    <Typography className='accordion-module-name'>
                      Admin Settings
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      {user?.permissions?.subModules?.includes('User list') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/admin-list/list?tabValue=adminsetting'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            User list
                          </Button>
                        </ListItem>
                      )}
                      {user?.permissions?.subModules?.includes('Roles') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/admin-list/role?tabValue=adminsetting'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Roles
                          </Button>
                        </ListItem>
                      )}
                      {user?.permissions?.subModules?.includes(
                        'Permissions'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/admin-list/permission?tabValue=adminsetting'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Permissions
                          </Button>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenus;
