import { Box, Grid, TextField, Button } from '@mui/material';
import { useState } from 'react';
import Toaster from 'src/Toaster/Toaster';
import {
  IOwnerInput,
  IParticularsOwners,
} from 'src/Types/module/member/UpdateCompanyInfoStateTypes';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/basic-rest/types';

interface IProps {
  setOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  memberId: string;
  refresh: boolean;
}
const particularsOwners: IParticularsOwners[] = [
  {
    no: 0,
    company_owner_name: 'company_owner_name',
    company_owner_designation: 'company_owner_designation',
    company_owner_nid: 'company_owner_nid',
    company_owner_address: 'company_owner_address',
    company_owner_phone: 'company_owner_phone',
    company_owner_email: 'company_owner_email',
    company_owner_photo: 'company_owner_photo',
    company_owner_nid_file: 'company_owner_nid_file',
    company_owner_biodata: 'company_owner_biodata',
    company_owner_passport: 'company_owner_passport',
    company_owner_signature: 'company_owner_signature',
  },
];

const ownerData: IOwnerInput = {
  no: 0,
  company_owner_address: '',
  company_owner_name: '',
  company_owner_designation: '',
  company_owner_nid: '',
  company_owner_phone: '',
};

export const OrganizationOwners = ({
  setOpen,
  setRefresh,
  memberId,
  refresh,
}: IProps) => {
  const [owner, setOwner] = useState<IOwnerInput[]>([ownerData]);
  const [ownerInput, setOwnerInput] =
    useState<IParticularsOwners[]>(particularsOwners);
  const [isLoading, setIsLoading] = useState(false);
  const formData = new FormData();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    formData.append(
      'owners',
      JSON.stringify(
        owner.map((item) => {
          const { no, ...rest } = item;
          return rest;
        })
      )
    );

    try {
      setIsLoading(true);
      const data: IResponse = await HttpClient.post(
        `/api/atab/admin/member-company/member/owner/${memberId}`,
        formData
      );
      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setOpen(false);
        setRefresh(!refresh);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });

      setIsLoading(false);
    }
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
  };

  const addOwnerData = (id: number, field: string, value: string) => {
    const newData = owner.map((item) => {
      if (item.no === id) {
        item[field] = value;
        return item;
      } else {
        return item;
      }
    });

    setOwner(newData);
  };

  const newOwnerAdd = (newInput: number) => {
    setOwnerInput([
      ...ownerInput,
      { ...particularsOwners[0], no: ownerInput.length },
    ]);
    setOwner([
      ...owner,
      {
        no: owner.length,
        company_owner_address: '',
        company_owner_name: '',
        company_owner_designation: '',
        company_owner_nid: '',
        company_owner_phone: '',
      },
    ]);
  };

  const addFileToFormData = (file: any, field: string, id: number) => {
    const fieldName = field + '_' + id;
    formData.append(fieldName, file);
  };

  const removeOwnerInput = (removed: number) => {
    setOwnerInput(ownerInput.filter((item) => item.no !== removed));
    setOwner(owner.filter((item) => item.no !== removed));
  };
  console.log(ownerInput);

  return (
    <Box component='form' onSubmit={handleSubmit} p={2}>
      <Box>
        <Box>
          {ownerInput.map((singleOwner: IParticularsOwners) => {
            return (
              <Box key={singleOwner.no} pt={5}>
                <Box>
                  <Grid container spacing={2} sx={{ position: 'relative' }}>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Box>
                          <label htmlFor=''>
                            Owner's name <span style={{ color: 'red' }}>*</span>
                          </label>
                          <TextField
                            size='small'
                            id='outlined-basic1'
                            placeholder='Enter owner name'
                            variant='outlined'
                            type='text'
                            required
                            fullWidth
                            onChange={(e) =>
                              addOwnerData(
                                singleOwner.no,
                                singleOwner.company_owner_name,
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <label htmlFor=''>
                            Owner's full address{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <TextField
                            size='small'
                            id='outlined-basic2'
                            variant='outlined'
                            placeholder='Enter owner full address'
                            type='text'
                            required
                            fullWidth
                            onChange={(e) =>
                              addOwnerData(
                                singleOwner.no,
                                singleOwner.company_owner_address,
                                e.target.value
                              )
                            }
                            // {...register(
                            //   'member_organization_owner_full_address'
                            // )}
                          />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <label htmlFor=''>
                            Upload Owner's photo{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <TextField
                            size='small'
                            id='outlined-basic3'
                            variant='outlined'
                            type='file'
                            required
                            fullWidth
                            onChange={(e: any) => {
                              addFileToFormData(
                                e.target.files[0],
                                singleOwner.company_owner_photo,
                                singleOwner.no
                              );
                            }}
                          />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <label htmlFor=''>
                            Upload applicant signature{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <TextField
                            size='small'
                            id='outlined-basic4'
                            variant='outlined'
                            required
                            type='file'
                            fullWidth
                            onChange={(e: any) => {
                              addFileToFormData(
                                e.target.files[0],
                                singleOwner.company_owner_signature,
                                singleOwner.no
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <label htmlFor=''>
                          Owner's designation{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <TextField
                          size='small'
                          id='outlined-basic5'
                          variant='outlined'
                          placeholder='Enter owner designation'
                          type='text'
                          required
                          fullWidth
                          onChange={(e) =>
                            addOwnerData(
                              singleOwner.no,
                              singleOwner.company_owner_designation,
                              e.target.value
                            )
                          }
                          // {...register(
                          //   'member_organization_owner_designation'
                          // )}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <label htmlFor=''>
                          Owner's phone number{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <TextField
                          size='small'
                          id='outlined-basic6'
                          placeholder='Enter owner phone number'
                          variant='outlined'
                          type='number'
                          required
                          fullWidth
                          onChange={(e) =>
                            addOwnerData(
                              singleOwner.no,
                              singleOwner.company_owner_phone,
                              e.target.value
                            )
                          }
                          // {...register('member_organization_owner_phone')}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <label htmlFor=''>
                          Owner's Biodata{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <TextField
                          size='small'
                          id='outlined-basic7'
                          variant='outlined'
                          type='file'
                          required
                          fullWidth
                          onChange={(e: any) => {
                            addFileToFormData(
                              e.target.files[0],
                              singleOwner.company_owner_biodata,
                              singleOwner.no
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <label htmlFor=''>
                          {' '}
                          Owner's NID number{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <TextField
                          size='small'
                          id='outlined-basic8'
                          variant='outlined'
                          placeholder='Enter owner nid number'
                          type='text'
                          required
                          fullWidth
                          onChange={(e) =>
                            addOwnerData(
                              singleOwner.no,
                              singleOwner.company_owner_nid,
                              e.target.value
                            )
                          }
                          // {...register('member_organization_owner_nid_no')}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <label htmlFor=''>
                          Upload owner's NID (Both Side)
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <TextField
                          size='small'
                          id='outlined-basic9'
                          variant='outlined'
                          type='file'
                          required
                          fullWidth
                          onChange={(e: any) => {
                            addFileToFormData(
                              e.target.files[0],
                              singleOwner.company_owner_nid_file,
                              singleOwner.no
                            );
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <label htmlFor=''>Upload owner's passport </label>
                        <TextField
                          size='small'
                          id='outlined-basic10'
                          variant='outlined'
                          type='file'
                          fullWidth
                          onChange={(e: any) => {
                            addFileToFormData(
                              e.target.files[0],
                              singleOwner.company_owner_passport,
                              singleOwner.no
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -16,
                        right: 0,
                      }}
                    >
                      {singleOwner.no > 0 ? (
                        <Button
                          variant='outlined'
                          className='add-remove-button'
                          onClick={() => removeOwnerInput(singleOwner.no)}
                          size='small'
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          variant='outlined'
                          className='add-remove-button'
                          onClick={() => newOwnerAdd(singleOwner.no)}
                          size='small'
                        >
                          Add
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};
