import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import SingleStallApplicationUpdateForm from "./SingleStallApplicationUpdateForm";
import { Helmet } from "react-helmet-async";
import { IStallSingleApplication } from "src/Utils/type";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { Link, useNavigate, useParams } from "react-router-dom";

function SingleStallApplication() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const [singleStallInfo, setSingleStallInfo] =
    useState<IStallSingleApplication | null>({} as IStallSingleApplication);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { stallApplicationId } = useParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/fair/stall/get/stall-application/single/${stallApplicationId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setSingleStallInfo(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [stallApplicationId, reload]);

  return !loading ? (
    <>
      <Helmet>
        <title>HAAB - Single stall application information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Container>
            <Card>
              <Box textAlign="end" sx={{ p: 2 }}>
                <Link
                  to={`/exhibitor/list/${singleStallInfo.fair_stall_application_fair_member_id}`}
                >
                  <Button variant="outlined">
                    Go to exhibitor of stall application
                  </Button>
                </Link>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={handleOpen}
                    sx={{ cursor: "pointer", ml: 2 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <CardContent>
                <Box>
                  <Typography
                    textAlign="center"
                    gutterBottom
                    variant="h2"
                    component="div"
                  >
                    Single stall application information
                  </Typography>
                  <Typography
                    textAlign="center"
                    variant="body2"
                    color="text.secondary"
                  >
                    {singleStallInfo?.fair_details}
                  </Typography>
                  <Box sx={{ m: 3 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Stall Application ID:
                        </Typography>
                        {singleStallInfo?.fair_stall_application_id}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Contact name:
                        </Typography>
                        {singleStallInfo?.user_fair_member_contact_name}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Stall name:
                        </Typography>
                        {singleStallInfo?.fair_name}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Stall application status:
                        </Typography>
                        {getStatusLabel(
                          singleStallInfo?.fair_stall_application_status
                        )}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Member status:
                        </Typography>
                        {getStatusLabel(
                          singleStallInfo?.user_fair_member_status
                        )}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Fair status:
                        </Typography>
                        {getStatusLabel(singleStallInfo?.fair_status)}
                      </Grid>
                      {singleStallInfo.user_fair_member_contact_designation && (
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Member designation:
                          </Typography>
                          {
                            singleStallInfo?.user_fair_member_contact_designation
                          }
                        </Grid>
                      )}
                      {singleStallInfo.fair_created_at && (
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Created date:
                          </Typography>
                          {moment(singleStallInfo?.fair_created_at).format(
                            "LL"
                          )}
                        </Grid>
                      )}
                      {singleStallInfo.fair_start_date && (
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Start date:
                          </Typography>
                          {moment(singleStallInfo?.fair_start_date).format(
                            "LL"
                          )}
                        </Grid>
                      )}
                      {singleStallInfo.fair_end_date && (
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            End date:
                          </Typography>
                          {moment(singleStallInfo?.fair_end_date).format("LL")}
                        </Grid>
                      )}

                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Contact number:
                        </Typography>
                        {singleStallInfo?.user_fair_member_contact_number}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Location:
                        </Typography>
                        {singleStallInfo?.fair_location}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Details:
                        </Typography>
                        {singleStallInfo?.fair_details}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Stall application fair ID:
                        </Typography>
                        {singleStallInfo?.fair_stall_application_fair_id}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Stall application fair member ID:
                        </Typography>
                        {singleStallInfo?.fair_stall_application_fair_member_id}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Stall application updated by:
                        </Typography>
                        {singleStallInfo?.fair_stall_application_updated_by}
                      </Grid>

                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Fair member contact designation:
                        </Typography>
                        {singleStallInfo?.user_fair_member_contact_designation}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Fair member contact email:
                        </Typography>
                        {singleStallInfo?.user_fair_member_contact_email}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Fair member contact number:
                        </Typography>
                        {singleStallInfo?.user_fair_member_contact_number}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Fair member email:
                        </Typography>
                        {singleStallInfo?.user_fair_member_email}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle sx={{ p: 4 }} id="responsive-dialog-title">
                <Typography variant="h4">
                  Update stall application information
                </Typography>
              </DialogTitle>
              <DialogContent>
                <SingleStallApplicationUpdateForm
                  details={singleStallInfo}
                  setReload={setReload}
                  handleClose={handleClose}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </Grid>
      </Container>
    </>
  ) : (
    <GlobalLoader />
  );
}

export default SingleStallApplication;
