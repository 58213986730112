import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { muiStyles } from "src/Utils/Styles";
import { Imembers } from "src/Types/module/member/memberTypes";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import Toaster from "src/Toaster/Toaster";

const useStyles = muiStyles;
const MultiSMS = () => {
  const classes = useStyles();
  const { user } = useAuthContext();
  const [allMember, setAllMember] = useState<Imembers[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("all");
  const Toast = Toaster();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setisLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?status=${status}`
        );
        if (data.success) {
          setAllMember(data.data);
          setisLoading(false);
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setisMsgSent(true);

    const body = {
      status: status,
      message: text,
    };

    // try {
    //   const { data } = await axios.post(
    //     `${url}/api/atab/user-member/send/sms/all-or-status?user_admin_id=${user.user_admin_id}`,
    //     body
    //   );
    //   if (data.success) {
    //     Toast.fire({
    //       icon: "success",
    //       title: `Message sent Successfully`,
    //     });
    //     setisMsgSent(false);
    //     setText("");
    //   }
    // } catch (err) {
    //   Toast.fire({
    //     icon: "success",
    //     title: `Message doesn't sent`,
    //   });
    //   setisMsgSent(false);
    // }
  };

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "active",
      name: "Active",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];

  const handleStatusChange = (e: SelectChangeEvent) => {
    setStatus(e.target.value);
  };
  return (
    <CardContent>
      <Box
        component='form'
        onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
        sx={{ p: 2 }}
        className=''
      >
        <FormControl sx={{ width: "120px" }}>
          <InputLabel>Member Status</InputLabel>
          <Select
            value={status}
            sx={{ width: "180px" }}
            onChange={handleStatusChange}
            label='Member status'
            autoWidth
          >
            {statusOptions &&
              statusOptions.map((statusOption) => (
                <MenuItem key={statusOption.id} value={statusOption.id}>
                  {statusOption.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          margin='normal'
          required
          fullWidth
          id='message'
          label='Message '
          autoComplete='message'
          autoFocus
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setText(e.target.value)
          }
          value={text}
          multiline
          minRows={10}
          variant='filled'
          className={classes.inputText}
          InputProps={{ classes }}
        />
        {!isMSgsent ? (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Send message
          </Button>
        ) : (
          <LoadingButton
            loading={isMSgsent}
            fullWidth
            color='secondary'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
            className={classes.signInButton}
            loadingPosition='end'
            variant='contained'
          >
            Loading
          </LoadingButton>
        )}
      </Box>
    </CardContent>
  );
};

export default MultiSMS;
