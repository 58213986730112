import {
  Card,
  CardContent,
  Container,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import BillingInfo from './BillingInfo';
import useTheme from '@mui/material/styles/useTheme';
import EditIcon from '@mui/icons-material/Edit';
import { ISingleEvent } from 'src/Types/module/member/Event';

interface IProps {
  singleEvent: ISingleEvent;
  editMode: boolean;
  handleEditModeChange: any;
}

const CardEvent = ({ singleEvent, editMode, handleEditModeChange }: IProps) => {
  const theme = useTheme();

  return (
    <Container>
      <Card className='invoice_print_form'>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'cneter',
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                gutterBottom
              >
                Event
              </Typography>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pl: 1 }}
                gutterBottom
              >
                # {singleEvent?.member_event_id}
              </Typography>
            </Box>
            <Tooltip title='Edit meeting'>
              <IconButton onClick={handleEditModeChange}>
                <EditIcon
                  sx={{ cursor: 'pointer', color: theme.colors.primary.main }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />
          {singleEvent && (
            <BillingInfo
              editMode={editMode}
              handleEditModeChange={handleEditModeChange}
              singleEvent={singleEvent}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardEvent;
