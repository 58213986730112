import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { useState } from 'react';

// import { imgUrl } from "src/Helpers/utils/Constant";
// import { IAuditAndTrail } from "src/Types/module/auditAndTrail/auditAndTrail";

const columnStyles: any = {
  0: {
    // First column
    cellWidth: 100, // Set width for the first column
  },
  1: {
    // Second column
    cellWidth: 300, // Set width for the second column
  },
  2: {
    // Third column
    cellWidth: 130, // Set width for the third column
  },
  // Add more columns as needed...
};

const AuditTrailPdfGenerator = ({ getDownloadData }: any) => {
  const generatePdf = async () => {
    const data = await getDownloadData();
    const pdf = new jsPDF('p', 'pt');
    pdf
      .setFontSize(22)
      .text('HAAB Audit Trail Reports', 300, 30, { align: 'center' });
    pdf.setFontSize(11).text(`Total: ${data?.data?.length}`, 40, 50);
    pdf
      .setFontSize(11)
      .text(`Date: ${new Date().toDateString()}`, 555, 50, { align: 'right' });

    const rows = data?.data?.map((obj: any) =>
      Object.values([
        obj.user_admin_name,
        obj.audit_trail_details,
        moment(obj.audit_trail_created_at).format('DD/MM/YYYY, h:mm:ss a'),
      ])
    );

    autoTable(pdf, {
      showHead: 'firstPage',
      head: [['Admin Name', 'Details', 'Date']],
      body: rows,
      columnStyles: columnStyles,
      headStyles: { halign: 'center', fillColor: [0, 0, 0] },
      startY: 60,
      theme: 'grid',
      willDrawPage: (data) => {
        // note: this function for footer
        pdf.setFontSize(10);
        pdf.text(
          'Page ' + data.pageNumber,
          data.settings.margin.left,
          pdf.internal.pageSize.height - 20
        );
      },
    });
    pdf.save('audit_trail_report_generate.pdf');
  };

  return (
    <Button onClick={generatePdf} variant='outlined' size='small'>
      Download PDF
    </Button>
  );
};

export default AuditTrailPdfGenerator;
