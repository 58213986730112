import { useEffect, useState } from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import Toaster from 'src/Toaster/Toaster';
import { ICompanyTinBin } from 'src/Types/module/member/CompanyInformation';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';

interface IProps {
  tinBin: ICompanyTinBin;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
}

const UpdateTinBin = ({ tinBin, memberId, setRefresh, setOpen }: IProps) => {
  const {
    updateData,
    loading: isLoading,
    success,
  } = UpdateDataApi(
    `/api/atab/admin/member-company/member/tin-bin-certificate/${memberId}`
  );
  const { register, handleSubmit } = useForm();
  const [info, setInfo] = useState({
    tin_number: '',
    tin_year: '',
    bin_year: '',
    bin_file: '',
    tin_file: '',
    bin_number: '',
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);

    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      Toaster().fire({
        icon: 'error',
        title: 'Please Change any field',
      });
    } else {
      await updateData(formData);
    }
  };

  useEffect(() => {
    if (success) {
      setRefresh(true);
      setOpen(false);
    }
  }, [success]);
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                defaultValue={tinBin.tin_number}
                label='TIN number'
                type='number'
                fullWidth
                sx={{ pb: 2 }}
                {...register('tin_number')}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    tin_number: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                defaultValue={tinBin.tin_year}
                label='TIN year'
                type='number'
                fullWidth
                sx={{ pb: 2 }}
                {...register('tin_year')}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    tin_year: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Upload TIN pdf'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ pb: 2 }}
                {...register('tin_file')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    tin_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='BIN number'
                type='text'
                defaultValue={tinBin.bin_number}
                fullWidth
                sx={{ pb: 2 }}
                {...register('bin_number')}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    bin_number: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='BIN year'
                type='text'
                defaultValue={tinBin.bin_year}
                fullWidth
                sx={{ pb: 2 }}
                {...register('bin_year')}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    bin_year: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Upload BIN pdf'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                {...register('bin_file')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    bin_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateTinBin;
