import { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import Toaster from 'src/Toaster/Toaster';
import PostDataApis from 'src/common/FetchData/PostDataApis';
import CommSelectFieldSearchmemberlistbyLicenseAndname from 'src/common/CommSelectFieldSearchmemberlistbyLicenseAndname';

interface IProps {
  setOpen?: (value: boolean) => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateGroup = ({ setOpen, setRefresh }: IProps) => {
  const {
    postData,
    success,
    loading: isLoading,
  } = PostDataApis('/api/member/group');
  useEffect(() => {
    if (success) {
      setRefresh(true);
      setOpen(false);
    }
  }, [success]);
  const [inputData, setInputData] = useState('');
  const [membersId, setMembersId] = useState<string[]>([]);
  const onSubmit = async () => {
    const body = {
      name: inputData,
      group_members: membersId,
    };

    if (body.name && body.group_members.length) {
      postData(body);
    } else {
      Toaster().fire({
        icon: 'error',
        title: 'Please fillup the field',
      });
    }
  };

  return (
    <Box
      sx={{
        paddingRight: 1,
        paddingLeft: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            required
            size='small'
            id='outlined-basic'
            placeholder='Enter Group Name'
            label='Enter Group Name'
            onChange={(e) => setInputData(e.target.value)}
            variant='outlined'
            type='text'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} mt={2}>
          <CommSelectFieldSearchmemberlistbyLicenseAndname
            multiple
            seTMemberId={setMembersId}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Button
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          size='small'
        >
          Loading ...
        </Button>
      ) : (
        <Button
          onClick={onSubmit}
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          size='small'
        >
          Create Group
        </Button>
      )}
    </Box>
  );
};

export default CreateGroup;
