import {
  TableCell,
  TableRow,
  useTheme,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material';
import { IBudgetListType } from '../type/budgetType';
import dayjs from 'dayjs';
import EditBudgetManagement from './EditBudgetManagement';
import { useState } from 'react';
import { getStatusLabel } from 'src/Utils/Combined';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
type IProps = {
  budgetData: IBudgetListType;
  index: number;
  refetchData: () => Promise<void>;
};
const BudgetListTableRow = ({ budgetData, index, refetchData }: IProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {budgetData && (
        <TableRow hover key={budgetData.id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            {budgetData.budget_date &&
              dayjs(budgetData.budget_date).format('DD-MM-YYYY')}
          </TableCell>
          <TableCell>{budgetData.budget_no}</TableCell>
          <TableCell>{budgetData.name}</TableCell>
          <TableCell>
            {budgetData?.status && getStatusLabel(budgetData?.status)}
          </TableCell>
          <TableCell>{budgetData.total_amount}</TableCell>
          <TableCell>{budgetData.current_forward}</TableCell>
          <TableCell>
            <Box>
              {budgetData?.final_approved_by ? (
                <Button disabled variant='text' color='primary'>
                  <EditIcon fontSize='small' />
                </Button>
              ) : (
                <EditBudgetManagement
                  budgetId={budgetData.id}
                  refetchData={refetchData}
                />
              )}

              <Link
                to={`/invoices/budget-list/${budgetData.id}?tabValue=accounts`}
              >
                <Visibility color='success' fontSize='small' />
              </Link>
            </Box>
          </TableCell>
        </TableRow>
      )}

      {/* update modal  */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          <Typography variant='h4'>Update money receipt item</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <UpdatePaymentableItem
              details={budgetData}
              handleClose={() => setOpen(false)}
              setReload={setReload}
            /> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BudgetListTableRow;
