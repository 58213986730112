import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Chip,
  Autocomplete,
  Grid,
  TextField,
} from '@mui/material';
import axios from 'axios';
import Toaster from '../../../../Toaster/Toaster';
import { url } from '../../../../Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import { muiStyles } from '../../../../Utils/Styles';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ISpeaker } from 'src/Utils/type';

const useStyles = muiStyles;
interface IProps {
  handleClose: Function;
  setAllEvents: Function;
}

const CreateEvent = ({ handleClose, setAllEvents }: IProps) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [allSpeakers, setAllSpeakers] = useState<ISpeaker[]>([]);
  const [speakerId, setSpeakerId] = useState<string[]>([]);
  const currentDate = new Date();
  const [timeValue, setTimeValue] = useState({
    event_start_time: dayjs(currentDate),
    event_end_time: dayjs(currentDate),
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${url}/api/fair/get/speaker/all/all`);
      if (data.success) {
        setAllSpeakers(data.data);
      }
    })();
  }, []);
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    inputs.event_created_by = user.user_admin_id;
    inputs.event_speaker = JSON.stringify(speakerId);
    inputs.event_start_time = timeValue.event_start_time
      .toISOString()
      .slice(11, 19);
    inputs.event_end_time = timeValue.event_end_time
      .toISOString()
      .slice(11, 19);
    try {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        if (typeof inputs[key] === 'object') {
          bodyFormData.append(key, inputs[key][0]);
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }

      const { data } = await axios.post(
        `${url}/api/fair/event/for-admin/create`,
        bodyFormData
      );

      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });
        setAllEvents((prev) => [
          {
            event_id: data.data.fair_event_id,
            event_title: inputs.event_title,
            event_venu: inputs.event_venu,
            event_date: inputs.event_date,
            event_photo: data.data.event_photo,
          },
          ...prev,
        ]);
        setTimeValue({
          event_start_time: dayjs(currentDate),
          event_end_time: dayjs(currentDate),
        });
        reset();
        setLoading(false);
        handleClose();
      } else {
        Toast.fire({
          icon: 'error',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Fair - Create Event</title>
      </Helmet>

      <Box sx={{ p: 2 }} className=''>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event title'
                label='Enter event title'
                {...register('event_title')}
                type='text'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event type'
                label='Enter event type'
                {...register('event_type')}
                type='text'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event venue'
                label='Enter event venue '
                {...register('event_venu')}
                type='text'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event date'
                label='Enter event date'
                InputLabelProps={{ shrink: true }}
                {...register('event_date')}
                type='date'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4} mt={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label='Start time'
                  value={timeValue.event_start_time}
                  onChange={(value: Dayjs | null) =>
                    setTimeValue({
                      ...timeValue,
                      event_start_time: value,
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={4} mt={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label='End time'
                  value={timeValue.event_end_time}
                  onChange={(value: Dayjs | null) =>
                    setTimeValue({
                      ...timeValue,
                      event_end_time: value,
                    })
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin='normal'
                fullWidth
                id='file'
                label='Choose event Photo'
                InputLabelProps={{ shrink: true }}
                {...register('event_photo')}
                autoComplete='file'
                type='file'
                autoFocus
              />

              <Autocomplete
                multiple
                id='tags-filled'
                options={allSpeakers as ISpeaker[]}
                freeSolo
                getOptionLabel={(option: ISpeaker) => option.guest_speaker_name}
                onChange={(event: any, newValue: any) =>
                  setSpeakerId(
                    newValue?.map((item: ISpeaker) => item.guest_speaker_id)
                  )
                }
                renderTags={(value: readonly ISpeaker[], getTagProps) =>
                  value?.map((option: ISpeaker, index: number) => (
                    <Chip
                      variant='filled'
                      label={option.guest_speaker_name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select speaker'
                    placeholder='select speaker..'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin='normal'
                fullWidth
                multiline
                className={classes.inputText}
                InputProps={{ classes }}
                rows={4}
                id='event description'
                label='Enter event description'
                {...register('event_description')}
                type='text'
                autoFocus
              />
            </Grid>
          </Grid>
          {loading ? (
            <Button
              disabled
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Event
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateEvent;
