import { useState } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import Toaster from 'src/Toaster/Toaster';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';

interface IDate {
  certificate_expiry_date: string;
  certificate_issue_date: string;
}

interface Props {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const CicilAviationTourusm = ({
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: Props) => {
  const { register, handleSubmit } = useForm();
  const [date, setDate] = useState<IDate>({
    certificate_expiry_date: '',
    certificate_issue_date: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...date };

    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    try {
      setIsLoading(true);
      const data: IResponse = await HttpClient.post(
        `/api/atab/admin/member-company/civil/aviation/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor=''>
              Civil aviation tourism certificate number{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              placeholder='Enter civil aviation tourism certificate number'
              fullWidth
              {...register('certificate_number')}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <label htmlFor=''>
              Civil aviation tourism certificate{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              {...register('certificate_file')}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor=''>
              Civil aviation tourism issue date
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='date'
              required
              type='date'
              onChange={(e) =>
                setDate({
                  ...date,
                  certificate_issue_date: e.target.value,
                })
              }
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor=''>
              Civil aviation tourism certificate expiry date
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='date'
              required
              type='date'
              onChange={(e) =>
                setDate({
                  ...date,
                  certificate_expiry_date: e.target.value,
                })
              }
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CicilAviationTourusm;
