import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  IgetAllConversation,
  IgetSupportConversation,
  IsingleSupportConversation,
} from 'src/Types/module/conversations/conversationTypes';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useSearchParams } from 'react-router-dom';
import ComplainMemberList from './ComplainMemberList';

function ComplainMember() {
  const [singleSuppportConversations, setSingleSupportConversations] =
    useState<IsingleSupportConversation>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const [query] = useSearchParams();
  const id = query.get('id');

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const { data } = await axios.get<IgetAllConversation>(
          `${url}/api/atab/support-conversation/get/all/for-admin`
        );

        if (data.success) {
          if (id) {
            const { data: singleSupportConversationMember } =
              await axios.get<IgetSupportConversation>(
                `${url}/api/atab/support-conversation/get/single/for-admin/${id}`
              );

            if (data.success) {
              setSingleSupportConversations(
                singleSupportConversationMember.data
              );
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            const { data: singleSupportConversationMember } =
              await axios.get<IgetSupportConversation>(
                `${url}/api/atab/support-conversation/get/single/for-admin/${data.data[0].support_conversation_id}`
              );

            if (data.success) {
              setSingleSupportConversations(
                singleSupportConversationMember.data
              );
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>HAAB - Complain Members</title>
      </Helmet>
      <ComplainMemberList status='active' title={'Complain Members'} />
    </>
  );
}

export default ComplainMember;
