import {
  Box,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IViewBudgetListType } from '../../type/budgetType';
import { TableLoader } from 'src/Utils/TableLoader';
import dayjs from 'dayjs';
import ViewAllForwardingNote from '../ViewAllForwardingNote';
import UploadDucumentAndForward from './UploadDucumentAndForward';
import PdfAndImageView from 'src/common/PdfAndImageView';
import BudgetApprovedForFinal from './BudgetApprovedForFinal';
import TableNoDataMessage from 'src/common/TableNoDataMessage';
import EditBudgetManagement from '../EditBudgetManagement';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import img from '../../images/approved1.png';
type IProps = {
  response: IViewBudgetListType;
  loading: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
};
const BudgetFinalPhase = ({ response, loading, setRefetch }: IProps) => {
  const { user } = useAuthContext();
  return (
    <Box
      sx={{
        p: 2,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: { xs: '10%', sm: '15%', md: '20%' }, // Adjust at each breakpoint
          left: { xs: '20%', sm: '25%', md: '30%' },
          width: '100%',
          maxWidth: {
            xs: '60%',
            sm: '40%',
            md: '52%',
            lg: '52%',
            xl: '35%',
          },
          height: { xs: '60%', sm: '70%', md: '80%', xl: '70%' },
          backgroundImage: response.final_approved_by ? `url(${img})` : '',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          opacity: 0.06, // Adjust opacity as needed
          zIndex: 10,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4} textAlign={'center'}>
          <Chip
            label={
              <h3>
                Final {response?.final_approved_by ? 'Approved' : ''} Budget
              </h3>
            }
            color='primary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
      <Box>
        <Box fontSize={15} fontWeight='bold' mb={1}>
          Final Budget Items List :{' '}
          {!response?.final_approved_by &&
            user?.user_admin_role === 'Accounts' && (
              <EditBudgetManagement
                approved_by={response?.approved_by}
                budgetId={response?.id}
                setRefetch={setRefetch}
                showButton
              />
            )}
        </Box>

        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small' align='center'>
                  SL no
                </TableCell>
                <TableCell align='center' size='small'>
                  Date
                </TableCell>
                <TableCell align='center' size='small'>
                  Budget Items
                </TableCell>

                <TableCell align='center' size='small'>
                  Initial Amount
                </TableCell>
                <TableCell align='center' size='small'>
                  Final Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {response?.budgetItems?.length ? (
                    <>
                      {response?.budgetItems?.map((sPayment, index) => (
                        <>
                          <TableRow
                            key={sPayment.id}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align='center' size='small'>
                              {index + 1}
                            </TableCell>

                            <TableCell align='center' size='small'>
                              {sPayment?.created_at &&
                                dayjs(sPayment?.created_at).format(
                                  'DD-MM-YYYY'
                                )}
                            </TableCell>

                            <TableCell align='center' size='small'>
                              {sPayment.name}
                            </TableCell>
                            <TableCell align='center' size='small'>
                              {Number(sPayment.initial_amount)}
                            </TableCell>
                            <TableCell align='center' size='small'>
                              {Number(sPayment.amount)}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container rowGap={1}>
          {/* 1st column--------- */}
          <Grid item xs={6}>
            <Box
              fontSize={15}
              fontWeight='bold'
              display={'flex'}
              justifyContent={'start'}
              mt={5}
            >
              Others Info :
            </Box>
            <Grid item xs={8}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Final Total Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                <Chip
                  size='small'
                  label={response?.total_amount}
                  color='primary'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Budget No.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.budget_no}
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Budget For.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.name}
              </Grid>

              {response.status === 'approved' && (
                <>
                  {' '}
                  <Grid item xs={6}>
                    <Typography variant='h5' component='h2'>
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    :{' '}
                    {response?.status === 'approved' && (
                      <Chip
                        size='small'
                        label={'Final Approved'}
                        color='primary'
                        variant='outlined'
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h5' component='h2'>
                      Approved By
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    :{' '}
                    <Chip
                      size='small'
                      label={response?.final_approved_by}
                      color='primary'
                      variant='outlined'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {/* 2nd column--------- */}
          {response?.status === 'approved' && (
            <>
              <Grid item xs={6}>
                <>
                  <Box
                    fontSize={15}
                    fontWeight='bold'
                    display={'flex'}
                    justifyContent={'start'}
                    mt={5}
                  >
                    Payment Info :
                  </Box>
                  <Grid item xs={8}>
                    <Divider sx={{ my: 1 }} />
                  </Grid>
                </>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant='h5' component='h2'>
                      Account Name.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    : {response?.account_name}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h5' component='h2'>
                      Account No.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    : {response?.account_no}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h5' component='h2'>
                      Payment Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    : {response?.method}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Grid container rowGap={1}>
        {/* 1st column--------- */}
        {response?.allFiles?.filter((item) => item).length === 0 ? (
          <Grid item>Not Uploaded Any Voucher Yet</Grid>
        ) : (
          <Grid item xs={6} sx={{ zIndex: 20 }}>
            <>
              <Box
                fontSize={15}
                fontWeight='bold'
                display='flex'
                justifyContent='start'
                mt={5}
              >
                Voucher Information:
              </Box>
              <Grid container gap={1}>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                {response?.allFiles?.map(
                  (
                    item,
                    index // Ensure no falsy values are included
                  ) => (
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      key={index} // Add key to avoid React warnings
                      sx={{ border: '1px solid gray', borderRadius: '5px' }}
                    >
                      <PdfAndImageView file={item} previewSmallSize='100%' />
                    </Grid>
                  )
                )}
              </Grid>
            </>
          </Grid>
        )}
      </Grid>

      <Grid container mt={5} justifyContent={'space-between'}>
        {!response?.final_approved_by && (
          <Grid item>
            <Grid container>
              <UploadDucumentAndForward
                setRefetch={setRefetch}
                currentForward={response?.current_forward}
              />
              <ViewAllForwardingNote response={response?.forward} />
            </Grid>
          </Grid>
        )}
        {(user?.user_admin_role === 'Secretary' ||
          user.user_admin_role === 'Finance Secretary') && (
          <Grid item>
            <BudgetApprovedForFinal
              budgetStatus={response?.status}
              setRefetch={setRefetch}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BudgetFinalPhase;
