import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  ChangeEvent,
} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";

import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import SuspenseLoader from "src/components/SuspenseLoader";
import { url } from "src/Helpers/utils/Constant";

interface IInputValue {
  materials_title: string;
  batch_id: any;
  batch_materials_file: any;
}

interface IProp {
  handleClose: Function;
  id: any;
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}

const CreateMaterialOfSingleBatch = ({
  handleClose,
  id,
  refresh,
  setRefresh,
}: IProp) => {
  const [values, setValues] = useState<IInputValue>({
    materials_title: "",
    batch_id: id,
    batch_materials_file: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const onSubmit = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();

    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    try {
      setLoadingBtn(true);
      const res = await axios.post(
        `${url}/api/training/batch/create/materials`,
        formData
      );
      if (res.status === 200) {
        setLoadingBtn(false);
        Toaster().fire({
          icon: "success",
          title: "Material created successfully!",
        });
        handleClose();
        setRefresh(!refresh);
      }
    } catch (error) {}
  };

  return (
    <div>
      <Helmet>
        <title>HAAB - Create Material</title>
      </Helmet>

      {loading ? (
        <SuspenseLoader />
      ) : (
        <CardContent>
          <Box onSubmit={onSubmit} component="form" sx={{ p: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} lg={6} item sx={{ mt: 1.7 }}>
                <TextField
                  required
                  id="name"
                  value={values.materials_title}
                  label="Material Title"
                  onChange={(newValue) => {
                    setValues({
                      ...values,
                      materials_title: newValue.target.value,
                    });
                  }}
                  type="text"
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid xs={12} md={6} lg={6} item>
                <TextField
                  margin="normal"
                  required
                  id="outlined-required"
                  label="Choose Material File"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValues({
                      ...values,
                      batch_materials_file: e.target.files[0],
                    })
                  }
                  autoComplete="file"
                  type="file"
                />
              </Grid>
            </Grid>
            {loadingBtn ? (
              <Button
                disabled
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Loading...
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create Material
              </Button>
            )}
          </Box>
        </CardContent>
      )}
    </div>
  );
};

export default CreateMaterialOfSingleBatch;
