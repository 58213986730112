import React, { useEffect, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateMoneyReceipt from '../components/CreateMoneyReceipt';
import MoneyReceiptTableRow from './MoneyReceiptTableRow';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IMoneyReceiuptList } from '../types/vouchersTypes';
import SearchTableListByLicense from 'src/common/CommonSearchTextFieldWithDebounce';
import AddIcon from '@mui/icons-material/Add';
const MoneyReceiptEntry = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<{
    page: string | number;
    listType: string | number;
    limit: string | number;
  }>({
    listType: '0',
    page: 1,
    limit: 30,
  });
  const { response, loading, total, getData } = GetDataApi<
    IMoneyReceiuptList[]
  >(
    `/api/atab/money-receipt?status=${
      filter.listType === 'all' ? '' : filter.listType
    }&limit=${filter.limit}&page=${filter.page}&searchParams=${search}`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter, search]);
  const [open, setOpen] = useState(false);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Grid
                  container
                  spacing={2} // Adds consistent spacing between items
                  justifyContent='space-between'
                  alignItems='center'
                >
                  {/* Title */}
                  <Grid item xs={12} md={6} xl={4}>
                    <h2 style={{ margin: 0 }}>Money Receipt List</h2>
                  </Grid>

                  {/* Controls Section */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    container
                    spacing={1} // Adds space between child items
                    justifyContent='flex-end'
                  >
                    {/* Search Input */}
                    <Grid item xs={12} sm={6} md={4}>
                      <SearchTableListByLicense onSearch={setSearch} />
                    </Grid>

                    {/* Select Dropdown */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Select
                        size='small'
                        fullWidth
                        variant='outlined'
                        placeholder='Payment type'
                        defaultValue={'0'}
                        sx={{ padding: 0, height: 36 }}
                        onChange={(e) =>
                          setFilter({ ...filter, listType: e.target.value })
                        }
                      >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='1'>Approved</MenuItem>
                        <MenuItem value='0'>Pending</MenuItem>
                      </Select>
                    </Grid>

                    {/* Create Voucher Button */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Button
                        onClick={() => setOpen(true)}
                        variant='contained'
                        startIcon={<AddIcon />}
                        size='small'
                        fullWidth
                        sx={{ padding: 0, height: 36 }}
                      >
                        Create Money Receipt
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SL No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>License No.</TableCell>
                      <TableCell>Money Receipt No</TableCell>
                      <TableCell>Voucher No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Total Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : response?.length ? (
                      <>
                        {response?.map((sInvoice, index) => (
                          <MoneyReceiptTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                            index={index}
                            getData={getData}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total as number}
                  onPageChange={(_event: any, newPage: number) =>
                    setFilter({ ...filter, page: newPage })
                  }
                  onRowsPerPageChange={(event) =>
                    // setLimit(parseInt(event.target.value))
                    setFilter({ ...filter, limit: event.target.value })
                  }
                  page={Number(filter.page) && Number(filter.page)}
                  rowsPerPage={Number(filter.limit)}
                  rowsPerPageOptions={[30, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: 'Add Money Receipt' }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateMoneyReceipt refetchData={getData} setOpen={setOpen} />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MoneyReceiptEntry;
