import { Imembers } from 'src/Types/module/member/memberTypes';
import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import { useState } from 'react';
import GlobalLoader from '../SuspenseLoader/GlobalLoader';
// import { imgUrl } from "src/Helpers/utils/Constant";

interface Props {
  getDownloadData: () => Promise<{
    total: number;
    data: Imembers[];
  }>;
}

const PdfGenerator = ({ getDownloadData }: Props) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    var doc = new jsPDF('p', 'pt');
    setLoading(true);
    const data = await getDownloadData();

    const ROWS_PER_PAGE = 25;
    const totalPages = Math.ceil(data.total / ROWS_PER_PAGE);

    for (let page = 1; page <= totalPages; page++) {
      doc.setFontSize(22).text('HAAB Member', 300, 25, { align: 'center' });
      doc.setFontSize(12).text(`Total: ${data.total}`, 30, 40);
      doc
        .setFontSize(12)
        .text(`Date:${new Date().toDateString()}`, 570, 40, { align: 'right' });

      doc
        .setFontSize(6)
        // .text("User ID", 15, 100)
        .text('Membership No', 55, 100)
        .text('Agency Name & Address', 120, 100)
        .text('Name of Representative & Designation', 225, 100)
        .text('TIN NO', 350, 100)
        // .text("Photo", 310, 100)
        .text('Contact No & Email', 400, 100);
      // .text("Phone", 480, 100)
      // .text("Zone", 540, 100);

      doc.setFontSize(8);
      const startIndex = (page - 1) * ROWS_PER_PAGE;
      const endIndex = Math.min(startIndex + ROWS_PER_PAGE, data.total);
      let j = 0;
      for (let i = startIndex; i < endIndex; i++) {
        const position = 105 + (j + 1) * 20;
        j++;
        doc
          .setFontSize(6)
          // .text(data.data[i].user_member_id.toString(), 15, position)
          .text(
            `MN-${data.data[i]?.user_member_id_card_number || ''}`,
            55,
            position
          )
          .text(
            `${data.data[i].user_member_company_name} & ${data.data[i].member_company_address}`,
            120,
            position,
            {
              maxWidth: 100,
              align: 'left',
            }
          )
          .text(
            `${data.data[i].user_member_representative_name}& ${data.data[i].user_member_representative_designation}`,
            225,
            position,
            {
              maxWidth: 80,
              align: 'left',
            }
          )
          .text(data.data[i].company_tin_number || '', 340, position)

          .text(
            `${data.data[i].user_member_phone} & ${data.data[i].user_member_email}`,
            400,
            position
          );

        doc.setFontSize(10).text(`Page ${page} of ${totalPages}`, 230, 800, {
          align: 'left',
        });
      }
      if (page < totalPages) {
        doc.addPage();
      }
    }
    setLoading(false);
    doc.save('member_list.pdf');
  };

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={generatePdf} variant='outlined' size='small'>
          Download pdf
        </Button>
      )}
    </>
  );
};

export default PdfGenerator;
