import { useRef, useState, useEffect } from "react";
import { TableRow, TableCell, Tooltip, IconButton, Box } from "@mui/material";
import {
  IMoneyReceipt,
  IResponseSingleMoneyReceipt,
  singleMoneyReceipt,
} from "src/Types/module/common/commonTypes";
import moment from "moment";
import useTheme from "@mui/material/styles/useTheme";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import PrintSingleMoneyReceipt from "./PrintInformationForm/PrintSingleMoneyReceipt/PrintSingleMoneyReceipt";
import { HttpClient } from "src/Helpers/http/http";

const ShowMoneyReceipt = ({ details }: { details: IMoneyReceipt }) => {
  const theme = useTheme();
  const [singleMoneyReceipt, setSingleMoneyReceipt] =
    useState<singleMoneyReceipt>({} as singleMoneyReceipt);
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const singleTransactionPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Single_transaction_information `,
  });

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data: IResponseSingleMoneyReceipt = await HttpClient.get(
          `/api/atab/money-receipt/${details.id}`
        );
        if (data.success) {
          setSingleMoneyReceipt(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [details.id]);
  return (
    <>
      <TableRow hover>
        <TableCell>{details.money_receipt_no}</TableCell>
        <TableCell>{details.total_collected_amount}</TableCell>
        <TableCell>{moment(details.created_at).format("DD-MM-YYYY")}</TableCell>
        <TableCell>{details.user_member_company_name}</TableCell>

        <TableCell>
          <Tooltip title="Print" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              onClick={singleTransactionPrint}
              color="inherit"
              size="small"
            >
              <PrintIcon
                sx={{
                  cursor: "pointer",
                  color: theme.colors.primary.main,
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <Box display={"none"}>
        <PrintSingleMoneyReceipt
          componentRef={componentRef}
          singleMoneyReceipt={singleMoneyReceipt}
        />
      </Box>
    </>
  );
};

export default ShowMoneyReceipt;
