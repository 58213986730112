import {
  Card,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import BodyContainer from 'src/common/BodyContainer';
import GetDataApi from 'src/common/FetchData/GetDataApi';

const ProfitLossReport = () => {
  const { response, setRefetch, loading, total, getData } = GetDataApi<{
    totalServices: number;
    totalBudget: number;
    profitLoss: number;
  }>(`/api/atab/new-report/profit-and-loss-report`);

  console.log(response);
  return (
    <BodyContainer>
      <Card>
        <CardHeader title='Profit Loss Report'></CardHeader>
        <TableContainer component={Paper}>
          <Table aria-label='custom pagination table'>
            <TableBody>
              <TableRow>
                <TableCell>Total Services</TableCell>
                <TableCell>{response?.totalServices} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Budget</TableCell>
                <TableCell>{response?.totalBudget} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ProfitLoss</TableCell>
                <TableCell>{response?.profitLoss} </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </BodyContainer>
  );
};

export default ProfitLossReport;
