import { IsingleMemberMeeting } from "src/Types/module/meeting/singleMemberMeeting";
import { getStatusLabel } from "src/Utils/Combined";
import MeetingUpdateForm from "./MeetingUpdateForm";
import { imgUrl } from "src/Helpers/utils/Constant";
import { Grid, Typography } from "@mui/material";
import MeetingItems from "./MeetingItems";
import { Box } from "@mui/system";
import moment from "moment";

const BillingInfo = ({
  singleInvoiceDetails,
  editMode,
  handleEditModeChange,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
  editMode: boolean;
  handleEditModeChange: any;
}) => {
  return (
    <div>
      <Box sx={{ mt: 3 }}>
        {!editMode ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Title:
              </Typography>
              {singleInvoiceDetails?.member_meeting_title}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Place:
              </Typography>
              {singleInvoiceDetails?.member_meeting_place}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Creator:
              </Typography>
              {singleInvoiceDetails?.meeting_created_by_name}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Status:
              </Typography>

              {getStatusLabel(
                singleInvoiceDetails?.member_meeting_status.toString()
              )}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Date:
              </Typography>
              {moment(singleInvoiceDetails?.member_meeting_date).format("LL")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting time:
              </Typography>
              {moment(
                singleInvoiceDetails?.member_meeting_time,
                "HH:mm"
              ).format("hh:mm A")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting create date:
              </Typography>
              {moment(singleInvoiceDetails?.member_meeting_created_at).format(
                "LL"
              )}
            </Grid>
            {singleInvoiceDetails?.meeting_updated_by_id && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting uptated by ID:
                </Typography>
                {singleInvoiceDetails?.meeting_updated_by_id}
              </Grid>
            )}

            {singleInvoiceDetails?.meeting_updated_by_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting updated by:
                </Typography>
                {singleInvoiceDetails?.meeting_updated_by_name}
              </Grid>
            )}

            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Image:
              </Typography>
              <a
                href={`${imgUrl}/${singleInvoiceDetails?.member_meeting_img}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {singleInvoiceDetails?.member_meeting_img}
              </a>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <a
                href={`${imgUrl}/${singleInvoiceDetails?.member_meeting_qr_code_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download QR Code
              </a>
            </Grid>
            {singleInvoiceDetails.member_meeting_remark && (
              <Grid item xs={2} sm={4} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting Remark:
                </Typography>
                {singleInvoiceDetails?.member_meeting_remark}
              </Grid>
            )}
            <Grid item xs={2} sm={4} md={6}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting details:
              </Typography>
              {singleInvoiceDetails?.member_meeting_details}
            </Grid>
          </Grid>
        ) : (
          <MeetingUpdateForm
            handleEditModeChange={handleEditModeChange}
            singleInvoiceDetails={singleInvoiceDetails}
          />
        )}

        {/* Form input  */}

        <MeetingItems singleInvoiceDetails={singleInvoiceDetails} />
      </Box>
    </div>
  );
};

export default BillingInfo;
