import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PostDataApis from 'src/common/FetchData/PostDataApis';

const CreateAccount = ({
  refetch,
  closeModal,
}: {
  refetch: () => Promise<void>;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { loading, postData, success } = PostDataApis(
    '/api/atab/account',
    'beneficiary-list'
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<any>();
  const account_type = watch('account_type');
  useEffect(() => {
    if (success) {
      refetch();
      closeModal(false);
    }
  }, [success]);

  const onSubmit = (data: any) => {
    postData(data);
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        padding: '20px',
        bgcolor: 'background.paper',
        boxShadow: 3,
      }}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Payment Type</InputLabel>
            <Select
              fullWidth
              label='Payment Type'
              variant='outlined'
              size='small'
              placeholder='Account Type'
              {...register('account_type', {
                required: 'Account Type is required',
              })}
              defaultValue={'Cash'}
              error={!!errors.account_type}
            >
              <MenuItem value='Cash'>Cash</MenuItem>
              <MenuItem value='Bank'>Bank</MenuItem>
              <MenuItem value='Mobile Banking'>Mobile Banking</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Account Name'
            variant='outlined'
            size='small'
            placeholder='Account Name'
            InputLabelProps={{ shrink: true }}
            {...register('name', {
              required: 'Account Name is required',
            })}
            error={!!errors.name}
            helperText={errors.name?.message as string}
          />
        </Grid>
        {(account_type === 'Bank' || account_type === 'Mobile Banking') && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Mobile number'
                variant='outlined'
                size='small'
                placeholder='Account number'
                InputLabelProps={{ shrink: true }}
                {...register('account_no', {
                  required: 'Account number is required',
                })}
                type='number'
                error={!!errors.account_no}
                helperText={errors.account_no?.message as string}
              />
            </Grid>
          </>
        )}
        {account_type === 'Bank' && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Bank name'
                variant='outlined'
                size='small'
                placeholder='Bank name'
                InputLabelProps={{ shrink: true }}
                {...register('bank_name', {
                  required: 'Bank name is required',
                })}
                error={!!errors.bank_name}
                helperText={errors.bank_name?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Brance name'
                variant='outlined'
                size='small'
                placeholder='Brance name'
                InputLabelProps={{ shrink: true }}
                {...register('branch', {
                  required: 'Brance name is required',
                })}
                error={!!errors.branch}
                helperText={errors.branch?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Routing no.'
                variant='outlined'
                size='small'
                placeholder='Routing no.'
                InputLabelProps={{ shrink: true }}
                {...register('routing_no', {
                  required: 'Routing name is required',
                })}
                error={!!errors.routing_no}
                helperText={errors.routing_no?.message as string}
              />
            </Grid>
          </>
        )}

        {account_type === 'Mobile Banking' && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Wallet Type</InputLabel>
                <Select
                  fullWidth
                  label='Wallet Type'
                  variant='outlined'
                  size='small'
                  placeholder='Wallet Type'
                  {...register('wallet_type', {
                    required: 'Wallet Type is required',
                  })}
                  defaultValue={'bKash'}
                  error={!!errors.wallet_type}
                >
                  <MenuItem value='bKash'>bKash</MenuItem>
                  <MenuItem value='Nagad'>Nagad</MenuItem>
                  <MenuItem value='Upay'>Upay</MenuItem>
                  <MenuItem value='ROCKET'>ROCKET</MenuItem>
                  <MenuItem value='MYCash'>MYCash</MenuItem>
                  <MenuItem value='mCash'>mCash</MenuItem>
                  <MenuItem value='TeleCash'>TeleCash</MenuItem>
                  <MenuItem value='Islamic Wallet'>Islamic Wallet</MenuItem>
                  <MenuItem value='Meghna Pay'>Meghna Pay</MenuItem>
                  <MenuItem value='OK Wallet'>OK Wallet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Wallet Account Type</InputLabel>
                <Select
                  label='Wallet Account Type'
                  placeholder='Mobile Operator Name'
                  {...register('wallet_account_type', {
                    required: 'Account type is required',
                  })}
                  error={!!errors.wallet_account_type}
                >
                  <MenuItem value='Merchant'>Merchant</MenuItem>
                  <MenuItem value='Agent'>Agent</MenuItem>
                  <MenuItem value='Personal'>Personal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Operator Type</InputLabel>
                <Select
                  fullWidth
                  label='Mobile Operator Name'
                  variant='outlined'
                  placeholder='Mobile Operator Name'
                  {...register('mobile_operator_name', {
                    required: 'Mobile operator name is required',
                  })}
                  error={!!errors.mobile_operator_name}
                >
                  <MenuItem value='Grameen Phone'>Grameenphone</MenuItem>
                  <MenuItem value='Robi'>Robi</MenuItem>
                  <MenuItem value='Airtel'>Airtel</MenuItem>
                  <MenuItem value='Banglalink'>Banglalink</MenuItem>
                  <MenuItem value='Teletalk'>Teletalk</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Opening Balance'
            variant='outlined'
            size='small'
            placeholder='Opening Balance'
            InputLabelProps={{ shrink: true }}
            {...register('opening_balance', {
              required: 'Opening Balance is required',
            })}
            type='number'
            error={!!errors.opening_balance}
            helperText={errors.opening_balance?.message as string}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'center'}>
            <Grid xs={8}>
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                fullWidth
              >
                {loading ? 'Loading...' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateAccount;
