import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  ICompanyOwners,
  ICompanyOwnersResponse,
} from 'src/Types/module/member/CompanyInformation';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { OrganizationOwners } from '../../../CompanyAddInfo/CompanyAddInfoComponents/OrganizationOwners';
import UpdateCompanyOwner from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyOwner';
import ShowOwner from './ShowOwner';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { HttpClient } from 'src/Helpers/http/http';

const CompanyOwnerInfo = () => {
  const { memberId } = useParams();
  const [open, setOpen] = useState(false);
  const [ownersInfo, setOwnersInfo] = useState<ICompanyOwners[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [singleOwner, setSingleOwner] = useState<ICompanyOwners>(
    {} as ICompanyOwners
  );

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data: ICompanyOwnersResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/owner/${memberId}`
        );
        if (data?.success) {
          setOwnersInfo(data?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Company Owner Information'
            actionButtonTitle={
              ownersInfo[0]?.member_company_type !== 'Proprietor'
                ? ownersInfo.length
                  ? 'Add'
                  : 'Add'
                : ''
            }
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {ownersInfo?.length ? (
              <Grid sx={{ pb: 5, mt: 5 }} container spacing={2}>
                {ownersInfo?.map((sOwner) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={ownersInfo.length > 1 ? 6 : 12}
                      sx={{
                        position: 'relative',
                      }}
                      key={sOwner.company_owner_id}
                    >
                      <ShowOwner
                        setSingleOwner={setSingleOwner}
                        sOwner={sOwner}
                        setUpdateModal={setUpdateModal}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: 'Add company owner',
        }}
      >
        <OrganizationOwners
          memberId={memberId}
          setRefresh={setRefresh}
          setOpen={setOpen}
          refresh={refresh}
        />
      </ModalsWrapper>

      {/* update modal  */}
      <ModalsWrapper
        setShowModal={setUpdateModal}
        showModal={updateModal}
        modalData={{
          title: "Update Company Owner's Information",
        }}
      >
        <UpdateCompanyOwner
          singleOwner={singleOwner}
          setRefresh={setRefresh}
          refresh={refresh}
          setUpdateModal={setUpdateModal}
        />
      </ModalsWrapper>
    </>
  );
};

export default CompanyOwnerInfo;
