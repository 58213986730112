// src/hooks/useGlobalCache.tsx

import React, { createContext, useContext, useMemo, useCallback } from 'react';

// Create a Map to store the cache
const responseCache = new Map<string, any>();

// Define a context to provide access to the cache
const CacheContext = createContext<{
  getCache: (key: string) => any | undefined;
  setCache: (key: string, data: any) => void;
  deleteCache: (key: string) => void;
  hasUrl: (key: string) => void;
  clearCache: () => void;
  responseCache: any;
} | null>(null);

export const CacheProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Retrieve an item from the cache
  const getCache = useCallback((key: string) => responseCache.get(key), []);
  const hasUrl = useCallback((key: string) => responseCache.has(key), []);

  // Add an item to the cache
  const setCache = useCallback((key: string, data: any) => {
    responseCache.set(key, data);
  }, []);

  // Delete an item from the cache
  const deleteCache = useCallback((key: string) => {
    responseCache.delete(key);
  }, []);

  // Clear the entire cache
  const clearCache = useCallback(() => {
    responseCache.clear();
  }, []);

  // Memoize the context value for performance
  const contextValue = useMemo(
    () => ({
      getCache,
      setCache,
      deleteCache,
      clearCache,
      hasUrl,
      responseCache,
    }),
    [getCache, setCache, deleteCache, clearCache, hasUrl, responseCache]
  );

  return (
    <CacheContext.Provider value={contextValue}>
      {children}
    </CacheContext.Provider>
  );
};

// Custom hook to access cache context
export const useGlobalCache = () => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error('useGlobalCache must be used within a CacheProvider');
  }
  return context;
};
