import { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  CardContent,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SingleCourseDetails from "./SingleCourseDetails";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ISingleCourseDetails } from "src/Types/module/courses/courseList";
import { Link } from "react-router-dom";
import UpdateCourse from "./UpdateCourse";

const SingleCourse = () => {
  const [course, setCourse] = useState<ISingleCourseDetails | null>(
    {} as ISingleCourseDetails
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const { id } = useParams();

  // modal status
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/course/get/single/${id}`;
        const { data } = await axios.get(uri);

        if (data.success) {
          setCourse(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [id, reload]);

  return (
    <>
      <Helmet>
        <title>HAAB - Single Course</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth="lg">
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography
                variant="h4"
                color="text.primary"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "35rem",
                }}
              >
                {`Information of ${course?.atab_training_course_name}`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Link
                  to={`/training-course/batch-list/${course?.atab_training_course_id}?course=${course?.atab_training_course_name}`}
                >
                  <Button sx={{ mx: 1 }} variant="outlined">
                    Batch list
                  </Button>
                </Link>
                <Link
                  to={`/training-course/student-list/${course?.atab_training_course_id}?course=${course?.atab_training_course_name}`}
                >
                  <Button sx={{ mx: 1 }} variant="outlined">
                    Student list
                  </Button>
                </Link>
                <Button
                  onClick={() => setShowModal(!showModal)}
                  variant="outlined"
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Divider />
            {isLoading ? (
              <SuspenseLoader />
            ) : (
              <SingleCourseDetails course={course} />
            )}
          </CardContent>
        </Card>
        {/* Update Course */}
        <UpdateCourse
          showModal={showModal}
          setShowModal={setShowModal}
          setReload={setReload}
          course={course}
        />
      </Container>
    </>
  );
};

export default SingleCourse;
