import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IBachOfTeacher } from "src/Types/module/teacher/teacherTypes";
import moment from "moment";
import { url } from "src/Helpers/utils/Constant";
import { TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";

const applyPagination = (
  batchList: IBachOfTeacher[],
  page: number,
  limit: number
): IBachOfTeacher[] => {
  return batchList.slice(page * limit, page * limit + limit);
};

const BatchOfTeacher = () => {
  const [batchList, setBatchList] = useState<IBachOfTeacher[] | null>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [query] = useSearchParams();
  const { teacherId } = useParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/training/teacher/get/all/batch/by/teacher-id/${teacherId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setBatchList(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [query.get("id")]);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedbatchList = applyPagination(batchList, page, limit);

  const theme = useTheme();
  return !loading ? (
    <>
      <Helmet>
        <title>Batch List of this Teacher</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          pt={5}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`Batch List of ${query.get("teacher")}`} />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Batch name</TableCell>
                      <TableCell>Batch start date</TableCell>
                      <TableCell>Batch end date</TableCell>
                      <TableCell>Batch status</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  {paginatedbatchList.length ? (
                    paginatedbatchList.map((batch: IBachOfTeacher) => {
                      return (
                        <TableBody key={batch.training_batch_id}>
                          <TableRow hover>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {batch.training_batch_id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {batch.training_batch_name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {batch.training_batch_start_date
                                ? moment
                                    .tz(
                                      batch.training_batch_start_date,
                                      "Asia/Dhaka"
                                    )
                                    .format("MMM Do YY")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {batch.training_batch_end_date
                                ? moment
                                    .tz(
                                      batch.training_batch_end_date,
                                      "Asia/Dhaka"
                                    )
                                    .format("LT")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {}{" "}
                              <Typography
                                variant="body1"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {getStatusLabel(batch.training_batch_status)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                to={`/training-batch/details/${batch.training_batch_id}`}
                              >
                                <Tooltip title="view" arrow>
                                  <IconButton
                                    sx={{
                                      "&:hover": {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color="inherit"
                                    size="small"
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableNotFound />
                  )}
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={batchList.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25, 30]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <GlobalLoader />
  );
};

export default BatchOfTeacher;
