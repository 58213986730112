import { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Divider,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import { TableNotFound } from 'src/Utils/TableLoader';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { DatePicker } from '@mui/lab';

const ReceiveReport = () => {
  const [date, setDate] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const [statusItem, setStatusItem] = useState<{
    status: string;
    item: number;
  }>({
    status: 'paid',
    item: 0,
  });
  const [filterItem, setFilterItem] = useState({
    zone: [],
    subscription: '',
  });
  const [selectFilter, setSelectFilter] = useState({
    zone: 'Dhaka',
    subscription: '',
  });
  const [invoiceItem, setInvoiceItem] = useState([]);

  // get all zone
  useEffect(() => {
    (async function () {
      const { data } = await axios.get(`${url}/api/atab/common/get/all/zone`);
      if (data.success) {
        setFilterItem({ ...filterItem, zone: data.data });
      }
    })();
  }, []);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid>
        <Grid item xs={12} mb={2}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <DatePicker
                  label='Start Date'
                  value={date.startDate}
                  onChange={(e: any) => setDate({ ...date, startDate: e })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label='End Date'
                  value={date.endDate}
                  onChange={(e: any) => setDate({ ...date, endDate: e })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      size='small'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={'Receive Report'}
              // action={
              //   <Box
              //     sx={{
              //       display: 'flex',
              //       justifyContent: 'center',
              //       alignItems: 'center',
              //       gap: 1,
              //     }}
              //   >
              //     <ExcelGenerator getDownloadData={getDownloadData} />
              //     <PdfGenerator getDownloadData={getDownloadData} />
              //   </Box>
              // }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Voucher No</TableCell>
                    <TableCell>Cheque No</TableCell>
                    <TableCell>Agency Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Cash</TableCell>
                    <TableCell>Bank</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableNotFound />
                  {/* {isLoading ? (
                    <TableLoader />
                  ) : members.total ? (
                    <>
                      {members.members.map((allMembers: Imembers) => {
                        return (
                          <InvoiceMemberTableRow
                            key={allMembers.user_member_id}
                            allMembers={allMembers}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReceiveReport;
