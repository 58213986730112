import { useEffect } from 'react';

import { Box, IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IEmailList } from './type';
import { getStatusLabel } from 'src/Utils/Combined';
import PdfAndImageView from 'src/common/PdfAndImageView';
import PopoverComponent from 'src/common/PopoverComponent';
import DeleteDataApi from 'src/common/FetchData/DeleteDataApi';

interface IProps {
  sNotice: IEmailList;
  index: number;
  getData: () => Promise<void>;
}
const EmailTableRow = ({ sNotice, index, getData }: IProps) => {
  const theme = useTheme();
  const { deleteData, success } = DeleteDataApi(
    '/api/haab/bulk-email/delete-mails'
  );

  useEffect(() => {
    if (success) {
      getData();
    }
  }, [success]);
  return (
    <>
      <TableRow hover>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{sNotice.user_member_hajj_license}</TableCell>
        <TableCell>{sNotice.group_name}</TableCell>
        <TableCell>{sNotice.recipient}</TableCell>
        <TableCell>{sNotice.subject}</TableCell>
        <TableCell>{sNotice.body}</TableCell>
        <TableCell>{getStatusLabel(sNotice.status)}</TableCell>
        <TableCell>
          <PdfAndImageView file={sNotice?.attachments[0]} />
        </TableCell>

        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PopoverComponent
              popOverTitle='Are you sure to Delete?'
              handleConfirm={() => deleteData('', { mailIds: [sNotice.id] })}
            >
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            </PopoverComponent>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmailTableRow;
