import { DatePicker } from '@mui/lab';
import { Box, Grid, TextField } from '@mui/material';
import dayjs from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';

type IProps = {
  setDateRange: (value: any) => void;
  filter: any;
  showClearIcon?: boolean;
};

const CommonDateRange = ({ setDateRange, filter, showClearIcon }: IProps) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <DatePicker
          label='Start Date'
          value={filter.from_date ? dayjs(filter.from_date) : null}
          onChange={(e: any) =>
            setDateRange({
              ...filter,
              from_date: e ? dayjs(e).format('YYYY-MM-DD') : null,
            })
          }
          renderInput={(params) => (
            <TextField
              size='small'
              {...params}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRight: 'none',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} position={'relative'}>
        {showClearIcon && filter.to_date && filter.from_date && (
          <Box
            position={'absolute'}
            right={'-1%'}
            bottom={'10%'}
            zIndex={10}
            onClick={() =>
              setDateRange({ ...filter, from_date: null, to_date: null })
            }
            sx={{ cursor: 'pointer' }}
          >
            <CancelIcon fontSize='small' />
          </Box>
        )}
        <DatePicker
          label='End Date'
          value={filter.to_date ? dayjs(filter.to_date) : null}
          onChange={(e: any) =>
            setDateRange({
              ...filter,
              to_date: e ? dayjs(e).format('YYYY-MM-DD') : null,
            })
          }
          renderInput={(params) => (
            <TextField
              size='small'
              {...params}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderLeft: 'none',
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CommonDateRange;
