import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { url } from 'src/Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import IdCardInfo from './IdCardInfo';
import EditIcon from '@mui/icons-material/Edit';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import Toaster from 'src/Toaster/Toaster';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import useTheme from '@mui/material/styles/useTheme';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import IdCardForm from './IdCardForm';
const SingleIdCard = () => {
  const { user } = useAuthContext();
  const { cardID } = useParams();
  const { handleSubmit } = useForm();
  const [singleIdCard, setSingleIdCard] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [refresh, setRefresh] = useState<boolean>(false);

  // print informatuion
  const theme = useTheme();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `id_card`,
  });
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/member/id-card/application/${cardID}`
        );

        if (data.success) {
          setSingleIdCard(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (err: any) {
      setLoading(false);
    }
  }, [cardID, refresh]);

  const onSubmit = async (inputsData: any) => {
    inputsData.id = singleIdCard.id;
    inputsData.user_member_id = singleIdCard?.user_member_id;
    inputsData.status = status;
    try {
      const { data } = await axios.patch(
        `${url}/api/member/id-card/application`,
        inputsData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });

        setOpen(false);
        setIsLoading(false);
        setRefresh((prv) => !prv);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Single ID card</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pb: 2,
                }}
              >
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  gutterBottom
                >
                  ID Card of {singleIdCard?.applicant_name}
                </Typography>
                <Box>
                  <Tooltip title='Print information form' sx={{ mr: 1 }}>
                    <IconButton onClick={handlePrint}>
                      <PrintIcon
                        fontSize='large'
                        sx={{
                          cursor: 'pointer',
                          color: theme.colors.primary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Button
                    variant='contained'
                    startIcon={<EditIcon />}
                    onClick={() => setOpen(true)}
                    size='small'
                  >
                    Update
                  </Button>
                </Box>
              </Box>

              <Divider />
              {singleIdCard && <IdCardInfo singleIdCard={singleIdCard} />}
              <Box display={'none'}>
                <IdCardForm
                  componentRef={componentRef}
                  singleIdCard={singleIdCard}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Container>

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: 'Update id card status',
        }}
      >
        <Box px={1} width={300}>
          <Box component='form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Select Status
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={status}
                    label='Select Status'
                    onChange={(e: any) => setStatus(e.target.value)}
                  >
                    <MenuItem value='completed'>Completed</MenuItem>
                    <MenuItem value='rejected'>Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid mt={1}>
              {isLoading ? (
                <Button
                  disabled
                  fullWidth
                  variant='contained'
                  disableElevation
                  className='add-remove-button'
                >
                  loading..
                </Button>
              ) : (
                <Button
                  fullWidth
                  type='submit'
                  variant='contained'
                  disableElevation
                  className='add-remove-button'
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Box>
        </Box>
      </ModalsWrapper>
    </>
  );
};

export default SingleIdCard;
