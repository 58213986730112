import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';

const UpdateModal = ({
  setShowModal,
  showModal,
  loading,
  setLoading,
  application,
  setApplication,
}) => {
  const [inputs, setInputs] = useState({
    duplicate_certificate_application_status: 'pending',
    duplicate_certificate_application_rejeceted_reason: '',
  });

  useEffect(() => {
    setInputs({
      duplicate_certificate_application_status:
        application.duplicate_certificate_application_status,
      duplicate_certificate_application_rejeceted_reason:
        application.duplicate_certificate_application_rejeceted_reason,
    });
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    try {
      const response = await axios.patch(
        `${url}/api/member/duplicate-certificate-application/${application.duplicate_certificate_application_id}`,
        inputs
      );

      setLoading(false);
      if (response.data.success) {
        alert('Application updated');
        setApplication({
          duplicate_certificate_application_status:
            inputs.duplicate_certificate_application_status,
          duplicate_certificate_application_rejeceted_reason:
            inputs.duplicate_certificate_application_rejeceted_reason,
        });
        setShowModal(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <ModalsWrapper
      setShowModal={setShowModal}
      showModal={showModal}
      modalData={{
        title: 'Update Duplicate Certificate application',
      }}
    >
      <Box p={2}>
        <Box component='form' onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>
                  Select Status
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={inputs.duplicate_certificate_application_status}
                  label='Select Status'
                  onChange={(e: any) =>
                    setInputs({
                      ...inputs,
                      duplicate_certificate_application_status: e.target.value,
                    })
                  }
                >
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='approved'>Approved</MenuItem>
                  <MenuItem value='rejected'>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  id='outlined-basic'
                  label='Note'
                  variant='outlined'
                  value={
                    inputs.duplicate_certificate_application_rejeceted_reason
                  }
                  multiline
                  type='text'
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      duplicate_certificate_application_rejeceted_reason:
                        e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ pt: 2, textAlign: 'end', mb: 2 }}>
            {loading ? (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ModalsWrapper>
  );
};

export default UpdateModal;
