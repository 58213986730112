import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ICompanyDocument,
  ICompanyDocumentResponse,
} from 'src/Types/module/member/CompanyInformation';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import OrganizatonOtherinput from '../../../CompanyAddInfo/CompanyAddInfoComponents/OrganizatonOtherinput';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import UpdateCompanyDocument from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyDocument';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { HttpClient } from 'src/Helpers/http/http';
import PdfAndImageView from 'src/common/PdfAndImageView';

function CompanyDocumentInfo() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [companyDocument, setCompanyDocument] = useState<ICompanyDocument>(
    {} as ICompanyDocument
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const data: ICompanyDocumentResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/documents/${memberId}`
        );
        if (data.success) {
          setCompanyDocument(data?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    company_ownership_status,
    company_staff_list_file,
    company_signboard_file,
    company_office_photographs,
    company_agreement_file,
    company_partnership_deed_file,
    company_incorporation_certificate,
    company_memorandum_file,
    company_one_staff_certificate,
    company_office_agreement,
    company_form_12,
    company_form_117,
    company_incorporation_date,
  } = companyDocument || {};

  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Company Document'
            actionButtonTitle={company_ownership_status ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {company_ownership_status ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company ownership status:
                          </TableCell>
                          <TableCell align='right'>
                            {company_ownership_status
                              ? company_ownership_status
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company office agreement type:
                          </TableCell>
                          <TableCell align='right'>
                            {company_office_agreement
                              ? company_office_agreement
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company staff list on office pad with seal and
                            singature:
                          </TableCell>
                          <TableCell align='right'>
                            {company_staff_list_file ? (
                              <PdfAndImageView file={company_staff_list_file} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Upload internal & external photographs of office
                            Including Signboard (PDF)
                          </TableCell>
                          <TableCell align='right'>
                            {company_signboard_file ? (
                              <PdfAndImageView file={company_signboard_file} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>

                        {company_ownership_status === 'Limited' && (
                          <>
                            <TableRow>
                              <TableCell component='th' scope='row'>
                                Company incorporation certificate:
                              </TableCell>
                              <TableCell align='right'>
                                {company_incorporation_certificate ? (
                                  <PdfAndImageView
                                    file={company_incorporation_certificate}
                                  />
                                ) : (
                                  'Not provided'
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        {company_ownership_status === 'Limited' && (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              Company memorandum file:
                            </TableCell>
                            <TableCell align='right'>
                              {company_memorandum_file ? (
                                <PdfAndImageView
                                  file={company_memorandum_file}
                                />
                              ) : (
                                'Not provided'
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {company_ownership_status === 'Limited' && (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              Download Form 12 file:
                            </TableCell>
                            <TableCell align='right'>
                              {company_form_12 ? (
                                <PdfAndImageView file={company_form_12} />
                              ) : (
                                'Not provided'
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {company_ownership_status === 'Limited' && (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              Download Form 117 file:
                            </TableCell>
                            <TableCell align='right'>
                              {company_form_117 ? (
                                <PdfAndImageView file={company_form_117} />
                              ) : (
                                'Not provided'
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {company_ownership_status === 'Partnership' && (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              Company partnership deed file:
                            </TableCell>
                            <TableCell align='right'>
                              {company_partnership_deed_file ? (
                                <PdfAndImageView
                                  file={company_partnership_deed_file}
                                />
                              ) : (
                                'Not provided'
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {company_office_agreement === 'Self premise' && (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              Download Self premise company agreement file:
                            </TableCell>
                            <TableCell align='right'>
                              {company_agreement_file ? (
                                <PdfAndImageView
                                  file={company_agreement_file}
                                />
                              ) : (
                                'Not provided'
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
        <Box textAlign={'end'}>
          <PageTitle
            actionButtonTitle={company_ownership_status ? 'Update' : ''}
            setAction={setOpen}
          />
        </Box>
      </>

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: company_ownership_status
            ? 'Update company document'
            : 'Add company document',
        }}
      >
        {company_ownership_status ? (
          <UpdateCompanyDocument
            memberId={memberId}
            companyDocument={companyDocument}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <OrganizatonOtherinput
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>
    </>
  );
}

export default CompanyDocumentInfo;
