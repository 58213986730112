import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { IBatchStudent } from "src/Types/module/courses/trainee";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface IBatchStudentsProps {
  student: IBatchStudent;
}

const SingleBatchStudentRow = ({ student }: IBatchStudentsProps) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {student.user_training_trainee_id}
      </TableCell>
      <TableCell component="th" scope="row">
        {student.user_training_trainee_name
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      </TableCell>
      <TableCell align="right">
        {moment(student.training_student_batch_join_date).format("MMM Do YY")}
      </TableCell>
      <TableCell align="right">
        <Tooltip title="view" arrow>
          <IconButton color="inherit" size="small">
            <Link
              to={`/training-trainee/details/${student.user_training_trainee_id}`}
            >
              <VisibilityIcon fontSize="small" />
            </Link>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default SingleBatchStudentRow;
