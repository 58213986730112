import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  Box,
  Button,
  TableContainer,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IEmailList } from './type';
import EmailTableRow from './EmailTableRow';
import CreateEmail from './CreateEmail/CreateEmail';
import CommonSearchTextFieldWithDebounce from 'src/common/CommonSearchTextFieldWithDebounce';
import DeleteDataApi from 'src/common/FetchData/DeleteDataApi';
import PopoverComponent from 'src/common/PopoverComponent';
const EmailTable = () => {
  const [query, setQuery] = useState('');
  const { deleteData, success } = DeleteDataApi('/api/haab/bulk-email');
  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState<{
    page: string | number;
    limit: string | number;
    status: string;
  }>({
    page: 1,
    limit: 30,
    status: 'success',
  });
  const {
    response,
    loading: isLoading,
    total,
    getData,
  } = GetDataApi<IEmailList[]>(
    `/api/haab/bulk-email?limit=${filter.limit}&page=${filter.page}&status=${filter.status}&searchParams=${query}`,
    true
  );

  useEffect(() => {
    getData();
  }, [filter, query]);

  useEffect(() => {
    if (success) {
      getData();
    }
  }, [success]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Grid container spacing={1} alignItems='center'>
                  <Grid item xs={12} sm={7} md={4} lg={4} xl={6}>
                    <Box fontWeight='bold' fontSize='h6.fontSize'>
                      Email List
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={5} md={8} lg={8} xl={6}>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={3} sm={2}>
                        <PopoverComponent
                          popOverTitle='Are You Sure to Delete all email?'
                          handleConfirm={() => deleteData()}
                        >
                          <Button
                            variant='outlined'
                            size='small'
                            color='error'
                            fullWidth
                            sx={{ height: '37px' }}
                          >
                            Delete All Email List
                          </Button>
                        </PopoverComponent>
                      </Grid>

                      <Grid item xs={12} sm={5} md={4} lg={5}>
                        <CommonSearchTextFieldWithDebounce
                          onSearch={setQuery}
                          label='Search here by license or others'
                        />
                      </Grid>

                      <Grid item xs={12} sm={5} md={4} lg={3}>
                        <FormControl fullWidth variant='outlined' size='small'>
                          <InputLabel id='status-select-label'>
                            Status
                          </InputLabel>
                          <Select
                            labelId='status-select-label'
                            id='status-select'
                            label='Status'
                            value={filter.status}
                            onChange={(e) =>
                              setFilter({ ...filter, status: e.target.value })
                            }
                          >
                            <MenuItem value='success'>Success</MenuItem>
                            <MenuItem value='failed'>Failed</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Button
                          onClick={() => setOpen(true)}
                          variant='contained'
                          size='small'
                          fullWidth
                          sx={{ height: '37px' }}
                        >
                          Create Email
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SL</TableCell>
                    <TableCell>License No.</TableCell>
                    <TableCell>Group Name</TableCell>
                    <TableCell>Recipient Email</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Email body</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Attachment</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : response?.length ? (
                    <>
                      {response?.map((sNotice, index) => {
                        return (
                          <EmailTableRow
                            sNotice={sNotice}
                            index={index}
                            getData={getData}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={total as number}
                onPageChange={(_event: any, newPage: number) =>
                  setFilter({ ...filter, page: newPage })
                }
                onRowsPerPageChange={(event) =>
                  setFilter({ ...filter, limit: event.target.value })
                }
                page={Number(filter.page) && Number(filter.page)}
                rowsPerPage={Number(filter.limit)}
                rowsPerPageOptions={[30, 50, 75, 100]}
              />
            </Box>
            <ModalsWrapper
              setShowModal={setOpen}
              showModal={open}
              modalData={{ title: 'Create Email' }}
              fullWidth
              maxWidth='sm'
            >
              <>
                <CreateEmail setOpen={setOpen} getData={getData} />
              </>
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmailTable;
