import { Dispatch, FC, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Typography, Button, Grid } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
interface PageTitleProps {
  heading?: string;
  subHeading?: string;
  actionButtonTitle?: string;
  setAction?: any;
  isforward?: boolean;
  isShowUpdate?: boolean;
  setOpenForward?: Dispatch<React.SetStateAction<boolean>>;
}

const PageTitle: FC<PageTitleProps> = ({
  heading = '',
  subHeading,
  setAction,
  actionButtonTitle,
  isforward,
  isShowUpdate,
  setOpenForward,
  ...rest
}) => {
  return (
    <Grid
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      {...rest}
    >
      <Grid item>
        <Typography variant='h3' component='h3' gutterBottom>
          {heading}
        </Typography>
        {subHeading && (
          <Typography variant='subtitle2'>{subHeading}</Typography>
        )}
      </Grid>
      {actionButtonTitle && (
        <Grid item>
          <>
            {isforward && (
              <Button
                onClick={() => setOpenForward(true)}
                sx={{ mt: { xs: 2, md: 0, marginRight: 2 } }}
                variant='contained'
                startIcon={
                  actionButtonTitle === 'Update' ? (
                    <ModeEditOutlineOutlinedIcon fontSize='small' />
                  ) : (
                    <AddTwoToneIcon fontSize='small' />
                  )
                }
                size='small'
              >
                Assign to
              </Button>
            )}
          </>
          {!isShowUpdate ? (
            <Button
              onClick={() => setAction(true)}
              sx={{ mt: { xs: 2, md: 0 } }}
              variant='contained'
              startIcon={
                actionButtonTitle === 'Update' ||
                actionButtonTitle === 'Membership Status Update' ? (
                  <ModeEditOutlineOutlinedIcon fontSize='small' />
                ) : (
                  <AddTwoToneIcon fontSize='small' />
                )
              }
              size='small'
            >
              {actionButtonTitle}
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Grid>
  );
};

PageTitle.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

export default PageTitle;
