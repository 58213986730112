import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { INotice } from 'src/Types/module/member/Notice';
import { Imembers } from 'src/Types/module/member/memberTypes';
import { useDebounce } from 'src/hooks/useDebounce';
import SelectManualOrGroupWise from './SelectManualOrGroupWise';
import GroupWiseMessage from './GroupWiseMessage';
import { useForm } from 'react-hook-form';

interface IProps {
  setOpen: (value: boolean) => void;
  allNotice: INotice[];
  setAllNotice: Function;
}
const CreateNotice = ({ setOpen, allNotice, setAllNotice }: IProps) => {
  const { user } = useAuthContext();
  const [search, setSearch] = useState('');
  const searchValue = useDebounce(search, 500);
  const [inputData, setInputData] = useState({
    title: '',
    file: '',
    member_id: '',
  });
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const [meetingType, setMeetingType] = useState('');
  const [membersId, setMembersId] = useState<string[]>([]);
  const [group_member_id, setGroupMemberId] = useState('');
  const [allMembers, setAllMembers] = useState<Imembers[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState('manual');
  console.log(group_member_id);
  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('member_notice_file', inputData.file);
    formData.append('member_notice_title', inputData.title);
    formData.append('member_notice_created_by', user.user_admin_id.toString());

    if (meetingType === 'all') {
      formData.append('type', meetingType);
    } else if (meetingType === 'specific') {
      formData.append('member_ids', JSON.stringify(membersId));
      formData.append('type', meetingType);
    }

    if (isActive === 'group_wise') {
      formData.append('member_ids', JSON.stringify(group_member_id));
      formData.append('type', 'specific');
    }

    try {
      setLoading(true);
      const { data } = await axios.post(`${url}/api/member/notice`, formData);
      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setAllNotice([
          {
            member_notice_id: data.data.member_notice_id,
            member_notice_file: data.data.member_notice_file,
            member_notice_title: inputData.title,
            member_notice_created_at: date.toISOString(),
            member_notice_created_by_id: user.user_admin_id,
            member_notice_created_by_name: user.user_admin_name,
          },
          ...allNotice,
        ]);
        setOpen(false);
        setLoading(false);
      } else {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        let uri;
        if (searchValue) {
          uri = `${url}/api/atab/user-member/search-member/by-company/${searchValue}?status=active&zone=0`;
        } else {
          uri = `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?status=active&skip=0&limit=25`;
        }

        const { data } = await axios.get(uri);
        if (data.success) {
          setAllMembers(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [searchValue]);
  const { register, handleSubmit, watch } = useForm();
  return (
    <Box sx={{ padding: 2, paddingBottom: 0 }}>
      <Grid spacing={2} maxWidth={350}>
        <Grid item xs={12} md={12}>
          <SelectManualOrGroupWise
            setIsActive={setIsActive}
            isActive={isActive}
          />
          {isActive === 'manual' ? (
            <>
              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>
                  Notice type
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={meetingType}
                  label=' Meeting type'
                  onChange={(e) => setMeetingType(e.target.value)}
                  required
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'specific'}>Specific</MenuItem>
                </Select>
              </FormControl>

              {meetingType === 'specific' && (
                <Grid xs={12} md={12} lg={12} item mt={2}>
                  <Autocomplete
                    size='small'
                    multiple
                    id='tags-filled'
                    options={allMembers}
                    getOptionLabel={(option: any) =>
                      option.user_member_company_name
                    }
                    filterSelectedOptions
                    onChange={(_event, newValue) => {
                      console.log(newValue);
                      const Ids = newValue.map((item) => item.user_member_id);
                      setMembersId(Ids);
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            key={index}
                            variant='filled'
                            label={option.user_member_company_name}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Select member'
                        placeholder='More..'
                        onInput={(e: any) => setSearch(e.target.value)}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          ) : (
            <GroupWiseMessage setGroupMemberId={setGroupMemberId} />
          )}
        </Grid>

        <Grid item xs={12} md={12} mt={2}>
          <TextField
            size='small'
            id='outlined-basic'
            placeholder='Enter notice title'
            label='Enter notice title'
            required
            {...register('groupId', { required: true })}
            onChange={(e) =>
              setInputData({ ...inputData, title: e.target.value })
            }
            variant='outlined'
            type='text'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <TextField
            size='small'
            id='outlined-basic'
            placeholder='Upload notice file'
            label='Upload notice file'
            required
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: any) =>
              setInputData({ ...inputData, file: e.target.files[0] })
            }
            variant='outlined'
            type='file'
            fullWidth
          />
        </Grid>
      </Grid>
      {loading ? (
        <Button
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          size='small'
        >
          Loading ...
        </Button>
      ) : (
        <Button
          onClick={onSubmit}
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          size='small'
        >
          Create Notice
        </Button>
      )}
    </Box>
  );
};

export default CreateNotice;
