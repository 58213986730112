import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { imgUrl } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import SpecificRoleFind from 'src/common/SpecificRoleFind';
import { useParams } from 'react-router-dom';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import MultipleImageUpload from './MultipleImageUpload';
const Toast = Toaster();

type IProps = {
  currentForward?: string;
  setNote?: React.Dispatch<
    React.SetStateAction<{
      forward_to: string;
      note: string;
    }>
  >;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
};
const UploadDucumentAndForward = ({ setRefetch, currentForward }: IProps) => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const { updateData, success } = UpdateDataApi(`/api/atab/budget/${id}`);
  const { roles_id: financeRoleId } = SpecificRoleFind('Finance Secretary');
  const { roles_id: SecretaryRoleId } = SpecificRoleFind('Secretary');
  const { roles_id: AccountsRoleId } = SpecificRoleFind('Accounts');
  const { user } = useAuthContext();
  const [memberData, setMemberData] = useState<any>({});
  const [comments, setComments] = useState<{
    account_comments: string;
    president_comments: string;
    computer_operator_comments: string;
    finance_comments: string;
    secretary_comments: string;
    admin_comments: string;
    secretary_general_comments: string;
  }>();
  const { response } = GetDataApi<{ signature: string }>(
    `/api/atab/admin/get/single/admin/${user.user_admin_id}`
  );
  const [openForwardModal, setOpenForward] = useState(false);
  const handleMemberUpdate = async () => {
    const formData = new FormData();

    /* view pages time work when accounts assign to finance secretary */
    if (memberData?.finance && comments?.finance_comments) {
      const notes = [
        {
          forward_to: String(financeRoleId),
          note: comments?.finance_comments,
        },
      ];

      if (images?.length > 0) {
        images.map((item, index) => {
          formData?.append(`files_${1}`, item?.file);
        });
      }
      formData.append('notes', JSON.stringify(notes));
      await updateData(formData);
    } else if (memberData?.secretary && comments?.secretary_comments) {
      /* view pages time work when finacne secretary assign to secretary */
      const notes = [
        {
          forward_to: String(SecretaryRoleId),
          note: comments?.secretary_comments,
        },
      ];
      formData.append('notes', JSON.stringify(notes));
      await updateData(formData);
    } else if (
      (memberData?.finance && comments?.finance_comments) ||
      (memberData?.accounts && comments?.account_comments)
    ) {
      /* view pages time work when secretary assign to finance secretary or accounts*/
      const notes = [];
      if (memberData?.finance && comments?.finance_comments) {
        notes?.push({
          forward_to: String(financeRoleId),
          note: comments?.finance_comments,
        });
      }
      if (memberData?.accounts && comments?.account_comments) {
        notes?.push({
          forward_to: String(AccountsRoleId),
          note: comments?.account_comments,
        });
      }
      formData.append('notes', JSON.stringify(notes));

      await updateData(formData);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Please fillup field',
      });
    }
  };
  useEffect(() => {
    if (success) {
      setOpenForward && setOpenForward(false);
      setRefetch(true);
      setImages([]);
    }
  }, [success]);
  return (
    <div>
      <Tooltip
        arrow
        title={
          user?.user_admin_role === 'Super Admin' ? 'You have no access' : ''
        }
        placement='top-start'
      >
        <Button
          disabled={
            /* Onace account forward to F.A. it will be disable for accounts */
            user?.user_admin_role === 'Accounts' &&
            (currentForward === 'Accounts' || currentForward == null)
              ? false
              : user?.user_admin_role === 'Finance Secretary' &&
                currentForward === 'Finance Secretary'
              ? false
              : user?.user_admin_role === 'Secretary' &&
                currentForward === 'Secretary'
              ? false
              : true
          }
          onClick={() =>
            user?.user_admin_role === 'Super Admin' ? '' : setOpenForward(true)
          }
          sx={{ mt: { xs: 2, md: 0, marginRight: 2 } }}
          variant='contained'
          startIcon={<ModeEditOutlineOutlinedIcon fontSize='small' />}
          size='small'
        >
          {
            /* Onace account forward to F.A. it will be disable for accounts */
            user?.user_admin_role === 'Accounts' &&
            (currentForward === 'Accounts' || currentForward == null)
              ? ' Upload Voucher & Forward'
              : user?.user_admin_role === 'Finance Secretary' &&
                currentForward === 'Finance Secretary'
              ? ' Upload Voucher & Forward'
              : user?.user_admin_role === 'Secretary' &&
                currentForward === 'Secretary'
              ? ' Upload Voucher & Forward'
              : `The file is now available, (${
                  currentForward ? currentForward : ''
                })`
          }
        </Button>
      </Tooltip>
      <ModalsWrapper
        setShowModal={setOpenForward}
        showModal={openForwardModal}
        modalData={{ title: 'Forward Budget Details' }}
        maxWidth='sm'
        fullWidth
      >
        <Grid container sx={{ px: 1 }}>
          {user?.user_admin_role?.toLowerCase() === 'accounts' && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setMemberData({
                          ...memberData,
                          finance: e.target.checked ? 1 : '',
                        });
                      } else {
                        setMemberData({
                          ...memberData,
                          finance: '',
                        });
                      }
                    }}
                    name='forward_to_finace_secretary'
                    color='primary'
                  />
                }
                label='Forward to Finance Secretary'
              />
              <Box>
                <TextField
                  type='text'
                  multiline
                  fullWidth
                  rows={2} // Set initial number of rows
                  variant='outlined' // Choose the variant (optional)
                  placeholder='Enter your note' // Optional label
                  onChange={(e) =>
                    setComments({
                      ...comments,
                      finance_comments: e.target.value,
                    })
                  }
                />
              </Box>
            </Grid>
          )}
          {user?.user_admin_role?.toLowerCase() === 'finance secretary' && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setMemberData({
                            ...memberData,
                            secretary: e.target.checked ? 1 : '',
                          });
                        } else {
                          setMemberData({
                            ...memberData,
                            secretary: '',
                          });
                        }
                      }}
                      name='forward_to_finace_secretary'
                      color='primary'
                    />
                  }
                  label='Forward to Secretary'
                />
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={2} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        secretary_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setMemberData({
                            ...memberData,
                            accounts: e.target.checked ? 1 : '',
                          });
                        } else {
                          setMemberData({
                            ...memberData,
                            accounts: '',
                          });
                        }
                      }}
                      name='forward_to_finace_secretary'
                      color='primary'
                    />
                  }
                  label='Forward to Account'
                />
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={2} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        account_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              </Grid>
            </>
          )}
          {user?.user_admin_role?.toLowerCase() === 'secretary' && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setMemberData({
                            ...memberData,
                            finance: e.target.checked ? 1 : '',
                          });
                        } else {
                          setMemberData({
                            ...memberData,
                            finance: '',
                          });
                        }
                      }}
                      name='forward_to_finace_secretary'
                      color='primary'
                    />
                  }
                  label='Forward to Finance Secretary'
                />
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={2} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        finance_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setMemberData({
                            ...memberData,
                            accounts: e.target.checked ? 1 : '',
                          });
                        } else {
                          setMemberData({
                            ...memberData,
                            accounts: '',
                          });
                        }
                      }}
                      name='forward_to_finace_secretary'
                      color='primary'
                    />
                  }
                  label='Forward to Account'
                />
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={2} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        account_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={4} p={1}>
          {response?.signature ? (
            (memberData?.finance_secretary || memberData?.secretary) && (
              <Box display={'flex'} flexDirection={'column'}>
                <img
                  width={100}
                  style={{ border: '1px solid gray' }}
                  src={`${imgUrl}/${response?.signature}`}
                />
                Signature
              </Box>
            )
          ) : (
            <Box border={'1px solid gray'} p={1}>
              No Signature Available
            </Box>
          )}
        </Grid>
        {/* multile image upload */}
        <MultipleImageUpload setImages={setImages} images={images} />
        <Grid sx={{ px: 1 }}>
          {' '}
          <Button
            size='small'
            onClick={handleMemberUpdate}
            variant='contained'
            style={{ width: '100%' }}
          >
            Submit
          </Button>
        </Grid>
      </ModalsWrapper>
    </div>
  );
};

export default UploadDucumentAndForward;
