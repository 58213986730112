import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@mui/material";

import { Helmet } from "react-helmet-async";
import SingleTeacherDetails from "./SingleTeacherDetails";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { ITeacher } from "src/Types/module/teacher/teacherTypes";
import UpdateTeacher from "./TeacherUpdate";
import SuspenseLoader from "src/components/SuspenseLoader";
import { Link } from "react-router-dom";

const SingleTeacher = () => {
  const [teacher, setTeacher] = useState<ITeacher>({} as ITeacher);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/teacher/get/single/${id}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setTeacher(data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id, showModal]);

  return (
    <>
      <Helmet>
        <title>HAAB - Single Teacher</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth="lg">
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Typography variant="h4" color="text.primary">
                Teacher Information
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Button
                  onClick={() => setShowModal(!showModal)}
                  variant="outlined"
                >
                  Edit
                </Button>
                <Link
                  to={`/training-teacher/batch-list/${teacher.training_teacher_id}/?teacher=${teacher.training_teacher_name}`}
                >
                  <Button sx={{ mx: 1 }} variant="outlined">
                    Batch list
                  </Button>
                </Link>
              </Box>
            </Box>
            <Divider />
            {/* Teacher Details */}
            {loading ? (
              <SuspenseLoader />
            ) : (
              <SingleTeacherDetails teacher={teacher} />
            )}

            {/* modals wrapper */}
            <UpdateTeacher
              showModal={showModal}
              setShowModal={setShowModal}
              teacher={teacher}
            />
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default SingleTeacher;
