import { Imembers } from 'src/Types/module/member/memberTypes';
import { Button } from '@mui/material';
import Excel from 'exceljs';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import GlobalLoader from '../SuspenseLoader/GlobalLoader';

const ExcelGenerator = ({
  getDownloadData,
}: {
  getDownloadData: () => Promise<{ total: number; data: Imembers[] }>;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();
  // const sheet = workbook.addWorksheet("member_list");

  const saveExcel = async () => {
    const data = await getDownloadData();
    try {
      setLoading(true);
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet('member_list');
      const titleRow = worksheet.addRow([
        'Membership No',
        'Agency Name & Address',
        'Name of Representative & Designation',
        'TIN NO',
        'Photo',
        'Contact No & Email',
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      worksheet.columns = [
        { header: 'Membership No', key: 'Membership No', width: 15 },
        {
          header: 'Agency Name & Address',
          key: 'Agency Name & Address',
          width: 25,
        },
        {
          header: 'Name of Representative & Designation',
          key: 'Name of Representative & Designation',
          width: 40,
          outlineLevel: 1,
        },
        { header: 'TIN NO', key: 'TIN NO', width: 15 },
        { header: 'Photo', key: 'Photo', width: 10 },
        { header: 'Contact No & Email', key: 'Contact No & Email', width: 45 },
      ];
      await Promise.all(
        data?.data?.map(async (item) => {
          const row = worksheet.addRow([
            item.user_member_id_card_number,
            item.user_member_company_name,
            `${item.user_member_representative_name} & ${item.user_member_representative_designation}`,
            item.company_tin_number,
            '',
            `${item.user_member_phone} & ${item.user_member_email}`,
          ]);

          // if (user_member_representative_photo) {
          //   row.height = 100;
          //   sheet.columns.forEach((column) => {
          //     column.width = 15;
          //   });

          //   const imgBuffer = await getImageBuffer(
          //     `${imgUrl}/${user_member_representative_photo}`
          //   );

          //   if (imgBuffer) {
          //     const imageId = workbook.addImage({
          //       buffer: imgBuffer,
          //       extension: "jpeg",
          //     });

          //     const imageCell = row.getCell(5);

          //     sheet.addImage(imageId, {
          //       tl: {
          //         col: Number(imageCell.col) - 1,
          //         row: Number(imageCell.row) - 1,
          //       },
          //       ext: { width: 100, height: 100 },
          //     });
          //   }
          // }

          // if (user_member_representative_photo) {
          //   row.height = 100;
          //   sheet.columns.forEach((column) => {
          //     column.width = 15;
          //   });

          //   const imgBuffer = await getImageBuffer(
          //     `${imgUrl}/${user_member_representative_photo}`
          //   );

          //   if (imgBuffer) {
          //     const imageId = workbook.addImage({
          //       buffer: imgBuffer.data,
          //       extension: "jpeg",
          //     });

          //     const imageCell = row.getCell(5);

          //     sheet.addImage(imageId, {
          //       tl: {
          //         col: Number(imageCell.col) - 1,
          //         row: Number(imageCell.row) - 1,
          //       },
          //       ext: { width: 100, height: 100 },
          //     });
          //   }
          // }
        })
      );

      // write the content using writeBuffer
      const excelDataGenerate = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([excelDataGenerate]), `member_list.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error('Something Went Wrong', error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant='outlined' size='small'>
          Download excel
        </Button>
      )}
    </>
  );
};

export default ExcelGenerator;

// // get image buffer
// async function getImageBuffer(url: string) {
//   try {
//     const imgBuffer = await axios.get(url, {
//       responseType: "arraybuffer",
//     });
//     return imgBuffer;
//   } catch (err) {
//     return null;
//   }
// }

//
const getImageBuffer = async (imageUrl: string) => {
  try {
    const response = await fetch(imageUrl);

    if (!response.ok) {
      throw new Error('Image fetch failed');
    }
    const buffer = await response.arrayBuffer();
    return Buffer.from(buffer);
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
};
