import { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { useParams } from 'react-router-dom';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { ICompanyownerPassport } from 'src/Types/module/member/CompanyInformation';
import moment from 'moment';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import UpdateOwnerpassport from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateOwnerpassport';
import AddOwnerPassport from '../../../CompanyAddInfo/CompanyAddInfoComponents/AddOwnerPassport';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import PdfAndImageView from 'src/common/PdfAndImageView';

const OwnerPassport = () => {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const { response, setRefetch, loading } = GetDataApi<ICompanyownerPassport>(
    `/api/atab/admin/member-company/member/owner-passport/${memberId}`
  );

  const { expiry_date, issue_date, passport_file, passport_number } =
    response || {};

  return (
    <Box>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Passport Information'
            actionButtonTitle={passport_number ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>

        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {passport_number ? (
              <Grid sx={{ pb: 3 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Passport number:
                          </TableCell>
                          <TableCell align='right'>
                            {passport_number ? passport_number : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Passport issue date:
                          </TableCell>
                          <TableCell align='right'>
                            {issue_date
                              ? moment(issue_date).format('Do MMM YYYY')
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Passport expiry date:
                          </TableCell>
                          <TableCell align='right'>
                            {expiry_date
                              ? moment(expiry_date).format('Do MMM YYYY')
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Download Passport :
                          </TableCell>
                          <TableCell align='right'>
                            {passport_file ? (
                              <PdfAndImageView file={`${passport_file}`} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
            <Box textAlign={'end'}>
              <PageTitle
                actionButtonTitle={passport_number ? 'Update' : ''}
                setAction={setOpen}
              />
            </Box>
          </>
        )}
      </>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: passport_number
            ? 'Update Owner Passport Information'
            : 'Add Owner Passport Information',
        }}
      >
        {passport_number ? (
          <UpdateOwnerpassport
            memberId={memberId}
            passport={response}
            setRefresh={setRefetch}
            setOpen={setOpen}
          />
        ) : (
          <AddOwnerPassport
            memberId={memberId}
            setRefresh={setRefetch}
            setOpen={setOpen}
          />
        )}
      </ModalsWrapper>
    </Box>
  );
};

export default OwnerPassport;
