import { RouterProvider, useRoutes } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import '../src/Styles/Login_reg.css';
import '../src/Styles/responsive.css';
import '../src/Styles/Dashboard.css';
import '../src/Styles/bind.css';
import '../src/Styles/index.css';
import { routes } from './router';

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <RouterProvider router={routes} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
