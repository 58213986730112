import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";

const MeetingTableRow = ({ allMembers }: any) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {allMembers.member_meeting_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {allMembers.member_meeting_title
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {moment(allMembers.member_meeting_date).format("LL")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {moment(allMembers.member_meeting_time, "HH:mm").format("hh:mm A")}
        </Typography>
      </TableCell>

      <TableCell>
        <Link
          to={`/admin-list/single-admin/${allMembers.meeting_created_by_id}`}
        >
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allMembers.meeting_created_by_name}
          </Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {getStatusLabel(allMembers.member_meeting_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          to={`/member-list/meeting/${allMembers.member_meeting_id.toString()}`}
        >
          <Tooltip title="view" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default MeetingTableRow;
