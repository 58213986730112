import {
  Box,
  Card,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { IJoinedExam, ISingleExamInfo } from "src/Utils/type";
import moment from "moment";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { getStatusLabel } from "src/Utils/Combined";
import UpdateExam from "./UpdateExam";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import FilterOfSingleExam from "./FilterOfSingleExam";
import AddResultMark from "./AddResultMark";
import AddAttendance from "./AddAttendance";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

const SingleExam = () => {
  const [singleExamInfo, setSingleExamInfo] = useState<ISingleExamInfo | null>(
    {} as ISingleExamInfo
  );
  const [joinedExam, setJoinedExam] = useState<IJoinedExam[] | null>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [tabLoading, setTabLoading] = useState<Boolean>(false);
  const [refresh, setRefresh] = useState<Boolean>(false);
  const { singleExamId } = useParams();
  const [open, setOpen] = useState({ open: false, value: "" });
  const [value, setValue] = useState<string>("attendance");

  useEffect(() => {
    setLoading(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/training/exam/get/single/with-attendence-result/by/exam-id/${singleExamId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setSingleExamInfo(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [singleExamId, refresh]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (value === "joined") {
      setTabLoading(true);
      axios
        .get(
          `${url}/api/training/exam/get/all/joined/student/by/exam-id/${singleExamId}`,
          {
            cancelToken: source.token,
          }
        )
        .then((data) => {
          setJoinedExam(data.data.data);
          setTabLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            setTabLoading(false);
          } else {
            // handle error
            setTabLoading(false);
          }
        });
    }
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [value, refresh]);

  let content: ReactNode;

  if (open.open && open.value === "attendance") {
    content = (
      <AddAttendance
        handleClose={() => setOpen({ open: false, value: "" })}
        singleExamInfo={singleExamInfo}
      />
    );
  }

  if (open.open && open.value === "exam") {
    content = (
      <UpdateExam
        singleExamInfo={singleExamInfo}
        setSingleExamInfo={setSingleExamInfo}
        handleClose={() => setOpen({ open: false, value: "" })}
      />
    );
  }
  if (open.open && open.value === "mark") {
    content = (
      <AddResultMark
        handleClose={() => setOpen({ open: false, value: "" })}
        singleExamInfo={singleExamInfo}
      />
    );
  }

  return !loading ? (
    <>
      <Helmet>
        <title>HAAB - Single exam information</title>
      </Helmet>

      <Container sx={{ mt: 8 }} maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {singleExamInfo && (
            <Card sx={{ p: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  textAlign="center"
                  gutterBottom
                  variant="h2"
                  component="div"
                >
                  Single exam information
                </Typography>
                <Box>
                  {singleExamInfo.attendance?.length > 0 ? (
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() => setOpen({ open: true, value: "mark" })}
                      title="Add result mark of this exam trainee"
                      variant="outlined"
                    >
                      <AddOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                      Add result mark
                    </Button>
                  ) : (
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() =>
                        setOpen({ open: true, value: "attendance" })
                      }
                      title="Add attendance of this exam trainee"
                      variant="outlined"
                    >
                      <AddOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                      Add attendance
                    </Button>
                  )}

                  <Button
                    sx={{ mx: 1 }}
                    onClick={() => setOpen({ open: true, value: "exam" })}
                    title="Edit exam"
                    variant="outlined"
                  >
                    <ModeEditOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                    Edit exam
                  </Button>
                  <Button
                    sx={{ mx: 1 }}
                    onClick={() => setRefresh((prev) => !prev)}
                    title="Refresh"
                    variant="outlined"
                  >
                    <RefreshRoundedIcon sx={{ mr: 1 }} fontSize="small" />
                    Refresh
                  </Button>
                </Box>
              </Box>
              <CardContent sx={{ mt: 2 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training batch ID:
                    </Typography>
                    {singleExamInfo?.training_batch_id}
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Batch name:
                    </Typography>
                    {singleExamInfo?.training_batch_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training exam ID:
                    </Typography>
                    {singleExamInfo?.training_exam_id}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training exam date:
                    </Typography>
                    {moment
                      .tz(singleExamInfo?.training_exam_date, "Asia/Dhaka")
                      .format("DD-MM-YYYY")}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training exam created at:
                    </Typography>
                    {moment
                      .tz(
                        singleExamInfo?.training_exam_created_at,
                        "Asia/Dhaka"
                      )
                      .format("DD-MM-YYYY")}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training exam time:
                    </Typography>
                    {moment(
                      singleExamInfo.training_exam_time,
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training exam status:
                    </Typography>
                    {getStatusLabel(singleExamInfo?.training_exam_status)}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Training exam remark:
                    </Typography>
                    {singleExamInfo?.training_exam_remark}
                  </Grid>
                  {singleExamInfo.training_exam_material_file && (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Training exam material file:
                      </Typography>
                      <a
                        href={`${imgUrl}/${singleExamInfo.training_exam_material_file}`}
                      >
                        {singleExamInfo.training_exam_material_file}
                      </a>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          )}
          <FilterOfSingleExam
            setValue={setValue}
            value={value}
            loading={tabLoading}
            attendance={singleExamInfo.attendance}
            joinedExam={joinedExam}
          />
        </Grid>
      </Container>
      {open.open && (
        <Dialog
          open={open.open}
          maxWidth="md"
          onClose={() => setOpen({ open: false, value: "" })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setOpen({ open: false, value: "" })}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  ) : (
    <GlobalLoader />
  );
};

export default SingleExam;
