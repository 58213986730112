import { useEffect, useState, ChangeEvent } from 'react';
import {
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Box,
  Divider,
  Container,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { DatePicker } from '@mui/lab';
import { IpaymentableItem } from 'src/Types/module/invoice/invoiceTypes';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import ShowSubscriptionReport from './ShowSubscriptionReport';
import { ISubscriptionReport } from 'src/Types/module/common/commonTypes';
import { useSearchParams } from 'react-router-dom';
import YearlyExcelGenerator from './YearlyExcelGenerator';

const SubscriptionReport = () => {
  const [SubscriptionsReport, setSubscriptionsReport] = useState<
    ISubscriptionReport[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [limit, setLimit] = useState<number>(25);

  const [searchParams, setSearchParams] = useSearchParams({
    status: 'all',
    page: '0',
  });
  const page = parseInt(searchParams.get('page'));
  const [total, setTotal] = useState<number>(0);
  const [statusItem, setStatusItem] = useState<{
    status: string;
    item: number;
  }>({
    status: 'paid',
    item: 0,
  });
  const [filterItem, setFilterItem] = useState({
    zone: [],
    subscription: '',
  });
  const [selectFilter, setSelectFilter] = useState<any>({
    zone: 0,
    subscription: '',
  });
  const [invoiceItem, setInvoiceItem] = useState([]);

  // get all zone
  useEffect(() => {
    (async function () {
      const { data } = await axios.get(`${url}/api/atab/common/get/all/zone`);
      if (data.success) {
        setFilterItem({ ...filterItem, zone: data.data });
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);

    (async function () {
      try {
        let uri = `${url}/api/member/subscription-report?`;

        if (date.startDate && date.endDate) {
          uri += `from_date=${
            date.startDate.toISOString().split('T')[0]
          }&to_date=${date.endDate.toISOString().split('T')[0]}&`;
        }
        if (page >= 0) {
          uri += `skip=${limit * page}&limit=${limit}&`;
        }

        if (selectFilter.zone) {
          uri += `zone=${selectFilter?.zone.zone_id}&`;
        }

        const { data } = await axios.get(uri);

        if (data.success) {
          setSubscriptionsReport(data.data);
          setTotal(data.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [selectFilter?.zone.zone_id, date.endDate, date.startDate, limit, page]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const getDownloadData: () => Promise<{
    data: ISubscriptionReport[];
  }> = async () => {
    try {
      let uri = `${url}/api/member/subscription-report/excel?`;

      if (selectFilter.zone) {
        uri += `zone=${selectFilter?.zone.zone_id}&`;
      }
      if (date.startDate && date.endDate) {
        uri += `from_date=${
          date.startDate.toISOString().split('T')[0]
        }&to_date=${date.endDate.toISOString().split('T')[0]}&`;
      }
      const { data } = await axios.get(uri);

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid>
        <Grid item xs={12} mb={2}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <DatePicker
                  label='Start year'
                  value={date.startDate}
                  openTo='year'
                  views={['year']}
                  onChange={(e: any) => setDate({ ...date, startDate: e })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label='End year'
                  value={date.endDate}
                  openTo='year'
                  views={['year']}
                  onChange={(e: any) => setDate({ ...date, endDate: e })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <FormControl sx={{ width: '120px' }} size='small'>
                  <InputLabel id='demo-simple-select-label'>Zone</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={selectFilter.zone}
                    label='Zone'
                    onChange={(e: any) =>
                      setSelectFilter({
                        ...selectFilter,
                        zone: e.target.value,
                      })
                    }
                  >
                    {filterItem.zone.map((sZone) => {
                      return (
                        <MenuItem value={sZone} key={sZone.zone_id}>
                          {sZone.zone_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: '120px' }} size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Subscription
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={statusItem.item}
                    label='Select invoice item'
                    onChange={(e: any) =>
                      setStatusItem({
                        item: e.target.value,
                        status: statusItem.status,
                      })
                    }
                  >
                    {invoiceItem.map((item: IpaymentableItem) => {
                      return (
                        <MenuItem
                          key={item.atab_paymentable_item_id}
                          value={item.atab_paymentable_item_id}
                        >
                          {item.atab_paymentable_item_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={`Subscription Report ( ${total})`}
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <YearlyExcelGenerator getDownloadData={getDownloadData} />
                  {/* <ExcelGenerator getDownloadData={getDownloadData} /> */}
                  {/* <PdfGenerator getDownloadData={getDownloadData} /> */}
                </Box>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial No</TableCell>
                    <TableCell>License No</TableCell>
                    <TableCell>Agency Name</TableCell>
                    <TableCell>Subscription Year</TableCell>
                    <TableCell>Zone</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : SubscriptionsReport.length ? (
                    <>
                      {SubscriptionsReport.map((sReport, index) => {
                        return (
                          <ShowSubscriptionReport
                            sReport={sReport}
                            key={index}
                            index={index}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscriptionReport;
