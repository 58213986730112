import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  CardContent,
  Autocomplete,
  Chip,
} from "@mui/material";
import { muiStyles } from "src/Utils/Styles";
import { Imembers } from "src/Types/module/member/memberTypes";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import Toaster from "src/Toaster/Toaster";

const useStyles = muiStyles;

const SingleSMS = () => {
  const classes = useStyles();
  const { user } = useAuthContext();
  const [allMember, setAllMember] = useState<Imembers[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<string[]>([]);
  const Toast = Toaster();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setisLoading(true);
    (async function () {
      try {
        let uri;
        if (user.permissions.modules.includes("all-admin")) {
          uri = `${url}/api/atab/user-member/get/all/by/zone/status/for-admin/all/all?skip=0&limit=10000`;
        }
        const { data } = await axios.get(uri);

        if (data.success) {
          setAllMember(data.data);
          setisLoading(false);
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setisMsgSent(true);
    const selectedMember = membersId.map((member) => {
      return parseInt(member.split(" ")[0]);
    });

    const body = {
      member_id: selectedMember,
      message: text,
    };

    try {
      const { data } = await axios.post(
        `${url}/api/atab/user-member/send/sms?user_admin_id=${user.user_admin_id}`,
        body
      );
      if (data.success) {
        Toast.fire({
          icon: "success",
          title: `Message sent Successfully`,
        });
        setisMsgSent(false);
        setText("");
      }
    } catch (err) {
      Toast.fire({
        icon: "success",
        title: `Message doesn't sent`,
      });
      setisMsgSent(false);
    }
  };
  return (
    <CardContent>
      <Box
        component='form'
        onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
        sx={{ p: 2 }}
        className=''
      >
        {!isLoading && (
          <Autocomplete
            multiple
            id='tags-filled'
            options={allMember.map(
              (option) =>
                option.user_member_id +
                " " +
                option.user_member_representative_name
            )}
            freeSolo
            onChange={(event, newValue) => {
              setMembersId(newValue);
            }}
            renderTags={(value: readonly any[], getTagProps) =>
              value.map((option: any, index: number) => (
                <Chip
                  variant='filled'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label='Member' placeholder='More..' />
            )}
          />
        )}
        <TextField
          margin='normal'
          required
          fullWidth
          id='message'
          label='Message '
          autoComplete='message'
          autoFocus
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setText(e.target.value)
          }
          value={text}
          multiline
          minRows={10}
          variant='filled'
          className={classes.inputText}
          InputProps={{ classes }}
        />
        {!isMSgsent ? (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Send message
          </Button>
        ) : (
          <LoadingButton
            loading={isMSgsent}
            fullWidth
            color='secondary'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
            className={classes.signInButton}
            loadingPosition='end'
            variant='contained'
          >
            Loading
          </LoadingButton>
        )}
      </Box>
    </CardContent>
  );
};

export default SingleSMS;
