import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import moment from "moment";
import { IAllAttendVisitor } from "src/Utils/type";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

const AllAttendVisitor = ({ eventId }: any) => {
  const [allAttendVisitor, setAllAttendVisitor] = useState<IAllAttendVisitor[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/event/get/all/attend-visitor/by/2`
        );
        if (data.success) {
          setAllAttendVisitor(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId]);
  return (
    <>
      <TableContainer sx={{ marginBottom: 10 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attend Visitor ID</TableCell>
              <TableCell align="center">Attend Visitor Name</TableCell>
              <TableCell align="center">Join Date</TableCell>
              <TableCell align="center">Join At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {allAttendVisitor.length ? (
                  <>
                    {allAttendVisitor.map((details) => (
                      <TableRow hover key={details.fair_event_attend_id}>
                        <TableCell align="center">
                          {details.fair_event_attend_id}
                        </TableCell>
                        <TableCell align="center">
                          {details.visitor_first_name}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {moment(
                            details.fair_event_joined_visitor_join_date
                          ).format("L")}
                        </TableCell>
                        <TableCell align="center">
                          {moment(details.fair_event_attend_at).format("LLL")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllAttendVisitor;
