import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Toaster from 'src/Toaster/Toaster';
import { url } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

interface IProps {
  handleClose: Function;
  allGuest?: any;
  setAllGuest?: Function;
}
const CreateSpeakerModal = ({ handleClose, allGuest, setAllGuest }: IProps) => {
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const [loading, setLoading] = useState<Boolean>(false);
  const [guestType, setGuestType] = useState<string>('');
  const [photo, setPhoto] = useState<string>('');
  const { user } = useAuthContext();
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('guest_speaker_type', guestType);
      formData.append('guest_speaker_photo', photo);

      for (const key in inputs) {
        if (typeof inputs[key] === 'object') {
          formData.append(key, inputs[key][0]);
        } else {
          formData.append(key, inputs[key]);
        }
      }

      const { data } = await axios.post(
        `${url}/api/fair/create/guest-or-speaker?user_admin_id=${user.user_admin_id}`,
        formData
      );

      if (data.success) {
        setAllGuest([
          {
            guest_speaker_id: data.data.guest_speaker_id,
            guest_speaker_name: data.data.guest_speaker_name,
            guest_speaker_designation: data.data.guest_speaker_designation,
            guest_speaker_company_name: data.data.guest_speaker_company_name,
            guest_speaker_details: data.data.guest_speaker_details,
            guest_speaker_photo: data.data.guest_speaker_photo,
            guest_speaker_status: data.data.guest_speaker_status,
            guest_speaker_type: data.data.guest_speaker_type,
          },
          ...allGuest,
        ]);
        Toast.fire({
          icon: 'success',
          title: `${data.message}`,
        });
        setLoading(false);
        handleClose();
      } else {
        Toast.fire({
          icon: 'fail',
          title: `${data.message}`,
        });
        setLoading(false);
      }

      reset();
    } catch (err: any) {
      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create Speaker</title>
      </Helmet>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component='form'
        sx={{ p: 2, mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='name'
              label='Speaker name'
              {...register('guest_speaker_name')}
              type='text'
              autoFocus
              variant='outlined'
            />
          </Grid>

          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='name'
              label='Company name'
              {...register('guest_speaker_company_name')}
              type='text'
              autoFocus
              variant='outlined'
            />
          </Grid>
          <Grid xs={12} sm={6} lg={4} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='text'
              label='Designation'
              {...register('guest_speaker_designation')}
              type='text'
              autoFocus
              variant='outlined'
            />
          </Grid>
          <Grid xs={12} sm={6} lg={4} item>
            <FormControl variant='outlined' fullWidth sx={{ mt: 2 }}>
              <InputLabel id='demo-simple-select-filled-label'>Type</InputLabel>
              <Select
                labelId='demo-simple-select-filled-label'
                id='demo-simple-select-filled'
                label='Type'
                value={guestType}
                onChange={(e) => setGuestType(e.target.value)}
              >
                <MenuItem value='guest'>Speaker</MenuItem>
                <MenuItem value='speaker'>Speaker</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              margin='normal'
              fullWidth
              id='file'
              label='Choose Speaker Photo'
              InputLabelProps={{ shrink: true }}
              onChange={(e: any) => setPhoto(e.target.files[0])}
              autoComplete='file'
              type='file'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <TextField
              label='Speaker details'
              placeholder='Enter some details about this Speaker here..'
              multiline
              {...register('guest_speaker_details')}
              minRows={5}
              fullWidth
            />
          </Grid>
        </Grid>

        {loading ? (
          <Button
            disabled
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            loading...
          </Button>
        ) : (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Create Speaker
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreateSpeakerModal;
