import { Box, Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
const CreateComplain = ({ memberId }: { memberId: number }) => {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, watch, reset } = useForm();
  const [inputData, setInputData] = useState<string>('');
  const [docs, setDocs] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const token = localStorage.getItem('haab_admin');
  const Toast = Toaster();

  const onSubmit = async (inputs: any) => {
    console.log(inputData, docs);
    const formData = new FormData();
    const body = {
      content: inputData,
      member_id: memberId,
    };

    if (inputData) {
      formData.append('content', inputData);
    }
    if (memberId) {
      formData.append('member_id', String(memberId));
    }
    if (docs) {
      formData.append('file', docs);
    }
    if (inputData && memberId) {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${url}/api/atab/complain`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });

          setInputData('');
          setLoading(false);
          setOpen(false);
        } else {
          Toast.fire({
            icon: 'error',
            title: data.message,
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      Toaster().fire({
        icon: 'error',
        title: 'write something',
      });
    }
  };
  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <ModalsWrapper
          modalData={{ title: 'Create New Complain' }}
          setShowModal={setOpen}
          showModal={open}
        >
          <Box sx={{ px: 2 }}>
            <Grid item xs={12} md={12}>
              <TextField
                label='Upload document'
                margin='normal'
                fullWidth
                {...register('file', {
                  onChange: (e) => {
                    setDocs(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }, // Custom onChange handler
                })}
                type='file'
                focused
              />
            </Grid>
            <Grid item xs={12} md={12} mt={2}>
              <TextField
                id='outlined-basic'
                placeholder='Enter Complain'
                label='Enter Complain'
                required
                onInput={(e: any) => setInputData(e.target.value)}
                variant='outlined'
                type='text'
                fullWidth
                multiline
                rows={4}
                sx={{ width: '600px' }}
                {...register('text')}
              />
            </Grid>
            <Grid item xs={12} md={12} mt={2}>
              {loading ? (
                <Button
                  onClick={() => setOpen(true)}
                  type='submit'
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                    width: '10%',
                  }}
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  onClick={onSubmit}
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                >
                  Submit Complain
                </Button>
              )}
            </Grid>
          </Box>
        </ModalsWrapper>

        <Button
          onClick={() => setOpen(true)}
          fullWidth
          variant='contained'
          disableElevation
          size='small'
        >
          Create Complain
        </Button>
      </Box>
    </>
  );
};

export default CreateComplain;
