import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  ICompanyTradeLicense,
  ICompanyTradeLicenseResponse,
} from 'src/Types/module/member/CompanyInformation';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateCompanyTradeLicense from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyTradeLicense';
import ParticularsOfPersonRepresentOrganization from '../../../CompanyAddInfo/CompanyAddInfoComponents/ParticularsOfPersonRepresentOrganization';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { HttpClient } from 'src/Helpers/http/http';
import PdfAndImageView from 'src/common/PdfAndImageView';

function TradeLicenseInformation() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [tradeLicense, setTradeLicense] = useState<ICompanyTradeLicense>(
    {} as ICompanyTradeLicense
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const data: ICompanyTradeLicenseResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/license/${memberId}`
        );
        if (data.success) {
          setTradeLicense(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    company_trade_license,
    company_trade_license_file,
    company_trade_license_expiry_date,
    company_tin_number,
    company_tin_file,
    company_bin_number,
    company_bin_file,
  } = tradeLicense || {};
  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Trade license information'
            actionButtonTitle={company_trade_license ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {company_trade_license ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company trade license:
                          </TableCell>
                          <TableCell align='right'>
                            {company_trade_license
                              ? company_trade_license
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company trade license file:
                          </TableCell>
                          <TableCell align='right'>
                            {company_trade_license_file ? (
                              <PdfAndImageView
                                file={company_trade_license_file}
                              />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>

                        {/* Need actual key of field */}
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Trade license expiry date:
                          </TableCell>
                          <TableCell align='right'>
                            {company_trade_license_expiry_date
                              ? moment(
                                  company_trade_license_expiry_date
                                ).format('Do MMM YYYY')
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'></TableCell>
                          <TableCell align='right'>
                            <Box textAlign={'end'}>
                              <PageTitle
                                actionButtonTitle={
                                  company_trade_license ? 'Update' : ''
                                }
                                setAction={setOpen}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: company_trade_license
            ? 'Update trade license information'
            : 'Add trade license information',
        }}
      >
        {company_trade_license ? (
          <UpdateCompanyTradeLicense
            memberId={memberId}
            tradeLicense={tradeLicense}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <ParticularsOfPersonRepresentOrganization
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>
    </>
  );
}

export default TradeLicenseInformation;
