import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@mui/material';
import { useEffect, useState } from 'react';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import Toaster from 'src/Toaster/Toaster';
const Toast = Toaster();
const TicketStatusUpdate = ({
  data,
  getData,
  setOpen,
}: {
  data: any;
  getData: () => Promise<void>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [statuses, setStatuses] = useState<string[]>([data?.status]); // State for the checkbox values
  const { response, loading, updateData, success } = UpdateDataApi<any>(
    `/api/atab/user-member/admin/ticket/${data.id}`
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setStatuses((prev) => [...prev, value]);
    } else {
      setStatuses((prev) => prev.filter((status) => status !== value));
    }
  };

  const handleData = async () => {
    if (statuses[1]?.length) {
      updateData({ status: statuses[1] });
    } else {
      Toast.fire({
        icon: 'error',
        title: `select first`,
      });
    }
  };
  useEffect(() => {
    if (success) {
      getData();
      setOpen(false);
    }
  }, [success]);
  return (
    <FormControl sx={{ width: 300, paddingLeft: 2, paddingRight: 2 }}>
      <FormLabel id='checkbox-group-label'>Status</FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            disabled={data.status === 'completed'}
            value='completed'
            checked={statuses.includes('completed')} // Check if 'completed' is in the array
            onChange={handleCheckboxChange}
          />
        }
        label='Completed'
      />

      <Button
        variant='contained'
        onClick={handleData} // Call the handleData function on button click
        disabled={loading || data.status === 'completed'} // Disable button if loading is true
      >
        {loading ? 'Submitting...' : 'Submit'}
      </Button>
    </FormControl>
  );
};

export default TicketStatusUpdate;
