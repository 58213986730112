import { Button, IconButton, Grid, Box } from '@mui/material';
import { PhotoCamera, DeleteForeverOutlined } from '@mui/icons-material';

type IProps = {
  setImages: React.Dispatch<React.SetStateAction<any[]>>;
  images: any[];
};
const MultipleImageUpload = ({ setImages, images }: IProps) => {
  // Handle image file upload and preview
  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      newImages.push({
        file: files[i],
        preview: URL.createObjectURL(files[i]),
      });
    }

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  // Remove selected image
  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Box sx={{ px: 1 }}>
      <Button
        size='small'
        fullWidth
        variant='contained'
        component='label'
        startIcon={<PhotoCamera />}
      >
        Upload Documents/Voucher
        <input
          type='file'
          accept='image/*'
          multiple
          hidden
          onChange={handleImageChange}
        />
      </Button>

      {/* Display uploaded images */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {images?.map((image, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <div style={{ position: 'relative' }}>
              <img
                src={image.preview}
                alt={`preview-${index}`}
                style={{
                  width: '100%',
                  height: '70px',
                  borderRadius: '8px',
                }}
              />
              <IconButton
                aria-label='remove'
                color='secondary'
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  background: 'rgba(255, 255, 255, 0.6)',
                }}
                onClick={() => handleRemoveImage(index)}
              >
                <DeleteForeverOutlined color='error' />
              </IconButton>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MultipleImageUpload;
