import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import { IsingleMemberMeeting } from "src/Types/module/meeting/singleMemberMeeting";
import CardMeeting from "./CardMeeting";

const SingleMeetingPage = () => {
  const [singleInvoiceInfo, setSingleInvoiceInfo] =
    useState<IsingleMemberMeeting>();
  const [loading, setLoading] = useState<Boolean>(false);
  const { meetingId } = useParams();

  const [editMode, setEditMode] = useState(false);

  const handleEditModeChange = () => {
    setEditMode((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/member/meeting/get/a/single/with/joined-member/${meetingId}`
        );
        if (data.success) {
          setSingleInvoiceInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setSingleInvoiceInfo(null);
      }
    })();
  }, [meetingId, editMode]);

  return (
    <div>
      <Helmet>
        <title>HAAB - Single Meeting</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <CardMeeting
            sx={{ p: 5 }}
            singleInvoiceInfo={singleInvoiceInfo}
            handleEditModeChange={handleEditModeChange}
            editMode={editMode}
          />
        </Grid>
      </Container>
    </div>
  );
};

export default SingleMeetingPage;
