import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import CheckIcon from "@mui/icons-material/Check";
import { IDocumentPdfPrintInfo } from "src/Types/module/member/AdmissionFromData";

const ThirdPageForm = ({ memberId }: { memberId: number }) => {
  const [FormInfo, setFromInfo] = useState<IDocumentPdfPrintInfo>(
    {} as IDocumentPdfPrintInfo
  );
  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/member/print-member/form/${memberId}`
      );

      if (data.success) {
        setFromInfo(data.data);
      }
    })();
  }, [memberId]);

  const moneyFormat = (value: number) => {
    const money = new Intl.NumberFormat("en-BD", {
      maximumSignificantDigits: 3,
    }).format(value);
    return money;
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          mb: 2,
          pt: 3,
        }}
      >
        <Typography
          fontSize={18}
          sx={{
            border: "2px solid gray",
            display: "inline-block",
            p: 0.5,
            borderRadius: "4px",
            textTransform: "uppercase",
          }}
        >
          Check list for membership
        </Typography>
      </Box>
      <table className="member-info-print document-submit">
        <thead>
          <tr>
            <th colSpan={8}>Documents to be Submitted</th>
            <th colSpan={2}>Received</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td colSpan={7}>
              pay order of TK{" "}
              {moneyFormat(
                Number(
                  FormInfo?.membershipFee?.atab_paymentable_item_bdt_amount
                ) +
                  Number(
                    FormInfo?.annualSubscriptionFee
                      ?.atab_paymentable_item_bdt_amount
                  ) *
                    3 +
                  Number(FormInfo?.idCardFee?.atab_paymentable_item_bdt_amount)
              )}{" "}
              from any Bank in Favor of HAAB
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.checkPaymentSatus && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.checkPaymentSatus ? <CheckIcon /> : ""}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td colSpan={7}>
              Two copies of latest passport size color photographs of owner
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_owner_photo && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_owner_photo && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td colSpan={7}>Photocopy of current trade license</td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_trade_license && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_trade_license && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td colSpan={7}>
              Photocopy of current TIN certificate / TAX payment certificate
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_tin_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_tin_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td colSpan={7}>
              Photocopy of certificate issued by Ministry of Civil Aviation and
              Tourism (MOCAT)
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.certificate_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.certificate_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td colSpan={7}>Photocopy of NID / Passport</td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_owner_nid && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_owner_nid && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td colSpan={7}>
              Photocopy of Incorporation Certificate (Only for Limited Company)
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_incorporation_certificate && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_incorporation_certificate && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td colSpan={7}>
              Photocopy of Company memorandum & articles (Only for Limited
              Company)
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_memorandum_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_memorandum_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td colSpan={7}>
              Photocopy of Deed partnership (Only for Partnership Business)
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_partnership_deed_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_partnership_deed_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td colSpan={7}>
              Deed of agreement of Rental Office space / Land Ownership
              Documents
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_agreement_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_agreement_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td colSpan={7}>Personal details of Owner/Owners/Bio Data</td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_owner_biodata && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_owner_biodata && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td colSpan={7}>Internal & External Photographs of Office</td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo?.company_office_photographs?.length && (
                    <CheckIcon />
                  )}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo?.company_office_photographs?.length && (
                    <CheckIcon />
                  )}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td colSpan={7}>
              At least one staff should have Certificate Hajj Agencies
              Association of Bangladesh (HAAB).
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_one_staff_certificate && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_one_staff_certificate && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>14</td>
            <td colSpan={7}>
              Staff list on office pad with seal and signature of owner/Managing
              Director/Managing Partner.
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.company_staff_list_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.company_staff_list_file && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td>15</td>
            <td colSpan={7}>
              HAAB Membership ID Card Form (Format available at HAAB Website
              www.haabbd.com, www.haab.com.bd).
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  NO
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {!FormInfo.checkIdCard && <CheckIcon />}
                </Typography>
              </Box>
            </td>
            <td>
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
                  YES
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid gray",
                    width: "25px",
                    height: "24px",
                  }}
                >
                  {FormInfo.checkIdCard && <CheckIcon />}
                </Typography>
              </Box>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography fontWeight={"600"} my={1}>
        N.B.: Complete application form should be submitted directly at HAAB
        Office or can be sent by registered post. (Incomplete Application will
        not be honored)
      </Typography>
      <Typography fontWeight={"600"} my={1} fontSize={14}>
        Description of Membership/Yearly Subscription/ID Card Fees chart is as
        below.
      </Typography>
      <Box mt={2}>
        <table className="member-info-print">
          <thead>
            <tr className="business-license-table-head">
              <th>Membership Fee</th>
              <th>Annual Subscriptions For Three Years</th>
              <th> ID Card Fee</th>
              <th> In Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="business-license-table-body ">
              <td>
                BDT{" "}
                {moneyFormat(
                  Number(
                    FormInfo?.membershipFee?.atab_paymentable_item_bdt_amount
                  )
                )}
              </td>
              <td>
                BDT{" "}
                {moneyFormat(
                  Number(
                    FormInfo?.annualSubscriptionFee
                      ?.atab_paymentable_item_bdt_amount
                  ) * 3
                )}
                (
                {moneyFormat(
                  Number(
                    FormInfo.annualSubscriptionFee
                      ?.atab_paymentable_item_bdt_amount
                  )
                )}
                x 3)
              </td>
              <td>
                BDT {FormInfo.idCardFee?.atab_paymentable_item_bdt_amount}
              </td>
              <td>
                BDT{" "}
                {moneyFormat(
                  Number(
                    FormInfo?.membershipFee?.atab_paymentable_item_bdt_amount
                  ) +
                    Number(
                      FormInfo?.annualSubscriptionFee
                        ?.atab_paymentable_item_bdt_amount
                    ) *
                      3 +
                    Number(
                      FormInfo?.idCardFee?.atab_paymentable_item_bdt_amount
                    )
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Typography fontWeight={"600"} my={2} textAlign={"center"}>
        -:End:-
      </Typography>
    </>
  );
};

export default ThirdPageForm;
