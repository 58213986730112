import { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IIdCard } from 'src/Types/module/IdCard/IdCard';
import { getStatusLabel } from 'src/Utils/Combined';
import { useDebounce } from 'src/hooks/useDebounce';

import CommonSearchfilterForAppliHeader from '../Common/CommonSearchfilterForAppliHeader';
const IdCard = () => {
  const [idCard, setIdCard] = useState<IIdCard[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/member/id-card/application?status=${status}&searchParams=${searchQuery}`
        );

        if (data.success) {
          setIdCard(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [status, searchQuery]);

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Card>
        <CommonSearchfilterForAppliHeader
          setSearchCompanyVal={setSearchCompanyVal}
          setStatus={setStatus}
          status={status}
          leftSideTitle='ID Card Applications'
          selectValue='completed'
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Agency Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>License No</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {idCard?.length ? (
                    <>
                      {idCard?.map((sIdCard) => {
                        return (
                          <TableRow key={sIdCard.id}>
                            <TableCell>{sIdCard.id}</TableCell>
                            <TableCell>{sIdCard.applicant_name}</TableCell>
                            <TableCell>
                              {sIdCard.user_member_hajj_license}
                            </TableCell>
                            <TableCell>
                              {moment(sIdCard.created_at).format('MMM Do YY')}
                            </TableCell>
                            <TableCell>
                              {getStatusLabel(sIdCard.status)}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/update-applications/id-card/${sIdCard.id}?tabValue=application`}
                              >
                                <Tooltip title='view' arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color='inherit'
                                    size='small'
                                  >
                                    <VisibilityIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default IdCard;
