import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  TextareaAutosize,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { IAccountType } from 'src/content/modules/Members/subModules/invoice/type/voucherType';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';
import PostDataApis from 'src/common/FetchData/PostDataApis';
import CommSelectFieldSearchmemberlistbyLicenseAndname from 'src/common/CommSelectFieldSearchmemberlistbyLicenseAndname';
import SpeceficOrAllWiseVoucher from './SpeceficOrAllWiseVoucher';

const CreateMoneyReceipt = ({
  refetchData,
  setOpen,
}: {
  refetchData: () => Promise<void>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { postData, loading, success } = PostDataApis(
    '/api/atab/money-receipt'
  );
  useEffect(() => {
    if (success) {
      refetchData();
      setOpen(false);
    }
  }, [success]);
  const { register, handleSubmit, watch, setValue } = useForm();
  const voucherType = watch('specific_type');
  const [memberId, seTMemberId] = useState<number>();
  const [id, setId] = useState();
  const onSubmit = async (inputs: any) => {
    const { ...removenullData } = removeUndefinedAndNull({
      ...inputs,
      amount: voucherType === 'specific' ? inputs.amount : '',
    });
    postData({ ...removenullData, account_id: id });
  };

  const paymentType = watch('payment_type');

  const { response, getData: accountData } = GetDataApi<IAccountType[]>(
    `/api/atab/account?account_type=${paymentType}`,
    true
  );
  useEffect(() => {
    if (paymentType !== 'Cheque' && paymentType) {
      accountData();
    }
  }, [paymentType]);

  return (
    <Box sx={{ p: 2 }}>
      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <CommSelectFieldSearchmemberlistbyLicenseAndname
                register={register}
                setValue={setValue}
                seTMemberId={seTMemberId}
              />
            </FormControl>
          </Grid>
          <SpeceficOrAllWiseVoucher
            register={register}
            setValue={setValue}
            watch={watch}
            memberId={memberId}
          />

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-simple-select-label'>
                Select Payment type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                required
                label='Select Payment Type'
                placeholder='Select Payment Type'
                {...register('payment_type')}
                value={paymentType || ''}
              >
                <MenuItem value={'Cash'}>Cash</MenuItem>
                <MenuItem value={'Bank'}>Bank</MenuItem>
                <MenuItem value={'Mobile Banking'}>Mobile Banking</MenuItem>
                <MenuItem value={'Cheque'}>Cheque</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {paymentType === 'Cash' && (
            <Grid xs={12} sm={6} item>
              <Autocomplete
                size='small'
                id='tags-standard'
                value={id}
                onChange={(e, mainValue: any) => {
                  setId(mainValue?.id);
                }}
                options={response?.length ? response : []}
                getOptionLabel={(option: any) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant='outlined'
                    label='Select accounts'
                    placeholder='More...'
                  />
                )}
              />
            </Grid>
          )}
          {paymentType === 'Bank' && (
            <Grid xs={12} sm={6} item>
              <Autocomplete
                size='small'
                id='tags-standard'
                onChange={(e, mainValue: any) => {
                  setId(mainValue?.id);
                }}
                options={response?.length ? response : []}
                getOptionLabel={(option: any) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant='outlined'
                    label='Select accounts'
                    placeholder='More...'
                  />
                )}
              />
            </Grid>
          )}
          {paymentType === 'Mobile Banking' && (
            <Grid xs={12} sm={6} item>
              <Autocomplete
                size='small'
                id='tags-standard'
                onChange={(e, mainValue: any) => {
                  setId(mainValue?.id);
                }}
                options={response?.length ? response : []}
                getOptionLabel={(option: any) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant='outlined'
                    label='Select accounts'
                    placeholder='More...'
                  />
                )}
              />
            </Grid>
          )}

          {paymentType === 'Cheque' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size='small'
                id='bank_name'
                label='Bank Name'
                {...register('bank_name')}
                type='text'
                variant='outlined'
              />
            </Grid>
          )}
          {paymentType === 'Bank' && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size='small'
                  id='branch'
                  label='Branch'
                  {...register('branch')}
                  type='text'
                  variant='outlined'
                />
              </Grid>
            </>
          )}

          {paymentType === 'Cheque' && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  fullWidth
                  id='cheque_no'
                  label='Cheque no'
                  {...register('cheque_no')}
                  type='text'
                  variant='outlined'
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextareaAutosize
              placeholder='Description'
              minRows={5}
              id='outlined-required'
              {...register('description')}
              style={{ width: '100%', padding: '10px' }}
            />
          </Grid>
        </Grid>
        <Box textAlign={'end'}>
          {loading ? (
            <LoadingButton
              loading={loading}
              fullWidth
              color='secondary'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant='contained'
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Money Receipt
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateMoneyReceipt;
