import React, { useContext, useReducer, useState, useEffect } from 'react';
import { IauthContextState } from './AuthTypes';
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
  url,
} from 'src/Helpers/utils/Constant';
import reducer from 'src/Reducers/AuthReducer/AuthReducer';
import { socket } from 'src/Helpers/utils/socket';
import { getToken } from 'src/Utils/Lib';
import { HttpClient } from 'src/Helpers/http/http';

const initialState: IauthContextState = {
  user: localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth'))
    : {},
  notification: { notification: [], count: 0 },
  token: '',
  permission: null,
};

const AuthContext = React.createContext(initialState);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  const token = getToken();
  useEffect(() => {
    (async () => {
      if (token) {
        setIsLoading(true);
        try {
          const data: { data: any; success: boolean; token: string } =
            await HttpClient.post(`${url}/api/atab/admin/verify/profile`, {
              token,
            });
          if (data.success) {
            dispatch({
              type: AUTH_USER_SUCCESS,
              payload: { data: data?.data, token },
            });
            setIsLoading(false);
          } else {
            dispatch({ type: AUTH_USER_FAILED });
            setIsLoading(false);
          }
          setIsLoading(false);
        } catch (err) {
          if (err && err?.response?.status === 401) {
            localStorage.removeItem('auth');
            localStorage.removeItem('haab_admin');
          }
          dispatch({ type: AUTH_USER_FAILED });
          setIsLoading(false);
        }
      } else {
        dispatch({ type: AUTH_USER_FAILED });
        setIsLoading(false);
      }
    })();
    setIsLoading(false);
    socket.on('user_online', (data) => {
      //console.log({ data });
    });

    return () => {
      socket.disconnect();
    };
  }, [token]);

  useEffect(() => {
    if (state.user?.user_admin_id) {
      socket.auth = { id: state.user?.user_admin_id, type: 'user_admin' };
      socket.connect();
    }
  }, [state.user]);

  return (
    <>
      <AuthContext.Provider
        value={{ ...state, dispatch, isLoading, setIsLoading }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
