import { IauthContextState } from 'src/components/Context/AuthContext/AuthTypes';
import {
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILED,
  CHANGE_LOADING_STATE,
  SUPPORT_CONVERSATION_MGS_ID,
  IMAGE_CHANGE_SUCCESS,
  NEW_NOTIFICATION,
  CLEAR_NOTIFICATION,
  FETCH_NOTIFICATION,
  READ_NOTIFICATION,
} from './../../Helpers/utils/Constant';

const reducer = (state: IauthContextState, action: any) => {
  switch (action.type) {
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        token: action.payload.token,
        authStatus: true,
        isLoading: false,
      };
    case IMAGE_CHANGE_SUCCESS:
      return {
        ...state,
        user_admin_photo: action.payload,
      };
    case AUTH_USER_FAILED:
      return { ...state, user: {}, token: '' };
    case CHANGE_LOADING_STATE:
      return { ...state, isLoading: action.payload };
    case SUPPORT_CONVERSATION_MGS_ID:
      return { ...state, support_conversation_msg_id: action.payload };
    case FETCH_NOTIFICATION:
      return {
        ...state,
        notification: {
          notification: action.payload.notification,
          count: action.payload.count,
        },
      };
    case NEW_NOTIFICATION:
      return {
        ...state,

        notification: {
          notification: [action.payload, ...state.notification.notification],
          count: state.notification.count + 1,
        },
      };
    case READ_NOTIFICATION:
      return {
        ...state,
        notification: {
          notification: action.payload,
          count: state.notification.count - 1,
        },
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: {
          notification: [],
          count: 0,
        },
      };
    default:
      throw new Error(`No matching action type - ${action.type}`);
  }
};

export default reducer;
