import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Toaster from "../../../../Toaster/Toaster";
import { Helmet } from "react-helmet-async";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import { LoadingButton } from "@mui/lab";

const CreateFairStall = ({ handleClose, setAllAdmins }) => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [fairStall, setFairStall] = useState<String>("");
  const [hallName, setHallName] = useState<String>("");
  const [airChecked, setAirChecked] = useState<boolean>(true);
  const [loading, setLoading] = useState<Boolean>(false);

  const handleStallChange = (e: SelectChangeEvent) => {
    setFairStall(e.target.value as string);
  };

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    if (user.user_admin_id && hallName && fairStall) {
      try {
        const { fair_stall_name, fair_stall_hall_no } = inputs;
        const { data } = await axios.post(
          `${url}/api/fair/stall/create?user_admin_id=${user.user_admin_id}`,
          {
            fair_stall_name,
            fair_stall_hall_no,
            fair_stall_type: fairStall,
            fair_stall_hall_name: hallName,
            fair_stall_air_conditioned: airChecked ? 1 : 0,
          }
        );

        if (data.success) {
          setLoading(false);
          setAllAdmins((prev) => [
            {
              fair_stall_id: data.data.fair_stall_id,
              fair_stall_name: inputs.fair_stall_name,
              fair_stall_type: fairStall,
              fair_stall_created_at: new Date().toString(),
              fair_stall_hall_name: hallName,
              fair_stall_air_conditioned: airChecked ? 1 : 0,
              fair_stall_available_status: "available",
            },
            ...prev,
          ]);
          Toast.fire({
            icon: "success",
            title: `Successfully created`,
          });
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });
      }
    } else {
      setLoading(false);
      Toast.fire({
        icon: "error",
        title: "Field required",
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>HAAB - Create Fair stall</title>
      </Helmet>

      <Box sx={{ p: 2 }} className="">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Fair stall name"
                required
                id="outlined-required"
                {...register("fair_stall_name")}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="outlined-required"
                label="Fair stall hall no"
                {...register("fair_stall_hall_no")}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required margin="normal" fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Fair Stall
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fairStall}
                  label="Stall type"
                  required
                  onChange={handleStallChange}
                >
                  <MenuItem value="STALL">STALL</MenuItem>
                  <MenuItem value="CORNER STALL">CORNER STALL</MenuItem>
                  <MenuItem value="PAVILLION">PAVILLION</MenuItem>
                  <MenuItem value="SEMI-PAVILLION">SEMI-PAVILLION</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required margin="normal" fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Hall name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hallName}
                  label="Hall name"
                  required
                  onChange={(e) => {
                    setHallName(e.target.value);
                  }}
                >
                  <MenuItem value="Hormone">Hormone</MenuItem>
                  <MenuItem value="Milky Way">Milky Way</MenuItem>
                  <MenuItem value="Carnival">Carnival</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                label="Air condition"
                control={
                  <Checkbox
                    checked={airChecked}
                    onChange={() => setAirChecked((prev) => !prev)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </Grid>
          </Grid>

          {!loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Fair stall
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Creating...
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateFairStall;
