import { Box, Button, Card, CardHeader, Divider, Grid } from '@mui/material';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IViewBudgetListType } from '../type/budgetType';
import BodyContainer from 'src/common/BodyContainer';
import { useParams } from 'react-router-dom';
import InitialPhase from './BudgetInitialPhase/InitialPhase';
import BudgetFinalPhase from './BudgetFianlPhase/BudgetFinalPhase';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
const ViewBudgetDetails = () => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Budget Details`,
  });

  const { id } = useParams();
  const { response, loading, setRefetch } = GetDataApi<IViewBudgetListType>(
    `/api/atab/budget/${id}`
  );
  return (
    <BodyContainer>
      <Box sx={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ position: 'absolute', right: 10, top: 10 }}
        >
          <Button style={{ margin: 0 }} onClick={() => handlePrint()}>
            <PrintIcon />
          </Button>
        </Grid>
        <Card ref={componentRef}>
          <CardHeader
            title={
              <Grid container justifyContent={'space-between'}>
                <Grid item xs={12} md={6}>
                  <h2 style={{ margin: 0 }}>Budget Details</h2>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {/* initial phase budget */}
          <InitialPhase
            response={response}
            loading={loading}
            setRefetch={setRefetch}
          />
          {/* final phase budget */}
          {(response?.status === 'initial_approved' ||
            response?.status === 'approved') && (
            <>
              <Divider />
              <BudgetFinalPhase
                response={response}
                loading={loading}
                setRefetch={setRefetch}
              />
            </>
          )}
        </Card>
      </Box>
    </BodyContainer>
  );
};

export default ViewBudgetDetails;
