import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { url } from 'src/Helpers/utils/Constant';
import { getToken } from 'src/Utils/Lib';

const GroupWiseMessage = ({
  setGroupMemberId,
}: {
  setGroupMemberId: React.Dispatch<React.SetStateAction<number[] | string>>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [allEvents, setAllEvents] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [allGroupIds, setAllGroupIds] = useState([]);
  const [groupId, setGroupId] = useState('');

  const token = getToken();
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(`${url}/api/member/group`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.success) {
          setAllEvents(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(`${url}/api/member/group/${groupId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.success) {
          const allgroupId = data?.data?.group_members?.map(
            (e) => e.user_member_id
          );
          if (allgroupId?.length) {
            setAllGroupIds(allgroupId);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [groupId]);

  useEffect(() => {
    if (allGroupIds?.length) {
      allGroupIds.length && setGroupMemberId(allGroupIds as number[]);
    }
  }, [allGroupIds]);
  return (
    <div>
      <Grid item xs={12} md={12} mt={2} component='form'>
        <FormControl fullWidth size='small'>
          <InputLabel id='demo-simple-select-label'>Select Group</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={groupId}
            label=' Meeting type'
            onChange={(e) => setGroupId(e.target.value)}
            required
          >
            {allEvents.map((e) => (
              <MenuItem value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </div>
  );
};

export default GroupWiseMessage;
