import {
  Avatar,
  Tooltip,
  Box,
  Button,
  styled,
  InputBase,
  useTheme,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import React, { useState } from 'react';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import {
  imgUrl,
  SUPPORT_CONVERSATION_MGS_ID,
  url,
} from 'src/Helpers/utils/Constant';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { socket } from 'src/Helpers/utils/socket';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
      font-size: ${theme.typography.pxToRem(18)};
      padding: ${theme.spacing(1)};
      width: 100%;
      `
);
const Input = styled('input')({
  display: 'none',
});

function ComplainBottonBar({
  selectedComplain,
}: {
  selectedComplain: {
    complain_id: string;
    member_id: string;
  };
}) {
  const theme = useTheme();
  const { user, dispatch } = useAuthContext();
  const [textMsg, setTextMsg] = useState<string>('');
  const [attachFile, setAttachFile] = useState<any>(null);
  console.log(attachFile);
  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      const body = {
        content: textMsg,
        complain_id: selectedComplain.complain_id,
        created_by: user.user_admin_id,
        file: attachFile,
        type: 'admin',
      };
      const formData = new FormData();
      Object.keys(body).forEach((item) => {
        if (typeof body[item] === 'object') {
          formData.append(item, body[item]);
        } else {
          formData.append(item, body[item]);
        }
      });
      socket.emit('read_message', formData);
      const { data } = await axios.post(
        `${url}/api/atab/complain/reply`,
        formData
      );
      if (data.success) {
        dispatch({
          type: SUPPORT_CONVERSATION_MGS_ID,
          payload: data.support_conversation_msg_id,
        });
        setTextMsg('');
        setAttachFile(null);
      }
    } catch (err: any) {}
  };
  return (
    <Box
      sx={{
        background: theme.colors.alpha.white[50],
        display: 'flex',
        alignItems: 'center',
        p: 1,
      }}
      component='form'
      onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
    >
      <Box flexGrow={1} display='flex' alignItems='center'>
        <Avatar
          sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
          alt={user.user_admin_name}
          src={`${imgUrl}/${user.user_admin_photo}`}
        />
        <MessageInputWrapper
          autoFocus
          placeholder='Write your message here...'
          fullWidth
          value={textMsg}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTextMsg(e.target.value)
          }
        />
      </Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {attachFile && (
            <>
              <Typography variant='body1'>{attachFile.name}</Typography>
              <IconButton
                onClick={() => setAttachFile(null)}
                aria-label='delete'
              >
                <CloseIcon color='primary' />
              </IconButton>
            </>
          )}
          <Input
            onChange={(e: any) => setAttachFile(e.target.files[0])}
            accept='image/*'
            id='messenger-upload-file'
            type='file'
          />
          <Tooltip arrow placement='top' title='Attach a file'>
            <label htmlFor='messenger-upload-file'>
              <IconButton sx={{ mx: 1 }} color='primary' component='span'>
                <AttachFileTwoToneIcon fontSize='small' />
              </IconButton>
            </label>
          </Tooltip>
          <Button
            type='submit'
            disabled={selectedComplain?.complain_id && textMsg ? false : true}
            startIcon={<SendTwoToneIcon />}
            variant='contained'
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ComplainBottonBar;
