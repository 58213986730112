import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const SelectManualOrGroupWiseEmail = ({
  setIsActive,
  isActive,
}: {
  isActive: any;
  setIsActive: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <FormControl>
      <FormLabel id='demo-row-radio-buttons-group-label'>
        Send Email Manual or Group wise
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        onChange={(e) => setIsActive(e.target.value)}
      >
        <FormControlLabel
          value='manual'
          control={<Radio checked={isActive === 'manual' ? true : false} />}
          label='Manual'
        />
        <FormControlLabel
          value='group_wise'
          control={<Radio checked={isActive === 'group_wise' ? true : false} />}
          label='Group Wise'
        />
      </RadioGroup>
    </FormControl>
  );
};

export default SelectManualOrGroupWiseEmail;
