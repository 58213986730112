import { Card, CardHeader, Divider } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const EmailPanel = () => {
  return (
    <Container sx={{ p: 5 }} maxWidth='lg'>
      <Helmet>
        <title>Email Panel</title>
      </Helmet>
      <Card>
        <CardHeader title='Email Panel' />
        <Divider />
        <Box sx={{ width: '100%', typography: 'body1', textAlign: 'center' }}>
          You can send email to any member from here ...
        </Box>
      </Card>
    </Container>
  );
};

export default EmailPanel;
