import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import Spinner from 'src/components/Spinner/Spinner';
import { IMAGE_CHANGE_SUCCESS, url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { Iadmin } from 'src/Types/module/admin/adminTypes';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import SingleDetailsinfo from './SingleDetailsinfo';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { IRoleList } from 'src/Types/module/roleAndPermission/roleAndPermissionType';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { HttpClient } from 'src/Helpers/http/http';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';

const SingleAdmin = () => {
  const { id } = useParams();
  const Toast = Toaster();
  const { register, handleSubmit } = useForm();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [singleAdmin, setSingleAdmin] = useState<Iadmin>({} as Iadmin);
  const [loading, setLoading] = useState<boolean>(false);
  const [allRole, setAllRole] = useState<IRoleList[]>([]);
  const { user, dispatch } = useAuthContext();
  const [info, setInfo] = useState({
    user_admin_name: '',
    user_admin_phone: '',
    user_admin_email: '',
    user_admin_id: '',
    user_admin_role: '',
    user_admin_zone: '',
    user_admin_status: '',
    user_admin_photo: '',
    signature: '',
  });

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/atab/admin/get/single/admin/${id}`
        );

        if (data.success) {
          setSingleAdmin(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id, showModal]);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        axios
          .get(`${url}/api/atab/admin/get/roles`)
          .then(function (res) {
            if (res.data.success) {
              setAllRole(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllRole([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);
  if (loading) {
    <Spinner />;
  }

  const onSubmit = async (inputs: any) => {
    const bodyFormData = new FormData();
    const removeData = removeUndefinedAndNull(info);

    if (singleAdmin.user_admin_id) {
      bodyFormData.append('user_admin_id', String(singleAdmin.user_admin_id));
    }
    Object.keys(removeData)?.forEach((data) => {
      if (removeData[data]) {
        bodyFormData.append(data, removeData[data]);
      }
    });

    if (Object.keys(removeData).length === 0) {
      Toaster().fire({
        icon: 'error',
        title: 'Please Change any field',
      });
    } else {
      try {
        setLoading(true);
        const res: any = await HttpClient.put(
          `/api/atab/admin/update/an/admin`,
          bodyFormData
        );

        if (res.success) {
          Toast.fire({
            icon: 'success',
            title: `Admin Updated Successfully`,
          });
          if (singleAdmin.user_admin_id === user.user_admin_id) {
            dispatch?.({
              type: IMAGE_CHANGE_SUCCESS,
              payload: res.user_admin_photo,
            });
          }
          setLoading(false);
          setShowModal(false);
        } else {
          setLoading(false);
        }
      } catch (error: any) {
        Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message,
        });
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>HAAB - Single Admin</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0 auto',
              }}
              maxWidth={1000}
            >
              <Typography variant='h4' color=''>
                Information of{' '}
                <Box component={'span'} color={'#1C59A8'}>
                  {singleAdmin.user_admin_name}
                </Box>
              </Typography>
              <Button
                onClick={() =>
                  singleAdmin.user_admin_id && setShowModal(!showModal)
                }
                variant='outlined'
                size='small'
              >
                Edit
              </Button>
            </Box>
            <SingleDetailsinfo singleAdmin={singleAdmin} />

            {/* modals wrapper */}

            <ModalsWrapper
              showModal={showModal}
              setShowModal={setShowModal}
              modalData={{ title: 'Admin Info Edit' }}
            >
              <Box sx={{ px: 2, maxWidth: 600 }}>
                <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin='normal'
                        fullWidth
                        id='name'
                        label='Admin Name'
                        defaultValue={singleAdmin?.user_admin_name}
                        {...register('user_admin_name')}
                        onChange={(e: any) => {
                          setInfo({
                            ...info,
                            user_admin_name: e.target.value,
                          });
                        }}
                        type='text'
                        autoFocus
                        variant='outlined'
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin='normal'
                        fullWidth
                        id='phone'
                        label='Phone'
                        defaultValue={singleAdmin?.user_admin_phone}
                        {...register('user_admin_phone')}
                        onChange={(e: any) => {
                          setInfo({
                            ...info,
                            user_admin_phone: e.target.value,
                          });
                        }}
                        type='number'
                        autoFocus
                        variant='outlined'
                        size='small'
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin='normal'
                        fullWidth
                        id='email'
                        label='Email'
                        defaultValue={singleAdmin?.user_admin_email}
                        {...register('user_admin_email')}
                        onChange={(e: any) => {
                          setInfo({
                            ...info,
                            user_admin_email: e.target.value,
                          });
                        }}
                        type='email'
                        autoFocus
                        variant='outlined'
                        size='small'
                      />
                    </Grid>
                    {singleAdmin?.user_admin_role && (
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl margin='normal' fullWidth size='small'>
                          <InputLabel id='demo-simple-select-label'>
                            Role
                          </InputLabel>
                          <Select
                            defaultValue={singleAdmin.user_admin_role}
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            // value={info.user_admin_role}
                            label='role'
                            onChange={(e: any) => {
                              setInfo({
                                ...info,
                                user_admin_role: e.target.value,
                              });
                            }}
                          >
                            {allRole.map((sRole) => {
                              return (
                                <MenuItem
                                  value={sRole.roles_id}
                                  key={sRole.roles_id}
                                >
                                  {sRole.roles_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {singleAdmin?.user_admin_zone && (
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl margin='normal' fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Zone
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            // value={zone ? zone : singleAdmin.user_admin_zone}
                            defaultValue={singleAdmin?.user_admin_zone}
                            label='zone'
                            onChange={(e: any) => {
                              setInfo({
                                ...info,
                                user_admin_zone: e.target.value,
                              });
                            }}
                            size='small'
                          >
                            <MenuItem value={1}>Dhaka</MenuItem>
                            <MenuItem value={2}>Chittagong</MenuItem>
                            <MenuItem value={3}>Sylhet</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl margin='normal' fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Status
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={
                            status ? status : singleAdmin.user_admin_status
                          }
                          defaultValue={singleAdmin.user_admin_status}
                          label='status'
                          required
                          onChange={(e: any) => {
                            setInfo({
                              ...info,
                              user_admin_status: e.target.value,
                            });
                          }}
                          size='small'
                        >
                          <MenuItem value='active'>Active</MenuItem>
                          <MenuItem value='rejected'>Rejected</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <TextField
                        margin='normal'
                        fullWidth
                        id='file'
                        label='Choose Admin Photo'
                        InputLabelProps={{ shrink: true }}
                        onChange={(e: any) => {
                          setInfo({
                            ...info,
                            user_admin_photo: e.target.files[0],
                          });
                        }}
                        autoComplete='file'
                        type='file'
                        size='small'
                      />
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <TextField
                        margin='normal'
                        fullWidth
                        id='file'
                        label='Add Signature'
                        InputLabelProps={{ shrink: true }}
                        onChange={(e: any) => {
                          setInfo({
                            ...info,
                            signature: e.target.files[0],
                          });
                        }}
                        autoComplete='file'
                        type='file'
                        size='small'
                      />
                    </Grid>
                  </Grid>

                  {!loading ? (
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                      size='small'
                    >
                      Update Admin
                    </Button>
                  ) : (
                    <LoadingButton
                      fullWidth
                      variant='contained'
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                    >
                      Updating...
                    </LoadingButton>
                  )}
                </Box>
              </Box>
            </ModalsWrapper>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default SingleAdmin;
