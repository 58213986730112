import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { useParams } from 'react-router-dom';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import {
  ICivilAviation,
  ICivilAviationResponse,
} from 'src/Types/module/member/CompanyInformation';
import moment from 'moment';
import UpdateCivilAviationInfo from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCivilAviationInfo';
import CicilAviationTourusm from '../../../CompanyAddInfo/CompanyAddInfoComponents/CicilAviationTourusm';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { HttpClient } from 'src/Helpers/http/http';
import PdfAndImageView from 'src/common/PdfAndImageView';

const CivilAviationInfo = () => {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [civilAviation, setCivilAviation] = useState<ICivilAviation>(
    {} as ICivilAviation
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const data: ICivilAviationResponse = await HttpClient.get(
          `/api/atab/admin/member-company/civil/aviation/${memberId}`
        );
        if (data.success) {
          setCivilAviation(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    certificate_number,
    certificate_issue_date,
    certificate_expiry_date,
    certificate_file,
  } = civilAviation || {};
  return (
    <Box>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Civil aviation information'
            actionButtonTitle={certificate_number ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>

        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {certificate_number ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Certificate number:
                          </TableCell>
                          <TableCell align='right'>
                            {certificate_number
                              ? certificate_number
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Certificate issue date:
                          </TableCell>
                          <TableCell align='right'>
                            {certificate_issue_date
                              ? moment(certificate_issue_date).format(
                                  'Do MMM YYYY'
                                )
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Certificate expiry date:
                          </TableCell>
                          <TableCell align='right'>
                            {certificate_expiry_date
                              ? moment(certificate_expiry_date).format(
                                  'Do MMM YYYY'
                                )
                              : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Civil aviation certificate :
                          </TableCell>
                          <TableCell align='right'>
                            {certificate_file ? (
                              <PdfAndImageView file={`${certificate_file}`} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
            <Box textAlign={'end'}>
              <PageTitle
                actionButtonTitle={certificate_number ? 'Update' : ''}
                setAction={setOpen}
              />
            </Box>
          </>
        )}
      </>
      {/* ) : (
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
          No data found
        </Typography>
      )} */}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: certificate_number
            ? 'Update civil aviation information'
            : 'Add civil aviation information',
        }}
      >
        {certificate_number ? (
          <UpdateCivilAviationInfo
            memberId={memberId}
            civilAviation={civilAviation}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <CicilAviationTourusm
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>
    </Box>
  );
};

export default CivilAviationInfo;
