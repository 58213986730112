import { Box, Grid, Typography, Container } from '@mui/material';
import image from '../../../../../assets/image/logo.png';
import moment from 'moment';
import { imgUrl } from 'src/Helpers/utils/Constant';

const IdCardForm = ({ componentRef, singleIdCard }: any) => {
  return (
    <Box ref={componentRef} mt={2}>
      <Container maxWidth='md'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <img src={`${image}`} alt='' loading='lazy' height='50px' />

              <Box>
                <Typography variant='h4' pt={2} pb={0.7}>
                  Hajj Agencies Association of Bangladesh (HAAB)
                </Typography>
                <Typography fontSize={13}>
                  Sattara Centre, 15th Floor, 30/A, Nayapaltan, VIP Road,
                  Dhaka-1000
                </Typography>
                <Typography fontSize={13}>
                  Tel : 58317030, 222228867, 222220868
                </Typography>
                <Typography fontSize={13}>
                  Email : haab01bd@gmail.com, Web : www.haabbd.com,
                  www.haab.com.bd
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box border={'1px solid black'} p={0.5} width={'220px'} ml={2}>
                <img
                  width={'200px'}
                  height={'200px'}
                  style={{ objectFit: 'contain' }}
                  src={`${imgUrl}/idcardfiles/${singleIdCard.photo}`}
                  alt=''
                />
              </Box>
              <Typography mt={2} fontWeight={'bold'} fontSize={15}>
                Date:
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 17,
              textDecoration: 'underline',
            }}
            mb={0.5}
          >
            Application Form for ID Card
          </Typography>

          <table className='member-info-print'>
            <tbody>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}>1</th>
                <th className='table-head'>Name of Applicant </th>
                <td> {singleIdCard.applicant_name}</td>
              </tr>

              <tr>
                <th style={{ borderRight: '1px solid gray' }}>2</th>
                <th
                  style={{ textAlign: 'start', borderRight: '1px solid gray' }}
                >
                  Designation
                </th>
                <td>{singleIdCard.designation}</td>
                <th className='table-middle-head'>Date of Birth</th>
                <td>{moment(singleIdCard.date_of_birth).format('L')}</td>
              </tr>

              <tr>
                <th style={{ borderRight: '1px solid gray' }}>3</th>
                <th className='table-head'>Agency Name & Address </th>
                <td> {singleIdCard.user_member_company_name}</td>
              </tr>

              <tr>
                <th style={{ borderRight: '1px solid gray' }}>4</th>
                <th
                  style={{ textAlign: 'start', borderRight: '1px solid gray' }}
                >
                  Tell
                </th>
                <td>{singleIdCard.telephone}</td>
                <th className='table-middle-head'>Cell</th>
                <td>{singleIdCard.cellphone}</td>
                <th className='table-middle-head'>Fax</th>
                <td>{singleIdCard.fax}</td>
              </tr>

              <tr>
                <th style={{ borderRight: '1px solid gray' }}>5</th>
                <th
                  style={{ textAlign: 'start', borderRight: '1px solid gray' }}
                >
                  Email
                </th>
                <td>{singleIdCard.email}</td>
                <th className='table-middle-head'>Website</th>
                <td>{singleIdCard.website}</td>
              </tr>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}>6</th>
                <th className='table-head'>TIN No </th>
                <td>{singleIdCard.tin_number} </td>
              </tr>
              <tr>
                <th colSpan={100} style={{ textAlign: 'start' }}>
                  Papers to be submitted
                </th>
              </tr>

              <tr>
                <th style={{ borderRight: '1px solid gray' }}>i</th>
                <td colSpan={100}>Photocopy of Trade License of the Agency</td>
              </tr>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}>ii</th>
                <td colSpan={100}>
                  Photocopy of renewed Certificate from Ministry of Civil
                  Aviation & Tourism
                </td>
              </tr>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}>iii</th>
                <td colSpan={100}>Photocopy of TIN Certificate</td>
              </tr>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}>iv</th>
                <td colSpan={100}>One copy passport size photography</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box mt={2}>
          <table className='member-info-print'>
            <thead>
              <tr className='business-license-table-head'>
                <th style={{ height: '100px', width: '50%' }}>
                  <img
                    width={'200px'}
                    style={{ objectFit: 'contain' }}
                    src={`${imgUrl}/idcardfiles/${singleIdCard.signature_file}`}
                    alt=''
                  />
                </th>

                <th>
                  {' '}
                  <img
                    width={'200px'}
                    style={{ objectFit: 'contain' }}
                    src={`${imgUrl}/idcardfiles/${singleIdCard.specimen_signature_file}`}
                    alt=''
                  />{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className='business-license-table-body'>
                <td> Signature with seal</td>
                <td> Specimen Signature of Applicant (Middle Space)</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Typography
          textAlign={'center'}
          fontWeight={'600'}
          my={0.5}
          bgcolor={'black'}
          color={'white'}
        >
          FOR HAAB OFFICE USE ONLY ( ACCOUNTS )
        </Typography>

        <Box>
          <table className='member-info-print'>
            <tbody>
              <tr>
                <th
                  style={{ textAlign: 'start', borderRight: '1px solid gray' }}
                >
                  MR. No:
                </th>
                <td></td>
                <th className='table-middle-head'>MR. Date</th>
                <td></td>
              </tr>

              <tr>
                <th className='table-head'>ID Validity Date </th>
                <td> </td>
              </tr>
            </tbody>
          </table>
          <table className='member-info-print id-card-form'>
            <thead>
              <tr className='business-license-table-head'>
                <th style={{ width: '50%' }}>Seal</th>

                <th> Signature (Accounts )</th>
              </tr>
            </thead>
            <tbody>
              <tr className='business-license-table-body'>
                <td width={'50%'} height={'70px'}></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Typography fontWeight={'600'} my={0.5}>
          NB:
        </Typography>
        <Box>
          <table className='member-info-print'>
            <tbody>
              <tr>
                <td>
                  1. Proprietor / Managing Director / Director / Managing
                  Partner / Partner are only entitled to get HAAB Identity Card.
                </td>
              </tr>
              <tr>
                <td>
                  2. Cash / Pay order in favor of HAAB for Taka 300.00 should be
                  enclosed.
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Container>
    </Box>
  );
};

export default IdCardForm;
