import { Box, Container, Typography } from "@mui/material";
import moment from "moment";
import image from "../../../../../assets/image/logo.png";
import { ToWords } from "to-words";

const toWords = new ToWords({
  localeCode: "en-BD",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      //  can be used to override defaults for the selected locale
      name: "Taka",
      plural: "Taka",
      symbol: "৳",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paisa",
        symbol: "",
      },
    },
  },
});
const Template = ({ singleInvoiceInfo }) => {
  return (
    <Container
      sx={{
        border: "1px solid black",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      maxWidth="md"
    >
      <Box width={"100%"}>
        {/* Header bar */}
        <Box textAlign={"center"}>
          {/* for logo */}
          <img
            src={`${image}`}
            alt=""
            loading="lazy"
            height="55px"
            // width="200px"
          />
          <Box>
            <Typography sx={{ fontSize: "12px" }}>
              Sattara Centre, 15th Floor, 30/A, Nayapaltan, VIP Road, Dhaka-1000
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Tel:58317030, 222228867, 222220868, Email: haab01bd@gmail.com,
              www.atab.org.bd
            </Typography>
            <Typography variant="h2" gutterBottom>
              Money Receipt
            </Typography>
          </Box>
        </Box>
        {/* mr and date  */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            py: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              border: "1.5px solid black",
              padding: "5px 20px 0 5px",
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              MR No:{" "}
              <span style={{ fontWeight: "bold" }}>
                {singleInvoiceInfo.training_payment_invoice_id}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              <span style={{ fontWeight: "bold" }}>Date: </span>
              {moment().format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
        {/* received from */}
        <Box sx={{ pb: 1 }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h4" gutterBottom>
            <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
              Received with thanks from:{" "}
            </span>{" "}
            {singleInvoiceInfo?.user_training_trainee_name}
          </Typography>
        </Box>
        {/* Invoice table  */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <table
            cellPadding="2px"
            cellSpacing="0"
            style={{
              width: "650px",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {singleInvoiceInfo.training_payment_invoice_total_amount}
                /-
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Discount:
              </td>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {singleInvoiceInfo.training_payment_invoice_discount_amount ||
                  0}
                /-
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Grand Total:
              </td>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {singleInvoiceInfo.training_payment_invoice_grand_total}
                /-
              </td>
            </tr>
          </table>
        </div>
        {/* money and description */}
        <Box>
          <Typography sx={{ padding: "15px 0 0 10px", fontSize: "12px" }}>
            <span style={{ fontWeight: "bold" }}>In Words: </span>
            {toWords.convert(
              singleInvoiceInfo?.training_payment_invoice_grand_total
            )}
          </Typography>
          <Typography sx={{ padding: "10px 0 0 10px", fontSize: "12px" }}>
            <span style={{ fontWeight: "bold" }}>Description: </span>{" "}
            {singleInvoiceInfo.training_payment_invoice_remark &&
              singleInvoiceInfo.training_payment_invoice_remark}
          </Typography>
        </Box>
        {/* Signature  */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            py: 2,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: 4,
              alignItems: "center",
            }}
          >
            <hr
              style={{
                border: "0.5px solid black",
                width: "250px",
                color: "black",
              }}
            />
            <Typography>Authorized</Typography>
            <Typography>Account Manager</Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: 4,
              alignItems: "center",
            }}
          >
            <hr
              style={{
                border: "0.5px solid black",
                width: "250px",
                color: "black",
              }}
            />
            <Typography>Accounts</Typography>
            {/* <Typography>Aronno</Typography> */}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Template;
