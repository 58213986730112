import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { Imembers } from 'src/Types/module/member/memberTypes';
import { getStatusLabel } from 'src/Utils/Combined';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { getToken } from 'src/Utils/Lib';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
type IProps = {
  allMembers: Imembers;
  status: string;
  index: number;
};
const CommonMemberTableRow = ({ allMembers, status, index }: IProps) => {
  const token = getToken();
  const theme = useTheme();
  const { user } = useAuthContext();
  return (
    <>
      <TableRow hover key={allMembers.user_member_id}>
        <TableCell>
          <Typography
            variant='body1'
            color='text.primary'
            gutterBottom
            noWrap
            fontSize={13}
          >
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: '50%',
                marginRight: '10px',
              }}
              src={
                allMembers.user_member_representative_photo
                  ? `${imgUrl}/${allMembers.user_member_representative_photo}`
                  : '/static/images/avatars/avatar2.png'
              }
              alt=''
            />
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '12rem',
              }}
            >
              <Typography
                variant='body1'
                color='text.primary'
                gutterBottom
                noWrap
                fontSize={13}
                title={allMembers.user_member_company_name}
              >
                {allMembers?.user_member_company_name
                  ?.toLowerCase()
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Typography>
            </div>
          </Box>
        </TableCell>
        <TableCell>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '12rem',
            }}
          >
            <Typography
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
              fontSize={13}
            >
              {allMembers?.user_member_hajj_license}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '12rem',
            }}
          >
            <Typography
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
              fontSize={13}
            >
              {allMembers?.user_member_email}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <Typography
            variant='body1'
            color='text.primary'
            gutterBottom
            noWrap
            fontSize={13}
          >
            {allMembers?.phones?.length > 0 ? allMembers?.phones[0] : ''}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant='body1'
            color='text.primary'
            gutterBottom
            noWrap
            fontSize={13}
          >
            {allMembers.user_member_zone_name}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabel(
            allMembers.user_member_account_status === 'blocked'
              ? 'Penalty'
              : allMembers.user_member_account_status
          )}
        </TableCell>
        {allMembers.user_member_account_status === 'pending' && (
          <TableCell>
            {allMembers.user_member_account_status === 'pending' &&
            allMembers.ck_accounts
              ? 'Accounts'
              : allMembers.ck_computer_operator
              ? 'Computer operator'
              : allMembers.ck_finance
              ? 'Finance Secretary'
              : allMembers.ck_president
              ? 'President'
              : allMembers.ck_secretary_general
              ? 'Secretary general'
              : allMembers.ck_secretary
              ? 'Secretary'
              : ''}
          </TableCell>
        )}

        <TableCell>
          {status === 'pending' ? (
            // This block only runs for 'pending' status
            (allMembers.ck_computer_operator === 1 &&
              user &&
              user?.user_admin_role?.toLowerCase() === 'computer operator') ||
            (allMembers.ck_secretary_general === 1 &&
              user?.user_admin_role?.toLowerCase() === 'secretary general') ||
            (allMembers.ck_secretary === 1 &&
              user?.user_admin_role?.toLowerCase() === 'secretary') ||
            (allMembers.ck_accounts === 1 &&
              user?.user_admin_role?.toLowerCase() === 'accounts') ||
            (allMembers.ck_finance === 1 &&
              user?.user_admin_role?.toLowerCase() === 'finance secretary') ||
            (allMembers.ck_admin === 1 &&
              user?.user_admin_role?.toLowerCase() === 'admin') ||
            (allMembers.ck_president === 1 &&
              user?.user_admin_role?.toLowerCase() === 'president') ||
            user?.user_admin_role?.toLowerCase() === 'super admin' ? (
              <Link
                to={`/member-list/new-member/${allMembers.user_member_id.toString()}`}
              >
                <Tooltip title='view' arrow>
                  <IconButton
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter,
                      },
                      color: theme.palette.primary.main,
                    }}
                    color='inherit'
                    size='small'
                  >
                    <VisibilityIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Link>
            ) : (
              // Renders "No Access" for 'pending' status if the user role doesn't match any
              <Link to={``}>
                <Tooltip title='No Access' arrow>
                  <IconButton
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter,
                      },
                      color: theme.palette.primary.main,
                    }}
                    color='inherit'
                    size='small'
                  >
                    <VisibilityIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Link>
            )
          ) : (
            // This block only runs for 'active', 'rejected', or 'blocked' statuses
            (status === 'active' ||
              status === 'rejected' ||
              status === 'inactive' ||
              status === 'blocked') && (
              <>
                <Link
                  to={`/member-list/${allMembers.user_member_id.toString()}`}
                >
                  <Tooltip title='view' arrow>
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter,
                        },
                        color: theme.palette.primary.main,
                      }}
                      color='inherit'
                      size='small'
                    >
                      <VisibilityIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  </Tooltip>
                </Link>
                {user?.user_admin_role?.toLowerCase() === 'super admin' ? (
                  <Link
                    target='_blank'
                    to={`https://www.member.haab.services/login?email=${allMembers?.user_member_email}&admin_data=${token}`}
                  >
                    <Tooltip title='Are you access to this member site?' arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color='inherit'
                        size='small'
                      >
                        <SwitchAccessShortcutAddIcon
                          sx={{ fontSize: '16px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            )
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default CommonMemberTableRow;
