import { Button, Grid, TextField } from '@mui/material';
import { Box, maxWidth } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function CreateMeeting({ handleClose, setAllMembers }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const Toast = Toaster();

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthContext();

  const currentDate = new Date();
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(currentDate));
  const [timeValue, setTimeValue] = useState<Dayjs | null>(dayjs(currentDate));

  const handleChange = (newValue: Dayjs | null) => {
    setDateValue(newValue);
  };
  const handleTimeChange = (newValue: Dayjs | null) => {
    setTimeValue(newValue);
  };

  const onSubmit = async (inputs: any) => {
    if (dateValue && timeValue) {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        if (typeof inputs[key] === 'object') {
          bodyFormData.append(key, inputs[key][0]);
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }

      bodyFormData.append(
        'member_meeting_time',
        timeValue.toISOString().slice(11, 19)
      );
      bodyFormData.append(
        'member_meeting_date',
        dateValue.toISOString().split('T')[0]
      );
      bodyFormData.append(
        'member_meeting_created_by',
        user.user_admin_id.toString()
      );
      try {
        setIsLoading(true);
        const { data } = await axios.post(
          `${url}/api/member/meeting/create/for-admin?user_admin_id=${user.user_admin_id}`,
          bodyFormData
        );

        if (data.success) {
          setAllMembers((prev) => [
            {
              member_meeting_id: data.data.member_meeting_id,
              member_meeting_title: inputs.member_meeting_title,
              member_meeting_date: dateValue.toISOString().split('T')[0],
              member_meeting_time: timeValue.toISOString().slice(11, 19),
              member_meeting_status: 'pending',
              meeting_created_by_id: user.user_admin_id.toString(),
              meeting_created_by_name: user.user_admin_name,
            },
            ...prev,
          ]);
          handleClose();
          Toast.fire({
            icon: 'success',
            title: `Create Successfully`,
          });
          setIsLoading(false);
          reset();
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create meeting</title>
      </Helmet>

      <Box component='form' onSubmit={handleSubmit(onSubmit)} maxWidth={600}>
        <Grid container spacing={{ xs: 2 }} mb={2}>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Member meeting title'
              required
              id='outlined-required'
              aria-invalid={errors.member_meeting_title ? 'true' : 'false'}
              helperText={
                errors.member_meeting_title?.type === 'required' &&
                'Meeting title is required'
              }
              type='text'
              {...register('member_meeting_title', { required: true })}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Member meeting place'
              required
              id='outlined-required'
              type='text'
              aria-invalid={errors.member_meeting_place ? 'true' : 'false'}
              helperText={
                errors.member_meeting_place?.type === 'required' &&
                'Meeting meeting is required'
              }
              {...register('member_meeting_place', { required: true })}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              required
              id='outlined-required'
              type='file'
              label='Upload a meeting image'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('member_meeting_img')}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label='Date desktop'
                inputFormat='MM/DD/YYYY'
                value={dateValue}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size='small' />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label='Time'
                value={timeValue}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size='small' />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label='Member meeting details'
              required
              multiline
              rows={4}
              id='outlined-required'
              type='text'
              aria-invalid={errors.member_meeting_detail ? 'true' : 'false'}
              helperText={
                errors.member_meeting_detail?.type === 'required' &&
                'Meeting details is required'
              }
              {...register('member_meeting_details', { required: true })}
            />
          </Grid>
        </Grid>
        {!isLoading ? (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            size='small'
          >
            Create Meeting
          </Button>
        ) : (
          <LoadingButton
            loading
            loadingIndicator='Creating...'
            variant='contained'
            disabled
            size='small'
          >
            <span>Creating...</span>
          </LoadingButton>
        )}
      </Box>
    </>
  );
}

export default CreateMeeting;
