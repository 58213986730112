import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { TOKEN_KEY, url } from 'src/Helpers/utils/Constant';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { useSearchParams } from 'react-router-dom';
import FreshMemberTableRow from './UnverifiedMemberTableRow';
import { useDebounce } from 'src/hooks/useDebounce';

export type IUnverified = {
  company_owner_designation: string;
  company_owner_name: string;
  member_company_mobile: string;
  member_company_name: string;
  member_company_type: string;
  user_member_id: number;
  zone_name: string;
  company_owner_photo: string;
  user_member_account_status: string;
  company_owner_mobile: string;
  user_member_hajj_license: string;
  user_member_email: string;
  phones: string[] | null;
};
const FreshMemberList = () => {
  const [allMembers, setAllMembers] = useState<IUnverified[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(25);
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  // Pagination
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'all',
    page: '0',
  });
  const token = localStorage.getItem(TOKEN_KEY);
  const page = parseInt(searchParams.get('page'));
  const [total, setTotal] = useState<number>(0);
  const [stateChange, setStateChange] = useState(false);
  // Search by zone
  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/haab/unverified-members?searchParams=${searchQuery}&`;
        if (page >= 0) {
          uri += `skip=${limit * page}&limit=${limit}`;
        }
        const { data } = await axios.get(uri, {
          headers: {
            Authorization: `Bearer ${token}` || '',
          },
        });

        if (data.success) {
          setAllMembers(data.data);
          setTotal(data.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [page, limit, searchQuery, stateChange]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            action={
              <Box display='flex' justifyContent='space-between' gap={2}>
                <TextField
                  id='outlined-basic'
                  label='Search Company'
                  variant='outlined'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchCompanyVal(e.target.value)
                  }
                  size='small'
                />
              </Box>
            }
            title={`Unverified members (${total})`}
          />
          <Divider />
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>SL</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>License No</TableCell>
                  <TableCell>Owner name</TableCell>
                  <TableCell>Owner Email</TableCell>
                  <TableCell>Owner Phone</TableCell>
                  <TableCell>Zone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading ? (
                  <TableLoader />
                ) : allMembers?.length ? (
                  <>
                    {allMembers?.map((allMembers: IUnverified, index) => {
                      return (
                        <FreshMemberTableRow
                          key={allMembers.user_member_id}
                          allMembers={allMembers}
                          index={index}
                          setStateChange={setStateChange}
                        />
                      );
                    })}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component='div'
              count={total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page && page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 75, 100]}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FreshMemberList;
