import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, Button, TextField } from '@mui/material';
import { ICompanyOwners } from 'src/Types/module/member/CompanyInformation';
import Toaster from 'src/Toaster/Toaster';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import moment from 'moment';

interface IProps {
  singleOwner: ICompanyOwners;
  setRefresh: (value: boolean) => void;
  setUpdateModal: (value: boolean) => void;
  refresh: boolean;
}
const UpdateCompanyOwner = ({
  singleOwner,
  setRefresh,
  setUpdateModal,
  refresh,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (inputsData: any) => {
    inputsData.company_owner_id = singleOwner?.company_owner_id;
    const formData = new FormData();

    Object.keys(inputsData).forEach((key) => {
      if (inputsData[key]) {
        if (typeof inputsData[key] === 'object') {
          if (inputsData[key][0]) {
            formData.append(key, inputsData[key][0]);
          }
        } else {
          formData.append(key, inputsData[key]);
        }
      }
    });

    try {
      setIsLoading(true);
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/owner/${singleOwner?.company_owner_id}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setUpdateModal(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });

      // setUpdateModal(false)
      setIsLoading(false);
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner name'
                type='text'
                defaultValue={singleOwner?.company_owner_name}
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_name')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                defaultValue={singleOwner?.company_owner_designation}
                label='Company owner designation'
                type='text'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_designation')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner nid  '
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_nid_file')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner signature '
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_signature')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='date'
                type='text'
                defaultValue={singleOwner?.company_owner_phone}
                label='Company owner phone'
                sx={{ width: '100%', pb: 2 }}
                {...register('company_owner_phone')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner email'
                defaultValue={singleOwner?.company_owner_email}
                type='email'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_email')}
              />
            </Box>

            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner nid'
                defaultValue={singleOwner?.company_owner_nid}
                type='text'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_nid')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner bio data '
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_biodata')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='text'
                fullWidth
                defaultValue={singleOwner?.company_owner_address}
                label='Company owner address'
                sx={{ pb: 2 }}
                {...register('company_owner_address')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner photo '
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                inputProps={{ accept: '.jpg,.jpeg,.png' }}
                {...register('company_owner_photo')}
              />
            </Box>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner date of birth'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={moment(singleOwner?.date_of_birth).format(
                  'YYYY-MM-DD'
                )}
                type='date'
                fullWidth
                sx={{ pb: 2 }}
                {...register('date_of_birth')}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyOwner;
