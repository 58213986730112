import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from '@mui/material';

import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import TraineeTableRow from './TraineeTableRow';
import { ItraineeList } from 'src/Types/module/courses/trainee';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';

const TraineeTable = () => {
  const { user } = useAuthContext();
  const [allMembers, setAllMembers] = useState<ItraineeList[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<string>('all');

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    (async function () {
      try {
        let uri: string;
        uri = `${url}/api/training/trainee/get/all/or/status/${status}`;
        const { data } = await axios.get(uri);

        if (data.success) {
          setAllMembers(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [user.user_admin_id, status]);

  const statusOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: '1',
      name: 'Active',
    },

    {
      id: '0',
      name: 'Rejected',
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedMembers = applyPagination(allMembers, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box>
            <FormControl sx={{ width: '150px' }} size='small'>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label='Status'
                autoWidth
              >
                {statusOptions &&
                  statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        }
        title='Trainee List'
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : allMembers.length ? (
              <>
                {paginatedMembers.map((allMembers: ItraineeList) => {
                  return (
                    <TraineeTableRow
                      key={allMembers.user_training_trainee_id}
                      allMembers={allMembers}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={allMembers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default TraineeTable;
