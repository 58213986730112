import React, { useEffect, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import TrainingReportExcelGen from '../TrainingReportExcelGen';
import { url } from 'src/Helpers/utils/Constant';
import axios from 'axios';
import { IReport } from 'src/Types/module/trainingReport';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

const AgencyWiseReport = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    limit: '',
    page: '',
  });
  const [agencyReport, setAgencyReport] = useState<IReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const page = searchParams.get('page');
  const limit = searchParams.get('limit') || 25;

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/services/agency-wise?limit=${limit}&skip=${
            Number(limit) * Number(page)
          }`
        );
        if (data.success) {
          setAgencyReport(data.data);
          setTotal(data.total);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [limit, page]);

  const getDownloadData: () => Promise<{
    total: number;
    data: IReport[];
  }> = async () => {
    try {
      const { data } = await axios.get(
        `${url}/api/training/services/agency-wise?limit=${limit}&skip=${
          Number(limit) * Number(page)
        }`
      );

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  return (
    <Container maxWidth='xl'>
      <Card>
        <CardHeader
          action={
            <>
              <TrainingReportExcelGen
                getDownloadData={getDownloadData}
                fileName={'agency_wise_report'}
              />
            </>
          }
          title='Agency wise report'
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Agency name </TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>nid no</TableCell>
                <TableCell>email</TableCell>
                <TableCell>Phone </TableCell>
                <TableCell>date of birth</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : agencyReport.length ? (
                <>
                  {agencyReport.map((sReport, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {sReport.user_member_company_name}
                        </TableCell>
                        <TableCell>
                          {sReport.user_training_trainee_name}
                        </TableCell>

                        <TableCell>
                          {sReport.training_course_application_nid_number}
                        </TableCell>
                        <TableCell>
                          {sReport.training_course_application_email}
                        </TableCell>
                        <TableCell>
                          {sReport.training_course_application_phone_number}
                        </TableCell>
                        <TableCell>
                          {moment(
                            sReport.training_course_application_birthdate
                          ).format('Do-MMMM-YYYY')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component='div'
            count={total}
            onPageChange={(_event, newPage) => {
              setSearchParams((prev) => {
                prev.set('page', String(newPage));
                return prev;
              });
            }}
            onRowsPerPageChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setSearchParams((prev) => {
                prev.set('limit', e.target.value);
                return prev;
              });
            }}
            page={Number(page) && Number(page)}
            rowsPerPage={Number(limit)}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default AgencyWiseReport;
