import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  ICompanyTinBin,
  ICompanyTinBinResponse,
} from 'src/Types/module/member/CompanyInformation';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { HttpClient } from 'src/Helpers/http/http';
import UpdateTinBin from '../../../CompanyAddInfo/UpdateCompanyInformation/UpdateTinBin';
import AddTinbin from '../../../CompanyAddInfo/CompanyAddInfoComponents/AddTinbin';
import PdfAndImageView from 'src/common/PdfAndImageView';
import GetDataApi from 'src/common/FetchData/GetDataApi';

function TinBinInformation() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const { response, loading, setRefetch } = GetDataApi<ICompanyTinBin>(
    `/api/atab/admin/member-company/member/tin-bin-certificate/${memberId}`
  );

  const {
    bin_file,
    bin_number,
    bin_year,
    created_at,
    id,
    member_id,
    tin_file,
    tin_number,
    tin_year,
    updated_at,
  } = response || {};
  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='TIN BIN Information'
            actionButtonTitle={tin_number ? '' : 'Add'}
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {tin_number ? (
              <Grid sx={{ pb: 3 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company TIN number:
                          </TableCell>
                          <TableCell align='right'>
                            {tin_number ? tin_number : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            TIN year:
                          </TableCell>
                          <TableCell align='right'>
                            {tin_year ? tin_year : 'Not provided'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company TIN file:
                          </TableCell>
                          <TableCell align='right'>
                            {tin_file ? (
                              <PdfAndImageView file={tin_file} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company BIN number:
                          </TableCell>
                          <TableCell align='right'>
                            {bin_number ? bin_number : 'Not provided'}{' '}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            BIN year:
                          </TableCell>
                          <TableCell align='right'>
                            {bin_year ? bin_year : 'Not provided'}{' '}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Company BIN file:
                          </TableCell>
                          <TableCell align='right'>
                            {bin_file ? (
                              <PdfAndImageView file={bin_file} />
                            ) : (
                              'Not provided'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
            <Box textAlign={'end'}>
              <PageTitle
                actionButtonTitle={tin_number ? 'Update' : ''}
                setAction={setOpen}
              />
            </Box>
          </>
        )}
      </>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: bin_number
            ? 'Update TIN/BIN Information'
            : 'Add TIN/BIN Information',
        }}
      >
        {bin_number ? (
          <UpdateTinBin
            memberId={memberId}
            tinBin={response}
            setRefresh={setRefetch}
            setOpen={setOpen}
          />
        ) : (
          <AddTinbin
            memberId={memberId}
            setRefresh={setRefetch}
            setOpen={setOpen}
          />
        )}
      </ModalsWrapper>
    </>
  );
}

export default TinBinInformation;
