import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import { ISingleOwnershipChangeApplication } from 'src/Types/module/applications/applications';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';

type IProps = {
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  application: ISingleOwnershipChangeApplication;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const UpdateModal = ({
  setShowModal,
  showModal,
  application,
  setRefetch,
}: IProps) => {
  const [inputs, setInputs] = useState({
    ownership_change_application_status: 'pending',
    ownership_change_application_rejected_reason: '',
  });
  const { updateData, loading, success } = UpdateDataApi(
    `${url}/api/member/owner-change-application/${application.ownership_change_application_id}`
  );

  useEffect(() => {
    setInputs({
      ownership_change_application_status:
        application.ownership_change_application_status,
      ownership_change_application_rejected_reason:
        application.ownership_change_application_rejected_reason,
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateData(inputs);
  };
  useEffect(() => {
    if (success) {
      setRefetch(true);
      setShowModal(false);
    }
  }, [success]);
  return (
    <ModalsWrapper
      setShowModal={setShowModal}
      showModal={showModal}
      modalData={{
        title: 'Update Ownership change application',
      }}
    >
      <Box p={2}>
        <Box component='form' onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth required>
                <InputLabel id='demo-simple-select-label'>
                  Select Status
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={inputs.ownership_change_application_status}
                  label='Select Status'
                  onChange={(e: any) =>
                    setInputs({
                      ...inputs,
                      ownership_change_application_status: e.target.value,
                    })
                  }
                >
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='approved'>Approved</MenuItem>
                  <MenuItem value='rejected'>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  id='outlined-basic'
                  label='Note'
                  variant='outlined'
                  value={inputs.ownership_change_application_rejected_reason}
                  multiline
                  type='text'
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      ownership_change_application_rejected_reason:
                        e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
            {loading ? (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ModalsWrapper>
  );
};

export default UpdateModal;
