import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IAllTicket } from '../type/ticketType';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import TableRowTicket from './TableRowTicket';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { useEffect, useState } from 'react';
import TicketStatusUpdate from './TicketStatusUpdate';
const TicketList = () => {
  const [filter, setFilter] = useState<{
    page: string | number;
    listType: string | number;
    limit: string | number;
  }>({
    listType: 'all',
    page: 1,
    limit: 30,
  });
  const { response, loading, getData, total } = GetDataApi<IAllTicket[]>(
    `/api/atab/user-member/admin/ticket?status=${
      filter.listType == 'all' ? '' : filter.listType
    }`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>({});
  return (
    <Box sx={{ m: 2 }}>
      <Card>
        <CardHeader
          title='Ticket List'
          action={
            <Grid item xs={6}>
              <Select
                size='small'
                fullWidth
                variant='outlined'
                placeholder='Payment type'
                defaultValue={'all'}
                sx={{ padding: 0, height: 35, width: 200 }}
                onChange={(e) =>
                  setFilter({ ...filter, listType: e.target.value })
                }
              >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='pending'>Pending</MenuItem>
                <MenuItem value='completed'>Completed</MenuItem>
              </Select>
            </Grid>
          }
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableLoader />
            ) : response?.length ? (
              <TableBody>
                {response?.map((singleGuest, index) => {
                  return (
                    <TableRowTicket
                      data={singleGuest}
                      index={index}
                      handleStatus={(id) => {
                        setOpen(true);
                        setData(singleGuest);
                      }}
                    />
                  );
                })}
              </TableBody>
            ) : (
              <TableNotFound />
            )}
          </Table>
          <Box p={2}>
            <TablePagination
              component='div'
              count={total as number}
              onPageChange={(_event: any, newPage: number) =>
                setFilter({ ...filter, page: newPage })
              }
              onRowsPerPageChange={(event) =>
                // setLimit(parseInt(event.target.value))
                setFilter({ ...filter, limit: event.target.value })
              }
              page={Number(filter.page) && Number(filter.page)}
              rowsPerPage={Number(filter.limit)}
              rowsPerPageOptions={[30, 50, 75, 100]}
            />
          </Box>
        </TableContainer>
      </Card>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: 'Update Status' }}
      >
        <TicketStatusUpdate data={data} getData={getData} setOpen={setOpen} />
      </ModalsWrapper>
    </Box>
  );
};

export default TicketList;
