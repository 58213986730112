import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  Button,
} from '@mui/material';

import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { IRoleList } from 'src/Types/module/roleAndPermission/roleAndPermissionType';
import CreateRole from './CreateRole';
import RoleTableRow from './RoleTableRow';

const RoleTable = () => {
  const [limit, setLimit] = useState<number>(25);
  const [allRole, setAllRole] = useState<IRoleList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });

  const page = parseInt(searchParams.get('page'));

  // Modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        axios
          .get(`${url}/api/atab/admin/get/roles`)
          .then(function (res) {
            if (res.data.success) {
              setAllRole(res.data.data);
              setLoading(false);
              setReload(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllRole([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [reload]);

  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedRole = applyPagination(allRole, page, limit);

  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <Button
                      onClick={() => setOpen(true)}
                      variant='contained'
                      size='small'
                    >
                      Create Role
                    </Button>
                  </Box>
                }
                title='All Roles'
              />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Role ID</TableCell>
                      <TableCell>Role Title</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : paginatedRole.length ? (
                      <>
                        {paginatedRole.map((sRole: IRoleList) => {
                          return (
                            <RoleTableRow key={sRole.roles_id} sRole={sRole} />
                          );
                        })}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component='div'
                  count={allRole.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
              {/* Modal */}
              <ModalsWrapper
                setShowModal={setOpen}
                showModal={open}
                modalData={{ title: 'Create Role' }}
              >
                <CreateRole handleClose={setOpen} setReload={setReload} />
              </ModalsWrapper>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RoleTable;
