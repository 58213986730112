import { useEffect, useState } from 'react';
import axios from 'axios';
import { Autocomplete, Grid, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { IMemberOrganizationProposer } from 'src/Types/module/member/UpdateCompanyInfoTypes';
import { IProposerInput } from 'src/Types/module/member/UpdateCompanyInfoStateTypes';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { HttpClient } from 'src/Helpers/http/http';
interface Props {
  memberId: string;
  setOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  refresh: boolean;
}
const ProposerInput = ({ memberId, setOpen, setRefresh, refresh }: Props) => {
  const { handleSubmit } = useForm();
  const [memberOrganizationProposer, setMemberOrganizationProposer] = useState<
    IMemberOrganizationProposer[]
  >([]);
  const [proposerInput, setProposerInput] = useState<IProposerInput>({
    member_1st_proposer_id: 0,
    member_2nd_proposer_id: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active`
      )
      .then(function (res) {
        if (res.data.success) {
          setMemberOrganizationProposer(res.data.data);
        }
      });
  }, []);

  const proposerOption = memberOrganizationProposer.map((option: any) => ({
    id: option.user_member_id,
    label: `${option.user_member_company_name}`,
  }));

  const onSubmit = async (inputData: any) => {
    inputData = { ...inputData, ...proposerInput };
    try {
      setIsLoading(true);
      const data: any = await HttpClient.post(
        `/api/atab/member-company/add/proposer/for-admin/${memberId}`,
        inputData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <label htmlFor=''>
                Select 1st proposer name <span style={{ color: 'red' }}>*</span>
              </label>

              <Box>
                <Autocomplete
                  size='small'
                  disablePortal
                  id='combo-box-demo'
                  options={proposerOption}
                  isOptionEqualToValue={(proposerOption, value) =>
                    proposerOption.id === value.id
                  }
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Select 1st proposer name'
                    />
                  )}
                  onChange={(e: any, newValue: any) =>
                    setProposerInput({
                      ...proposerInput,
                      member_1st_proposer_id: newValue?.id,
                    })
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ mt: 1 }}>
              <label htmlFor=''>
                Select 2nd proposer name <span style={{ color: 'red' }}>*</span>
              </label>
              <Box>
                <Autocomplete
                  size='small'
                  disablePortal
                  options={proposerOption}
                  isOptionEqualToValue={(proposerOption, value) =>
                    proposerOption.id === value.id
                  }
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder='Select 2nd proposer name'
                    />
                  )}
                  onChange={(e: any, newValue: any) =>
                    setProposerInput({
                      ...proposerInput,
                      member_2nd_proposer_id: newValue?.id,
                    })
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
            color='primary'
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ProposerInput;
