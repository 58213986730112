import { useState, Dispatch } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { IMemberInfo } from 'src/Types/module/member/memberTypes';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateBasicInfo from './UpdateBasicInfo';
import { getStatusLabel } from 'src/Utils/Combined';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import RoleUpdateComponent from 'src/common/RoleUpdateComponent';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

type IProps = {
  memberInfo: IMemberInfo;
  setRefresh: Dispatch<React.SetStateAction<boolean>>;
};

function BasicInformation({ memberInfo, setRefresh }: IProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useAuthContext();
  const [openForwardModal, setOpenForward] = useState<boolean>(false);
  const {
    user_member_email,
    user_member_phone,
    user_member_account_status,
    user_member_membership_payment_date,
    user_member_membership_payment_expiry_date,
    user_member_company_name,
    user_member_id_card_number,
    user_member_reject_reason,
    user_member_active_date,
    user_member_last_reject_date,
    user_member_id_card_issue_date,
    user_member_id_card_expiry_date,
    user_member_hajj_license,
  } = memberInfo || {};

  return (
    <Box>
      <PageTitleWrapper>
        <PageTitle
          heading={`Basic Information of ${user_member_company_name}`}
          subHeading={`ID: ${user_member_hajj_license}`}
          //actionButtonTitle='Update'
          setAction={setOpen}
          setOpenForward={setOpenForward}
          // isforward={
          //   user?.user_admin_role?.toLowerCase() === 'super admin'
          //     ? false
          //     : true
          // }
          // isShowUpdate={
          //   (memberInfo.ck_accounts &&
          //     user?.user_admin_role?.toLowerCase() === 'accounts') ||
          //   (memberInfo.ck_finance &&
          //     user?.user_admin_role?.toLowerCase() === 'finance secretary') ||
          //   (memberInfo.ck_president &&
          //     user?.user_admin_role?.toLowerCase() === 'president') ||
          //   (memberInfo.ck_computer_operator &&
          //     user?.user_admin_role?.toLowerCase() === 'computer operator') ||
          //   (memberInfo.ck_secretary_general &&
          //     user?.user_admin_role?.toLowerCase() === 'secretary general')
          //     ? true
          //     : false
          // }
        />

        <ModalsWrapper
          setShowModal={setOpenForward}
          showModal={openForwardModal}
          modalData={{ title: 'Update member information' }}
        >
          <RoleUpdateComponent
            memberInfo={memberInfo}
            setRefresh={setRefresh}
            setShowModal={setOpen}
          />
        </ModalsWrapper>
      </PageTitleWrapper>
      <Grid sx={{ pb: 5 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label='custom pagination table'>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Membership Status:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_account_status
                      ? getStatusLabel(
                          user_member_account_status === 'blocked'
                            ? 'Penalty'
                            : user_member_account_status
                        )
                      : 'Inactive'}
                  </TableCell>
                </TableRow>

                {user_member_account_status === 'active' ? (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      Active date:
                    </TableCell>
                    <TableCell align='right'>
                      {moment(user_member_active_date).format('Do MMM YYYY')}
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {user_member_account_status === 'blocked' ||
                user_member_account_status === 'rejected' ? (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      Deactivate date:
                    </TableCell>
                    <TableCell align='right'>
                      {moment(user_member_last_reject_date).format(
                        'Do MMM YYYY'
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {user_member_reject_reason ? (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      Reject reason:
                    </TableCell>
                    <TableCell align='right'>
                      {user_member_reject_reason}
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                <TableRow>
                  <TableCell component='th' scope='row'>
                    ID card number:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_hajj_license
                      ? user_member_hajj_license
                      : 'Not provided'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    ID card issue date:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_id_card_issue_date
                      ? moment(user_member_id_card_issue_date).format(
                          'Do MMM YYYY'
                        )
                      : 'Not provided'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    ID card expiry date:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_id_card_expiry_date
                      ? moment(user_member_id_card_expiry_date).format(
                          'Do MMM YYYY'
                        )
                      : 'Not provided'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label='custom pagination table'>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Company Name:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_company_name
                      ? user_member_company_name
                      : 'Not provided yet'}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component='th' scope='row'>
                    Membership payment date:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_membership_payment_date
                      ? moment(user_member_membership_payment_date).format(
                          'Do MMM YYYY'
                        )
                      : 'Payment Date not found'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Membership payment expiry date:
                  </TableCell>
                  <TableCell align='right'>
                    {user_member_membership_payment_expiry_date
                      ? moment(
                          user_member_membership_payment_expiry_date
                        ).format('Do MMM YYYY')
                      : 'Expiry date not found'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: 'Update Membership Information' }}
      >
        <UpdateBasicInfo
          memberInfo={memberInfo}
          setRefresh={setRefresh}
          setShowModal={setOpen}
        />
      </ModalsWrapper>
      <PageTitle
        heading={``}
        subHeading={``}
        actionButtonTitle='Membership Status Update'
        setAction={setOpen}
        setOpenForward={setOpenForward}
      />
    </Box>
  );
}

export default BasicInformation;
