import React, { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import FirstPageFrom from "./FirstPageFrom";
import SecondPageFrom from "./SecondPageFrom";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { IFromData } from "src/Types/module/member/AdmissionFromData";
import ThirdPageForm from "./ThirdPageForm";

const PrintInformationForm = ({ componentRef, memberId }: any) => {
  const [fromData, setFromData] = useState<IFromData>({} as IFromData);

  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/member/print-member/${memberId}`
      );

      if (data.success) {
        setFromData(data.data);
      }
    })();
  }, [memberId]);

  return (
    <div ref={componentRef}>
      <Container maxWidth="md">
        <FirstPageFrom fromData={fromData} />

        <Box mt={15}>
          <SecondPageFrom fromData={fromData} />
        </Box>
        <Box mt={70}>
          <ThirdPageForm memberId={memberId} />
        </Box>
      </Container>
    </div>
  );
};

export default PrintInformationForm;
