import { useState, useEffect, ChangeEvent } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  Box,
  Button,
  TableContainer,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import NoticeTableRow from './NoticeTableRow';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateNotice from './CreateNotice/CreateNotice';
import { INotice, IPaginationItem } from 'src/Types/module/member/Notice';

const NoticeTable = () => {
  const [allNotice, setAllNotice] = useState<INotice[]>([]);
  const [open, setOpen] = useState(false);
  const [paginationItem, setPaginationItem] = useState<IPaginationItem>({
    page: 0,
    limit: 25,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`${url}/api/member/notice`);
        if (data.success) {
          setAllNotice(data.data);
          setPaginationItem({ ...paginationItem, total: data.total });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const handlePageChange = (event: any, newPage: number): void => {
    setPaginationItem({ ...paginationItem, page: newPage });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPaginationItem({
      ...paginationItem,
      limit: parseInt(event.target.value),
    });
  };
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <Button
                    onClick={() => setOpen(true)}
                    variant='contained'
                    size='small'
                  >
                    Create Notice
                  </Button>
                </Box>
              }
              title='Notice List'
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Create date</TableCell>
                    <TableCell>Creator Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : allNotice.length ? (
                    <>
                      {allNotice?.map((sNotice) => {
                        return (
                          <NoticeTableRow
                            sNotice={sNotice}
                            key={sNotice.member_notice_id}
                            setAllNotice={setAllNotice}
                            allNotice={allNotice}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={allNotice?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={paginationItem.page}
                rowsPerPage={paginationItem.limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
            <ModalsWrapper
              setShowModal={setOpen}
              showModal={open}
              modalData={{ title: 'Create Notice' }}
            >
              <>
                <CreateNotice
                  setOpen={setOpen}
                  setAllNotice={setAllNotice}
                  allNotice={allNotice}
                />
              </>
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NoticeTable;
