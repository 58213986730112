import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import UpdateSingleRole from './UpdateRole/UpdateSingleRole';
import { useParams } from 'react-router-dom';
import { url } from 'src/Helpers/utils/Constant';
import axios from 'axios';
import SingleRoleDetails from './SingleRoleDetails';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';

const SingleRole = () => {
  const [singleRole, setSingleRole] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/role/${id}`)
          .then(function (res) {
            if (res.data.success) {
              setSingleRole(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title>HAAB - Single Role</title>
      </Helmet>
      <Container sx={{ mt: 4, pb: 4 }} maxWidth='xl'>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Typography variant='h4' color='text.primary'>
                Role Information
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Button
                  onClick={() => setShowModal(!showModal)}
                  variant='outlined'
                  size='small'
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ mt: 3 }}>
              {loading ? (
                <GlobalLoader />
              ) : (
                <Grid
                  container
                  spacing={{ lg: 5, md: 3, sm: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                >
                  <Grid
                    xs={12}
                    lg={12}
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='h3'>
                      {singleRole?.role?.roles_name
                        .toLowerCase()
                        .split(' ')
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(' ')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    {singleRole?.permissions?.map((p: any, index: number) => (
                      <SingleRoleDetails singleRoleDetails={p} key={index} />
                    ))}
                  </Grid>
                </Grid>
              )}
            </Box>
            {/* modals wrapper */}
            <UpdateSingleRole
              showModal={showModal}
              setShowModal={setShowModal}
              singleRole={singleRole}
              setReload={setReload}
              reload={reload}
            />
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default SingleRole;
