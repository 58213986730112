import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  TextField,
  Chip,
  Autocomplete,
  Grid,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

import {
  IpaymentableItem,
  IuserMemberList,
} from 'src/Types/module/invoice/invoiceTypes';
import { LoadingButton } from '@mui/lab';
import { IPaymentItem } from '../type/voucherType';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import PostDataApis from 'src/common/FetchData/PostDataApis';
import Toaster from 'src/Toaster/Toaster';
import CommSelectFieldSearchmemberlistbyLicenseAndname from 'src/common/CommSelectFieldSearchmemberlistbyLicenseAndname';
const Toast = Toaster();
interface IProps {
  refetch: () => Promise<void>;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateInvoice = ({ handleClose, refetch }: IProps) => {
  const { user } = useAuthContext();
  const { response } = GetDataApi<IPaymentItem[]>(
    `/api/atab/payment/get/all/paymentable/item`
  );
  const { response: companyList } = GetDataApi<IuserMemberList[]>(
    `/api/atab/user-member/company-name/get-all/status-or-all/all`
  );
  const { postData, loading, success } = PostDataApis<IuserMemberList[]>(
    `/api/atab/invoice/create/for-admin?user_admin_id=${user?.user_admin_id}`
  );

  useEffect(() => {
    if (success) {
      refetch();
      handleClose(false);
    }
  }, [success]);
  const [membersId, setMembersId] = useState<any | null>(null);
  const [paymentableId, setPaymentableId] = useState<IpaymentableItem[]>([]);
  const [discount, setDiscount] = useState<number>(0);
  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    let body = {
      atab_payment_invoice_user_member_id: membersId,
      atab_payment_invoice_paymentable_items: paymentableId,
      atab_payment_invoice_discount_amount: discount,
    };
    if (
      body.atab_payment_invoice_paymentable_items.length &&
      body.atab_payment_invoice_user_member_id
    ) {
      postData(body);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Please write something',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create Voucher</title>
      </Helmet>

      <Box
        sx={{
          mx: 4,
          mt: 2,
          maxWidth: 500,
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={{ xs: 2 }} mb={2}>
          <Grid xs={12} lg={12} item>
            {/* <Autocomplete
              placeholder='Select Member'
              value={membersId}
              onChange={(_event: any, newValue: any) => {
                setMembersId(newValue);
              }}
              size='small'
              id='controllable-states-demo'
              options={companyList?.length ? companyList : []}
              getOptionLabel={(option) => option.user_member_company_name}
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant='filled'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label='Select Company' />
              )}
            /> */}
            <CommSelectFieldSearchmemberlistbyLicenseAndname
              seTMemberId={setMembersId}
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              multiple
              size='small'
              id='tags-standard'
              onChange={(e, mainValue: any) => {
                const ids = mainValue?.map(
                  (item) => item.atab_paymentable_item_id
                );
                setPaymentableId(ids);
              }}
              options={response?.length ? response : []}
              getOptionLabel={(option) =>
                option.atab_paymentable_item_name +
                ' (' +
                option.atab_paymentable_item_bdt_amount +
                'tk)'
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select Payment items'
                  placeholder='More...'
                />
              )}
            />
          </Grid>

          <Grid xs={12} item>
            <TextField
              fullWidth
              label='Discount'
              variant='outlined'
              size='small'
              placeholder='discount'
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDiscount(Number(e.target.value))}
            />
          </Grid>
          <Grid lg={12} item>
            {!loading ? (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                fullWidth
              >
                Create
              </Button>
            ) : (
              <LoadingButton
                loading
                loadingIndicator='Creating...'
                variant='contained'
                disabled
                fullWidth
              >
                <span>Creating...</span>
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateInvoice;
