import { ChangeEvent, useEffect, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import CreateGuest from './CreateGuestAndSpeaker';
import { IGuest } from 'src/Types/module/guestAndSpecker/guestAndSpeaker';
import GuestAndSpeakerTableRow from './GuestAndSpeakerTableRow';

const AllGuestList = () => {
  const [allGuest, setAllGuest] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/fair/get/guest-speaker/for-admin/guest-speaker-type/all`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllGuest(data.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allGuest, page, limit);

  return (
    <Box sx={{ m: 2 }}>
      <Card>
        <CardHeader
          action={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Button
                onClick={() => handleOpen()}
                variant='contained'
                size='small'
              >
                Create Guest
              </Button>
            </Box>
          }
          title='Guest List'
        />

        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableLoader />
            ) : allGuest.length ? (
              <TableBody>
                {paginatedInvoice.map((singleGuest: IGuest) => {
                  return (
                    <GuestAndSpeakerTableRow
                      key={singleGuest.guest_speaker_id}
                      guest={singleGuest}
                      setAllGuest={setAllGuest}
                      allGuest={allGuest}
                    />
                  );
                })}
              </TableBody>
            ) : (
              <TableNotFound />
            )}
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component='div'
            count={allGuest.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
        {/* Modal */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          maxWidth='md'
          onClose={handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ mb: 2 }}> Create Guest</Typography>
            </Box>
            <CreateGuest
              handleClose={handleClose}
              allGuest={allGuest}
              setAllGuest={setAllGuest}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
};

export default AllGuestList;
