import { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { getStatusLabel } from 'src/Utils/Combined';
import { IAgencyUpdateInfoApplication } from 'src/Types/module/applications/applications';
import { useDebounce } from 'src/hooks/useDebounce';
import CommonSearchfilterForAppliHeader from '../Common/CommonSearchfilterForAppliHeader';

const AgencyInfoUpdateApplication = () => {
  const [allAgencyUpdateInfo, setAllAgencyUpdateInfo] = useState<
    IAgencyUpdateInfoApplication[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  const [status, setStatus] = useState<string>('');

  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        let uri = `${url}/api/atab/agency-member-info/all?status=${status}&searchParams=${searchQuery}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllAgencyUpdateInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [status, searchQuery]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Card>
        <CommonSearchfilterForAppliHeader
          setSearchCompanyVal={setSearchCompanyVal}
          setStatus={setStatus}
          status={status}
          leftSideTitle='Agency information update application'
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Agency Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>License No</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {allAgencyUpdateInfo?.length ? (
                    <>
                      {allAgencyUpdateInfo.map((item) => {
                        return (
                          <TableRow
                            key={item.agency_info_update_application_id}
                          >
                            <TableCell>
                              {item.agency_info_update_application_id}
                            </TableCell>
                            <TableCell>
                              {item.user_member_company_name}
                            </TableCell>
                            <TableCell>
                              {item.user_member_hajj_license}
                            </TableCell>
                            <TableCell>
                              {moment(item.agency_info_created_at).format(
                                'MMM Do YYYY'
                              )}
                            </TableCell>
                            <TableCell>{getStatusLabel(item.status)}</TableCell>
                            <TableCell>
                              <Link
                                to={`/update-applications/agency-info-update/${item.agency_info_update_application_id}?tabValue=application`}
                              >
                                <Tooltip title='view' arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color='inherit'
                                    size='small'
                                  >
                                    <VisibilityIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default AgencyInfoUpdateApplication;
