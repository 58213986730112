import { Container, Grid } from '@mui/material';

const BodyContainer = ({ children }: any) => {
  return (
    <Container sx={{ mt: 2 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default BodyContainer;
