import { Dispatch, SetStateAction } from 'react';
import { Box, FormGroup, Typography } from '@mui/material';
import SingleCheckedBox from './SingleCheckedBox';
import {
  IPermission,
  IPermissionList,
} from 'src/Types/module/roleAndPermission/roleAndPermissionType';

interface IProps {
  listOfPermission: IPermission[];
  permissionItem: IPermission;
  deletedPermissionList: number[];
  addedPermissionList: number[];
  setDeletedPermissionList: Dispatch<SetStateAction<number[]>>;
  setAddedPermissionList: Dispatch<SetStateAction<number[]>>;
}
const CheckBoxDetails = ({
  listOfPermission,
  permissionItem,
  deletedPermissionList,
  addedPermissionList,
  setDeletedPermissionList,
  setAddedPermissionList,
}: IProps) => {
  let isChecked = false;
  let existingPermissionList = [];

  for (let index = 0; index < listOfPermission.length; index++) {
    const element = listOfPermission[index];
    for (let index = 0; index < element.permissions.length; index++) {
      const id = element.permissions[index];
      existingPermissionList.push(id.permissions_id);
    }
  }

  const handlePermissionChange = (e, permissionId: number) => {
    const deletedId = deletedPermissionList.every((id) => id !== permissionId);
    if (!e.target.checked) {
      if (deletedId) {
        const newArry = addedPermissionList.filter((id) => id !== permissionId);
        setAddedPermissionList(newArry);
        setDeletedPermissionList([...deletedPermissionList, permissionId]);
      }
    }

    if (e.target.checked) {
      const newArry = deletedPermissionList.filter((id) => id !== permissionId);
      const checkExistingId = existingPermissionList.includes(permissionId);
      if (!checkExistingId) {
        setAddedPermissionList([...addedPermissionList, permissionId]);
      }
      setDeletedPermissionList(newArry);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          bgcolor: '#ededed',
          borderRadius: '5px',
        }}
      >
        <Typography variant='h4' sx={{ p: 1 }}>
          {permissionItem.permissions_section
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          ml: 2,
        }}
      >
        <FormGroup>
          {permissionItem.permissions.map((permission: IPermissionList) => {
            listOfPermission.map((sPermission) => {
              sPermission.permissions.map((item) => {
                if (item.permissions_id === permission.permissions_id) {
                  isChecked = true;
                }
              });
            });

            return (
              <SingleCheckedBox
                key={permission?.permissions_id}
                permission={permission}
                isChecked={isChecked}
                handlePermissionChange={handlePermissionChange}
              />
            );
          })}
        </FormGroup>
      </Box>
    </>
  );
};

export default CheckBoxDetails;
