import React from "react";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { IsingleTrainee } from "src/Types/module/courses/singleTrainee";
import { imgUrl } from "src/Helpers/utils/Constant";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import Label from "src/components/Label";

const ParticularsOwnerInfo = ({
  traineeInfo,
}: {
  traineeInfo: IsingleTrainee;
}) => {
  const {
    user_training_trainee_id,
    user_training_trainee_name,
    user_training_trainee_email,
    user_training_trainee_phone,
    user_training_trainee_photo,
    user_training_trainee_account_status,
    user_training_trainee_created_at,
  } = traineeInfo || {};

  return (
    <Container maxWidth="lg">
      {traineeInfo && (
        <Card sx={{ p: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              textAlign="center"
              gutterBottom
              variant="h2"
              component="div"
            >
              {user_training_trainee_name}
            </Typography>
            <Typography
              textAlign="center"
              gutterBottom
              variant="subtitle1"
              component="div"
            >
              Trainee Information
            </Typography>
          </Box>
          <CardContent sx={{ mt: 2 }}>
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              alignItems="center"
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {user_training_trainee_photo ? (
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      border: "5px solid #dedede",
                    }}
                    src={`${imgUrl}/${user_training_trainee_photo}`}
                  />
                ) : (
                  <Avatar />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={{ xs: 1 }}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Trainee id:
                    </Typography>
                    {user_training_trainee_id}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Registration date:
                    </Typography>
                    {moment(user_training_trainee_created_at).format(
                      "Do MMMM YYYY"
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Phone:
                    </Typography>
                    {user_training_trainee_phone
                      ? user_training_trainee_phone
                      : "Phone Not Provided"}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {user_training_trainee_account_status ? (
                      <Label color="success">Active</Label>
                    ) : (
                      <Label color="error">Blocked</Label>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Email:
                    </Typography>
                    {user_training_trainee_email
                      ? user_training_trainee_email
                      : "Email Not Provided"}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default ParticularsOwnerInfo;
