import React, { useState } from "react";
import jsPDF from "jspdf";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { Button, Tooltip } from "@mui/material";
import logo from "../../../../../assets/image/logo.png";
import moment from "moment";
import autoTable from "jspdf-autotable";
type RowData = {
  user_member_id_card_number: string;
  user_member_company_name: string;
  member_company_address: string;
  user_member_representative_name: string;
  user_member_representative_designation: string;
  company_tin_number: string;
  user_member_phone: string;
  user_member_email: string;
};

type TableData = {
  total: number;
  data: RowData[];
};

type Props = {
  getDownloadData?: any;
  pdfTitle: string;
  columns: {
    label: string;
    key: keyof RowData;
    width?: number;
    align?: "left" | "center" | "right";
  }[];
  rowsPerPage: number;
};
const AccountReportPdfGenerator = ({
  getDownloadData,
  pdfTitle,
  columns,
  rowsPerPage,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    var doc = new jsPDF("p", "pt");
    setLoading(true);
    const data = await getDownloadData();

    const totalPages = Math.ceil(data.total / rowsPerPage);
    // Define table headers and data
    const headers = [["Account Head", "Head Code", "Debit", "Credit"]];
    const tableData = [
      ["Asset", "1002", "0", "51265"],
      ["Asset", "1002", "0", "51265"],
      ["Asset", "1002", "4545", ""],
      ["Asset", "1002", "4545", ""],

      // Add more rows as needed
    ];

    for (let page = 1; page <= totalPages; page++) {
      doc.addImage(logo, "png", 30, 15, 120, 30);
      doc.setFontSize(14).text(pdfTitle, 250, 25);
      doc
        .setFontSize(8)
        .text(
          "Sattara Centre, 15th Floor, 30/A, Nayapaltan, VIP Road, Dhaka-1000",
          200,
          35
        );
      doc
        .setFontSize(8)
        .text(
          "Tel :58317030, 222228867, 222220868 , Email: haab01bd@gmail.com",
          200,
          45
        );
      doc.setFontSize(10).text("Trial Balance", 300, 60);
      doc
        .setFontSize(7)
        .text(
          `Print Date : ${moment(new Date()).format("DD/MM/YYYY")}`,
          490,
          60
        );

      // doc.setFontSize(18).text(pdfTitle, 300, 25, { align: "center" });
      // doc.setFontSize(12).text(`Total: ${data.total}`, 30, 40);
      // doc.setFontSize(12).text(`Date: ${new Date().toDateString()}`, 570, 40, {
      //   align: "right",
      // });

      autoTable(doc, {
        head: headers,
        body: tableData,
        startY: 85,

        theme: "plain",
        headStyles: {
          lineWidth: 0.3,
        },
        bodyStyles: {
          lineWidth: 0.3,
        },
      });

      doc.setFontSize(6);
      // columns.forEach((column, index) => {
      //   const { label, width = 80, align = "left" } = column;
      //   doc.text(label, index * 100 + 55, 100);
      // });

      doc.setFontSize(8);
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, data.total);
      let j = 0;
      // for (let i = startIndex; i < endIndex; i++) {
      //   const position = 105 + (j + 1) * 20;
      //   j++;
      //   columns.forEach((column, index) => {
      //     const { key, width = 80, align = "left" } = column;
      //     doc.text(String(data.data[i][key]), index * 100 + 55, position, {
      //       maxWidth: width,
      //       align,
      //     });
      //   });

      //   doc.setFontSize(10).text(`Page ${page} of ${totalPages}`, 230, 800, {
      //     align: "left",
      //   });
      // }

      doc.setFontSize(8).text("Developed by M360ict", 50, 800);
      doc.setFontSize(8).text("page 1", 280, 800, { align: "center" });
      if (page < totalPages) {
        doc.addPage();
      }
    }
    setLoading(false);
    doc.save(`${pdfTitle}.pdf`);
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Tooltip title="Download pdf report">
          <Button onClick={generatePdf} variant="outlined" size="small">
            Pdf
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default AccountReportPdfGenerator;
