import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import List from '@mui/material/List';
import {
  Autocomplete,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material';
import { IDivisionList } from 'src/basic-rest/types';
import { IGetID } from './Address';
import { AddressLoading, AddressNotFound } from './utils';
import { useFetchDivisionData } from 'src/basic-rest/useFetchAddressData';
import { Box } from '@mui/system';

type IProps = {
  checked: IGetID;
  setChecked: Dispatch<SetStateAction<IGetID>>;
};

function DivisionList({ checked, setChecked }: IProps) {
  const { data: divisionData, fetchData, loading } = useFetchDivisionData();
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  let content: ReactNode;
  if (loading) {
    content = <AddressLoading />;
  }
  if (!loading && divisionData.length === 0) {
    content = <AddressNotFound />;
  }
  if (divisionData.length > 0 && !loading) {
    content = (
      <Autocomplete
        size='small'
        getOptionLabel={(option: IDivisionList) => option.division_name}
        id='combo-box-demo'
        options={divisionData}
        renderOption={(params: any, option: IDivisionList) => (
          <Box component='li' {...params}>
            {option.division_name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label='Select division' variant='outlined' />
        )}
        onChange={(_event, value: IDivisionList) => {
          setChecked({
            district: null,
            thana: null,
            area: null,
            division: value,
          });
        }}
      />
    );
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 },
      }}
    >
      <ListSubheader
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          top: '12px !important',
          padding: '12px',
          mb: 2,
        }}
      >
        <Typography variant='h4'>Division list</Typography>
      </ListSubheader>
      {content}
    </List>
  );
}

export default DivisionList;
