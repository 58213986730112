import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import Status404 from 'src/content/pages/Status/Status404';

const PermissionRoute = ({ component, route }) => {
  const { user } = useAuthContext();

  if (route === '*' ? true : user?.permissions?.subModules?.includes(route)) {
    return component;
  } else {
    return /* <Status404 />; */ <GlobalLoader />;
  }
};

export default PermissionRoute;
