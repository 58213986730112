import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IBudgetReport } from '../type/budgetType';
import dayjs from 'dayjs';
import { getStatusLabel } from 'src/Utils/Combined';
import BodyContainer from 'src/common/BodyContainer';
import CommonDateRange from 'src/common/CommonDateRange';
const BudgetReport = () => {
  const [filter, setFilter] = useState<{
    page: string | number;
    limit: string | number;
    status: string;
    from_date: string;
    to_date: string;
  }>({
    page: 1,
    limit: 30,
    status: 'Approved',
    from_date: '',
    to_date: '',
  });
  const { response, loading, total, getData } = GetDataApi<IBudgetReport[]>(
    `/api/atab/new-report/budget/report?limit=${filter.limit}&page=${filter.page}&status=${filter.status}&from_date=${filter?.from_date}&to_date=${filter?.to_date}`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter]);

  console.log(filter);
  return (
    <BodyContainer>
      <Card title='dssaf'>
        <CardHeader
          title={
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <h2 style={{ margin: 0 }}>Budget Report List</h2>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Select
                  size='small'
                  value={filter?.status}
                  onChange={(e) =>
                    setFilter({ ...filter, status: e.target.value })
                  }
                  displayEmpty
                  style={{ marginLeft: 'auto', display: 'block' }}
                >
                  <MenuItem value='Approved'>Approved</MenuItem>
                  <MenuItem value='Rejected'>Rejected</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <CommonDateRange setDateRange={setFilter} filter={filter} />
              </Grid>
            </Grid>
          }
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell>Budget Date</TableCell>
                <TableCell>Approved Date</TableCell>
                <TableCell>Budget No</TableCell>
                <TableCell>Budget Name</TableCell>
                <TableCell>Approved By</TableCell>
                <TableCell>Budget Status</TableCell>
                <TableCell>Account Name</TableCell>
                <TableCell>Account Type</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableLoader />
              ) : response?.length ? (
                <>
                  {response?.map((budget, index) => {
                    return (
                      <TableRow hover key={budget.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {budget.budget_date &&
                            dayjs(budget.budget_date).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell>
                          {budget?.final_approved_date &&
                            dayjs(budget.final_approved_date).format(
                              'DD-MM-YYYY'
                            )}
                        </TableCell>
                        <TableCell>{budget.budget_no}</TableCell>
                        <TableCell>{budget.name}</TableCell>
                        <TableCell>{budget.final_approved_by_name}</TableCell>
                        <TableCell>
                          {budget?.status && getStatusLabel(budget?.status)}
                        </TableCell>
                        <TableCell>{budget?.account_name}</TableCell>
                        <TableCell>{budget?.account_type}</TableCell>
                        <TableCell>{budget.total_amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component='div'
            count={total as number}
            onPageChange={(_event: any, newPage: number) =>
              setFilter({ ...filter, page: newPage })
            }
            onRowsPerPageChange={(event) =>
              // setLimit(parseInt(event.target.value))
              setFilter({ ...filter, limit: event.target.value })
            }
            page={Number(filter.page) && Number(filter.page)}
            rowsPerPage={Number(filter.limit)}
            rowsPerPageOptions={[30, 50, 75, 100]}
          />
        </Box>
      </Card>
    </BodyContainer>
  );
};

export default BudgetReport;
