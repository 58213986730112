import { Box } from '@mui/system';
import { useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import ShowAllComplain from '../ShowAllComplain';
import { Divider, Drawer, IconButton, styled, useTheme } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import ComplainBottonBar from '../ComplainBottonBar';
import ComplainCardDetails from './ComplainCardDetails';
import ComplainTopBar from '../ComplainTopBar';
const RootWrapper = styled(Box)(
  ({ theme }) => `
    height: calc(100vh - ${'110px'});
    display: flex;
`
);

const Sidebar = styled(Box)(
  ({ theme }) => `
          width: 300px;
          background: ${theme.colors.alpha.white[100]};
          border-right: ${theme.colors.alpha.black[10]} solid 1px;
  `
);

const ChatWindow = styled(Box)(
  () => `
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
  `
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
          background: ${theme.colors.alpha.white[100]};
          border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
          padding: ${theme.spacing(2)};
          align-items: center;
  `
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    background: ${theme.colors.alpha.white[100]};
  `
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
      width: 340px;
      flex-shrink: 0;
  
    & > .MuiPaper-root {
          width: 340px;
          z-index: 3;
    }
  `
);
const ComplainChattingPage = () => {
  const [isComplainList, setIsCompalinList] = useState<number>();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const [selectedComplain, setSelectedComplain] = useState({
    complain_id: '',
    member_id: '',
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <RootWrapper className='Mui-FixedWrapper'>
        <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' },
          }}
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <Scrollbar>
            <h4 style={{ textAlign: 'center', padding: '2px' }}>
              Complain List
            </h4>
            <ComplainCardDetails
              selectedComplain={selectedComplain}
              setSelectedComplain={setSelectedComplain}
              setIsCompalinList={setIsCompalinList}
            />
          </Scrollbar>
        </DrawerWrapperMobile>
        <Sidebar
          sx={{
            display: { xs: 'none', lg: 'inline-block', width: '350px' },
          }}
        >
          <Scrollbar>
            <h4 style={{ textAlign: 'center' }}>Complain List</h4>
            {!selectedComplain.complain_id && isComplainList !== 0 && (
              <h4 style={{ textAlign: 'center', color: 'red' }}>
                Please select a complain
              </h4>
            )}
            <ComplainCardDetails
              selectedComplain={selectedComplain}
              setSelectedComplain={setSelectedComplain}
              setIsCompalinList={setIsCompalinList}
            />
          </Scrollbar>
        </Sidebar>

        <ChatWindow>
          {isComplainList ? (
            <>
              <ChatTopBar
                sx={{
                  display: { xs: 'flex', lg: 'inline-block' },
                }}
              >
                <IconButtonToggle
                  sx={{
                    display: { lg: 'none', xs: 'flex' },
                    mr: 2,
                  }}
                  color='primary'
                  onClick={handleDrawerToggle}
                  size='small'
                >
                  <MenuTwoToneIcon />
                </IconButtonToggle>
                <ComplainTopBar />
              </ChatTopBar>
              <Box flex={1}>
                <Scrollbar>
                  <ShowAllComplain
                    selectedComplain={selectedComplain}
                    setSelectedComplain={setSelectedComplain}
                  />
                </Scrollbar>
              </Box>
              <Divider />
              <ComplainBottonBar selectedComplain={selectedComplain} />
            </>
          ) : (
            <Box
              display='flex'
              width='100%'
              height='100%'
              alignContent='center'
              alignItems='center'
              justifyContent='center'
            >
              No Complain Found
            </Box>
          )}
        </ChatWindow>
      </RootWrapper>
    </div>
  );
};

export default ComplainChattingPage;
