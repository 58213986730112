import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import moment from "moment";
import { url } from "src/Helpers/utils/Constant";
import { IJoinedExam, ISingleExamInfo } from "src/Utils/type";
import Spinner from "src/components/Spinner/Spinner";

type IInput = {
  training_exam_attendance: number;
  training_exam_attendance_exam: number;
  training_exam_attendance_student: number;
};

type IProps = {
  singleExamInfo: ISingleExamInfo;
  handleClose: Function;
};

const AddAttendance = ({ singleExamInfo, handleClose }: IProps) => {
  const [classStudents, setClassStudents] = useState<IJoinedExam[]>([]);
  const [attendance, setAttendance] = useState<IInput[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await axios.get(
          `${url}/api/training/exam/get/all/joined/student/by/exam-id/${singleExamInfo.training_exam_id}`
        );
        if (data.data.success) {
          setClassStudents(data.data.data);
          setAttendance(
            data.data.data.map((item: IJoinedExam) => {
              return {
                training_exam_attendance_exam: singleExamInfo.training_exam_id,
                training_exam_attendance_student: item.user_training_trainee_id,
                training_exam_attendance: 0,
              };
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setAddLoading(true);
      const res = await axios.post(`${url}/api/training/exam/attend`, {
        attendance,
      });
      if (res.data.success) {
        setAddLoading(false);
        Toaster().fire({
          icon: "success",
          title: `Attendance successfully`,
        });
        handleClose();
      } else {
        Toaster().fire({
          icon: "error",
          title: `${res.data.message}`,
        });
        setAddLoading(false);
      }
    } catch (err) {
      Toaster().fire({
        icon: "error",
        title: `Attendance already added`,
      });
      setAddLoading(false);
    }
  };

  const handleAttendance = ({ attend, student_id }) => {
    setAttendance(
      attendance.map((item: IInput) => {
        if (item.training_exam_attendance_student === student_id) {
          return {
            training_exam_attendance_exam: singleExamInfo.training_exam_id,
            training_exam_attendance_student: student_id,
            training_exam_attendance: attend,
          };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Add Attendance</title>
      </Helmet>
      <Container sx={{ mt: 4, width: "500px" }} maxWidth="lg">
        <Card>
          <CardHeader
            title={`Exam date ${moment
              .tz(singleExamInfo?.training_exam_date, "Asia/Dhaka")
              .format("DD-MM-YYYY")}`}
            action={
              !addLoading ? (
                <Button onClick={handleSubmit} variant="outlined">
                  Submit
                </Button>
              ) : (
                <Button variant="outlined">wait...</Button>
              )
            }
          />
          <Divider />
          <CardContent>
            {loading ? (
              <Spinner />
            ) : (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Attendance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classStudents?.map((row: IJoinedExam, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.user_training_trainee_id}
                          </TableCell>
                          <TableCell align="center">
                            {row.user_training_trainee_name}
                          </TableCell>
                          <TableCell align="right">
                            <FormControl variant="filled" size="small">
                              <InputLabel id="demo-simple-select-filled-label">
                                Attendance
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                defaultValue="absent"
                                onChange={(e) =>
                                  handleAttendance({
                                    attend: e.target.value === "attend" ? 1 : 0,
                                    student_id: row.user_training_trainee_id,
                                  })
                                }
                              >
                                <MenuItem value="attend">Attend</MenuItem>
                                <MenuItem value="absent">Absent</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddAttendance;
