import { Button, Grid, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import Toaster from 'src/Toaster/Toaster';
import { Imembers } from 'src/Types/module/member/memberTypes';
import SpecificRemoveFromGroup from './SpecificRemoveFromGroup';
import VisibilityIcon from '@mui/icons-material/Edit';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import CommSelectFieldSearchmemberlistbyLicenseAndname from 'src/common/CommSelectFieldSearchmemberlistbyLicenseAndname';
const EditGroup = ({
  id,
  name,
  setRefresh,
}: {
  id: any;
  name: any;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { updateData, success, loading } = UpdateDataApi(
    `/api/member/group/${id}`
  );
  useEffect(() => {
    if (success) {
      setRefresh(true);
      setOpen(false);
    }
  }, [success]);
  const [open, setOpen] = useState(false);
  const [removeMember, setRemoveMember] = useState<number[]>();
  const [inputData, setInputData] = useState<{
    add: string | string[];
    del: string | string[];
    name: string;
  }>({
    add: '',
    del: '',
    name: '',
  });

  const onSubmit = async () => {
    const body = {
      name: inputData.name,
      del: removeMember,
      add: inputData.add,
    };

    const removeData = removeUndefinedAndNull(body);
    if (Object.keys(removeData).length === 0) {
      Toaster().fire({
        icon: 'warning',
        title: 'please change any field',
      });
    } else {
      updateData(removeData);
    }
  };
  return (
    <>
      <Box sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
        <IconButton
          sx={{
            color: 'green',
          }}
          color='inherit'
          size='small'
        >
          <VisibilityIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
      <Grid container>
        <Grid item sm={12} md={12}>
          <ModalsWrapper
            setShowModal={setOpen}
            showModal={open}
            modalData={{ title: 'Update Group Members' }}
          >
            <Box sx={{ width: 500, padding: 2 }}>
              <Grid container rowGap={3}>
                <Grid item xs={12}>
                  <Grid item xs={12} md={12} mt={2}>
                    <TextField
                      size='small'
                      id='outlined-basic'
                      placeholder='Enter Group Name'
                      label='Enter Group Name'
                      defaultValue={name}
                      required
                      onChange={(e) =>
                        setInputData({ ...inputData, name: e.target.value })
                      }
                      variant='outlined'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    {/* <Box>Add More Members</Box> */}
                    <CommSelectFieldSearchmemberlistbyLicenseAndname
                      multiple
                      seTMemberId={(Ids) =>
                        setInputData({ ...inputData, add: Ids })
                      }
                    />
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Box>Remove specific member from group</Box>
                  <SpecificRemoveFromGroup
                    id={id}
                    setRemoveMember={setRemoveMember}
                  />
                </Grid>
              </Grid>

              {loading ? (
                <Button
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                  size='small'
                >
                  Loading ...
                </Button>
              ) : (
                <Button
                  onClick={onSubmit}
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                  size='small'
                >
                  Create Group
                </Button>
              )}
            </Box>
          </ModalsWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditGroup;
