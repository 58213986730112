import { Box, Button, Grid, Typography } from '@mui/material';
import PdfAndImageView from 'src/common/PdfAndImageView';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { Iadmin } from 'src/Types/module/admin/adminTypes';
import { getStatusLabel } from 'src/Utils/Combined';

const SingleDetailsinfo = ({ singleAdmin }: { singleAdmin: Iadmin }) => {
  const {
    user_admin_email,
    user_admin_photo,
    user_admin_role_name,
    user_admin_status,
    user_admin_phone,
    user_admin_name,
    user_admin_zone_name,
  } = singleAdmin || {};
  return (
    <Box sx={{ mt: 3 }}>
      <Grid container maxWidth={1000} margin={'0 auto'}>
        <Grid lg={6} item>
          <img
            width={150}
            height={200}
            style={{
              borderRadius: '10px',
            }}
            src={
              user_admin_photo
                ? `${imgUrl}/${user_admin_photo}`
                : '/static/images/avatars/avatar2.png'
            }
            alt=''
          />
        </Grid>
        <Grid lg={6} item>
          <Grid container spacing={{ xs: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Role:
              </Typography>
              {user_admin_role_name}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Status:
              </Typography>
              {getStatusLabel(user_admin_status)}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Phone:
              </Typography>
              {user_admin_phone}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Email:
              </Typography>
              {user_admin_email}
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Zone:
              </Typography>
              <Button>{user_admin_zone_name}</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                gutterBottom
              >
                Signature:
              </Typography>
              <Button>
                <PdfAndImageView file={singleAdmin.signature} showpreview />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleDetailsinfo;
