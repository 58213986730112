import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import EditIcon from "@mui/icons-material/Edit";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateModal from "./UpdateModal";
const SingleBook = () => {
  const { bookID } = useParams();
  const [singleBook, setSingleBook] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/services/trainee/trainee-books/${bookID}`
        );

        if (data.success) {
          setSingleBook(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [bookID]);
  return (
    <>
      <Helmet>
        <title>HAAB - Single Book</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {loading ? (
            <GlobalLoader />
          ) : (
            <Container>
              <Card>
                <CardHeader
                  title={`Book #${singleBook.trainee_books_id}`}
                  action={
                    <>
                      {singleBook.status === "pending" && (
                        <Button
                          size="small"
                          onClick={() => setOpen(true)}
                          variant="contained"
                          startIcon={<EditIcon />}
                        >
                          Update
                        </Button>
                      )}
                    </>
                  }
                />

                <Grid
                  container
                  p={2}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12} sm={4} md={3}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {getStatusLabel(singleBook.status)}
                  </Grid>
                  <Grid item xs={2} sm={4} md={3}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Created at:
                    </Typography>
                    {moment(singleBook.created_at).format("MMM Do YYYY")}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Details :
                    </Typography>
                    {singleBook.details}
                  </Grid>

                  {singleBook.note && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Note :
                      </Typography>
                      {singleBook.note}
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Container>
          )}
        </Grid>
      </Container>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: "Update single book ",
        }}
      >
        <UpdateModal
          singleBook={singleBook}
          handelClose={() => setOpen(false)}
          bookId={singleBook.trainee_books_id}
          setSingleBook={setSingleBook}
        />
        {/* <CreateArea setOpen={setOpen} setReload={setReload} /> */}
      </ModalsWrapper>
    </>
  );
};

export default SingleBook;
