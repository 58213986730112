import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Button, TextField, Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import EditIcon from '@mui/icons-material/Edit';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IViewBudgetListType } from '../type/budgetType';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import dayjs from 'dayjs';
import Toaster from 'src/Toaster/Toaster';
const Toast = Toaster();
type IProps = {
  budgetId: number;
  refetchData?: () => Promise<void>;
  setRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  approved_by?: string;
  showButton?: boolean;
};
const EditBudgetManagement = ({
  budgetId,
  refetchData,
  setRefetch,
  approved_by,
  showButton,
}: IProps) => {
  const [open, setOpen] = useState(false);
  const { response, getData, loading } = GetDataApi<IViewBudgetListType>(
    `/api/atab/budget/${budgetId}`,
    true
  );

  const handleOpen = async () => {
    setOpen(true);
    await getData();
  };
  const { updateData, success, setSuccess } = UpdateDataApi(
    `/api/atab/budget/${budgetId}`
  );
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      name: '',
      budget_date: '',
      budgetItem: [{ name: '', amount: '' }],
    },
  });

  // Use the useFieldArray hook to manage dynamic fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'budgetItem',
  });
  const onSubmit = async (data) => {
    const editBudgetItems = data?.budgetItem
      ?.filter((item) => {
        //check if the existing data have edited or not
        const matchingItem = response?.budgetItems?.find(
          (secondItem) => secondItem.id === item.id
        );
        return (
          matchingItem &&
          (item.name !== matchingItem.name ||
            item.amount !== matchingItem.amount)
        );
      })
      .map((item) => ({ name: item.name, amount: item.amount, id: item.id }));
    const newItems = data?.budgetItem?.filter((item) => item.id === undefined);
    const secondArrayIds = data?.budgetItem
      ?.filter((item) => item.id !== undefined && item.id)
      .map((item) => item.id);

    const missingIds = response?.budgetItems
      ?.filter((item) => item.id && !secondArrayIds?.includes(item.id))
      .map((item) => item.id);
    const updateFormData: {
      name?: string;
      budget_date?: string;
      removeBudgetItems?: number[];
      addBudgetItems?: { name: string; amount: number }[];
      editBudgetItems?: { name: string; amount: number; id: number }[];
    } = {};

    if (data.name !== response.name) {
      updateFormData.name = data.name;
    }

    if (data.budget_date !== dayjs(response.budget_date).format('YYYY-MM-DD')) {
      updateFormData.budget_date = data.budget_date;
    }
    if (missingIds.length) {
      updateFormData.removeBudgetItems = missingIds;
    }
    if (newItems.length) {
      updateFormData.addBudgetItems = newItems;
    }
    if (editBudgetItems?.length) {
      updateFormData.editBudgetItems = editBudgetItems;
    }

    if (
      Object.values(updateFormData).length === 0 &&
      Object.is(
        JSON.stringify(response.budgetItems),
        JSON.stringify(data?.budgetItem)
      )
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Please change any field',
      });
    } else {
      await updateData(updateFormData);
    }
  };

  useEffect(() => {
    if (response) {
      setValue('name', response?.name);
      setValue(
        'budget_date',
        response?.budget_date &&
          dayjs(response?.budget_date).format('YYYY-MM-DD')
      );
      setValue('budgetItem', response?.budgetItems);
    }
  }, [response]);
  useEffect(() => {
    console.log(success);
    if (success) {
      refetchData && refetchData();
      setRefetch && setRefetch(true);
      setSuccess(false);
      setOpen(false);
    }
  }, [success]);
  return (
    <>
      {showButton ? (
        <Button
          size='small'
          variant='text'
          color='primary'
          onClick={handleOpen}
          sx={{
            backgroundColor: 'rgba(0, 152, 74, 0.1)',
          }}
        >
          Budget Adjustment
        </Button>
      ) : (
        <Button variant='text' color='primary' onClick={handleOpen}>
          <EditIcon fontSize='small' />
        </Button>
      )}

      <ModalsWrapper
        modalData={{
          title: (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
              }}
            >
              <Box>Update Budget Details </Box>
            </Box>
          ),
        }}
        showModal={open}
        setShowModal={setOpen}
      >
        <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
          <Box sx={{ pb: 2 }}>
            {!approved_by && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Controller
                    name='name'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        size='small'
                        {...field}
                        label={`Title`}
                        variant='outlined'
                        fullWidth
                        sx={{ mr: 2 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='budget_date'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type='date'
                        required
                        size='small'
                        {...field}
                        label={`Date`}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        fullWidth
                        sx={{ mr: 2 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          {fields?.map((field, index) => (
            <Box key={field.id} mb={2}>
              <Grid container spacing={1}>
                {/* Name Input */}
                <Grid item md={5}>
                  <Controller
                    name={`budgetItem.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        size='small'
                        {...field}
                        label={`Budget for ?`}
                        variant='outlined'
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* Amount Input */}
                <Grid item md={4}>
                  <Controller
                    name={`budgetItem.${index}.amount`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        size='small'
                        {...field}
                        label={`Amount`}
                        variant='outlined'
                        fullWidth
                        type='number'
                      />
                    )}
                  />
                </Grid>

                {/* Buttons (+ and -) */}
                <Grid item md={3}>
                  <Grid container spacing={1}>
                    {/* Remove Button (-) */}
                    <Grid item xs={6}>
                      <Button
                        size='small'
                        variant='outlined'
                        color='error'
                        onClick={() => remove(index)}
                        disabled={fields?.length === 1}
                        sx={{
                          '&.Mui-disabled': {
                            color: (theme) => theme.palette.grey[600],
                            borderColor: (theme) => theme.palette.grey[600],
                          },
                          height: '35px',
                        }}
                        fullWidth
                      >
                        <RemoveIcon />
                      </Button>
                    </Grid>

                    {/* Add Button (+) */}
                    <Grid item xs={6}>
                      <Button
                        size='small'
                        variant='contained'
                        onClick={() => append({ name: '', amount: '' })}
                        sx={{ height: '35px' }}
                        fullWidth
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box display={'flex'} justifyContent={'end'} mt={5}>
            <Button
              size='small'
              type='submit'
              variant='contained'
              color='primary'
            >
              Submit
            </Button>
          </Box>
        </Box>{' '}
      </ModalsWrapper>
    </>
  );
};

export default EditBudgetManagement;
