import { Visibility } from '@mui/icons-material';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getStatusLabel } from 'src/Utils/Combined';
import { IMoneyReceipt } from 'src/Types/module/common/commonTypes';
import { IVoucherType } from '../type/voucherType';
import getStatusChip from 'src/common/getStatusChip';
import ViewAllVoucher from './ViewAllVoucher';

type IProps = {
  sInvoice: IVoucherType;
  index: number;
  getData: () => Promise<void>;
};

const InvoiceTableRow = ({ sInvoice: invoiceData, index, getData }: IProps) => {
  const theme = useTheme();

  return (
    <>
      {invoiceData && (
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{invoiceData.user_member_company_name}</TableCell>
          <TableCell>{invoiceData.user_member_hajj_license}</TableCell>
          <TableCell>{invoiceData.voucher_no}</TableCell>
          <TableCell>
            {moment(invoiceData.atab_payment_invoice_issue_date).format(
              'DD/MMM/YYYY'
            )}
          </TableCell>
          <TableCell>
            {Number(invoiceData.atab_payment_invoice_grand_total)}
          </TableCell>
          <TableCell>{Number(invoiceData.due)}</TableCell>
          <TableCell>
            {getStatusChip(invoiceData.atab_payment_invoice_status)}
          </TableCell>
          <TableCell>
            <ViewAllVoucher
              voucherId={invoiceData.atab_payment_invoice_id}
              getData={getData}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default InvoiceTableRow;
