import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import Toaster from 'src/Toaster/Toaster';
import { removeUndefinedAndNull } from './removeUndefinedAndNull';
import { HttpClient } from 'src/Helpers/http/http';
import { IMemberInfo } from 'src/Types/module/member/memberTypes';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import GetDataApi from './FetchData/GetDataApi';
import { imgUrl } from 'src/Helpers/utils/Constant';

type IProps = {
  memberInfo: IMemberInfo;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setRefresh?: any;
};
const RoleUpdateComponent = ({
  memberInfo,
  setShowModal,
  setRefresh,
}: IProps) => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const [memberData, setMemberData] = useState<any>({});
  const { response } = GetDataApi<{ signature: string }>(
    `/api/atab/admin/get/single/admin/${user.user_admin_id}`
  );
  const [comments, setComments] = useState<{
    account_comments: string;
    president_comments: string;
    computer_operator_comments: string;
    finance_comments: string;
    secretary_comments: string;
    admin_comments: string;
    secretary_general_comments: string;
  }>();

  const { memberId } = useParams();
  const [loading, setLoading] = useState(false);
  const handleMemberUpdate = async () => {
    const removeData = removeUndefinedAndNull({ ...memberData, ...comments });
    const values = Object.values(removeData);
    if (!values.length) {
      Toast.fire({
        icon: 'error',
        title: `You must change any field`,
      });
      setLoading(false);
      return;
    } else if (!Object.values(removeUndefinedAndNull(memberData))?.length) {
      Toast.fire({
        icon: 'error',
        title: `Fillup Assing Field`,
      });
      setLoading(false);
      return;
    } else if (!Object?.values(removeUndefinedAndNull(comments))?.length) {
      Toast.fire({
        icon: 'error',
        title: `Please fillup comments field`,
      });
      setLoading(false);
      return;
    } else {
      if (response?.signature) {
        try {
          setLoading(true);
          const res: any = await HttpClient.put(
            `/api/atab/user-member/admin/forward/${memberId}`,
            removeData
          );

          if (res?.success) {
            Toast.fire({
              icon: 'success',
              title: 'Assigned successfully updated',
            });
            setShowModal(false);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (err) {
          Toast.fire({
            icon: 'error',
            title: `Update Failed ! \n ${err?.response?.data?.message}`,
          });
          setLoading(false);
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: `Plese add signature`,
        });
      }
    }
  };
  return (
    <Box paddingRight={2} paddingLeft={2}>
      {memberInfo?.ck_computer_operator === 1 &&
        user?.user_admin_role?.toLowerCase() === 'computer operator' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    memberData.ck_accounts && memberData.ck_secretary
                      ? true
                      : false
                  }
                  disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_accounts: e.target.checked ? 1 : '',
                      ck_secretary: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_accounts'
                  color='primary'
                />
              }
              label='Assign to Accounts & Secretary'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={2} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    account_comments: e.target.value,
                    secretary_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}
      {/* ------------secretary start----------- */}
      <Grid container spacing={1}>
        {memberInfo?.ck_secretary === 1 &&
          user?.user_admin_role?.toLowerCase() === 'secretary' && (
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={memberData.ck_president ? true : false}
                    // disabled={memberInfo.ck_accounts ? true : false}
                    onChange={(e: any) =>
                      setMemberData({
                        ...memberData,
                        ck_president: e.target.checked ? 1 : '',
                      })
                    }
                    name='forward_to_presedent'
                    color='primary'
                  />
                }
                label='Assign to Presedent'
              />

              <Box>
                <TextField
                  type='text'
                  multiline
                  fullWidth
                  rows={1} // Set initial number of rows
                  variant='outlined'
                  placeholder='Enter your note' // Choose the variant (optional)
                  onChange={(e) =>
                    setComments({
                      ...comments,
                      president_comments: e.target.value,
                    })
                  }
                />
              </Box>
            </Grid>
          )}
        {memberInfo?.ck_secretary === 1 &&
          user?.user_admin_role?.toLowerCase() === 'secretary' && (
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={memberData.ck_computer_operator ? true : false}
                    // disabled={memberInfo.ck_accounts ? true : false}
                    onChange={(e: any) =>
                      setMemberData({
                        ...memberData,
                        ck_computer_operator: e.target.checked ? 1 : '',
                      })
                    }
                    name='forward_to_com_operator'
                    color='primary'
                  />
                }
                label='Assign to Computer Operators'
              />
              {
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={1} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        computer_operator_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              }
            </Grid>
          )}
        {memberInfo?.ck_secretary === 1 &&
          user?.user_admin_role?.toLowerCase() === 'secretary' && (
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={memberData.ck_secretary_general ? true : false}
                    // disabled={memberInfo.ck_accounts ? true : false}
                    onChange={(e: any) =>
                      setMemberData({
                        ...memberData,
                        ck_secretary_general: e.target.checked ? 1 : '',
                      })
                    }
                    name='forward_to_presedent'
                    color='primary'
                  />
                }
                label='Assign to Secretary General'
              />
              {
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={1} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        secretary_general_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              }
            </Grid>
          )}
        {memberInfo?.ck_secretary === 1 &&
          user?.user_admin_role?.toLowerCase() === 'secretary' && (
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={memberData.ck_accounts ? true : false}
                    // disabled={memberInfo.ck_accounts ? true : false}
                    onChange={(e: any) =>
                      setMemberData({
                        ...memberData,
                        ck_accounts: e.target.checked ? 1 : '',
                      })
                    }
                    name='forward_to_presedent'
                    color='primary'
                  />
                }
                label='Assign to Accounts'
              />

              {
                <Box>
                  <TextField
                    type='text'
                    multiline
                    fullWidth
                    rows={1} // Set initial number of rows
                    variant='outlined' // Choose the variant (optional)
                    placeholder='Enter your note' // Optional label
                    onChange={(e) =>
                      setComments({
                        ...comments,
                        account_comments: e.target.value,
                      })
                    }
                  />
                </Box>
              }
            </Grid>
          )}
        {memberInfo?.ck_secretary === 1 &&
          user?.user_admin_role?.toLowerCase() === 'secretary' && (
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={memberData.ck_finance ? true : false}
                    // disabled={memberInfo.ck_accounts ? true : false}
                    onChange={(e: any) =>
                      setMemberData({
                        ...memberData,
                        ck_finance: e.target.checked ? 1 : '',
                      })
                    }
                    name='forward_to_presedent'
                    color='primary'
                  />
                }
                label='Assign to Finance Secretary'
              />

              <Box>
                <TextField
                  type='text'
                  multiline
                  fullWidth
                  rows={1} // Set initial number of rows
                  variant='outlined' // Choose the variant (optional)
                  placeholder='Enter your note' // Optional label
                  onChange={(e) =>
                    setComments({
                      ...comments,
                      finance_comments: e.target.value,
                    })
                  }
                />
              </Box>
            </Grid>
          )}
      </Grid>
      {/* SECRETARY GENERAL START------------- */}
      <Grid container spacing={1}>
        {user?.user_admin_role?.toLowerCase() === 'secretary general' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_secretary ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_secretary: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_presedent'
                  color='primary'
                />
              }
              label='Assign to Security'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={1} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    secretary_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}
        {user?.user_admin_role?.toLowerCase() === 'secretary general' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_computer_operator ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_computer_operator: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_presedent'
                  color='primary'
                />
              }
              label='Assign to Computer Operators'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={1} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    computer_operator_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}
        {user?.user_admin_role?.toLowerCase() === 'secretary general' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_accounts ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_accounts: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_presedent'
                  color='primary'
                />
              }
              label='Assign to Accounts'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={1} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    account_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}
        {user?.user_admin_role?.toLowerCase() === 'secretary general' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_finance ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_finance: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_presedent'
                  color='primary'
                />
              }
              label='Assign to Finance Secretary'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={1} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    finance_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {/* SECRETARY GENERAL end------------- */}
      {memberInfo?.ck_president === 1 &&
        user?.user_admin_role?.toLowerCase() === 'president' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_secretary ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_secretary: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_presedent'
                  color='primary'
                />
              }
              label='Assign to Secretary'
            />
          </Grid>
        )}
      {memberInfo?.ck_president === 1 &&
        user?.user_admin_role?.toLowerCase() === 'president' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_president ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_computer_operator: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_com_operator'
                  color='primary'
                />
              }
              label='Assign to Computer Operators'
            />
          </Grid>
        )}
      {memberInfo?.ck_president === 1 &&
        user?.user_admin_role?.toLowerCase() === 'president' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_accounts ? true : false}
                  // disabled={memberInfo.ck_accounts ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_accounts: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_com_operator'
                  color='primary'
                />
              }
              label='Assign to Accounts'
            />
          </Grid>
        )}
      {memberInfo?.ck_president === 1 &&
        user?.user_admin_role?.toLowerCase() === 'president' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_finance ? true : false}
                  // disabled={memberInfo.ck_finance ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_finance: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_com_operator'
                  color='primary'
                />
              }
              label='Assign to Finance Secretary'
            />
          </Grid>
        )}
      {memberInfo?.ck_accounts === 1 &&
        user?.user_admin_role?.toLowerCase() === 'accounts' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={memberInfo.ck_finance ? true : false}
                  checked={memberData.ck_finance ? true : false}
                  onChange={(e: any) =>
                    setMemberData({
                      ...memberData,
                      ck_finance: e.target.checked ? 1 : '',
                    })
                  }
                  name='forward_to_finace_secretary'
                  color='primary'
                />
              }
              label='Assign to Finance Secretary'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={2} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    finance_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}

      {memberInfo?.ck_finance === 1 &&
        user?.user_admin_role?.toLowerCase() === 'finance secretary' && (
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={memberData.ck_secretary ? true : false}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setMemberData({
                        ...memberData,
                        ck_secretary: e.target.checked ? 1 : '',
                      });
                    } else {
                      setMemberData({
                        ...memberData,
                        ck_secretary: '',
                      });
                    }
                  }}
                  name='forward_to_finace_secretary'
                  color='primary'
                />
              }
              label='Assign to Secretary'
            />
            <Box>
              <TextField
                type='text'
                multiline
                fullWidth
                rows={2} // Set initial number of rows
                variant='outlined' // Choose the variant (optional)
                placeholder='Enter your note' // Optional label
                onChange={(e) =>
                  setComments({
                    ...comments,
                    secretary_comments: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        )}

      <Grid item xs={4} mt={3}>
        {response?.signature ? (
          (memberData.ck_president ||
            memberData.ck_computer_operator ||
            memberData.ck_accounts ||
            memberData.ck_secretary ||
            memberData.ck_finance ||
            memberData.ck_secretary_general) && (
            <Box display={'flex'} flexDirection={'column'}>
              <img
                width={100}
                style={{ border: '1px solid gray' }}
                src={`${imgUrl}/${response?.signature}`}
              />
              Signature
            </Box>
          )
        ) : (
          <Box border={'1px solid gray'} p={1}>
            No Signature Available
          </Box>
        )}
      </Grid>
      <Box mt={3} textAlign='right'>
        {loading ? (
          <Button
            size='small'
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            loading..
          </Button>
        ) : (
          <Button
            size='small'
            onClick={() => handleMemberUpdate()}
            variant='contained'
            style={{ width: '100%' }}
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RoleUpdateComponent;
