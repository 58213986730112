import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IBatchList } from "src/Types/module/courses/trainee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getStatusLabel } from "src/Utils/Combined";
import { Link } from "react-router-dom";

const SingleBatchRow = ({ batch }: { batch: IBatchList }) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {batch.training_batch_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {batch.training_batch_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell
          title={batch.training_batch_course_name}
          sx={{ maxWidth: "250px" }}
        >
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {batch.training_batch_course_name}
          </Typography>
        </TableCell>
        <TableCell sx={{ maxWidth: "100px" }}>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {batch.training_teacher_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {getStatusLabel(batch.training_batch_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Link to={`/training-batch/details/${batch.training_batch_id}`}>
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleBatchRow;
