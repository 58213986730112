import { useState, useEffect, ChangeEvent } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import {
  IBatchList,
  ISingleMaterial,
} from 'src/Types/module/batch/allTypeOfBatch';
import AllMaterialsTableRow from '../Material/AllMaterialsTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { RefreshButton } from 'src/Utils/CommonAllShowUtils';
import CreateMaterial from './CreateMaterial';

const AllMaterials = () => {
  const [allMaterials, setAllMaterials] = useState<ISingleMaterial[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [statusId, setStatusId] = useState<string>('all');
  const [optionValue, setOptionValue] = useState<IBatchList[]>([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri: string;
        if (statusId) {
          uri = `${url}/api/training/batch/get/materials/${statusId}`;
        }
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllMaterials(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [refresh, statusId]);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/training/batch/get/all/or/status/running`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setOptionValue(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [refresh]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display='flex' alignItems='center' gap={2}>
                  <FormControl
                    sx={{ width: '150px' }}
                    variant='outlined'
                    size='small'
                  >
                    <InputLabel>Select batch</InputLabel>
                    <Select
                      onChange={(e: any) => setStatusId(e.target.value)}
                      label='Select batch'
                      autoWidth
                      value={statusId}
                    >
                      <MenuItem value='all'>All</MenuItem>
                      {optionValue.map((statusOption: IBatchList) => (
                        <MenuItem
                          key={statusOption.training_batch_id}
                          value={statusOption.training_batch_id}
                        >
                          {statusOption.training_batch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={() => handleOpen()}
                    variant='contained'
                    size='small'
                  >
                    Create material
                  </Button>
                  <RefreshButton action={setRefresh} />{' '}
                </Box>
              }
              title='All Materials'
            ></CardHeader>
            <Divider />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Batch Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {isLoading ? (
                  <TableLoader />
                ) : allMaterials.length ? (
                  <TableBody>
                    {allMaterials.map((material: ISingleMaterial) => {
                      return (
                        <AllMaterialsTableRow
                          key={material.training_batch_materials_id}
                          material={material}
                          allMaterials={allMaterials}
                          setAllMaterials={setAllMaterials}
                        />
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableNotFound />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={AllMaterials.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <Box>
            <Typography sx={{ mb: 2 }}> Create Material</Typography>
            <Divider />
          </Box>
          <CreateMaterial
            handleClose={handleClose}
            setAllMaterials={setAllMaterials}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AllMaterials;
