import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { ISingleRenewalApplication } from 'src/Types/module/applications/applications';
import UpdateModal from './UpdateModal';
import PdfViewer from './PdfViewer';
import PdfAndImageView from 'src/common/PdfAndImageView';
import { getStatusLabel } from 'src/Utils/Combined';

const SingleRenewalApplication = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [application, setApplication] =
    useState<ISingleRenewalApplication | null>(null);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const type = file?.split('.')?.[1];
  useEffect(() => {
    (async () => {
      try {
        const data = await axios.get(
          `${url}/api/member/renewal-application/${id}`
        );
        if (data.data.success) {
          setApplication(data.data.data);
        }
      } catch (err) {
        alert('No application found');
        navigate('/update-applications/renewal-application');
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>HAAB - Renewal application</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Card>
          <CardHeader
            title='Renewal application'
            action={
              <Button onClick={() => setShowModal(true)} variant='outlined'>
                Update
              </Button>
            }
          />
          <Divider />
          <CardContent>
            {application ? (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Application Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {moment(
                          application.renewal_application_created_at
                        ).format('MMM Do YY')}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Company Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {application.user_member_company_name}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>Status</Typography>
                      </TableCell>
                      <TableCell>
                        {getStatusLabel(
                          application?.renewal_application_status
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Civil Aviation
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.renewal_application_updated_civil_aviation_file
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Trade License
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.renewal_application_updated_trade_license_file
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Tax Return
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.renewal_application_tax_return_certificate_file
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Previous Certificate
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.renewal_application_prev_atab_certificate_file
                          }
                        />
                      </TableCell>
                    </TableRow>

                    {application.renewal_application_reject_reason && (
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Note</Typography>
                        </TableCell>
                        <TableCell>
                          {application.renewal_application_reject_reason}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                No Data found
              </Typography>
            )}
          </CardContent>
        </Card>

        {file && type ? (
          <Box sx={{ py: 5 }}>
            {type === 'pdf' ? (
              <>
                <PdfViewer pdfUrl={`${imgUrl}/${file}`} />
              </>
            ) : (
              <>
                <img
                  src={`${imgUrl}/${file}`}
                  alt=''
                  height={400}
                  width={400}
                />
                <br />
                <a
                  href={`${imgUrl}/${file}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Download
                </a>
              </>
            )}
          </Box>
        ) : (
          ''
        )}
      </Container>
      {application && (
        <UpdateModal
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          application={application}
          setApplication={setApplication}
        />
      )}
    </>
  );
};

export default SingleRenewalApplication;
