import { Box } from '@mui/material';
import ComplainChattingPage from './ComplainChattingPage';

const ViewComplain = () => {
  return (
    <Box>
      <ComplainChattingPage />
    </Box>
  );
};

export default ViewComplain;
