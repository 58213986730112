import { useState, ChangeEvent } from "react";
import {
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { DatePicker, TimePicker } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import { url } from "../../../../../Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

const CreateExam = ({ handleClose, batchId, refresh, setRefresh }) => {
  const { handleSubmit, reset } = useForm();
  const currentDate = new Date();
  const [loading, setLoading] = useState<Boolean>(false);
  const [newDate, setNewDate] = useState<Date | null>(currentDate);
  const [mark, setMark] = useState();
  const [image, setImage] = useState("");
  const [timeValue, setTimeValue] = useState<Date | null>(currentDate);

  const { user } = useAuthContext();

  const onSubmit = async (inputs: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("training_exam_batch", batchId.toString());
    formData.append("training_exam_date", newDate.toISOString().split("T")[0]);
    formData.append(
      "training_exam_time",
      timeValue.toTimeString().split(" ")[0]
    );
    formData.append("training_exam_material_file", image);
    formData.append("training_exam_total_mark", mark);
    setLoading(true);
    try {
      for (const key in inputs) {
        formData.append(key, inputs[key]);
      }
      const { data } = await axios.post(
        `${url}/api/training/exam/create?user_admin_id=${user.user_admin_id}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        setRefresh(!refresh);
        handleClose();
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
        setLoading(false);
      }
      reset();
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
    reset();
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create a exam</title>
      </Helmet>
      <Container maxWidth="sm">
        <CardHeader title="Create a exam" />
        <Divider />
        <CardContent>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            sx={{ pt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                <DatePicker
                  label="Select exam date"
                  value={newDate}
                  onChange={(e: any) => setNewDate(e)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <TimePicker
                  label="Select exam time"
                  value={timeValue}
                  onChange={(e: any) => setTimeValue(e)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="file"
                  label="Choose exam file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => setImage(e.target.files[0])}
                  autoComplete="file"
                  type="file"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="number"
                  label=" Exam mark"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => setMark(e.target.value)}
                  autoComplete="file"
                  type="number"
                />
              </Grid>
            </Grid>

            {loading ? (
              <Button
                disabled
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                Creating...
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                Create exam
              </Button>
            )}
          </Box>
        </CardContent>
      </Container>
    </>
  );
};

export default CreateExam;
