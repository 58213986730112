import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { IAgencyUpdateInfoApplication } from 'src/Types/module/applications/applications';
import { getStatusLabel } from 'src/Utils/Combined';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateAgencyInfoApplication from './UpdateAgencyInfoApplication';
import { Link } from 'react-router-dom';
import PdfAndImageView from 'src/common/PdfAndImageView';

const SingleAgencyInfoUpdateApplication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [application, setApplication] = useState<IAgencyUpdateInfoApplication>(
    {} as IAgencyUpdateInfoApplication
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/atab/agency-member-info/${id}`
        );

        if (data.success) {
          setApplication(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [id]);
  return (
    <>
      {' '}
      <Helmet>
        <title>HAAB - Agency information update application</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Card>
          <CardHeader
            title='Agency information update application'
            action={
              application.status !== 'approved' ? (
                <Button onClick={() => setShowModal(true)} variant='outlined'>
                  Update
                </Button>
              ) : (
                ''
              )
            }
          />
          <Divider />
          <CardContent>
            {application ? (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Application Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {moment(application.agency_info_created_at).format(
                          'MMM Do YY'
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Company Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {application.user_member_company_name}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>Status</Typography>
                      </TableCell>
                      <TableCell>
                        {getStatusLabel(application.status)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Company Logo
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={application.member_company_logo}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Representative Photo
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={application.user_member_representative_photo}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>Cellphone</Typography>
                      </TableCell>
                      <TableCell>{application.cellphone}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>Phone</Typography>
                      </TableCell>
                      <TableCell>{application.phone}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>Website</Typography>
                      </TableCell>
                      <TableCell>
                        <a
                          href={application.member_company_website}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {application.member_company_website}
                        </a>
                      </TableCell>
                    </TableRow>

                    {application.note && (
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Note</Typography>
                        </TableCell>
                        <TableCell>{application.note}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                No Data found
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
      <ModalsWrapper
        setShowModal={setShowModal}
        showModal={showModal}
        modalData={{
          title: 'Update agency information update application',
        }}
      >
        <UpdateAgencyInfoApplication
          setApplication={setApplication}
          application={application}
          handleClose={() => setShowModal(false)}
        />
      </ModalsWrapper>
    </>
  );
};

export default SingleAgencyInfoUpdateApplication;
