import { useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { TableLoader } from 'src/Utils/TableLoader';
import TableNoDataMessage from 'src/common/TableNoDataMessage';
import Visibility from '@mui/icons-material/Visibility';
import { ISingleViewMoneyReceipt } from '../types/vouchersTypes';
import moment from 'moment';
import VerifiedMoneyReceiptStatus from './VerifiedMoneyReceiptStatus';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import PdfAndImageView from 'src/common/PdfAndImageView';
const SingleViewMoneyRe = ({
  voucherId,
  getData: getAll,
}: {
  voucherId: number;
  getData: () => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);
  // Fetch voucher data on button click
  const { response, setRefetch, getData, loading } =
    GetDataApi<ISingleViewMoneyReceipt>(
      `/api/atab/money-receipt/${voucherId}`,
      true
    );

  const handleOpen = async () => {
    getData();
    setOpen(true);
  };
  console.log(response);
  return (
    <>
      <Button variant='text' color='primary' onClick={handleOpen}>
        <Visibility fontSize='small' />
      </Button>
      <Box>
        <ModalsWrapper
          modalData={{
            title: (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '20px',
                }}
              >
                <Box></Box>
                <Box> Money Receipt Details </Box>
                <Box>
                  <VerifiedMoneyReceiptStatus
                    voucher_id={response?.id}
                    status={response?.status}
                    refetchData={() => {
                      setOpen(false), getAll();
                    }}
                  />
                </Box>
              </Box>
            ),
          }}
          showModal={open}
          setShowModal={setOpen}
        >
          <Box sx={{ width: '100%', maxWidth: '1000px', pl: 2, pr: 2 }}>
            <TableContainer>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell size='small' align='center'>
                      SL no
                    </TableCell>

                    <TableCell align='center' size='small'>
                      Paid for
                    </TableCell>
                    <TableCell align='center' size='small'>
                      Year
                    </TableCell>
                    <TableCell align='center' size='small'>
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : (
                    <>
                      {response?.money_receipt_details?.length ? (
                        <>
                          {response?.money_receipt_details?.map(
                            (sPayment, index) => (
                              <TableRow
                                key={sPayment.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align='center' size='small'>
                                  {index + 1}
                                </TableCell>

                                <TableCell align='center' size='small'>
                                  {sPayment?.atab_payment_invoice_item_name}
                                </TableCell>

                                <TableCell align='center' size='small'>
                                  {sPayment.year}
                                </TableCell>
                                <TableCell align='center' size='small'>
                                  {Number(sPayment.collected_amount)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </>
                      ) : (
                        <TableNoDataMessage />
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container rowGap={1}>
              <Grid item xs={12}>
                <Divider sx={{ mt: 4 }} />
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Total Amount
                </Typography>
              </Grid>

              <Grid item xs={3}>
                : {response?.total_collected_amount}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  Account Name
                </Typography>
              </Grid>
              <Grid item xs={3}>
                : {response?.account_holder_name}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  Payment Type
                </Typography>
              </Grid>
              <Grid item xs={3}>
                : {response?.payment_type}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  Voucher No.
                </Typography>
              </Grid>
              <Grid item xs={3}>
                : {response?.voucher_no}
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Payment Date
                </Typography>
              </Grid>
              <Grid item xs={3}>
                : {moment(response?.payment_date)?.format('DD-MMM-YYYY')}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  Bank Name
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} columnGap={1}>
                : {response?.bank_name}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  Branch Name
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} columnGap={1}>
                : {response?.branch_name}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  Payment Method
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} columnGap={1}>
                : {response?.payment_method}
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  View Voucher
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} columnGap={1}>
                : <PdfAndImageView file={response?.voucher_photo} />
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  View Payorder
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} columnGap={1}>
                : <PdfAndImageView file={response?.payorder_photo} />
              </Grid>
              <Grid item xs={3}>
                <Typography variant='h5' component='h2'>
                  View Cheque
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} columnGap={1}>
                : <PdfAndImageView file={response?.cheque_photo} />
              </Grid>
            </Grid>
          </Box>
        </ModalsWrapper>
      </Box>
    </>
  );
};

export default SingleViewMoneyRe;
