import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import { useParams } from 'react-router-dom';
import {
  ICompanyBusinessLicense,
  ICompanyBusinessLicenseResponse,
} from 'src/Types/module/member/CompanyInformation';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CompanyBusinessLicense from '../../../CompanyAddInfo/UpdateCompanyInformation/CompanyBusinessLicense';
import OtherBusinessLicense from '../../../CompanyAddInfo/CompanyAddInfoComponents/OtherBusinessLicense';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { HttpClient } from 'src/Helpers/http/http';
import PdfAndImageView from 'src/common/PdfAndImageView';

function BusinessLicenseInfo() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [businessLicense, setBusinessLicense] =
    useState<ICompanyBusinessLicense>({} as ICompanyBusinessLicense);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const data: ICompanyBusinessLicenseResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/business-license/${memberId}`
        );
        if (data.success) {
          setBusinessLicense(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    hajj_license,
    hajj_license_file,
    umrah_license,
    umrah_license_file,
    recruting_license,
    recruting_license_file,
    baira,
    baira_file,
    iata_agent,
    iata_agent_file,
    tdab,
    tdab_file,
    toab,
    toab_file,
  } = businessLicense || {};
  return (
    <>
      <>
        <PageTitleWrapper>
          <PageTitle
            heading='Business license information'
            actionButtonTitle={
              hajj_license || umrah_license || recruting_license ? '' : 'Add'
            }
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {hajj_license || umrah_license || recruting_license ? (
              <Grid sx={{ pb: 3 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Hajj license:
                          </TableCell>
                          <TableCell align='right'>
                            {hajj_license ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>

                        {hajj_license ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              HAJJ License file :
                            </TableCell>
                            <TableCell align='right'>
                              {hajj_license_file ? (
                                <PdfAndImageView
                                  file={`${hajj_license_file}`}
                                />
                              ) : (
                                'Not provided'
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Umrah license:
                          </TableCell>
                          <TableCell align='right'>
                            {umrah_license ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>

                        {umrah_license ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              UMRA License file:
                            </TableCell>
                            <TableCell align='right'>
                              {umrah_license_file ? (
                                <PdfAndImageView
                                  file={`${umrah_license_file}`}
                                />
                              ) : (
                                'Not provided'
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}

                        <TableRow>
                          <TableCell component='th' scope='row'>
                            TDAB Certificate:
                          </TableCell>
                          <TableCell align='right'>
                            {tdab ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                        {tdab_file ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              TDAB Certificate file:
                            </TableCell>
                            <TableCell align='right'>
                              {tdab_file ? (
                                <PdfAndImageView file={`${tdab_file}`} />
                              ) : (
                                'Not provided'
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            TOAB Certificate:
                          </TableCell>
                          <TableCell align='right'>
                            {toab ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                        {toab_file ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              TOAB Certificate file:
                            </TableCell>
                            <TableCell align='right'>
                              {toab_file ? (
                                <PdfAndImageView file={`${toab_file}`} />
                              ) : (
                                'Not provided'
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label='custom pagination table'>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Recruiting license :
                          </TableCell>
                          <TableCell align='right'>
                            {recruting_license ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                        {recruting_license_file ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              Recruiting License file:
                            </TableCell>
                            <TableCell align='right'>
                              {recruting_license_file ? (
                                <PdfAndImageView
                                  file={`${recruting_license_file}`}
                                />
                              ) : (
                                'Not provided'
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            IATA Certificate:
                          </TableCell>
                          <TableCell align='right'>
                            {iata_agent ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                        {iata_agent_file ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              IATA Certificate file:
                            </TableCell>
                            <TableCell align='right'>
                              {iata_agent_file ? (
                                <PdfAndImageView file={`${iata_agent_file}`} />
                              ) : (
                                'Not provided'
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            BAIRA Certificate:
                          </TableCell>
                          <TableCell align='right'>
                            {baira ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                        {baira_file ? (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              BAIRA Certificate file:
                            </TableCell>
                            <TableCell align='right'>
                              {baira_file ? (
                                <PdfAndImageView file={`${baira_file}`} />
                              ) : (
                                'Not provided'
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textAlign: 'center' }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
        <Box textAlign={'end'}>
          <PageTitle
            actionButtonTitle={
              hajj_license || umrah_license || recruting_license ? 'Update' : ''
            }
            setAction={setOpen}
          />
        </Box>
      </>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title:
            hajj_license || umrah_license || recruting_license
              ? 'Update business license information'
              : 'Add business license information',
        }}
      >
        {hajj_license || umrah_license || recruting_license ? (
          <CompanyBusinessLicense
            memberId={memberId}
            businessLicense={businessLicense}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <OtherBusinessLicense
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>
    </>
  );
}

export default BusinessLicenseInfo;
