import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import FairStallTable from "./FairStallTable";
import { IFairStall } from "src/Types/module/fairStall/fairStallType";
import { Box, Container } from "@mui/system";

const FairStallList = () => {
  const [allAdmins, setAllAdmins] = useState<IFairStall[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("available");
  const [secStatus, setSecStatus] = useState<string>("all");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(
            `${url}/api/fair/stall/get/all/status/hall-name/${status}/${secStatus}`
          )
          .then(function (res) {
            if (res.data.success) {
              setAllAdmins(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmins([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status, secStatus]);

  return (
    <Box sx={{ m: 2 }}>
      <FairStallTable
        loading={loading}
        allAdmin={allAdmins}
        setAllAdmins={setAllAdmins}
        setStatus={setStatus}
        setSecStatus={setSecStatus}
        secStatus={secStatus}
        status={status}
      />
    </Box>
  );
};

export default FairStallList;
