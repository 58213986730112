import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ISingleBatch } from "src/Types/module/courses/trainee";
import SingleBatchStudentRow from "./SingleBatchStudentRow";
import UpdateBatch from "./UpdateBatch/UpdateBatch";
import AddTraineeToBatch from "./AddTraineeToBatch";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";

const SingleBatch = () => {
  const { id } = useParams();
  const [batchDetails, setBatchDetails] = useState<ISingleBatch>(
    {} as ISingleBatch
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [statusValue, setStatusValue] = useState<string>();

  // modal status
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const batch = await axios.get(
          `${url}/api/training/batch/get/single/with/students/${id}`
        );

        if (batch.data.success) {
          setBatchDetails(batch.data.data);
          setLoading(false);
        } else {
          Toaster().fire({ icon: "info", title: "No batch found" });
          setLoading(false);
        }
      } catch (err) {
        Toaster().fire({ icon: "info", title: "No batch found" });
        setLoading(false);
      }
    })();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title>HAAB - Batch Details</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <SuspenseLoader />
        ) : (
          batchDetails && (
            <Container>
              <Box m={1}>
                <Card sx={{ margin: "10px" }}>
                  <CardHeader
                    title="Training Batch Details"
                    action={
                      <Box>
                        <Link
                          to={`/training-batch/class-list/${batchDetails.training_batch_id}?batch=${batchDetails.training_batch_name}`}
                        >
                          <Button sx={{ margin: "3px" }} variant="outlined">
                            Class list
                          </Button>
                        </Link>
                        <Link
                          to={`/training-batch/exam-list/${id}?batch=${batchDetails.training_batch_name}`}
                        >
                          <Button sx={{ margin: "3px" }} variant="outlined">
                            Exam list
                          </Button>
                        </Link>
                        <Link
                          to={`/training-batch/material/batch/${id}?batch=${batchDetails.training_batch_name}`}
                        >
                          <Button sx={{ margin: "3px" }} variant="outlined">
                            Material list
                          </Button>
                        </Link>
                        <Button
                          sx={{ margin: "3px" }}
                          onClick={() => setShowModal(!showModal)}
                          variant="outlined"
                        >
                          Edit
                        </Button>
                      </Box>
                    }
                  />
                  <Divider />
                  <Grid spacing={2} container>
                    <Grid xs={12} md={6} item>
                      <Box sx={{ minWidth: 275 }}>
                        <CardHeader
                          title="Batch Information"
                          action={
                            <Link
                              to={`/training-batch/notice/batch/${id}?batch=${batchDetails.training_batch_name}`}
                            >
                              <Button variant="outlined">All Notice</Button>
                            </Link>
                          }
                        />
                        <Divider />
                        <CardContent>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Batch Id
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_id}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Batch Name
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Total Students
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_total_student}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Start Date
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {moment(
                                  batchDetails.training_batch_start_date
                                ).format("MMMM Do YYYY")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Status
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {getStatusLabel(
                                  statusValue
                                    ? statusValue
                                    : batchDetails.training_batch_status
                                )}
                              </Typography>
                            </Box>
                          </Box>

                          {batchDetails.training_batch_end_date && (
                            <Box display="flex" height="100%">
                              <Box width={"30%"}>
                                <Typography
                                  sx={{ mb: 1.5 }}
                                  color="text.secondary"
                                >
                                  End Date
                                </Typography>
                              </Box>
                              <Box width={"70%"}>
                                <Typography>
                                  {batchDetails.training_batch_end_date}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Teacher Name
                              </Typography>
                            </Box>
                            <Box width={"60%"}>
                              <Typography>
                                {batchDetails.training_teacher_name}
                              </Typography>
                            </Box>
                            <Box width="10%">
                              <Tooltip title="view" arrow>
                                <IconButton color="inherit" size="small">
                                  <Link
                                    to={`/training-teacher/details/${batchDetails.training_batch_course_id}`}
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </Link>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Course
                              </Typography>
                            </Box>
                            <Box width={"60%"}>
                              <Typography>
                                {batchDetails.training_batch_course_name}
                              </Typography>
                            </Box>
                            <Box width="10%">
                              <Tooltip title="view" arrow>
                                <IconButton color="inherit" size="small">
                                  <Link
                                    to={`/training-course/details/${batchDetails.training_batch_course_id}`}
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </Link>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Batch Details
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_details}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Box>
                    </Grid>
                    <Grid
                      xs={12}
                      md={6}
                      item
                      height={780}
                      sx={{
                        overflow: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <Box>
                        <CardHeader
                          title="Batch Students"
                          action={
                            <Button
                              onClick={() => handleOpen()}
                              variant="outlined"
                              startIcon={<AddIcon />}
                            >
                              Add trainee to a batch
                            </Button>
                          }
                        />
                        <Divider />
                        <CardContent>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Id</TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Joined</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {batchDetails?.batchStudents?.length ? (
                                  batchDetails.batchStudents.map((student) => (
                                    <SingleBatchStudentRow
                                      key={student.user_training_trainee_id}
                                      student={student}
                                    />
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={8}>Not Found</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>

                {/* modals wrapper */}
                <UpdateBatch
                  setStatusValue={setStatusValue}
                  statusValue={statusValue}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  loading={loading}
                  setLoading={setLoading}
                  batchDetails={batchDetails}
                />
              </Box>
            </Container>
          )
        )}
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ p: 4 }} id="responsive-dialog-title">
          <Typography variant="h4">Add trainee to a batch</Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <AddTraineeToBatch
            handleClose={handleClose}
            setReload={setReload}
            courseID={batchDetails.training_batch_course_id}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleBatch;
