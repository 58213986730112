import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";
import { IExamList } from "src/Utils/type";
import moment from "moment";

type IProps = {
  examList: IExamList;
};

const ExamListTableRow = ({ examList }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {examList.training_exam_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {examList.training_exam_batch}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {moment(examList.training_exam_date).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {moment(examList.training_exam_time, "HH:mm:ss").format("hh:mm A")}
          </Typography>
        </TableCell>
        <TableCell>{getStatusLabel(examList.training_exam_status)}</TableCell>
        <TableCell>{examList.training_exam_total_mark}</TableCell>
        <TableCell>
          <Link
            to={`/training-batch/exam-list/details/${examList.training_exam_id.toString()}`}
          >
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExamListTableRow;
