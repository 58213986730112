import { TableCell, TableRow } from '@mui/material';
import getStatusChip from 'src/common/getStatusChip';
import { IMoneyReceiuptList } from '../types/vouchersTypes';
import moment from 'moment';
import SingleViewMoneyRe from './SingleViewMoneyRe';

type IProps = {
  sInvoice: IMoneyReceiuptList;
  index: number;
  getData: () => Promise<void>;
};

const MoneyReceiptTableRow = ({
  sInvoice: invoiceData,
  index,
  getData,
}: IProps) => {
  return (
    <>
      {invoiceData && (
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{invoiceData.user_member_company_name}</TableCell>
          <TableCell>{invoiceData.user_member_hajj_license}</TableCell>
          <TableCell>{invoiceData.money_receipt_no}</TableCell>
          <TableCell>{invoiceData.voucher_no}</TableCell>
          <TableCell>
            {moment(invoiceData.created_at).format('DD/MMM/YYYY')}
          </TableCell>
          <TableCell>{invoiceData.total_collected_amount}</TableCell>
          <TableCell>
            {getStatusChip(invoiceData.status ? 'approved' : 'pending')}
          </TableCell>
          <TableCell>
            <SingleViewMoneyRe voucherId={invoiceData.id} getData={getData} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MoneyReceiptTableRow;
