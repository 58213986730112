import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  CardContent,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import {
  IBatchTraineeList,
  ItraineeList,
} from "src/Types/module/courses/trainee";
import { useParams } from "react-router-dom";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

type IProps = {
  handleClose: Function;
  setReload: Dispatch<SetStateAction<boolean>>;
  courseID: number;
};

const AddTraineeToBatch = ({ handleClose, setReload, courseID }: IProps) => {
  const [batchTrainee, setBatchTrainee] = useState([]);
  const [selectBatchTrainee, setSelectBatchTrainee] = useState<ItraineeList[]>(
    []
  );
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const Toast = Toaster();
  const { id } = useParams();
  const { user } = useAuthContext();
  const resetFun = () => {
    // setSelectBatchTrainee([]);
    setSubmitLoading(false);
  };
  // ${courseDetails.atab_training_course_id}
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/training/course/application?training_course_application_course_id=${courseID}&training_course_application_status=pending`
        );

        if (data.success) {
          setBatchTrainee(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        // Toaster().fire({ icon: "info", title: "No Trainee found" });
        setLoading(false);
      }
    })();
  }, [courseID]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (selectBatchTrainee.length === 0) {
      Toast.fire({
        icon: "error",
        title: `Select a trainee`,
      });
    } else {
      const selectedMember = selectBatchTrainee.map((member: any) => {
        return member.training_course_application_trainee_id;
      });

      let body = {
        batch_id: id,
        batch_trainee: selectedMember,
      };
      setSubmitLoading(true);
      try {
        const { data } = await axios.post(
          `${url}/api/training/batch/add/trainee/to/batch?user_admin_id=${user.user_admin_id}`,
          body
        );
        if (data.success) {
          Toast.fire({
            icon: "success",
            title: `Successfully added`,
          });
          resetFun();
          handleClose();
          setReload((prev) => !prev);
        } else {
          setSelectBatchTrainee([]);
          setSubmitLoading(false);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: err.response?.data?.message,
        });
        resetFun();
      }
    }
  };

  return !isLoading ? (
    <CardContent sx={{ minWidth: "350px" }}>
      <Box p={2} component="form" onSubmit={handleSubmit}>
        <Box textAlign="center">
          <Autocomplete
            sx={{ padding: "12px" }}
            multiple
            id="tags-standard"
            value={selectBatchTrainee}
            onChange={(e, mainValue: any) => setSelectBatchTrainee(mainValue)}
            options={batchTrainee}
            getOptionLabel={(option: any) => option.user_training_trainee_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select trainee"
                placeholder="More..."
              />
            )}
          />
        </Box>
        <Box textAlign="center">
          {!submitLoading ? (
            <Button
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
                ml: 2,
              }}
            >
              Add trainee to batch
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Creating..."
              variant="contained"
              disabled
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              <span>Creating...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </CardContent>
  ) : (
    <GlobalLoader />
  );
};

export default AddTraineeToBatch;
