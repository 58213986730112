import { Button, Checkbox } from "@mui/material";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import Tooltip from "@mui/material/Tooltip";

const Certificate = ({ sCertificate, setRefresh }: any) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handelUpdate = async (checked: number, id: number) => {
    const { data } = await axios.patch(
      `${url}/api/atab/user-member/certificate/${id}`,
      {
        status: checked,
      }
    );
    if (data.success) {
      Toaster().fire({
        icon: "success",
        title: data.message,
      });
      setRefresh((prv) => !prv);
    } else {
      Toaster().fire({
        icon: "error",
        title: data.message,
      });
    }
  };

  return (
    <>
      <a href={`${imgUrl}/${sCertificate.c_photo}`} target="_blank">
        <Button
          variant="contained"
          size="small"
          sx={{
            mt: 1,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "15px",
            textAlign: "left",
          }}
          startIcon={<DownloadIcon />}
        >
          {sCertificate.c_type === "member_certificate" ? (
            <>
              Member <br /> Certificate
            </>
          ) : (
            <>
              Renewal <br /> Certificate
            </>
          )}
        </Button>
      </a>

      <Tooltip
        title={sCertificate.status ? "Deactivate" : "Active"}
        sx={{ mr: 1 }}
      >
        <Checkbox
          {...label}
          defaultChecked={sCertificate.status ? true : false}
          onClick={(e: any) => {
            handelUpdate(e.target.checked ? 1 : 0, sCertificate.c_id);
          }}
        />
      </Tooltip>
    </>
  );
};

export default Certificate;
