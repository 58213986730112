import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';
import PdfAndImageView from 'src/common/PdfAndImageView';

const IdCardInfo = ({ singleIdCard }: any) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Application Date</Typography>
              </TableCell>
              <TableCell>
                {moment(singleIdCard.created_at).format('MMM Do YY')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Company Name</Typography>
              </TableCell>
              <TableCell>{singleIdCard.user_member_company_name}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Applicant Name</Typography>
              </TableCell>
              <TableCell>{singleIdCard.applicant_name}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Designation</Typography>
              </TableCell>
              <TableCell>{singleIdCard.designation}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Status</Typography>
              </TableCell>
              <TableCell>{getStatusLabel(singleIdCard.status)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Download TIN File</Typography>
              </TableCell>
              <TableCell>
                <PdfAndImageView file={singleIdCard.tin_file} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>
                  Download Applicant Photo
                </Typography>
              </TableCell>
              <TableCell>
                <PdfAndImageView file={singleIdCard?.photo} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Download NID File</Typography>
              </TableCell>
              <TableCell>
                <PdfAndImageView file={singleIdCard.nid_file} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant='subtitle2'>Download Signature</Typography>
              </TableCell>
              <TableCell>
                <PdfAndImageView file={singleIdCard.signature_file} />
              </TableCell>
            </TableRow>

            {singleIdCard.specimen_signature_file && (
              <TableRow>
                <TableCell>
                  <Typography variant='subtitle2'>
                    Download Specimen Signature
                  </Typography>
                </TableCell>
                <TableCell>
                  <PdfAndImageView
                    file={singleIdCard.specimen_signature_file}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default IdCardInfo;
