import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import moment from "moment";
import { url } from "src/Helpers/utils/Constant";
import { IExamAttendTrainee, ISingleExamInfo } from "src/Utils/type";
import Spinner from "src/components/Spinner/Spinner";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

type IProps = {
  singleExamInfo: ISingleExamInfo;
  handleClose: Function;
};

const AddResultMark = ({ singleExamInfo, handleClose }: IProps) => {
  const [classStudents, setClassStudents] = useState<IExamAttendTrainee[]>([]);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [addLoading, setAddLoading] = useState<Boolean>(false);
  const { user } = useAuthContext();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await axios.get(
          `${url}/api/training/exam/get/all/attend/student/by/exam-id/${singleExamInfo.training_exam_id}`
        );
        if (data.data.success) {
          setClassStudents(data.data.data);
          setAttendance(
            data.data.data.map((item) => {
              return {
                training_exam_attendance_id: item.training_exam_attendance_id,
                training_exam_result_marks: 0,
              };
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setAddLoading(true);
      const res = await axios.post(
        `${url}/api/training/exam/add/result?user_admin_id=${user.user_admin_id}`,
        {
          result: attendance,
        }
      );
      if (res.data.success) {
        setAddLoading(false);
        Toaster().fire({
          icon: "success",
          title: `Mark added successfully`,
        });
        handleClose();
      } else {
        Toaster().fire({
          icon: "error",
          title: `${res.data.message}`,
        });
        setAddLoading(false);
      }
    } catch (err) {
      Toaster().fire({
        icon: "error",
        title: `Result mark already added`,
      });
      setAddLoading(false);
    }
  };

  const handleAttendance = ({ student_id, attend }) => {
    setAttendance(
      attendance.map((item) => {
        if (item.training_exam_attendance_id === student_id) {
          return {
            training_exam_attendance_id: student_id,
            training_exam_result_marks: parseInt(attend),
          };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Add result mark</title>
      </Helmet>
      <Container sx={{ mt: 4, width: "500px" }} maxWidth="lg">
        <Card>
          <CardHeader
            title={`Exam date ${moment
              .tz(singleExamInfo?.training_exam_date, "Asia/Dhaka")
              .format("DD-MM-YYYY")}`}
            action={
              !addLoading ? (
                <Button onClick={handleSubmit} variant="outlined">
                  Submit
                </Button>
              ) : (
                <Button variant="outlined">wait...</Button>
              )
            }
          />
          <Divider />
          <CardContent>
            {loading ? (
              <Spinner />
            ) : (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Result Mark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classStudents.map((row: IExamAttendTrainee) => (
                      <TableRow
                        key={row.user_training_trainee_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.user_training_trainee_id}
                        </TableCell>
                        <TableCell align="center">
                          {row.user_training_trainee_name}
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            sx={{ width: "150px" }}
                            id="outlined-uncontrolled"
                            label="Add result mark"
                            type="number"
                            onChange={(e) =>
                              handleAttendance({
                                attend: e.target.value,
                                student_id: row.training_exam_attendance_id,
                              })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddResultMark;
