import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { IAuditAndTrail } from 'src/Types/module/auditAndTrail/auditAndTrail';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import AuditAndTrailTableRow from './AuditAndTrailTableRow';
import { Iadmin } from 'src/Types/module/admin/adminTypes';
import AuditAndTrailExcelGenerator from 'src/components/ExcelGenerator/AuditAndTrailExcelGenerator';
import AuditTrailPdfGenerator from 'src/components/PdfGenerator/AuditTrailPdfGenerator';
import { HttpClient } from 'src/Helpers/http/http';
import { DatePicker } from '@mui/lab';
import moment from 'moment';

const AuditAndTrail = () => {
  const [limit, setLimit] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [allAuditTrail, setAllAuditTrail] = useState<IAuditAndTrail[]>([]);
  const [allAdmin, setAllAdmin] = useState<Iadmin[]>([]);
  const [adminId, setAdminId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const from_date =
    moment(date.startDate).format('YYYY-MM-DD') === 'Invalid date'
      ? false
      : moment(date.startDate).format('YYYY-MM-DD');
  const to_date =
    moment(date.endDate).format('YYYY-MM-DD') === 'Invalid date'
      ? false
      : moment(date.endDate).format('YYYY-MM-DD');

  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });

  const page = parseInt(searchParams.get('page'));
  let skip: number = limit * page;

  useEffect(() => {
    (async function () {
      try {
        let uri = `${url}/api/atab/audit-trail?limit=${limit}&skip=${skip}`;

        if (from_date && to_date) {
          uri += `&from_date=${from_date}&to_date=${to_date}`;
        }
        if (adminId) {
          uri += `&user_admin_id=${adminId}`;
        }

        if ((from_date && to_date) || adminId) {
          setLoading(true);
          const res: any = await HttpClient.get(uri);
          if (res.success) {
            setAllAuditTrail(res.data);
            setTotal(res.total);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [date.startDate, date.endDate, adminId, page, limit]);

  useEffect(() => {
    //Initialy fetch audit trail fetch
    async function audit() {
      try {
        setLoading(true);
        const res: any = await HttpClient.get(
          `${url}/api/atab/audit-trail?limit=${limit}&skip=${skip}`
        );
        if (res.success) {
          setAllAuditTrail(res.data);
          setTotal(res.total);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    audit();

    (async function () {
      try {
        setLoading(true);
        axios
          .get(`${url}/api/atab/admin/get/all/status/all`)
          .then(function (res) {
            if (res.data.success) {
              setAllAdmin(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmin([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const getDownloadData = async () => {
    try {
      let uri = `${url}/api/atab/audit-trail`;

      if (from_date && to_date) {
        uri += `&from_date=${from_date}&to_date=${to_date}`;
      }
      if (adminId) {
        uri += `&user_admin_id=${adminId}`;
      }
      const res: any = await HttpClient.get(uri);

      if (res.success) {
        return res;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        item
        sx={{ marginBottom: '1.5em' }}
        columns={{ xs: 12, sm: 12, md: 4, xl: 4 }}
      >
        <Card
          sx={{
            p: 2,
          }}
        >
          <Grid
            display={'flex'}
            alignItems={'center'}
            justifyContent={'end'}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                fontWeight={'bold'}
                variant='h6'
                component='h6'
                gutterBottom
              >
                From
              </Typography>
              <DatePicker
                label='Start Date'
                value={date.startDate}
                onChange={(e: any) => {
                  console.log(
                    e,
                    moment(e)?.format('YYYY-MM-DD')
                    // e?.toISOString()?.split('T')[0]
                  );

                  setDate({ ...date, startDate: e });
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} size='small' />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                fontWeight={'bold'}
                variant='h6'
                component='h6'
                gutterBottom
              >
                To
              </Typography>

              <DatePicker
                label='End Date'
                value={date.endDate}
                onChange={(e: any) => setDate({ ...date, endDate: e })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    sx={{ width: '100%' }}
                    // InputProps={{
                    //   endAdornment: date.endDate ? (
                    //     <Box
                    //       style={{ cursor: 'pointer' }}
                    //       onClick={() => setDate({ ...date, endDate: null })}
                    //     />
                    //   ) : (
                    //     ''
                    //   ),
                    // }}
                  />
                )}
              />
            </Grid>
            <Grid mt={3} ml={2}>
              <FormControl
                sx={{ width: '250px' }}
                variant='outlined'
                size='small'
              >
                <InputLabel>Admin</InputLabel>
                <Select
                  value={adminId}
                  onChange={(e) => setAdminId(e.target.value)}
                  label='Status'
                  autoWidth
                >
                  {allAdmin &&
                    allAdmin?.map((sAdmin) => (
                      <MenuItem
                        key={sAdmin.user_admin_id}
                        value={sAdmin.user_admin_id}
                      >
                        {sAdmin.user_admin_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display='flex' justifyContent='space-between' gap={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  ></Box>

                  <AuditAndTrailExcelGenerator
                    getDownloadData={getDownloadData}
                  />
                  <AuditTrailPdfGenerator getDownloadData={getDownloadData} />
                </Box>
              }
              title={`Audit Trail (${total})`}
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>ID</TableCell>
                    <TableCell align='center'>Image</TableCell>
                    <TableCell align='center'>Admin Name</TableCell>
                    <TableCell align='center'>Details</TableCell>
                    <TableCell align='center'>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : allAuditTrail.length ? (
                    <>
                      {allAuditTrail.map((sAudit) => {
                        return (
                          <AuditAndTrailTableRow
                            sAudit={sAudit}
                            key={sAudit.audit_trail_id}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AuditAndTrail;
