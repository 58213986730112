import { useState, useEffect } from 'react';
import axios from 'axios';
import { Autocomplete, Grid, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { IMemberOrganizationProposer } from 'src/Types/module/member/UpdateCompanyInfoTypes';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { ICompanyProposer } from 'src/Types/module/member/CompanyInformation';
import { HttpClient } from 'src/Helpers/http/http';
import CommSelectFieldSearchmemberlistbyLicenseAndname from 'src/common/CommSelectFieldSearchmemberlistbyLicenseAndname';

interface IProps {
  singleProposer: ICompanyProposer;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setUpdateModal: (value: boolean) => void;
  refresh: boolean;
}

const UpdateCompanyProposer = ({
  singleProposer,
  memberId,
  setRefresh,
  setUpdateModal,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { handleSubmit } = useForm();
  const [memberOrganizationProposer, setMemberOrganizationProposer] = useState<
    IMemberOrganizationProposer[]
  >([]);
  const [proposerInput, setProposerInput] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active`
      )
      .then(function (res) {
        if (res.data.success) {
          setMemberOrganizationProposer(res.data.data);
        }
      });
  }, []);

  const [proposerInput1, setProposerInput1] = useState<{
    user_member_id: number;
    user_member_hajj_license: number;
    company_owner_email: string;
    company_owner_phone: string;
    company_owner_designation: string;
    company_owner_name: string;
  }>();
  const onSubmit = async (inputData: any) => {
    inputData.proposer_request_id = singleProposer.proposer_request_id;
    inputData.proposer_request_from = memberId;
    inputData.proposer_request_to = proposerInput1.user_member_id;

    try {
      setIsLoading(true);
      const data: any = await HttpClient.put(
        `/api/atab/member-company/change/proposer/for-admin`,
        inputData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setUpdateModal(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} px={2}>
      <Grid container rowGap={1} sx={{ width: '100%', maxWidth: 400 }}>
        <Grid item xs={12}>
          <Grid container rowGap={1}>
            <Grid xs={12}>
              <label htmlFor=''>Search Proposer</label>
              <Box>
                <CommSelectFieldSearchmemberlistbyLicenseAndname
                  setWholeValue={setProposerInput1}
                />
              </Box>
            </Grid>
            {proposerInput1?.user_member_id && (
              <>
                <Grid xs={12}>
                  <TextField
                    variant='standard'
                    disabled
                    defaultValue={proposerInput1?.user_member_hajj_license}
                    size='small'
                    label='Hajj License Number'
                    id='outlined-basic'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_name}
                    size='small'
                    label="License Owner's Name"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_designation}
                    size='small'
                    label="Owner's Designation"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_phone}
                    size='small'
                    label="Owner's Mobile Number"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_email}
                    size='small'
                    label="Owner's Email Address"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            {isLoading ? (
              <Button
                disabled
                variant='contained'
                disableElevation
                className='add-remove-button'
                fullWidth
                size='small'
              >
                loading..
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
                fullWidth
                size='small'
              >
                Update
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateCompanyProposer;
