import { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Grid,
  TextField,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import {
  IMoneyReceipt,
  IResponseMoneyReceipt,
} from 'src/Types/module/common/commonTypes';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { DatePicker } from '@mui/lab';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import PrintMoneyReceipt from './PrintInformationForm/PrintMoneyReceipt';
import { HttpClient } from 'src/Helpers/http/http';
import ShowMoneyReceipt from './ShowMoneyReceipt';
import { IMemberInfo } from 'src/Types/module/member/memberTypes';
export type TDate = {
  startDate: Date | null;
  endDate: Date | null;
};
const CompanyInvoices = ({ companyName }: { companyName: string }) => {
  const { memberId } = useParams();
  const [memberInvoice, setMemberInvoice] = useState<IMoneyReceipt[]>([]);
  const [loading, setLoading] = useState(false);
  const [openForwardModal, setOpenForward] = useState(false);
  const [date, setDate] = useState<TDate>({ startDate: null, endDate: null });
  const theme = useTheme();
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${memberId}_transaction `,
  });

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/money-receipt?member_id=${memberId}`;
        if (date.startDate && date.endDate) {
          uri += `&from_date=${
            date.startDate.toISOString().split('T')[0]
          }&to_date=${date.endDate.toISOString().split('T')[0]}`;
        }

        const data: IResponseMoneyReceipt = await HttpClient.get(uri);
        if (data.success) {
          setMemberInvoice(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, date]);

  return (
    <Box maxWidth={'100%'}>
      {/* {applied ? ( */}
      <Box>
        <Grid item xs={12}>
          {/* <Card sx={{ p: 2 }}> */}
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 1,
              pb: 2,
            }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                fontWeight={'bold'}
                variant='h6'
                component='h6'
                gutterBottom
              >
                From
              </Typography>

              <DatePicker
                label='Start Date'
                value={date.startDate}
                onChange={(e: any) => e && setDate({ ...date, startDate: e })}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} size='small' />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                fontWeight={'bold'}
                variant='h6'
                component='h6'
                gutterBottom
              >
                To
              </Typography>

              <DatePicker
                label='End Date'
                value={date.endDate}
                onChange={(e: any) => e && setDate({ ...date, endDate: e })}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} size='small' />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Tooltip title='Print money receipt'>
                <IconButton onClick={handlePrint}>
                  <PrintIcon
                    sx={{
                      cursor: 'pointer',
                      color: theme.colors.primary.main,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {/* </Card> */}
        </Grid>
      </Box>
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Receipt no
                  {/* Fee type */}
                </TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Paid date</TableCell>
                <TableCell>Paid by</TableCell>
                <TableCell>Print</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {memberInvoice.length ? (
                    <>
                      {memberInvoice.map(
                        (details: IMoneyReceipt, index: number) => (
                          <ShowMoneyReceipt details={details} key={index} />
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <TableNotFound />
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>

      <Box display={'none'}>
        <PrintMoneyReceipt
          componentRef={componentRef}
          memberInvoice={memberInvoice}
          companyName={companyName}
          date={date}
        />
      </Box>
    </Box>
  );
};

export default CompanyInvoices;
