import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAccountType } from '../type/voucherType';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import Toaster from 'src/Toaster/Toaster';
const Toast = Toaster();
const EditAccount = ({
  refetch,
  closeModal,
  accountEditData,
}: {
  refetch: () => Promise<void>;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  accountEditData: IAccountType;
}) => {
  const [info, setInfo] = useState({});
  const { loading, updateData, success } = UpdateDataApi(
    `/api/atab/account/${accountEditData?.id}`
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();
  const [account_type, setAccount_type] = useState(
    accountEditData.account_type
  );
  useEffect(() => {
    if (success) {
      refetch();
      closeModal(false);
    }
  }, [success]);

  const onSubmit = (data: any) => {
    if (Object.keys(info).length !== 0) {
      updateData(info);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Change any Field',
      });
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        padding: '20px',
        bgcolor: 'background.paper',
        boxShadow: 3,
      }}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Account Type</InputLabel>
            <Select
              fullWidth
              label='Account Type'
              variant='outlined'
              size='small'
              disabled
              placeholder='Account Type'
              {...register('account_type', {
                onChange: (e) => {
                  setInfo({ ...info, account_type: e.target.value });
                  setAccount_type(e.target.value);
                },
              })}
              defaultValue={accountEditData.account_type}
              error={!!errors.account_type}
            >
              <MenuItem value='Cash'>Cash</MenuItem>
              <MenuItem value='Bank'>Bank</MenuItem>
              <MenuItem value='Mobile Banking'>Mobile Banking</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Account Name'
            variant='outlined'
            size='small'
            placeholder='Account Name'
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setInfo({ ...info, name: e.target.value })}
            defaultValue={accountEditData.name}
            error={!!errors.name}
            helperText={errors.name?.message as string}
          />
        </Grid>
        {(account_type === 'Bank' || account_type === 'Mobile Banking') && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Account number'
                variant='outlined'
                disabled
                size='small'
                placeholder='Account number'
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setInfo({ ...info, account_no: e.target.value })
                }
                defaultValue={accountEditData.account_no}
                type='number'
                error={!!errors.account_no}
                helperText={errors.account_no?.message as string}
              />
            </Grid>
          </>
        )}
        {account_type === 'Bank' && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Bank name'
                variant='outlined'
                size='small'
                placeholder='Bank name'
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setInfo({ ...info, bank_name: e.target.value })
                }
                defaultValue={accountEditData.bank_name}
                error={!!errors.bank_name}
                helperText={errors.bank_name?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Brance name'
                variant='outlined'
                size='small'
                placeholder='Brance name'
                defaultValue={accountEditData.bank_name}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setInfo({ ...info, branch: e.target.value })}
                error={!!errors.branch}
                helperText={errors.branch?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Routing no.'
                variant='outlined'
                size='small'
                placeholder='Routing no.'
                defaultValue={accountEditData.routing_no}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setInfo({ ...info, routing_no: e.target.value })
                }
                error={!!errors.routing_no}
                helperText={errors.routing_no?.message as string}
              />
            </Grid>
          </>
        )}

        {account_type === 'Mobile Banking' && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Wallet Type</InputLabel>
                <Select
                  fullWidth
                  label='Wallet Type'
                  variant='outlined'
                  size='small'
                  placeholder='Wallet Type'
                  defaultValue={accountEditData.wallet_type}
                  onChange={(e) =>
                    setInfo({ ...info, wallet_type: e.target.value })
                  }
                  error={!!errors.wallet_type}
                >
                  <MenuItem value='bKash'>bKash</MenuItem>
                  <MenuItem value='Nagad'>Nagad</MenuItem>
                  <MenuItem value='Upay'>Upay</MenuItem>
                  <MenuItem value='ROCKET'>ROCKET</MenuItem>
                  <MenuItem value='MYCash'>MYCash</MenuItem>
                  <MenuItem value='mCash'>mCash</MenuItem>
                  <MenuItem value='TeleCash'>TeleCash</MenuItem>
                  <MenuItem value='Islamic Wallet'>Islamic Wallet</MenuItem>
                  <MenuItem value='Meghna Pay'>Meghna Pay</MenuItem>
                  <MenuItem value='OK Wallet'>OK Wallet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Wallet Account Type</InputLabel>
                <Select
                  label='Wallet Account Type'
                  placeholder='Mobile Operator Name'
                  defaultValue={accountEditData.wallet_account_type}
                  onChange={(e) =>
                    setInfo({ ...info, wallet_account_type: e.target.value })
                  }
                  error={!!errors.wallet_account_type}
                >
                  <MenuItem value='Merchant'>Merchant</MenuItem>
                  <MenuItem value='Agent'>Agent</MenuItem>
                  <MenuItem value='Personal'>Personal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Operator Type</InputLabel>
                <Select
                  fullWidth
                  label='Mobile Operator Name'
                  variant='outlined'
                  placeholder='Mobile Operator Name'
                  defaultValue={accountEditData.mobile_operator_name}
                  onChange={(e) =>
                    setInfo({ ...info, mobile_operator_name: e.target.value })
                  }
                  error={!!errors.mobile_operator_name}
                >
                  <MenuItem value='Grameen Phone'>Grameenphone</MenuItem>
                  <MenuItem value='Robi'>Robi</MenuItem>
                  <MenuItem value='Airtel'>Airtel</MenuItem>
                  <MenuItem value='Banglalink'>Banglalink</MenuItem>
                  <MenuItem value='Teletalk'>Teletalk</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        {accountEditData?.account_type !== 'Cash' && (
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '10px',
              }}
            >
              <Grid item>Status</Grid>
              <Grid item>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  defaultValue={accountEditData?.is_active}
                  onChange={(e) =>
                    setInfo({ ...info, is_active: e.target.value })
                  }
                >
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    label='Active'
                  />
                  <FormControlLabel
                    value='0'
                    control={<Radio />}
                    label='inActive'
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={8}>
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                fullWidth
              >
                {loading ? 'Loading...' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditAccount;
