import { useState, Dispatch, SetStateAction } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";

const useStyles = muiStyles;
import axios from "axios";
import { IGuest } from "src/Types/module/guestAndSpecker/guestAndSpeaker";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import Toaster from "src/Toaster/Toaster";
import { muiStyles } from "src/Utils/Styles";
import { url } from "src/Helpers/utils/Constant";

interface IProps {
  guest: IGuest;
  setGuest: Function;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
const UpdateGuestAndSpeaker = ({ guest, setGuest, setShowModal }: IProps) => {
  const classes = useStyles();
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const [loading, setLoading] = useState<Boolean>(false);
  const [guestType, setGuestType] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [photo, setPhoto] = useState<string>("");

  const { user } = useAuthContext();

  const onSubmit = async (inputs: any) => {
    setLoading(true);

    try {
      const formData = new FormData();

      formData.append(
        "guest_speaker_type",
        guestType ? guestType : guest.guest_speaker_type
      );
      formData.append("guest_speaker_id", guest.guest_speaker_id);
      formData.append(
        "guest_speaker_status",
        status ? status : guest.guest_speaker_status
      );

      formData.append(
        "guest_speaker_photo",
        photo ? photo : guest.guest_speaker_photo
      );
      formData.append(
        "guest_speaker_updated_by",
        user.user_admin_id.toString()
      );

      for (const key in inputs) {
        if (typeof inputs[key] === "object") {
          formData.append(key, inputs[key][0]);
        } else {
          formData.append(key, inputs[key]);
        }
      }

      const { data } = await axios.put(
        `${url}/api/fair/for-admin/guest-or-speaker/update`,
        formData
      );

      if (data.success) {
        Toast.fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        setShowModal(false);
        setGuest({
          guest_speaker_company_name: inputs.guest_speaker_company_name,
          guest_speaker_designation: inputs.guest_speaker_designation,
          guest_speaker_details: inputs.guest_speaker_details,
          guest_speaker_id: inputs.guest_speaker_id,
          guest_speaker_name: inputs.guest_speaker_name,
          guest_speaker_photo: inputs.guest_speaker_photo,
          guest_speaker_status: inputs.guest_speaker_status,
          guest_speaker_type: inputs.guest_speaker_type,
        });
      } else {
        Toast.fire({
          icon: "fail",
          title: `${data.message}`,
        });
        setLoading(false);
      }

      reset();
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Update Guest & Speaker</title>
      </Helmet>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{ p: 2, mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Guest name"
              defaultValue={guest.guest_speaker_name}
              {...register("guest_speaker_name")}
              type="text"
              autoFocus
              variant="outlined"
            />
          </Grid>

          <Grid xs={12} sm={6} item>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Company name"
              defaultValue={guest.guest_speaker_company_name}
              {...register("guest_speaker_company_name")}
              type="text"
              autoFocus
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              margin="normal"
              fullWidth
              id="text"
              label="Designation"
              defaultValue={guest.guest_speaker_designation}
              {...register("guest_speaker_designation")}
              type="text"
              autoFocus
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-filled-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Status"
                defaultValue={guest ? guest.guest_speaker_status : "pending"}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} lg={4} item>
            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Type"
                defaultValue={guest.guest_speaker_type}
                onChange={(e) => setGuestType(e.target.value)}
              >
                <MenuItem value="guest">Guest</MenuItem>
                <MenuItem value="speaker">Speaker</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              margin="normal"
              fullWidth
              id="file"
              label="Choose Guest Photo"
              InputLabelProps={{ shrink: true }}
              onChange={(e: any) => setPhoto(e.target.files[0])}
              autoComplete="file"
              type="file"
              autoFocus
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              label="Guest details"
              placeholder="Enter some details about this Guest here.."
              multiline
              defaultValue={guest.guest_speaker_details}
              {...register("guest_speaker_details")}
              rows={5}
              maxRows={10}
              fullWidth
            />
          </Grid>
        </Grid>

        {loading ? (
          <Button
            disabled
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Updating...
          </Button>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Update Guest
          </Button>
        )}
      </Box>
    </>
  );
};

export default UpdateGuestAndSpeaker;
