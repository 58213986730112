import { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import InvoiceTableRow from './InvoiceTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateInvoice from './CreateInvoice';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IVoucherType } from '../type/voucherType';
import CommonSearchTextFieldWithDebounce from 'src/common/CommonSearchTextFieldWithDebounce';
const InvoiceTable = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<{
    page: string | number;
    listType: string | number;
    limit: string | number;
  }>({
    listType: 'pending',
    page: 1,
    limit: 30,
  });
  const { response, setRefetch, loading, total, getData } = GetDataApi<
    IVoucherType[]
  >(
    `/api/atab/invoice/get/all/for-admin/status/${filter.listType}/?limit=${filter.limit}&page=${filter.page}&searchParams=${search}`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter, search]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Grid
                  container
                  spacing={2} // Adds consistent spacing between items
                  justifyContent='space-between'
                  alignItems='center'
                >
                  {/* Title */}
                  <Grid item xs={12} md={6} xl={4}>
                    <h2 style={{ margin: 0 }}>Voucher List</h2>
                  </Grid>

                  {/* Controls Section */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    container
                    spacing={1} // Adds space between child items
                    justifyContent='flex-end'
                  >
                    {/* Search Input */}
                    <Grid item xs={12} sm={6} md={4}>
                      <CommonSearchTextFieldWithDebounce onSearch={setSearch} />
                    </Grid>

                    {/* Select Dropdown */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Select
                        size='small'
                        fullWidth
                        variant='outlined'
                        defaultValue='pending'
                        onChange={(e) =>
                          setFilter({ ...filter, listType: e.target.value })
                        }
                        sx={{ height: 36 }}
                      >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='unpaid'>Unpaid</MenuItem>
                        <MenuItem value='pending'>Pending</MenuItem>
                      </Select>
                    </Grid>

                    {/* Create Voucher Button */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Button
                        onClick={() => setOpen(true)}
                        variant='contained'
                        size='small'
                        sx={{ height: 36 }}
                        fullWidth
                      >
                        Create Voucher
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SL</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>License No.</TableCell>
                      <TableCell>Voucher no</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Voucher Amount</TableCell>
                      <TableCell>Due Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : response?.length ? (
                      <>
                        {response?.map((sInvoice, index) => (
                          <InvoiceTableRow
                            key={sInvoice.atab_payment_invoice_id}
                            sInvoice={sInvoice}
                            index={index}
                            getData={getData}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total as number}
                  onPageChange={(_event: any, newPage: number) =>
                    setFilter({ ...filter, page: newPage })
                  }
                  onRowsPerPageChange={(event) =>
                    // setLimit(parseInt(event.target.value))
                    setFilter({ ...filter, limit: event.target.value })
                  }
                  page={Number(filter.page) && Number(filter.page)}
                  rowsPerPage={Number(filter.limit)}
                  rowsPerPageOptions={[30, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: 'Create Voucher' }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateInvoice handleClose={setOpen} refetch={getData} />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoiceTable;
