import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IViewBudgetListType } from '../../type/budgetType';
import { TableLoader } from 'src/Utils/TableLoader';
import dayjs from 'dayjs';
import TableNoDataMessage from 'src/common/TableNoDataMessage';
import BudgetDetailsForWard from './BudgetDetailsForWard';
import ViewAllForwardingNote from '../ViewAllForwardingNote';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { ApprovedBudget } from './ApprovedBudget';
type IProps = {
  response: IViewBudgetListType;
  loading: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
};
const InitialPhase = ({ response, loading, setRefetch }: IProps) => {
  const { user } = useAuthContext();
  const currentRole = user?.user_admin_role?.toLowerCase();
  return (
    <Box sx={{ p: 2 }}>
      <Box display={'flex'} justifyContent={'center'}>
        <Chip
          label={
            <h3>Initial {response?.approved_by ? 'Approved' : ''} Budget</h3>
          }
          color='primary'
          variant='outlined'
        />
      </Box>

      <Box>
        <Box fontSize={15} fontWeight='bold' mb={1}>
          Budget Items List :{' '}
        </Box>

        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small' align='center'>
                  SL no
                </TableCell>
                <TableCell align='center' size='small'>
                  Date
                </TableCell>
                <TableCell align='center' size='small'>
                  Budget Items
                </TableCell>

                <TableCell align='center' size='small'>
                  Initial Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {response?.budgetItems?.length ? (
                    <>
                      {response?.budgetItems?.map((sPayment, index) => (
                        <TableRow
                          key={sPayment.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align='center' size='small'>
                            {index + 1}
                          </TableCell>

                          <TableCell align='center' size='small'>
                            {sPayment?.created_at &&
                              dayjs(sPayment?.created_at).format('DD-MM-YYYY')}
                          </TableCell>

                          <TableCell align='center' size='small'>
                            {sPayment.name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {Number(sPayment.initial_amount)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container rowGap={1}>
          {/* 1st column--------- */}
          <Grid item xs={6}>
            <Box
              fontSize={15}
              fontWeight='bold'
              display={'flex'}
              justifyContent={'start'}
              mt={5}
            >
              Others Info :
            </Box>
            <Grid item xs={8}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Total Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                <Chip
                  size='small'
                  label={response?.initial_total_amount}
                  color='primary'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Budget No.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.budget_no}
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Budget For.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.name}
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Budget Date
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                {response?.budget_date &&
                  dayjs(response.budget_date).format('DD-MMM-YYYY')}
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                {response?.status === 'pending' ? (
                  <Chip
                    size='small'
                    label={response?.status}
                    color='error'
                    variant='outlined'
                  />
                ) : response?.status === 'initial_approved' ||
                  response?.status === 'approved' ? (
                  <Chip
                    size='small'
                    label={'Budget Initial Approved'}
                    color='primary'
                    variant='outlined'
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' component='h2'>
                  Approved By
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                {response?.approved_by ? (
                  <Chip
                    size='small'
                    label={response?.approved_by}
                    color='primary'
                    variant='outlined'
                  />
                ) : (
                  ' Not Approved Yet'
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container mt={5} justifyContent={'space-between'}>
        {!response?.final_approved_by && (
          <Grid item>
            <Grid container>
              <BudgetDetailsForWard
                currentForward={response?.current_forward}
                setRefetch={setRefetch}
              />

              <ViewAllForwardingNote response={response?.forward} />
            </Grid>
          </Grid>
        )}

        {((currentRole === 'finance secretary' &&
          response?.current_forward === 'Finance Secretary') ||
          (currentRole === 'secretary' &&
            response?.current_forward === 'Secretary')) &&
          response?.status !== 'approved' &&
          response?.status !== 'initial_approved' && (
            <Grid item>
              <ApprovedBudget
                budgetStatus={response?.status}
                setRefetch={setRefetch}
              />
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default InitialPhase;
