import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  useTheme,
  useMediaQuery,
  Button,
  Grid,
  Tooltip,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import BudgetListTableRow from './BudgetListTableRow';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IBudgetListType } from '../type/budgetType';
import CreateBudget from './CreateBudget';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import SearchTableListByLicense from 'src/common/CommonSearchTextFieldWithDebounce';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import CommonSearchTextFieldWithDebounce from 'src/common/CommonSearchTextFieldWithDebounce';

const BudgetListTable: FC = () => {
  const { user } = useAuthContext();
  // Modal
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<{
    page: string | number;

    limit: string | number;
  }>({
    page: 1,
    limit: 30,
  });
  const { response, loading, total, getData } = GetDataApi<IBudgetListType[]>(
    `/api/atab/budget?limit=${filter.limit}&page=${filter.page}&searchParams=${search}`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter, search]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid container>
            <Grid item xs={12} md={6}>
              <h2 style={{ margin: 0 }}>Budget Management List</h2>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Grid container justifyContent={'end'} spacing={1}>
                <Grid item xs={12} md={4}>
                  <CommonSearchTextFieldWithDebounce
                    onSearch={setSearch}
                    label='Search here'
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Tooltip
                    title={
                      user?.user_admin_role?.toLowerCase() === 'accounts'
                        ? ''
                        : `You haven't access`
                    }
                  >
                    <Button
                      onClick={() =>
                        user?.user_admin_role?.toLowerCase() === 'accounts'
                          ? setOpen(true)
                          : ''
                      }
                      variant='contained'
                      sx={{ mr: 1, width: '100%', height: 36 }}
                      size='small'
                    >
                      Create Budget
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SL</TableCell>
              <TableCell>Budget Date</TableCell>
              <TableCell>Budget No</TableCell>
              <TableCell>Budget Name</TableCell>
              <TableCell>Budget Status</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Forward Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableLoader />
            ) : response?.length ? (
              <>
                {response?.map((budget, index) => {
                  return (
                    <BudgetListTableRow
                      key={budget.id}
                      budgetData={budget}
                      index={index}
                      refetchData={getData}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={total as number}
          onPageChange={(_event: any, newPage: number) =>
            setFilter({ ...filter, page: newPage })
          }
          onRowsPerPageChange={(event) =>
            // setLimit(parseInt(event.target.value))
            setFilter({ ...filter, limit: event.target.value })
          }
          page={Number(filter.page) && Number(filter.page)}
          rowsPerPage={Number(filter.limit)}
          rowsPerPageOptions={[30, 50, 75, 100]}
        />
      </Box>

      {/* Modal */}
      <ModalsWrapper
        modalData={{ title: 'Create Budget' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <CreateBudget setOpen={setOpen} refetchData={getData} />
      </ModalsWrapper>
    </Card>
  );
};

export default BudgetListTable;
