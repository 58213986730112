import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Toaster from 'src/Toaster/Toaster';
import { IBusinessLicense } from 'src/Types/module/member/UpdateCompanyInfoStateTypes';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import { imgUrl } from 'src/Helpers/utils/Constant';

interface IProps {
  businessLicense: IBusinessLicense;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}

const businessLicense = ({
  businessLicense,
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [businessLicenses, setBusinessLicense] = useState<any>({
    hajj_license: businessLicense.hajj_license,
    umrah_license: businessLicense.umrah_license,
    recruting_license: businessLicense.recruting_license,
    iata_agent: businessLicense.iata_agent,
    toab: businessLicense.toab,
    tdab: businessLicense.tdab,
    baira: businessLicense.baira,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...businessLicenses };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      setIsLoading(true);
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/business-license/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(true);
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ maxWidth: 600, px: 2 }}>
        <Grid item xs={12}>
          <Typography variant='h6' color={'black'}>
            Business License
          </Typography>
          <Divider />
        </Grid>

        {/* License Checkboxes */}
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.hajj_license === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='Hajj'
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.umrah_license === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='Umrah'
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.recruting_license === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='Recruiting'
            />
          </Box>
        </Grid>

        {/* Conditionally Render License File Inputs */}
        <Grid item xs={12}>
          {businessLicenses.hajj_license > 0 && (
            <Box sx={{ mb: 2, width: 300 }}>
              <Typography variant='body1' gutterBottom>
                Member organization Hajj license{' '}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('hajj_license_file')}
              />
            </Box>
          )}
          {businessLicenses.umrah_license > 0 && (
            <Box sx={{ mb: 2, width: 300 }}>
              <Typography variant='body1' gutterBottom>
                Member organization Umrah license{' '}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('umrah_license_file')}
              />
            </Box>
          )}{' '}
          {businessLicenses.recruting_license > 0 && (
            <Box sx={{ mb: 2, width: 300 }}>
              <Typography variant='body1' gutterBottom>
                Member organization Recruiting license{' '}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('recruting_license_file')}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' color={'black'}>
            Other Certificate
          </Typography>
          <Divider />
        </Grid>
        {/* Other Certificates */}
        <Grid container spacing={2}>
          {/* IATA Certificate */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.iata_agent === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      iata_agent: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='IATA Certificate'
            />
            {businessLicenses.iata_agent > 0 && (
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('iata_agent_file')}
                sx={{ mt: 1 }}
              />
            )}
          </Grid>

          {/* BAIRA Certificate */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.baira === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      baira: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='BAIRA Certificate'
            />
            {businessLicenses.baira > 0 && (
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('baira_file')}
                sx={{ mt: 1 }}
              />
            )}
          </Grid>

          {/* TDAB Certificate */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.tdab === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      tdab: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='TDAB Certificate'
            />
            {businessLicenses.tdab > 0 && (
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('tdab_file')}
                sx={{ mt: 1 }}
              />
            )}
          </Grid>

          {/* TOAB Certificate */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={businessLicenses.toab === 1}
                  onChange={(e) =>
                    setBusinessLicense({
                      ...businessLicenses,
                      toab: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label='TOAB Certificate'
            />
            {businessLicenses.toab && (
              <TextField
                size='small'
                variant='outlined'
                type='file'
                fullWidth
                {...register('toab_file')}
                sx={{ mt: 1 }}
              />
            )}
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'end', mt: 4 }}>
            {isLoading ? (
              <Button variant='contained' disableElevation disabled>
                Loading...
              </Button>
            ) : (
              <Button type='submit' variant='contained' disableElevation>
                Save
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default businessLicense;
