import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import Toaster from 'src/Toaster/Toaster';
const Toast = Toaster();
type ISpecificGroup = {
  id: number;
  name: string;
  group_members: {
    user_member_company_name: string;
    user_member_email: string;
    user_member_id: number;
  }[];
};
const SpecificRemoveFromGroup = ({
  id,
  setRemoveMember,
}: {
  id: any;
  setRemoveMember: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { response, getData, loading } = GetDataApi<ISpecificGroup>(
    `/api/member/group/${id}`
  );
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  const handleChange = (event, user_member_id) => {
    setCheckedItems({
      ...checkedItems,
      [user_member_id]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    const selectedIds = Object.keys(checkedItems)
      .filter((user_member_id) => checkedItems[user_member_id])
      .map(Number);
    if (selectedIds.length > 0) {
      setRemoveMember(selectedIds);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'No member selected to remove?',
      });
    }
  };

  return (
    <div>
      <FormGroup>
        {response?.group_members?.map((member, index) => (
          <FormControlLabel
            key={index} // Ensure you have a unique key for each item
            control={
              <Checkbox
                checked={checkedItems[member.user_member_id] || false} // Dynamically handle checked state based on user_member_id
                onChange={(event) => handleChange(event, member.user_member_id)} // Pass user_member_id to your change handler
                name={`item${index + 1}`} // Dynamic name (optional, for debugging)
              />
            }
            label={member.user_member_company_name} // Dynamic label based on index or member data
          />
        ))}
      </FormGroup>
      <Button
        size='small'
        // sx={{ color: 'red', border: '1px solid red' }}
        variant='outlined'
        color='error'
        onClick={handleSubmit}
      >
        Remove Done
      </Button>
    </div>
  );
};

export default SpecificRemoveFromGroup;
