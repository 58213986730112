import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState, useEffect } from "react";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";

const UpdateModal = ({
  setShowModal,
  showModal,
  loading,
  setLoading,
  application,
  setApplication,
}) => {
  const [inputs, setInputs] = useState({
    renewal_application_status: "pending",
    renewal_application_reject_reason: "",
  });

  useEffect(() => {
    setInputs({
      renewal_application_status: application.renewal_application_status,
      renewal_application_reject_reason:
        application.renewal_application_reject_reason,
    });
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    try {
      const response = await axios.patch(
        `${url}/api/member/renewal-application/${application.renewal_application_id}`,
        inputs
      );

      setLoading(false);
      if (response.data.success) {
        Toaster().fire({
          icon: "success",
          title: `Application updated Successfully `,
        });

        setApplication({
          ...application,
          renewal_application_status: inputs.renewal_application_status,
          renewal_application_reject_reason:
            inputs.renewal_application_reject_reason,
        });
        setShowModal(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err?.response?.data?.message} `,
      });
      setLoading(false);
    }
  };

  return (
    <ModalsWrapper
      setShowModal={setShowModal}
      showModal={showModal}
      modalData={{
        title: "Update renewal application",
      }}
    >
      <Box p={2}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.renewal_application_status}
                  label="Select Status"
                  onChange={(e: any) =>
                    setInputs({
                      ...inputs,
                      renewal_application_status: e.target.value,
                    })
                  }
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Note"
                  variant="outlined"
                  value={inputs.renewal_application_reject_reason}
                  multiline
                  type="text"
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      renewal_application_reject_reason: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
            {loading ? (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="add-remove-button"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="add-remove-button"
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ModalsWrapper>
  );
};

export default UpdateModal;
