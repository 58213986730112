import { Button } from '@mui/material';
import Excel from 'exceljs';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { ISubscriptionReport } from 'src/Types/module/common/commonTypes';
import moment from 'moment';

const YearlyExcelGenerator = ({
  getDownloadData,
}: {
  getDownloadData: () => Promise<{ data: ISubscriptionReport[] }>;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();
  const saveExcel = async () => {
    setLoading(true);
    const data = await getDownloadData();
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet('Subscription_yearly_report');

      const titleRow = worksheet.addRow([
        'Serial No',
        'Membership No',
        'Agency Name ',
        'Subscription Year',
        'Zone',
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      await Promise.all(
        data.data.map(async (item, index) => {
          const row = worksheet.addRow([
            index + 1,
            item.user_member_id,
            item.user_member_company_name,
            `${moment(item.user_member_membership_payment_date).format(
              'YYYY'
            )} - ${moment(
              item.user_member_membership_payment_expiry_date
            ).format('YYYY')} `,
            item.user_member_zone_name,
          ]);

          // if (item.user_member_representative_photo) {
          //   row.height = 100;
          //   worksheet.columns.forEach((column) => {
          //     column.width = 15;
          //   });

          //   // const imgBuffer = await getImageBuffer(
          //   //   `${imgUrl}/${item.user_member_representative_photo}`
          //   // );

          //   // if (imgBuffer) {
          //   //   const imageId = workbook.addImage({
          //   //     buffer: imgBuffer.data,
          //   //     extension: 'jpeg',
          //   //   });

          //   //   const imageCell = row.getCell(5);

          //   //   worksheet.addImage(imageId, {
          //   //     tl: {
          //   //       col: Number(imageCell.col) - 1,
          //   //       row: Number(imageCell.row) - 1,
          //   //     },
          //   //     ext: { width: 100, height: 100 },
          //   //   });
          //   // }
          // }
        })
      );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `Subscription_yearly_report.xlsx`);
    } catch (error) {
      setLoading(false);

      console.error('Something Went Wrong', error.message);
    }
  };
  return (
    <>
      <>
        {loading ? (
          <GlobalLoader />
        ) : (
          <Button onClick={saveExcel} variant='outlined' size='small'>
            Download excel
          </Button>
        )}
      </>
    </>
  );
};

export default YearlyExcelGenerator;
