import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { ISingleTradeLicense } from "src/Types/module/UpdateRequest/RequestTradeLicense";
import moment from "moment";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { useState } from "react";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";

interface IProps {
  id: number | string;
  requestTradeLicenseInfo: ISingleTradeLicense;
}

const UpdateTradeLicenseInfo = ({ id, requestTradeLicenseInfo }: IProps) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const handelAccept = async () => {
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/license/${id}?user_admin_id=${user.user_admin_id}`
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/trade-license");
      }
    } catch (err: any) {
      console.log(err?.response?.data.message);
    }
  };

  const handelReject = async () => {
    setLoading(true);
    if (!rejectReason) {
      setLoading(false);
      return Toaster().fire({
        icon: "error",
        title: "Please enter reject reason",
      });
    }
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/license/${id}?confirmation_type=reject&user_admin_id=${user.user_admin_id}`,
        {
          reject_reason: rejectReason,
        }
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/trade-license");
      }
      setLoading(false);
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4" fontSize={21}>
              Requested information
            </Typography>
          }
          action={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button size="small" variant="outlined" onClick={handelAccept}>
                Accept request
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setOpen(true)}
              >
                Reject request
              </Button>
            </Box>
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Trade license
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {requestTradeLicenseInfo?.company_trade_license}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Company bin number
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {requestTradeLicenseInfo?.company_bin_number}
                </TableCell>
              </TableRow>
              {requestTradeLicenseInfo.company_bin_file && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company bin file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestTradeLicenseInfo.company_bin_file}`}
                    >
                      {requestTradeLicenseInfo.company_bin_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell component="th" scope="row">
                  Company tin number
                </TableCell>
                <TableCell align="right">
                  {requestTradeLicenseInfo?.company_tin_number}
                </TableCell>
              </TableRow>
              {requestTradeLicenseInfo.company_tin_file && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company tin file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestTradeLicenseInfo.company_tin_file}`}
                    >
                      {requestTradeLicenseInfo.company_tin_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell component="th" scope="row">
                  Company trade license expiry date
                </TableCell>
                <TableCell align="right">
                  {moment(
                    requestTradeLicenseInfo?.company_trade_license_expiry_date
                  ).format("LL")}
                </TableCell>
              </TableRow>

              {requestTradeLicenseInfo.company_trade_license_file && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company tarde license file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestTradeLicenseInfo.company_trade_license_file}`}
                    >
                      {requestTradeLicenseInfo.company_trade_license_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <ModalsWrapper
        modalData={{ title: "Rejected reason" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <Box
          sx={{
            mx: 4,
          }}
        >
          <TextField
            required
            rows={4}
            fullWidth
            id="reason"
            label="Rejected reason"
            placeholder="Rejected reason"
            onChange={(e) => setRejectReason(e.target.value)}
            type="text"
            multiline
            autoFocus
          />

          {loading ? (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading ...
            </Button>
          ) : (
            <Button
              onClick={handelReject}
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Submit
            </Button>
          )}
        </Box>

        {/* <CreateMember setAllMembers={setAllMembers} handleClose={setOpen} /> */}
      </ModalsWrapper>
    </>
  );
};

export default UpdateTradeLicenseInfo;
