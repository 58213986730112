import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  useTheme,
  useMediaQuery,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { ITrainingInvoiceItem } from 'src/Utils/type';
import TrainingInvoiceItemTableRow from './TrainingInvoiceItemTableRow';
import CreateTrainingInvoiceItem from './CreateTrainingInvoiceItem';

function TrainingInvoiceItem() {
  const [trainingInvoiceItem, setTrainingInvoiceItem] = useState<
    ITrainingInvoiceItem[]
  >([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/training/payment/get/all/paymentable/item`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setTrainingInvoiceItem(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(trainingInvoiceItem, page, limit);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title='Trainee Invoice Item list'
              action={
                <Button
                  onClick={() => handleOpen()}
                  variant='contained'
                  size='small'
                >
                  Create training invoice item
                </Button>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item ID</TableCell>
                    <TableCell>Item name</TableCell>
                    <TableCell>BDT amount</TableCell>
                    <TableCell>USD amount</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableLoader />
                ) : paginatedInvoice.length ? (
                  <TableBody>
                    {paginatedInvoice.map(
                      (trainingInvoiceItem: ITrainingInvoiceItem) => {
                        return (
                          <TrainingInvoiceItemTableRow
                            key={
                              trainingInvoiceItem.training_paymentable_item_id
                            }
                            trainingInvoiceItem={trainingInvoiceItem}
                          />
                        );
                      }
                    )}
                  </TableBody>
                ) : (
                  <TableNotFound />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={trainingInvoiceItem.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}>
              {' '}
              Create training invoice item
            </Typography>
            <Divider />
          </Box>
          <CreateTrainingInvoiceItem
            handleClose={handleClose}
            setTrainingInvoiceItem={setTrainingInvoiceItem}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TrainingInvoiceItem;
