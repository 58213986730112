import { useState } from 'react';
import { Grid, Button, TextField, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import Toaster from 'src/Toaster/Toaster';
import { ICivilAviation } from 'src/Types/module/member/CompanyInformation';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import moment from 'moment';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';

interface IDate {
  certificate_expiry_date: string;
  certificate_issue_date: string;
}

interface IProps {
  civilAviation: ICivilAviation;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const UpdateCivilAviationInfo = ({
  memberId,
  civilAviation,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { register, handleSubmit } = useForm();

  const [info, setInfo] = useState({
    certificate_expiry_date: '',
    certificate_issue_date: '',
    certificate_file_bn: '',
    certificate_file: '',
    certificate_number: '',
  });
  const [certificateFile, setCertificateFile] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);
    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      Toaster().fire({
        icon: 'error',
        title: 'Please Change any field',
      });
    } else {
      try {
        setIsLoading(true);
        const data: IResponse = await HttpClient.patch(
          `/api/atab/admin/member-company/civil/aviation/${memberId}`,
          formData
        );

        if (data.success) {
          Toaster().fire({
            icon: 'success',
            title: data.message,
          });
          setRefresh(!refresh);
          setOpen(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err: any) {
        Toaster().fire({
          icon: 'error',
          title: err?.response?.data.message,
        });
        setIsLoading(false);
      }
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Certificate number'
                placeholder='Certificate number'
                fullWidth
                defaultValue={civilAviation.certificate_number}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    certificate_number: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                type='date'
                label='Issue date'
                defaultValue={moment(
                  civilAviation.certificate_issue_date
                )?.format('YYYY-MM-DD')}
                placeholder='Issue date'
                onChange={(e) =>
                  setInfo({
                    ...info,
                    certificate_issue_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                placeholder='Expiry date'
                label='Expiry date'
                type='date'
                defaultValue={moment(
                  civilAviation.certificate_expiry_date
                )?.format('YYYY-MM-DD')}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    certificate_expiry_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Upload Certificate Bangla (pdf)'
                placeholder='Upload Certificate'
                variant='outlined'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    certificate_file_bn: e.target.files[0],
                  })
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Upload Certificate English (pdf)'
                placeholder='Upload Certificate'
                variant='outlined'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    certificate_file: e.target.files[0],
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCivilAviationInfo;
