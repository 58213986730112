import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/components/Context/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthContextProvider } from './components/Context/AuthContext/AuthContext';
import { ModalContextProvider } from './components/Context/ModalContext';
import React from 'react';
import { CacheProvider } from './common/FetchData/useGlobalCache';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ModalContextProvider>
        <SidebarProvider>
          <AuthContextProvider>
            <CacheProvider>
              <App />
            </CacheProvider>
          </AuthContextProvider>
        </SidebarProvider>
      </ModalContextProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
