import { useEffect, useState } from 'react';
import { Grid, Button, TextField, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import Toaster from 'src/Toaster/Toaster';
import { ICompanyownerPassport } from 'src/Types/module/member/CompanyInformation';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import moment from 'moment';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';

interface IDate {
  certificate_expiry_date: string;
  certificate_issue_date: string;
}

interface IProps {
  passport: ICompanyownerPassport;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
}
const UpdateOwnerpassport = ({
  memberId,
  passport,
  setRefresh,
  setOpen,
}: IProps) => {
  const {
    updateData,
    loading: isLoading,
    success,
  } = UpdateDataApi(
    `/api/atab/admin/member-company/member/owner-passport/${memberId}`
  );
  const { register, handleSubmit } = useForm();

  const [info, setInfo] = useState({
    expiry_date: '',
    issue_date: '',
    passport_file: '',
    passport_number: '',
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);
    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      Toaster().fire({
        icon: 'error',
        title: 'Please Change any field',
      });
    } else {
      await updateData(formData);
    }
  };
  useEffect(() => {
    if (success) {
      setRefresh(true);
      setOpen(false);
    }
  }, [success]);
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Passport number'
                placeholder='Passport number'
                fullWidth
                defaultValue={passport.passport_number}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    passport_number: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                type='date'
                label='Issue date'
                defaultValue={moment(passport.issue_date)?.format('YYYY-MM-DD')}
                placeholder='Issue date'
                onChange={(e) =>
                  setInfo({
                    ...info,
                    issue_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                placeholder='Expiry date'
                label='Expiry date'
                type='date'
                defaultValue={moment(passport.expiry_date)?.format(
                  'YYYY-MM-DD'
                )}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    expiry_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Owner passport (pdf)'
                placeholder='Upload passport'
                variant='outlined'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    passport_file: e.target.files[0],
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateOwnerpassport;
