import { useState, useEffect, ChangeEvent } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Typography,
} from '@mui/material';

import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { RefreshButton } from 'src/Utils/CommonAllShowUtils';
import { IBatchList } from 'src/Types/module/courses/trainee';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IBatchNoticeList } from 'src/Types/module/batch/allTypeOfBatch';
import AllBatchNoticeTableRow from './AllNoticeTableRow';
import CreateNotice from '../../BatchNotice/CreateNotice';

const AllBatchNotice = () => {
  const [allBatchNotice, setAllBatchNotice] = useState<IBatchNoticeList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [filterValue, setFilterValue] = useState<string>('all');
  const [optionValue, setOptionValue] = useState<IBatchList[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri: string;
        if (filterValue) {
          (uri = `${url}/api/training/batch/get/notice/${filterValue}`),
            { cancelToken: source.token };
        }
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllBatchNotice(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
      return () => {
        // cancel the request before component unmounts
        source.cancel();
      };
    })();
  }, [refresh, filterValue]);

  useEffect(() => {
    setIsLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/training/batch/get/all/or/status/all`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setOptionValue(data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setIsLoading(false);
        } else {
          // handle error
          setIsLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid container direction='row' justifyContent='center' spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display='flex' alignItems='center' gap={2}>
                  <FormControl
                    sx={{ width: '150px' }}
                    variant='outlined'
                    size='small'
                  >
                    <InputLabel>Select batch</InputLabel>
                    <Select
                      onChange={(e: any) => setFilterValue(e.target.value)}
                      label='Select batch'
                      autoWidth
                      size='small'
                      value={filterValue}
                    >
                      <MenuItem value='all'>All</MenuItem>
                      {optionValue.map((statusOption: IBatchList) => (
                        <MenuItem
                          key={statusOption.training_batch_id}
                          value={statusOption.training_batch_id}
                        >
                          {statusOption.training_batch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={() => handleOpen()}
                    variant='contained'
                    size='small'
                  >
                    Create Notice
                  </Button>
                  <RefreshButton action={setRefresh} />{' '}
                </Box>
              }
              title='All Notice'
            ></CardHeader>
            <Divider />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell> Title</TableCell>
                    <TableCell> Batch Name</TableCell>
                    <TableCell> Date</TableCell>
                    <TableCell> Time</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableLoader />
                ) : allBatchNotice.length ? (
                  <TableBody>
                    {allBatchNotice.map((notice: IBatchNoticeList) => {
                      return (
                        <AllBatchNoticeTableRow
                          key={notice.training_batch_notice_id}
                          notice={notice}
                          allBatchNotice={allBatchNotice}
                          setAllBatchNotice={setAllBatchNotice}
                        />
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableNotFound />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={AllBatchNotice.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <Box>
            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
              {' '}
              Create batch notice
            </Typography>
            <Divider />
          </Box>
          <CreateNotice
            handleClose={handleClose}
            setAllBatchNotice={setAllBatchNotice}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='outlined'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AllBatchNotice;
