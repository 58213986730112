import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import moment from 'moment';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { url } from 'src/Helpers/utils/Constant';
import { ISingleOwnershipChangeApplication } from 'src/Types/module/applications/applications';
import { getStatusLabel } from 'src/Utils/Combined';
import UpdateModal from './UpdateModal';
import PdfAndImageView from 'src/common/PdfAndImageView';
import GetDataApi from 'src/common/FetchData/GetDataApi';

const SingleOwnershipChangeApplication = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  const { response, setRefetch } =
    GetDataApi<ISingleOwnershipChangeApplication>(
      `${url}/api/member/owner-change-application/${id}`
    );

  return (
    <>
      <Helmet>
        <title>HAAB - Ownership change application</title>
      </Helmet>
      <Card sx={{ mx: 2 }}>
        <CardHeader
          title='Ownership change application'
          action={
            <Button
              size='small'
              onClick={() => setShowModal(true)}
              variant='outlined'
            >
              Update
            </Button>
          }
        />
        <Divider />
        <CardContent>
          {response ? (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Application Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {moment(
                        response.ownership_change_application_created_at
                      ).format('MMM Do YY')}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>Company Name</Typography>
                    </TableCell>
                    <TableCell>{response.user_member_company_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        New Owner Name
                      </Typography>
                    </TableCell>
                    <TableCell>{response.company_owner_name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        New Owner Designation
                      </Typography>
                    </TableCell>
                    <TableCell>{response.company_owner_designation}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>New Owner NID</Typography>
                    </TableCell>
                    <TableCell>{response.company_owner_nid}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        New Owner Address
                      </Typography>
                    </TableCell>
                    <TableCell>{response.company_owner_address}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        New Owner Phone
                      </Typography>
                    </TableCell>
                    <TableCell>{response.company_owner_phone}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        New Owner Email
                      </Typography>
                    </TableCell>
                    <TableCell>{response.company_owner_email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>Status</Typography>
                    </TableCell>
                    <TableCell>
                      {getStatusLabel(
                        response.ownership_change_application_status
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Owner Photo
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={response.company_owner_photo} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Civil Aviation
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={
                          response.ownership_change_application_civil_aviation_bn_en
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>Download TIN</Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={response.ownership_change_application_tin}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Trade License
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={
                          response.ownership_change_application_trade_license
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Hajj License
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={
                          response.ownership_change_application_hajj_license
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Forwarding Letter
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={
                          response.ownership_change_application_forwarding_letter
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Ownership Change Deed
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={
                          response.ownership_change_application_ownership_change_deed
                        }
                      />
                    </TableCell>
                  </TableRow>

                  {response.ownership_change_application_rejected_reason && (
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Rejection Reason
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {response.ownership_change_application_rejected_reason}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Previous Owner NID File
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={response.prev_company_owner_nid_file}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download New Owner Bio-data
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={response.company_owner_biodata} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download New Owner Passport
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={response.company_owner_passport} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download New Owner Signature
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView
                        file={response.company_owner_signature}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              No Data found
            </Typography>
          )}
        </CardContent>
      </Card>
      {response && (
        <UpdateModal
          setShowModal={setShowModal}
          showModal={showModal}
          application={response}
          setRefetch={setRefetch}
        />
      )}
    </>
  );
};

export default SingleOwnershipChangeApplication;
