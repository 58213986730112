import {
  Box,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import UpdateDataApi from 'src/common/FetchData/UpdateDataApi';
import { useParams } from 'react-router-dom';
import { IAccountType } from 'src/content/modules/Members/subModules/invoice/type/voucherType';

export const ApprovedBudget = ({
  budgetStatus,
  setRefetch,
}: {
  budgetStatus: string;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { id: paramId } = useParams();
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, watch } = useForm();

  const { updateData, loading, success } = UpdateDataApi(
    `/api/atab/budget/${paramId}`
  );
  useEffect(() => {
    if (success) {
      setRefetch(true);
      setOpen(false);
    }
  }, [success]);

  const onSubmit = async (inputs: any) => {
    if (inputs) {
      updateData({ ...inputs });
    } else if (inputs?.status === 'rejected') {
      updateData({ status: inputs?.status });
    }
  };

  const paymentType = watch('method');
  const status = watch('status');

  const { response, getData: accountData } = GetDataApi<IAccountType[]>(
    `/api/atab/account?account_type=${paymentType}`,
    true
  );
  useEffect(() => {
    if (paymentType !== 'Cheque' && paymentType) {
      accountData();
    }
  }, [paymentType]);
  return (
    <>
      <Button
        onClick={() =>
          budgetStatus === 'initial_approved' || budgetStatus === 'rejected'
            ? ''
            : setOpen(true)
        }
        variant='contained'
        sx={{ mr: 1, width: '100%', height: 36 }}
        size='small'
      >
        {budgetStatus === 'initial_approved'
          ? 'Already Approved'
          : budgetStatus === 'rejected'
          ? 'Approved rejected'
          : 'Approved Budget'}
      </Button>

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: 'Approved Budget' }}
        maxWidth='sm'
        fullWidth={true}
      >
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            p: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='status-select-label'>Status</InputLabel>
                <Select
                  required
                  labelId='status-select-label'
                  id='status-select'
                  value={status}
                  {...register('status')}
                  label='Status' // The label that will appear above the select field
                >
                  <MenuItem value='initial_approved'>Initial Approved</MenuItem>
                  <MenuItem value='rejected'>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box textAlign={'end'}>
            {loading ? (
              <LoadingButton
                size='small'
                loading={loading}
                fullWidth
                color='secondary'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant='contained'
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                size='small'
                type='submit'
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Approved
              </Button>
            )}
          </Box>
        </Box>
      </ModalsWrapper>
    </>
  );
};
