import {
  Card,
  CardContent,
  Container,
  Typography,
  Box,
  Divider,
  Grid,
} from '@mui/material';
import moment from 'moment';
import PdfAndImageView from 'src/common/PdfAndImageView';

const CardNotice = ({ singleNotice }: any) => {
  return (
    <Container>
      <Card className='invoice_print_form'>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'cneter',
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                gutterBottom
              >
                Notice
              </Typography>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pl: 1 }}
                gutterBottom
              >
                # {singleNotice?.member_notice_id}
              </Typography>
            </Box>
          </Box>
          <Divider />

          <>
            <Box sx={{ mt: 3 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Notice Title:
                  </Typography>
                  {singleNotice?.member_notice_title}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Notice Created by:
                  </Typography>
                  {singleNotice?.member_notice_created_by_name}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Notice Created at:
                  </Typography>
                  {moment(singleNotice.member_notice_created_at).format(
                    'MMMM Do YYYY, h:mm a'
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <h4 style={{ display: 'flex', gap: '10px' }}>
                    Notice file:{' '}
                    <PdfAndImageView
                      file={`${singleNotice?.member_notice_file}`}
                      text='View Notice File'
                    />
                  </h4>
                </Grid>
              </Grid>
            </Box>
          </>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardNotice;
