import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import PdfAndImageView from 'src/common/PdfAndImageView';
import { ICompanyOwners } from 'src/Types/module/member/CompanyInformation';

interface IProps {
  sOwner: ICompanyOwners;
  setUpdateModal: (value: boolean) => void;
  setSingleOwner: Function;
}
const ShowOwner = ({ sOwner, setUpdateModal, setSingleOwner }: IProps) => {
  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 5 }}>
        <Table aria-label='custom pagination table'>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner name
              </TableCell>
              <TableCell align='right'>{sOwner.company_owner_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner designation
              </TableCell>
              <TableCell align='right'>
                {sOwner.company_owner_designation}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner phone
              </TableCell>
              <TableCell align='right'>{sOwner.company_owner_phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner email
              </TableCell>
              <TableCell align='right'> {sOwner.company_owner_email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner address
              </TableCell>
              <TableCell align='right'>
                {' '}
                {sOwner.company_owner_address}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner nid
              </TableCell>
              <TableCell align='right'> {sOwner.company_owner_nid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner nid photo
              </TableCell>
              <TableCell align='right'>
                <PdfAndImageView file={sOwner.company_owner_nid_file} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner photo
              </TableCell>
              <TableCell align='right'>
                <PdfAndImageView file={sOwner.company_owner_photo} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner bio data
              </TableCell>
              <TableCell align='right'>
                <PdfAndImageView file={sOwner.company_owner_biodata} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company owner signature
              </TableCell>
              <TableCell align='right'>
                <PdfAndImageView file={sOwner.company_owner_signature} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='right'>
                <Box
                // sx={{
                //   position: "absolute",
                //   top: -25,
                //   right: 0,
                // }}
                >
                  <Button
                    size='small'
                    variant='contained'
                    onClick={() => {
                      setUpdateModal(true);
                      setSingleOwner(sOwner);
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowOwner;
