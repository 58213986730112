import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Switch } from '@mui/material';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { getStatusLabel } from 'src/Utils/Combined';
import { IUnverified } from './UnverifiedMemberList';
import { useState } from 'react';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import Toaster from 'src/Toaster/Toaster';
import axios from 'axios';
import { getToken } from 'src/Utils/Lib';

const FreshMemberTableRow = ({
  allMembers,
  index,
  setStateChange,
}: {
  allMembers: IUnverified | undefined;
  index: number;
  setStateChange: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [inputData, setInputData] = useState<{ email: string }>({
    email: '',
  });
  const Toast = Toaster();
  const [loading, setLoading] = useState(false);
  const token = getToken();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [newStatus, setNewStatus] = useState('');

  const handleOpenConfirm = (status) => {
    setNewStatus(status);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleChangeStatus = async () => {
    try {
      const { data } = await axios.patch(
        `${url}/api/atab/user-member/update-member/draft-to-pending/${allMembers.user_member_id}`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });
        setStateChange(true);
      } else {
        Toast.fire({
          icon: 'warning',
          title: data.message,
        });
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: err?.response?.data.message || 'An error occurred',
      });
    }
    handleCloseConfirm();
  };

  const onSubmit = async (email: string, member_id: number) => {
    if (email === inputData.email) {
      Toast.fire({
        icon: 'warning',
        title: `Change Email Please`,
      });
    }
    if (inputData.email && email !== inputData.email) {
      try {
        setLoading(true);
        const { data } = await axios.patch(
          `${url}/api/atab/user-member/update-member/email/${member_id}`,
          inputData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (data.success) {
          Toaster().fire({
            icon: 'success',
            title: data.message,
          });
          setStateChange(true);
          setOpen(false);
          setLoading(false);
        } else {
          Toaster().fire({
            icon: 'warning',
            title: data.message,
          });
          setLoading(false);
        }
      } catch (err: any) {
        Toaster().fire({
          icon: 'error',
          title: err?.response?.data.message,
        });
        setLoading(false);
      }
    }
  };
  return (
    <>
      <TableRow hover key={allMembers.user_member_id}>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: '50%',
                marginRight: '10px',
              }}
              src={
                allMembers.company_owner_photo
                  ? `${imgUrl}/${allMembers.company_owner_photo}`
                  : '/static/images/avatars/avatar2.png'
              }
              alt=''
            />
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '12rem',
              }}
            >
              <Typography
                variant='body1'
                color='text.primary'
                gutterBottom
                noWrap
                title={allMembers.member_company_name}
              >
                {allMembers?.member_company_name}
              </Typography>
            </div>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allMembers.user_member_hajj_license}
          </Typography>
        </TableCell>
        <TableCell>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '12rem',
            }}
          >
            <Typography
              title={allMembers.company_owner_name}
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
            >
              {allMembers?.company_owner_name}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allMembers.user_member_email}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allMembers?.phones?.length && allMembers?.phones[0]}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allMembers.zone_name}
          </Typography>
        </TableCell>

        <TableCell>
          {allMembers?.user_member_account_status === 'pending' ? (
            <>{getStatusLabel(allMembers?.user_member_account_status)}</>
          ) : (
            <>{getStatusLabel(allMembers?.user_member_account_status)}</>
          )}
        </TableCell>

        <Dialog open={openConfirm} onClose={handleCloseConfirm}>
          <DialogTitle>
            The current status is{' '}
            <Chip
              size='small'
              label='Draft'
              color='primary'
              variant='outlined'
            />
            .Please verify this member?
          </DialogTitle>
          <DialogContent>Are you sure to Pending this request?</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>Cancel</Button>
            <Button onClick={handleChangeStatus} color='primary'>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <TableCell
          sx={{
            width: '150px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            onClick={() => setOpen(true)}
            sx={{ cursor: 'pointer', flex: 1 }}
          >
            Edit
          </Box>
          <Box
            onClick={() => setOpenView(true)}
            sx={{ cursor: 'pointer', flex: 1, textAlign: 'center' }}
          >
            View
          </Box>
          {allMembers.user_member_account_status === 'draft' && (
            <Box>
              <Switch
                color='primary'
                checked={false}
                onChange={() => handleOpenConfirm('pending')}
                size='small'
              />
            </Box>
          )}
        </TableCell>
      </TableRow>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: 'Edit Member' }}
      >
        <Box sx={{ padding: 2, paddingTop: 0, width: 300 }}>
          <Grid item xs={12} md={12} mt={2}>
            <TextField
              size='small'
              id='outlined-basic'
              placeholder='Enter New Email'
              label='Enter New Email'
              required
              onChange={(e) =>
                setInputData({ ...inputData, email: e.target.value })
              }
              variant='outlined'
              type='text'
              fullWidth
            />
          </Grid>

          {loading ? (
            <Button
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              size='small'
            >
              Loading ...
            </Button>
          ) : (
            <Button
              onClick={() =>
                onSubmit(
                  allMembers.user_member_email,
                  allMembers.user_member_id
                )
              }
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              size='small'
            >
              Submit
            </Button>
          )}
        </Box>
      </ModalsWrapper>
      <ModalsWrapper
        setShowModal={setOpenView}
        showModal={openView}
        modalData={{ title: 'View Member' }}
      >
        <Box sx={{ mt: 3, maxWidth: 600, paddingLeft: 2, paddingRight: 2 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Name:
                  </Typography>
                  {allMembers?.company_owner_name}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Company Name:
                  </Typography>
                  {allMembers?.member_company_name}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Designation:
                  </Typography>
                  {allMembers?.company_owner_designation}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Phone:
                  </Typography>
                  {allMembers?.member_company_mobile}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Company Type:
                  </Typography>
                  {allMembers?.member_company_type}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Status:
                  </Typography>
                  {getStatusLabel(allMembers?.user_member_account_status)}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Email:
                  </Typography>
                  {allMembers?.user_member_email}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Zone:
                  </Typography>
                  {allMembers?.zone_name}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ModalsWrapper>
    </>
  );
};

export default FreshMemberTableRow;
