import { Box, Button, Popover, Typography } from '@mui/material';
import { useState } from 'react';

type IProps = {
  handleConfirm?: () => void;
  children: any;
  popOverTitle?: string;
};
const PopoverComponent = ({
  children,
  popOverTitle,
  handleConfirm,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'confirm-popover' : undefined;
  return (
    <div>
      <Box
        aria-describedby={id}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography>{popOverTitle || 'Are you sure to update?'}</Typography>
          <Box mt={1} display='flex' justifyContent='space-between'>
            <Button
              size='small'
              color='primary'
              onClick={() => {
                handleConfirm();
                setAnchorEl(null);
              }}
            >
              Yes
            </Button>
            <Button
              size='small'
              color='error'
              onClick={() => setAnchorEl(null)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
export default PopoverComponent;
