import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { ITraineeApplication } from "src/Utils/type";

type IProps = {
  traineeApplication: ITraineeApplication;
};

const TraineeApplicationTableRow = ({ traineeApplication }: IProps) => {
  const theme = useTheme();

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {traineeApplication.training_course_application_id}
        </Typography>
      </TableCell>
      <TableCell>
        {traineeApplication.training_course_application_name}
      </TableCell>
      <TableCell>
        <Typography
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "25rem",
          }}
          variant="body1"
          color="text.primary"
          gutterBottom
        >
          {traineeApplication.atab_training_course_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          to={`/training-trainee/application/details/${traineeApplication.training_course_application_id}`}
        >
          <Tooltip title="view" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default TraineeApplicationTableRow;
