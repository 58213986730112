import React, { ReactNode, useState } from "react";
import { Card, Container } from "@mui/material";
import { Box } from "@mui/system";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import AllJoinVisitor from "./AllJoinVisitor";
import AllAttendVisitor from "./AllAttendVisitor";

const SingleEventInfoBottom = ({ loading, eventId }: any) => {
  const [value, setValue] = useState("event visitor");
  let content: ReactNode;
  if (value === "invoice") {
  } else if (value === "event visitor") {
    content = <AllJoinVisitor eventId={eventId} />;
  } else if (value === "attend member") {
    content = <AllAttendVisitor eventId={eventId} />;
  }
  return (
    <>
      {!loading ? (
        <Container maxWidth="lg">
          <Box sx={{ pt: 3 }}>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(_event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                value="event visitor"
                label="Join event visitor"
                icon={<InventoryTwoToneIcon />}
              />
              <BottomNavigationAction
                value="attend member"
                label="Attend member"
                icon={<PeopleAltTwoToneIcon />}
              />
            </BottomNavigation>
          </Box>
          <Card>{content}</Card>
        </Container>
      ) : (
        <GlobalLoader />
      )}
    </>
  );
};

export default SingleEventInfoBottom;
