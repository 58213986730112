import { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { getStatusLabel } from 'src/Utils/Combined';
import { IDuplicateCertificateApplication } from 'src/Types/module/applications/applications';
import { useDebounce } from 'src/hooks/useDebounce';
import CommonSearchfilterForAppliHeader from '../Common/CommonSearchfilterForAppliHeader';

const DuplicateCertificate = () => {
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  const [status, setStatus] = useState<string>('');

  const [applications, setApplications] = useState<
    IDuplicateCertificateApplication[]
  >([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/member/duplicate-certificate-application?duplicate_certificate_application_status=${status}&searchParams=${searchQuery}`
        );

        if (data.success) {
          setApplications(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [searchQuery, status]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Card>
        <CommonSearchfilterForAppliHeader
          setSearchCompanyVal={setSearchCompanyVal}
          setStatus={setStatus}
          status={status}
          leftSideTitle='Duplicate certificate application'
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Agency Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>License No</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {applications?.length ? (
                    <>
                      {applications?.map((item) => {
                        return (
                          <TableRow
                            key={item.duplicate_certificate_application_id}
                          >
                            <TableCell>
                              {item.duplicate_certificate_application_id}
                            </TableCell>
                            <TableCell
                              sx={{ color: 'blue', fontWeight: 'bold' }}
                            >
                              <Link to={`/member-list/${item.user_member_id}`}>
                                {item.user_member_company_name}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {item.user_member_hajj_license}
                            </TableCell>
                            <TableCell>
                              {moment(
                                item.duplicate_certificate_application_created_at
                              ).format('MMM Do YY')}
                            </TableCell>
                            <TableCell>
                              {getStatusLabel(
                                item.duplicate_certificate_application_status
                              )}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/update-applications/duplicate-certificate/${item.duplicate_certificate_application_id}?tabValue=application`}
                              >
                                <Tooltip title='view' arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color='inherit'
                                    size='small'
                                  >
                                    <VisibilityIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default DuplicateCertificate;
