import { TableCell, TableRow, Typography } from '@mui/material';
import { IAllTicket } from '../type/ticketType';
import { Box } from '@mui/system';
import getStatusChip from 'src/common/getStatusChip';
const TableRowTicket = ({
  data,
  index,
  handleStatus,
}: {
  data: IAllTicket;
  index: number;
  handleStatus: (id: number) => any;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {index + 1}
          </Typography>
        </TableCell>

        <TableCell>{data.member_company_name}</TableCell>

        <TableCell>{data.type}</TableCell>

        <TableCell>{data.details}</TableCell>
        <TableCell>{getStatusChip(data.status)}</TableCell>
        <TableCell>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => handleStatus(data?.id)}
          >
            Update
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowTicket;
