import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader/GlobalLoader";
import { url } from "src/Helpers/utils/Constant";
import { IsingleTrainee } from "src/Types/module/courses/singleTrainee";

import ParticularsOwnerInfo from "./TraineeMemberInfo/ParticularsOwnerInfo";

import { ICourseApplication } from "src/Types/module/courses/courseList";
import { IBatchList } from "src/Types/module/courses/trainee";

interface ISingleTraineeDetails extends IsingleTrainee {
  application: ICourseApplication[];
  batchList: IBatchList[];
}

function SingleTrainee() {
  const { id } = useParams();
  const [traineeInfo, setTraineeInfo] = useState<ISingleTraineeDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/trainee/get/single/for-admin/${id}`
        );

        if (data.success) {
          setTraineeInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id]);
  return (
    <>
      <Helmet>
        <title>HAAB - Trainee Information</title>
      </Helmet>

      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          <ParticularsOwnerInfo traineeInfo={traineeInfo} />
          {/* <TraineeDetailsInfo id={id} /> */}
        </>
      )}
    </>
  );
}

export default SingleTrainee;
