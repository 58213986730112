import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Container,
  Grid,
  Button,
  SelectChangeEvent,
  Typography,
  Link,
} from '@mui/material';
import { Imembers } from 'src/Types/module/member/memberTypes';
import { useDebounce } from 'src/hooks/useDebounce';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import MemberTableRow from './MemberTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/lab';
import ExcelGenerator from 'src/components/ExcelGenerator/ExcelGenerator';
import PdfGenerator from 'src/components/PdfGenerator/PdfGenerator';

const statusOptions = [
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'pending',
    name: 'Pending',
  },
  {
    id: 'rejected',
    name: 'Rejected',
  },
];

const MemberTable = () => {
  const [allMembers, setAllMembers] = useState<Imembers[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(25);
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');

  // Pagination
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'all',
    page: '0',
  });
  const status = searchParams.get('status');

  const page = parseInt(searchParams.get('page'));
  const [total, setTotal] = useState<number>(0);

  // Search by zone
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  const [zone, setZone] = useState<number>(0);
  // const [status, setStatus] = useState("");

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);

  // Modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        if (!searchQuery) {
          let uri = `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?`;
          if (startDate && endDate) {
            uri += `from_date=${
              startDate.toISOString().split('T')[0]
            }&to_date=${endDate.toISOString().split('T')[0]}&`;
          }
          if (zone > 0) {
            uri += `zone=${zone}&`;
          }
          if (status !== 'all') {
            uri += `status=${status}&`;
          }
          if (page >= 0) {
            uri += `skip=${limit * page}&limit=${limit}`;
          }

          const { data } = await axios.get(uri);
          if (data.success) {
            setAllMembers(data.data);
            setTotal(data.total);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          const { data } = await axios.get(
            `${url}/api/atab/user-member/search-member/by-company/${searchQuery}?status=${status}&zone=${zone}`
          );
          if (data.success) {
            setIsLoading(false);
            setAllMembers(data.data);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [searchQuery, page, limit, startDate, endDate, zone, status]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  const handleStatus = (e: SelectChangeEvent<string>) => {
    searchParams.set('status', e.target.value);
    setSearchParams(searchParams);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const getDownloadData: () => Promise<{
    total: number;
    data: Imembers[];
  }> = async () => {
    let memberListUrl: string;
    if (status === 'all' && zone === 0) {
      memberListUrl = `${url}/api/atab/user-member/download/member/data/for-admin`;
    } else if (status !== 'all') {
      memberListUrl = `${url}/api/atab/user-member/download/member/data/for-admin?status=${status}`;
    } else if (zone !== 0) {
      memberListUrl = `${url}/api/atab/user-member/download/member/data/for-admin?zone=${zone}`;
    } else {
      memberListUrl = `${url}/api/atab/user-member/download/member/data/for-admin?status=${status}&zone=${zone}`;
    }

    const token = localStorage.getItem('haab_admin');

    try {
      const { data } = await axios.get(memberListUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  From
                </Typography>
                <DatePicker
                  label='Start Date'
                  value={startDate}
                  onChange={(e: any) => setStartDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: '100%' }} />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label='End Date'
                  value={endDate}
                  onChange={(e: any) => setEndDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: '100%' }} />
                  )}
                />
              </Grid>
              <Grid item>
                <FormControl sx={{ width: '120px' }}>
                  <InputLabel id='demo-simple-select-label'>
                    Select zone
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={zone}
                    defaultValue={0}
                    label='Select zone'
                    onChange={(e: any) => setZone(e.target.value)}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>Dhaka</MenuItem>
                    <MenuItem value={2}>Chittagong</MenuItem>
                    <MenuItem value={3}>Sylhet</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl sx={{ width: '150px' }} variant='outlined'>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => handleStatus(e)}
                    label='Status'
                    autoWidth
                  >
                    {statusOptions &&
                      statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display='flex' justifyContent='space-between' gap={2}>
                  <TextField
                    id='outlined-basic'
                    label='Search Company'
                    variant='outlined'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchCompanyVal(e.target.value)
                    }
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <ExcelGenerator getDownloadData={getDownloadData} />
                    <PdfGenerator getDownloadData={getDownloadData} />
                  </Box>
                  <Button onClick={() => setOpen(true)} variant='contained'>
                    Create Member
                  </Button>
                </Box>
              }
              title={`All Members (${total})`}
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Representative name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Zone</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : allMembers.length ? (
                    <>
                      {allMembers.map((allMembers: Imembers) => {
                        return (
                          <MemberTableRow
                            key={allMembers.user_member_id}
                            allMembers={allMembers}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MemberTable;
