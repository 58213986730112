import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";

type IProps = {
  label: string;
  onChange?: any;
  value?: any;
};
export const CommonDatePicker = ({ label, onChange, value }: IProps) => {
  return (
    <DatePicker
      label={label}
      value={value}
      inputFormat="dd/MM/yyyy"
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} sx={{ width: "100%" }} size="small" />
      )}
    />
  );
};
