import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Button, TextField, Box, Grid } from '@mui/material';
import PostDataApis from 'src/common/FetchData/PostDataApis';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BudgetDetailsForWard from './BudgetInitialPhase/BudgetDetailsForWard';
import dayjs from 'dayjs';
type IProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchData: () => Promise<void>;
};
function CreateBudget({ setOpen, refetchData }: IProps) {
  const [note, setNote] = useState({
    forward_to: '',
    note: '',
  });

  const { postData, success } = PostDataApis('/api/atab/budget');
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      budget_date: dayjs().format('YYYY-MM-DD'),
      budgetItem: [{ name: '', amount: '' }],
    },
  });

  // Use the useFieldArray hook to manage dynamic fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'budgetItem',
  });
  const onSubmit = async (data) => {
    if (note.forward_to && note.note) {
      data.notes = [note];
    }

    await postData(data);
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      refetchData();
    }
  }, [success]);
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
      <Box sx={{ pb: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size='small'
                  {...field}
                  label={`Title`}
                  variant='outlined'
                  fullWidth
                  sx={{ mr: 2 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='budget_date'
              control={control}
              render={({ field }) => (
                <TextField
                  type='date'
                  required
                  size='small'
                  {...field}
                  label={`Date`}
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  fullWidth
                  sx={{ mr: 2 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {fields.map((field, index) => (
        <Box key={field.id} mb={2}>
          <Grid container spacing={1}>
            {/* Name Input */}
            <Grid item md={5}>
              <Controller
                name={`budgetItem.${index}.name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size='small'
                    {...field}
                    label={`Budget for ?`}
                    variant='outlined'
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Amount Input */}
            <Grid item md={4}>
              <Controller
                name={`budgetItem.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size='small'
                    {...field}
                    label={`Amount`}
                    variant='outlined'
                    fullWidth
                    type='number'
                  />
                )}
              />
            </Grid>

            {/* Buttons (+ and -) */}
            <Grid item md={3}>
              <Grid container spacing={1}>
                {/* Remove Button (-) */}
                <Grid item xs={6}>
                  <Button
                    size='small'
                    variant='outlined'
                    color='error'
                    onClick={() => remove(index)}
                    disabled={fields?.length === 1}
                    sx={{
                      '&.Mui-disabled': {
                        color: (theme) => theme.palette.grey[600],
                        borderColor: (theme) => theme.palette.grey[600],
                      },
                      height: '35px',
                    }}
                    fullWidth
                  >
                    <RemoveIcon />
                  </Button>
                </Grid>

                {/* Add Button (+) */}
                <Grid item xs={6}>
                  <Button
                    size='small'
                    variant='contained'
                    onClick={() => append({ name: '', amount: '' })}
                    sx={{ height: '35px' }}
                    fullWidth
                  >
                    <AddIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box display={'flex'} my={5}>
        <BudgetDetailsForWard setNote={setNote} />
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <Button size='small' type='submit' variant='contained' color='primary'>
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default CreateBudget;
