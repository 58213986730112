import { useState, useEffect, ReactNode, useRef } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { IMemberInfo } from 'src/Types/module/member/memberTypes';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BasicInformation from './BasicInformation';
import CompanyInformation from './CompanyInformation';
import CompanyDocumentInfo from './CompanyDocumentInfo';
import TradeLicenseInformation from './TradeLicenseInformation';
import BusinessLicenseInfo from './BusinessLicenseInfo';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import CivilAviationInfo from './CivilAviationInfo';
import CreateIcon from '@mui/icons-material/Create';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateCertificateModal from './CreateCertificateModal';
import ProposerInformation from './ProposerInformation';
import CompanyOwnerInfo from './CompanyOwnerInfo';
import CompanyInvoices from './CompanyInvoices';
import Certificate from './Certificate';
import PrintIcon from '@mui/icons-material/Print';
import useTheme from '@mui/material/styles/useTheme';
import { useReactToPrint } from 'react-to-print';
import PrintInformationForm from './PrintInformationForm/PrintInformationForm';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RoleUpdateComponent from 'src/common/RoleUpdateComponent';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import TinBinInformation from './TinBinInformation';
import OwnerPassport from './OwnerPassport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewAllNotes from 'src/common/ViewAllNotes';
const NewMemberList = () => {
  const { memberId } = useParams();
  const { user } = useAuthContext();
  const [openForwardModal, setOpenForward] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<IMemberInfo>(
    {} as IMemberInfo
  );
  let [searchParams, setSearchParams] = useSearchParams({ tabvalue: '' });

  const theme = useTheme();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `HAAB_${companyInfo.user_member_company_name}_${companyInfo.user_member_id}`,
  });

  const tabValue =
    searchParams.get('tabvalue') ||
    (user?.user_admin_role?.toLowerCase() === 'accounts' ||
    user?.user_admin_role?.toLowerCase() === 'finance secretary'
      ? 'transaction'
      : user?.user_admin_role?.toLowerCase() === 'secretary' ||
        user?.user_admin_role?.toLowerCase() === 'super admin'
      ? 'basic'
      : 'ownerInfo');

  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [downloadCertificate, setDownloadCertificate] = useState([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (memberId) {
      setLoading(true);
      axios
        .get(`${url}/api/atab/user-member/get/a/member/for-admin/${memberId}`, {
          cancelToken: source.token,
        })
        .then((data) => {
          setCompanyInfo(data.data.data);
          setLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    }
    return () => {
      source.cancel();
    };
  }, [memberId, refresh]);

  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/atab/user-member/certificate/${memberId}?user=admin`
      );
      if (data.success) {
        setDownloadCertificate(data.data);
      }
    })();
  }, [memberId, refresh]);

  let content: ReactNode;

  if (loading) {
    content = <SuspenseLoader />;
  }
  if (tabValue === 'basic' && companyInfo) {
    content = (
      <BasicInformation setRefresh={setRefresh} memberInfo={companyInfo} />
    );
  }

  if (tabValue === 'ownerInfo' && companyInfo) {
    content = <CompanyOwnerInfo />;
  }
  if (tabValue === 'companyInfo') {
    content = <CompanyInformation />;
  }
  if (tabValue === 'tradeLicense') {
    content = <TradeLicenseInformation />;
  }
  if (tabValue === 'tinbin') {
    content = <TinBinInformation />;
  }
  if (tabValue === 'civilAviation') {
    content = <CivilAviationInfo />;
  }
  if (tabValue === 'ownerPassport') {
    content = <OwnerPassport />;
  }

  if (tabValue === 'document') {
    content = <CompanyDocumentInfo />;
  }

  // if (tabValue === 'representative') {
  //   content = <RepresentativeInfo />;
  // }

  if (tabValue === 'business') {
    content = <BusinessLicenseInfo />;
  }
  // if (tabValue === 'association') {
  //   content = <OtherBusinessLicense />;
  // }

  if (tabValue === 'proposer') {
    content = <ProposerInformation />;
  }
  if (tabValue === 'transaction') {
    content = (
      <CompanyInvoices companyName={companyInfo?.user_member_company_name} />
    );
  }

  return (
    <>
      {!loading ? (
        <>
          <Helmet>
            <title>HAAB - Company info</title>
          </Helmet>
          <Box>
            <Box mt={3}>
              <Container sx={{ mb: 2 }} maxWidth='xl'>
                <Typography variant='h2' component='h2' gutterBottom>
                  {` ${companyInfo?.user_member_company_name
                    ?.toLowerCase()
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}`}
                </Typography>
                <Typography variant='h6' pt={1}>
                  {`ID: ${
                    companyInfo?.user_member_hajj_license
                      ? companyInfo?.user_member_hajj_license
                      : 'N/A'
                  }`}
                </Typography>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Grid item sx={{ my: 2 }}>
                    <Grid
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      {companyInfo.user_member_account_status === '"active"' ? (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                          <Button
                            size='small'
                            variant='contained'
                            sx={{
                              mt: 1,
                              mr: 2,
                              fontSize: 12,
                              fontWeight: 400,
                              lineHeight: '15px',
                              textAlign: 'left',
                            }}
                            onClick={() => setOpen(true)}
                            startIcon={<CreateIcon />}
                          >
                            Create <br />
                            Certificate
                          </Button>
                          <Box>
                            {downloadCertificate?.map((sCertificate) => (
                              <Certificate
                                key={sCertificate.c_id}
                                sCertificate={sCertificate}
                                setRefresh={setRefresh}
                              />
                            ))}
                          </Box>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {/* {companyInfo.is_applied ? ( */}
                      <Tooltip title='Print information form'>
                        <IconButton onClick={handlePrint}>
                          <PrintIcon
                            sx={{
                              cursor: 'pointer',
                              color: theme.colors.primary.main,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      {/* ) : (
                        ""
                      )} */}
                    </Grid>
                  </Grid>

                  <Grid item>
                    {companyInfo?.user_member_representative_photo ? (
                      <img
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '50%',
                          border: '5px solid #dedede',
                        }}
                        src={`${imgUrl}/${companyInfo?.user_member_representative_photo}`}
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Grid>
                </Grid>
              </Container>
              <Container maxWidth='xl' sx={{ pb: 6 }}>
                <Card>
                  <CardContent>
                    <BottomNavigation
                      sx={{
                        py: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                        height: 'auto',
                      }}
                      showLabels
                      value={tabValue}
                      onChange={(_event, newValue) => {
                        setSearchParams({
                          tabvalue: newValue,
                        });
                      }}
                    >
                      {(user?.user_admin_role?.toLowerCase() === 'secretary' ||
                        user?.user_admin_role?.toLowerCase() ===
                          'super admin') && (
                        <BottomNavigationAction
                          value='basic'
                          label='Basic '
                          icon={<InfoOutlinedIcon />}
                        />
                      )}
                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='ownerInfo'
                            label="Owner's Information"
                            icon={<PeopleIcon />}
                          />
                        )}
                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='companyInfo'
                            label='Company Information'
                            icon={<BusinessIcon />}
                          />
                        )}
                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='tradeLicense'
                            label='Trade License '
                            icon={<BusinessCenterOutlinedIcon />}
                          />
                        )}
                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='tinbin'
                            label='TIN/BIN'
                            icon={<BusinessCenterOutlinedIcon />}
                          />
                        )}
                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='civilAviation'
                            label='Civil Aviation Certificate'
                            icon={<DescriptionOutlinedIcon />}
                          />
                        )}
                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='ownerPassport'
                            label='Owner Passport'
                            icon={<DescriptionOutlinedIcon />}
                          />
                        )}

                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='document'
                            label='Company Document'
                            icon={<CorporateFareOutlinedIcon />}
                          />
                        )}

                      {/* {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='representative'
                            label='Representative '
                            icon={<DescriptionOutlinedIcon />}
                          />
                        )} */}

                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='business'
                            label='Business license '
                            icon={<DescriptionOutlinedIcon />}
                          />
                        )}
                      {/* {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='association'
                            label='Other business license'
                            icon={<InventoryTwoToneIcon />}
                          />
                        )} */}

                      {user?.user_admin_role?.toLowerCase() !== 'accounts' &&
                        user?.user_admin_role?.toLowerCase() !==
                          'finance secretary' && (
                          <BottomNavigationAction
                            value='proposer'
                            label='Agency Proposer'
                            icon={<InfoOutlinedIcon />}
                          />
                        )}

                      {(companyInfo &&
                        Number(companyInfo?.ck_accounts) === 1 &&
                        user?.user_admin_role?.toLowerCase() === 'accounts') ||
                      (Number(companyInfo?.ck_finance) === 1 &&
                        user?.user_admin_role?.toLowerCase() ===
                          'finance secretary') ||
                      user?.user_admin_role?.toLowerCase() === 'super admin' ||
                      user?.user_admin_role?.toLowerCase() === 'president' ||
                      user?.user_admin_role?.toLowerCase() === 'secretary' ? (
                        <BottomNavigationAction
                          value='transaction'
                          label='Money receipt'
                          icon={<InfoOutlinedIcon />}
                        />
                      ) : null}
                    </BottomNavigation>

                    {((user?.user_admin_role?.toLowerCase() !== 'super admin' &&
                      user.user_admin_role.toLowerCase() ===
                        'computer operator' &&
                      tabValue === 'proposer') ||
                      user.user_admin_role.toLowerCase() === 'accounts' ||
                      (user.user_admin_role.toLowerCase() === 'president' &&
                        tabValue === 'transaction') ||
                      (user.user_admin_role.toLowerCase() === 'secretary' &&
                        tabValue === 'transaction') ||
                      (user.user_admin_role.toLowerCase() ===
                        'secretary general' &&
                        tabValue === 'proposer') ||
                      user.user_admin_role.toLowerCase() ===
                        'finance secretary') && (
                      <Grid item textAlign={'end'} mb={1}>
                        <Button
                          onClick={() => setOpenForward(true)}
                          sx={{ mt: { xs: 2, md: 0, marginRight: 2 } }}
                          variant='contained'
                          startIcon={
                            <ModeEditOutlineOutlinedIcon fontSize='small' />
                          }
                          size='small'
                        >
                          Assign to
                        </Button>

                        {user?.user_admin_role?.toLowerCase() !==
                          'super admin' && (
                          <Button
                            onClick={() => setOpenNoteModal(true)}
                            sx={{ mt: { xs: 2, md: 0, marginRight: 2 } }}
                            variant='contained'
                            startIcon={<VisibilityIcon fontSize='small' />}
                            size='small'
                          >
                            View note
                          </Button>
                        )}

                        <ModalsWrapper
                          setShowModal={setOpenNoteModal}
                          showModal={openNoteModal}
                          modalData={{ title: 'View all note' }}
                          fullWidth={true}
                          maxWidth='md'
                        >
                          <ViewAllNotes />
                        </ModalsWrapper>
                        <ModalsWrapper
                          setShowModal={setOpenForward}
                          showModal={openForwardModal}
                          modalData={{ title: 'Update member information' }}
                        >
                          <RoleUpdateComponent
                            memberInfo={companyInfo}
                            setShowModal={setOpenForward}
                          />
                        </ModalsWrapper>
                      </Grid>
                    )}

                    {content}
                  </CardContent>
                </Card>
              </Container>
            </Box>
          </Box>
        </>
      ) : (
        <GlobalLoader />
      )}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: 'Create member certificate' }}
      >
        <CreateCertificateModal setOpen={setOpen} setRefresh={setRefresh} />
      </ModalsWrapper>

      <Box display={'none'}>
        <PrintInformationForm componentRef={componentRef} memberId={memberId} />
      </Box>
    </>
  );
};

export default NewMemberList;
