import { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import { IMemberInfo } from 'src/Types/module/member/memberTypes';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Toaster from 'src/Toaster/Toaster';
import { useParams } from 'react-router-dom';
import { HttpClient } from 'src/Helpers/http/http';
import moment from 'moment';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

type IProps = {
  memberInfo: IMemberInfo;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: any;
};

const UpdateBasicInfo = ({ memberInfo, setShowModal, setRefresh }: IProps) => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const [memberData, setMemberData] = useState<any>({});

  const { memberId } = useParams();
  const [loading, setLoading] = useState(false);
  const handleMemberUpdate = async () => {
    const removeData = removeUndefinedAndNull(memberData);
    const values = Object.keys(memberData);
    if (!values.length) {
      Toast.fire({
        icon: 'error',
        title: `You must change any field`,
      });
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const res: any = await HttpClient.put(
        `/api/atab/user-member/admin/update/member/${memberId}`,
        memberData
      );

      if (res.success) {
        Toast.fire({
          icon: 'success',
          title: 'Member successfully updated',
        });
        setRefresh((pre) => !pre);
        setLoading(false);
        setShowModal(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: `Update Failed ! \n ${err?.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={1} sx={{ width: 300 }}>
        {(memberInfo.ck_secretary &&
          user?.user_admin_role?.toLowerCase() === 'secretary') ||
        user?.user_admin_role?.toLowerCase() === 'super admin' ? (
          <Grid item xs={12} md={12}>
            <FormControl fullWidth size='small'>
              <InputLabel required id='demo-simple-select-label'>
                Status
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Status'
                required
                defaultValue={memberInfo.user_member_account_status}
                onChange={(e: any) =>
                  setMemberData({
                    ...memberData,
                    user_member_account_status: e.target.value,
                  })
                }
              >
                <MenuItem value='pending'>Pending</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='rejected'>Cancelled</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        {/* <Grid item xs={12} md={6}>
          <TextField
            size='small'
            id='outlined-textarea'
            label='ID card expiry date'
            InputLabelProps={{
              shrink: true,
            }}
            value={
              memberData.user_member_id_card_expiry_date
                ? memberData.user_member_id_card_expiry_date
                : moment(memberInfo.user_member_id_card_expiry_date).format(
                    'YYYY-MM-DD'
                  )
            }
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_id_card_expiry_date: e.target.value,
              })
            }
            fullWidth
            type='date'
          />
        </Grid> */}
        {/* {!memberInfo.user_member_membership_payment_date && (
          <Grid item xs={12} md={4}>
            <TextField
              size='small'
              id='outlined-textarea'
              label='Membership payment date'
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={
                memberInfo.user_member_membership_payment_date || ''
              }
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_membership_payment_date: e.target.value,
                })
              }
              fullWidth
              type='date'
            />
          </Grid>
        )} */}

        {/* {!memberInfo.user_member_membership_payment_expiry_date && (
          <Grid item xs={12} md={4}>
            <TextField
              size='small'
              id='outlined-textarea'
              label='Membership payment expiry date'
              InputLabelProps={{
                shrink: true,
              }}
              value={
                memberData.user_member_membership_payment_expiry_date
                  ? memberData.user_member_membership_payment_expiry_date
                  : moment(
                      memberInfo.user_member_membership_payment_expiry_date
                    ).format('YYYY-MM-DD')
              }
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_membership_payment_expiry_date: e.target.value,
                })
              }
              fullWidth
              type='date'
            />
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={4}>
          <TextField
            size='small'
            id='outlined-textarea'
            label='ID card issue date'
            InputLabelProps={{
              shrink: true,
            }}
            value={
              memberData.user_member_id_card_issue_date
                ? memberData.user_member_id_card_issue_date
                : moment(memberInfo.user_member_id_card_issue_date).format(
                    'YYYY-MM-DD'
                  )
            }
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_id_card_issue_date: e.target.value,
              })
            }
            fullWidth
            type='date'
          />
        </Grid> */}

        {/* <Grid item xs={12} md={4}>
          <TextField
            size='small'
            id='outlined-textarea'
            label='Membership Expiration Date'
            InputLabelProps={{
              shrink: true,
            }}
    
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_membership_expiry_date: e.target.value,
              })
            }
            fullWidth
            type='date'
          />
        </Grid> */}

        {/* {memberData.user_member_account_status === 'rejected' ||
        memberData.user_member_account_status === 'blocked' ? (
          <Grid item xs={12} md={8}>
            <TextField
              size='small'
              rows={4}
              id='outlined-textarea'
              label='Penalty reason'
              placeholder='ID card number'
              defaultValue={memberInfo.user_member_reject_reason || ''}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_reject_reason: e.target.value,
                })
              }
              multiline
              fullWidth
              type='text'
            />
          </Grid>
        ) : (
          ''
        )} */}
      </Grid>

      <Box mt={3} textAlign='right'>
        {loading ? (
          <Button
            size='small'
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            loading..
          </Button>
        ) : (
          <Button
            size='small'
            onClick={() => handleMemberUpdate()}
            variant='contained'
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateBasicInfo;
