import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, Select } from "@mui/material";
import CommonTexField from "src/common/CommonTexField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
import { CommonDatePicker } from "src/common/CommonDatePicker";

const CreateChequeRegisterEntry = () => {
  const { register, handleSubmit, watch } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (inputs: any) => {
    // setIsLoading(true);
    console.log(inputs);
  };

  const bank = watch("bank");
  return (
    <Box sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Bank</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={bank}
                label="Bank"
                {...register("bank")}
              >
                <MenuItem value={"None"}>None</MenuItem>
                <MenuItem value={"Savings"}>Savings</MenuItem>
                <MenuItem value={"Current"}>Current</MenuItem>
                <MenuItem value={"STD"}>STD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Account Name"}
              register={register}
              name={"account_name"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Branch Name"}
              register={register}
              name={"branch-name"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonDatePicker label={"Book Issue Date"} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Account Number"}
              register={register}
              name={"account_number"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Cheque Prefix"}
              register={register}
              name={"cheque_prefix"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Starting Number"}
              register={register}
              name={"starting_number"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Total Cheques"}
              register={register}
              name={"total_cheques"}
              type={"number"}
            />
          </Grid>
        </Grid>
        <Box textAlign={"end"}>
          {isLoading ? (
            <LoadingButton
              loading={isLoading}
              fullWidth
              color="secondary"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant="contained"
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              size="small"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateChequeRegisterEntry;
