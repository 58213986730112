import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SelectManualOrGroupWiseEmail from './SelectManualOrGroupWiseEmail';
import GroupWiseEmail from './GroupWiseEmail';
import PostDataApis from 'src/common/FetchData/PostDataApis';
import CommSelectFieldSearchmemberlistbyLicenseAndname from 'src/common/CommSelectFieldSearchmemberlistbyLicenseAndname';
import Toaster from 'src/Toaster/Toaster';
const Toast = Toaster();
interface IProps {
  setOpen: (value: boolean) => void;
  getData: () => Promise<void>;
}
const CreateEmail = ({ setOpen, getData }: IProps) => {
  const { postData, success, loading } = PostDataApis('/api/haab/bulk-email');
  const [inputData, setInputData] = useState({
    subject: '',
    attachment: '',
    body: '',
    member_id: '',
  });
  const [meetingType, setMeetingType] = useState('');
  const [membersId, setMembersId] = useState<string[]>([]);
  const [groups_id, setGroupsId] = useState('');
  const [isActive, setIsActive] = useState('manual');

  const onSubmit = async () => {
    const formData = new FormData();
    if (inputData.attachment) {
      formData.append('attachment', inputData?.attachment);
    }
    formData.append('subject', inputData.subject);
    formData.append('body', inputData.body);

    if (!inputData.subject || !inputData.body) {
      return Toast.fire({
        icon: 'error',
        title: 'Please fillup all fields',
      });
    }
    if (isActive !== 'group_wise') {
      if (!meetingType) {
        return Toast.fire({
          icon: 'error',
          title: 'Please fillup all fields',
        });
      } else {
        if (meetingType === 'all') {
          formData.append('type', meetingType);
          postData(formData);
        } else if (meetingType === 'specific') {
          formData.append('member_ids', JSON.stringify(membersId));
          formData.append('type', meetingType);
          postData(formData);
        }
      }
    } else {
      formData.append('group_id', JSON.stringify(groups_id));
      postData(formData);
    }
  };
  useEffect(() => {
    if (success) {
      getData();
      setOpen(false);
    }
  }, [success]);
  return (
    <Box sx={{ padding: 2, paddingBottom: 0 }}>
      <Grid spacing={2}>
        <Grid item xs={12} md={12}>
          <SelectManualOrGroupWiseEmail
            setIsActive={setIsActive}
            isActive={isActive}
          />
          {isActive === 'manual' ? (
            <>
              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>
                  Email type
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={meetingType}
                  label=' Meeting type'
                  onChange={(e) => setMeetingType(e.target.value)}
                  required
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'specific'}>Specific</MenuItem>
                </Select>
              </FormControl>

              {meetingType === 'specific' && (
                <Grid xs={12} md={12} lg={12} item mt={2}>
                  <CommSelectFieldSearchmemberlistbyLicenseAndname
                    multiple
                    seTMemberId={setMembersId}
                    placeholder={'Type License or company name'}
                  />
                </Grid>
              )}
            </>
          ) : (
            <GroupWiseEmail setGroupsId={setGroupsId} />
          )}
        </Grid>

        <Grid item xs={12} md={12} mt={2}>
          <TextField
            size='small'
            id='outlined-basic'
            placeholder='Enter Email Subject'
            label='Enter Email Subject'
            required
            onChange={(e) =>
              setInputData({ ...inputData, subject: e.target.value })
            }
            variant='outlined'
            type='text'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <TextField
            size='small'
            id='outlined-basic'
            placeholder='Enter Email body'
            label='Enter Email body'
            required
            multiline
            rows={5}
            onChange={(e) =>
              setInputData({ ...inputData, body: e.target.value })
            }
            variant='outlined'
            type='text'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <TextField
            size='small'
            id='outlined-basic'
            placeholder='Upload email attachment'
            label='Upload email attachment'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: any) =>
              setInputData({ ...inputData, attachment: e.target.files[0] })
            }
            variant='outlined'
            type='file'
            fullWidth
          />
        </Grid>
      </Grid>
      {loading ? (
        <Button
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          size='small'
        >
          Loading ...
        </Button>
      ) : (
        <Button
          onClick={onSubmit}
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
          size='small'
        >
          Create Notice
        </Button>
      )}
    </Box>
  );
};

export default CreateEmail;
