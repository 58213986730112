import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import EditGroup from './EditGroup';
import { Container } from '@mui/system';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import CreateGroup from './CreateGroup';
import VisibilityIcon from '@mui/icons-material/Delete';
import DeleteDataApi from 'src/common/FetchData/DeleteDataApi';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import PopoverComponent from 'src/common/PopoverComponent';

type IGroupList = {
  id: number;
  name: string;
  total_members: number;
};
const GroupList = () => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<{
    page: string | number;
    limit: string | number;
  }>({
    page: 1,
    limit: 30,
  });
  const { response, loading, getData, total } = GetDataApi<IGroupList[]>(
    `/api/member/group?limit=${filter.limit}&page=${filter.page}`,
    true
  );
  const { deleteData, success } = DeleteDataApi(`/api/member/group`);

  useEffect(() => {
    getData();
  }, [filter]);
  useEffect(() => {
    if (success) {
      getData();
    }
  }, [success]);
  return (
    <Box>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <Button
                      onClick={() => setOpen(true)}
                      variant='contained'
                      size='small'
                    >
                      Create Group
                    </Button>
                  </Box>
                }
                title='Group List'
              />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                      <TableCell>Total Member</TableCell>

                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : response?.length ? (
                      <>
                        {response?.map((e) => (
                          <TableRow>
                            <TableCell>{e.name}</TableCell>
                            <TableCell>
                              <Box sx={{ ml: 5 }}>{e.total_members}</Box>{' '}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '60px',
                                  alignContent: 'center',
                                }}
                              >
                                <EditGroup
                                  id={e.id}
                                  name={e.name}
                                  setRefresh={getData}
                                  setOpen={setOpen}
                                />
                                <PopoverComponent
                                  popOverTitle='Are you sure to delete this?'
                                  handleConfirm={() => deleteData(e.id)}
                                >
                                  <IconButton
                                    sx={{
                                      color: 'red',
                                    }}
                                    color='inherit'
                                    size='small'
                                    disabled={loading}
                                  >
                                    <VisibilityIcon sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </PopoverComponent>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total as number}
                  onPageChange={(_event: any, newPage: number) =>
                    setFilter({ ...filter, page: newPage })
                  }
                  onRowsPerPageChange={(event) =>
                    setFilter({ ...filter, limit: event.target.value })
                  }
                  page={Number(filter.page) && Number(filter.page)}
                  rowsPerPage={Number(filter.limit)}
                  rowsPerPageOptions={[30, 50, 75, 100]}
                />
              </Box>
              <ModalsWrapper
                setShowModal={setOpen}
                showModal={open}
                modalData={{ title: 'Create Notice Group' }}
                fullWidth
                maxWidth='sm'
              >
                <CreateGroup setOpen={setOpen} setRefresh={getData} />
              </ModalsWrapper>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default GroupList;
