import { useEffect, useState } from "react";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";

import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IInputValue {
  notice_title: string;
  batch_id: any;
  batch_notice_file: any;
}

interface ISelectOption {
  training_batch_id: number;
  training_batch_name: string;
  training_batch_status: string;
  training_batch_course_name: string;
  training_teacher_name: string;
  training_batch_total_student: number;
}

interface IProps {
  handleClose: Function;
  setAllBatchNotice: Function;
}
const CreateNotice = ({ handleClose, setAllBatchNotice }: IProps) => {
  const [values, setValues] = useState<IInputValue>({
    notice_title: "",
    batch_id: 0,
    batch_notice_file: "",
  });
  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingbtn, setLoadingBtn] = useState<boolean>(false);
  const [batchName, setBatchName] = useState<string>("");
  const { user } = useAuthContext();
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${url}/api/training/batch/get/all/or/status/all`
        );
        if (data.success) {
          setSelectOptions(data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (!values.batch_id) {
      Toaster().fire({
        icon: "error",
        title: "You must select a batch",
      });
      return;
    }

    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    try {
      setLoadingBtn(true);
      const res = await axios.post(
        `${url}/api/training/batch/create/notice?user_admin_id=${user.user_admin_id}`,
        formData
      );
      if (res.status === 200) {
        setAllBatchNotice((prev) => [
          {
            training_batch_notice_date: new Date().toISOString(),
            training_batch_notice_id: res.data.data.training_batch_notice_id,
            training_batch_notice_title: values.notice_title,
            training_batch_name: batchName,
          },
          ...prev,
        ]);
        setLoadingBtn(false);
        Toaster().fire({
          icon: "success",
          title: "Batch created successfully!",
        });
        handleClose();
      }
    } catch (error) {
      Toaster().fire({
        icon: "fail",
        title: "Something Went to wrong!",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create Batch Notice</title>
      </Helmet>

      <Box onSubmit={onSubmit} component="form" sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item>
            <TextField
              required
              id="name"
              value={values.notice_title}
              label="Notice Title"
              onChange={(newValue) => {
                setValues({
                  ...values,
                  notice_title: newValue.target.value,
                });
              }}
              type="text"
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Autocomplete
              options={selectOptions}
              getOptionLabel={(option: ISelectOption) =>
                option.training_batch_name
              }
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.training_batch_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Batch"
                  variant="outlined"
                />
              )}
              onChange={(_event, value: ISelectOption) => {
                setValues({
                  ...values,
                  batch_id: value.training_batch_id,
                });
                setBatchName(value.training_batch_name);
              }}
            />
          </Grid>
          <Grid xs={12} md={12} item>
            <TextField
              margin="normal"
              required
              id="outlined-required"
              label="Choose Notice File"
              InputLabelProps={{ shrink: true }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValues({
                  ...values,
                  batch_notice_file: e.target.files[0],
                })
              }
              autoComplete="file"
              type="file"
            />
          </Grid>
        </Grid>
        {loadingbtn ? (
          <Button
            disabled
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Creating...
          </Button>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Create Notice
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreateNotice;
