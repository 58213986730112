import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ICompanyOwnerInfo } from 'src/Types/module/member/CompanyInformation';

const MultiplePhoneNumbersModal = ({
  setInfo,
  info,
  companyData,
}: {
  setInfo: any;
  info: any;
  companyData: ICompanyOwnerInfo;
}) => {
  const [phoneNumbers, setPhoneNumbers] = useState(
    companyData?.phones?.length
      ? companyData?.phones?.map((pho, index) => ({
          id: index + 1,
          number: pho,
        }))
      : [{ id: 1, number: '' }]
  );
  const [open, setOpen] = useState(false);
  // Handle opening/closing the modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle adding a new phone number field
  const handleAddPhoneNumber = () => {
    setPhoneNumbers([
      ...phoneNumbers,
      { id: phoneNumbers?.length + 1, number: '' },
    ]);
  };

  // Handle removing a phone number field
  const handleRemovePhoneNumber = (index: any) => {
    const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    setPhoneNumbers(updatedPhoneNumbers);
  };

  // Handle input change for each phone number field
  const handlePhoneNumberChange = (index: any, value: any) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index].number = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };
  useEffect(() => {
    if (phoneNumbers.length && phoneNumbers[0].number) {
      const phones = phoneNumbers.map((pho) => pho.number);
      console.log(phones);
      setInfo({ ...info, phones: phones });
    } else {
      setInfo({ ...info, phones: '' });
    }
  }, [phoneNumbers]);
  useEffect(() => {
    if (phoneNumbers?.length === companyData?.phones?.length) {
      setInfo({ ...info, phones: '' });
    }
  }, []);
  return (
    <div>
      {/* Button to open modal */}
      <Button
        size='medium'
        variant='outlined'
        color='primary'
        onClick={handleClickOpen}
        style={{ width: '100%', padding: 5 }}
      >
        Agency Phone Number
      </Button>

      {/* Modal (Dialog) */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>Agency Phone Number</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} padding={1}>
            {phoneNumbers?.map((phone, index) => (
              <Grid item xs={12} key={phone.id}>
                <TextField
                  size='small'
                  label={`Phone Number ${index + 1}`}
                  value={phone.number}
                  onChange={(e) =>
                    handlePhoneNumberChange(index, e.target.value)
                  }
                  variant='outlined'
                  fullWidth
                  InputProps={{
                    endAdornment: phoneNumbers.length > 1 && (
                      <IconButton
                        onClick={() => handleRemovePhoneNumber(index)}
                        aria-label='delete'
                      >
                        <DeleteIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {/* Button to add more phone number fields */}
          <Button
            size='small'
            variant='contained'
            color='primary'
            onClick={handleAddPhoneNumber}
            startIcon={<AddIcon />}
            style={{ marginTop: '16px' }}
          >
            Add
          </Button>
        </DialogContent>

        {/* Modal actions (Close/Save) */}
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleClose} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MultiplePhoneNumbersModal;
