import React from 'react';
import {
  CardHeader,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
type IProps = {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setSearchCompanyVal: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  leftSideTitle: string;
  selectValue?: 'completed';
};
const CommonSearchfilterForAppliHeader = ({
  setSearchCompanyVal,
  setStatus,
  status,
  leftSideTitle,
  selectValue,
}: IProps) => {
  return (
    <CardHeader
      title={
        <Typography sx={{ fontWeight: 700, lineHeight: 1.34 }}>
          {leftSideTitle}
        </Typography>
      }
      action={
        <Box display={'flex'} gap={1}>
          <TextField
            size='small'
            id='outlined-basic'
            label='Search By Agency & License'
            variant='outlined'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchCompanyVal(e.target.value)
            }
          />
          <Stack direction='row' alignItems='center' spacing={1}>
            <FormControl
              sx={{ width: '150px', position: 'relative' }}
              size='small'
            >
              <InputLabel id='demo-simple-select-label'>Status</InputLabel>
              <Select
                size='small'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label='dsfa'
                placeholder='dsfa'
              >
                <MenuItem value={selectValue || 'approved'}>
                  {selectValue ? 'Completed' : 'Approved'}
                </MenuItem>
                <MenuItem value='pending'>Pending</MenuItem>
                <MenuItem value='rejected'>Rejected</MenuItem>
              </Select>
              {status && (
                <Box
                  onClick={() => setStatus('')}
                  sx={{
                    position: 'absolute',
                    top: '55%',
                    right: 0,
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                  }}
                >
                  <HighlightOffIcon fontSize='small' />
                </Box>
              )}
            </FormControl>
          </Stack>
        </Box>
      }
    />
  );
};

export default CommonSearchfilterForAppliHeader;
