import { Button } from '@mui/material';
import Excel from 'exceljs';
import { IAuditAndTrail } from 'src/Types/module/auditAndTrail/auditAndTrail';
import FileSaver from 'file-saver';
import moment from 'moment';

const AuditAndTrailExcelGenerator = ({ getDownloadData }: any) => {
  const workbook = new Excel.Workbook();

  const downloadAuditExcelReport = async () => {
    const data = await getDownloadData();
    const sheet = workbook.addWorksheet('audit_trail_report');
    sheet
      .addRow(['Admin Name', 'Details', 'Date'])
      .eachCell((cell) => (cell.font = { bold: true }));
    data?.data?.map(
      ({
        user_admin_name,
        audit_trail_details,
        audit_trail_created_at,
      }: IAuditAndTrail) =>
        sheet.addRow([
          user_admin_name,
          audit_trail_details,
          moment(audit_trail_created_at).format('DD/MM/YYYY, h:mm:ss a'),
        ])
    );
    const genarateExcelFile = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([genarateExcelFile]), 'audit_trail_report.xlsx');
  };

  return (
    <Button onClick={downloadAuditExcelReport} variant='outlined' size='small'>
      Download Excel
    </Button>
  );
};

export default AuditAndTrailExcelGenerator;
