import { TableCell, TableRow, Typography } from '@mui/material';
import { IAuditAndTrail } from 'src/Types/module/auditAndTrail/auditAndTrail';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface IProps {
  sAudit: IAuditAndTrail;
}

const AuditAndTrailTableRow = ({ sAudit }: IProps) => {
  const {
    audit_trail_admin_id,
    audit_trail_id,
    user_admin_name,
    user_admin_photo,
    audit_trail_created_at,
    audit_trail_details,
  }: IAuditAndTrail = sAudit;

  return (
    <>
      <TableRow hover>
        <TableCell align='center'>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {audit_trail_id}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <img
            width={30}
            height={30}
            style={{
              borderRadius: '50%',
              // marginRight: "10px",
            }}
            src={
              user_admin_photo
                ? `${imgUrl}/${user_admin_photo}`
                : '/static/images/avatars/avatar2.png'
            }
            alt=''
          />
        </TableCell>
        <TableCell align='center'>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            <Link to={`/admin-list/single-admin/${audit_trail_admin_id}`}>
              {user_admin_name}
            </Link>
          </Typography>
        </TableCell>

        <TableCell
          align='center'
          sx={{ width: '600px', wordBreak: 'break-all' }}
        >
          <Typography variant='body1' color='text.primary' gutterBottom>
            {audit_trail_details}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(audit_trail_created_at).fromNow()}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AuditAndTrailTableRow;
