import { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import ShowComplainMessage from './ShowComplainMessage';
import { socket } from 'src/Helpers/utils/socket';

export type IReplyies = {
  id: number;
  complain_id: number;
  content: string;
  created_by: number;
  type: string;
  created_at: string;
  name: string;
  photo: string;
  file: string;
};
type IsingleSupportConversations = {
  id: number;
  member_id: number;
  content: string;
  created_by: string;
  created_at: string;
  member_name: string;
  replies: IReplyies[];
};
function ShowAllComplain({
  selectedComplain,
  setSelectedComplain,
}: {
  selectedComplain: {
    complain_id: string;
    member_id: string;
  };
  setSelectedComplain: React.Dispatch<
    React.SetStateAction<{
      complain_id: string;
      member_id: string;
    }>
  >;
}) {
  const datas = useAuthContext();
  const [singleSuppportConversations, setSingleSupportConversations] =
    useState<IsingleSupportConversations>();
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    const complain_loc_id = localStorage.getItem('complain_id');
    setSelectedComplain({ complain_id: complain_loc_id, member_id: '' });
    return () => localStorage.removeItem('complain_id');
  }, []);
  useEffect(() => {
    setLoading(true);
    (async function () {
      if (selectedComplain.complain_id) {
        //when reload data and fetch data with selected data wise
        localStorage.setItem('complain_id', selectedComplain.complain_id);
      }

      try {
        const { data } = await axios.get(
          `${url}/api/atab/complain/${selectedComplain.complain_id}`
        );

        if (data.success) {
          setSingleSupportConversations(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [selectedComplain.complain_id, datas]);

  useEffect(() => {
    socket.on('complain_reply_admin', (data) => {
      //its not work. backend issue
      if (data) {
        setSingleSupportConversations((prevdata: any) => {
          const existingReply = prevdata?.replies?.find(
            (reply: IReplyies) => reply.id === data.id
          );

          if (!existingReply) {
            return {
              ...prevdata,
              replies: [...prevdata?.replies, data],
            };
          }

          return prevdata;
        });
      }
    });
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('complain_reply_admin');
    };
  }, []);
  const setRef = useCallback((node: any) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);
  return (
    <>
      <Box height='100%' p={3}>
        {loading ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <CircularProgress size={64} disableShrink thickness={3} />
          </Box>
        ) : (
          <>
            {singleSuppportConversations?.replies?.length ? (
              singleSuppportConversations?.replies?.map(
                (conversation: IReplyies, index) => {
                  const lastMessage =
                    singleSuppportConversations?.replies?.length - 1 === index;
                  return (
                    <Box
                      key={conversation.id}
                      ref={lastMessage ? setRef : null}
                    >
                      <ShowComplainMessage conversation={conversation} />
                    </Box>
                  );
                }
              )
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant='h4'>No Complain Found</Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ShowAllComplain;
