import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PdfAndImageView from 'src/common/PdfAndImageView';
import { url } from 'src/Helpers/utils/Constant';

type GetAllComplain = {
  id: number;
  member_id: number;
  content: string;
  created_by: string;
  created_at: string;
  member_name: string;
  file: string;
};
type IProps = {
  setSelectedComplain: React.Dispatch<
    React.SetStateAction<{
      complain_id: string;
      member_id: string;
    }>
  >;
  selectedComplain: {
    complain_id: string;
    member_id: string;
  };
  setIsCompalinList: React.Dispatch<React.SetStateAction<number>>;
};
const ComplainCardDetails = ({
  selectedComplain,
  setSelectedComplain,
  setIsCompalinList,
}: IProps) => {
  const { memberId } = useParams();
  const [data, setData] = useState<GetAllComplain[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  useEffect(() => {
    const getDownloadData = async () => {
      const token = localStorage.getItem('haab_admin');

      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/atab/complain?member_id=${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (data.success) {
          setData(data.data);
          setLoading(false);
          setIsCompalinList(data?.data?.length);
        } else {
          setData([]);
        }
      } catch (err) {
        setData([]);
      }
    };

    getDownloadData();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '20px',
      }}
    >
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} width={300}>
          <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
      ) : (
        <>
          {data?.map((item, index) => (
            <Card
              key={index}
              sx={{
                width: 300,
                background:
                  Number(selectedComplain.complain_id) === item.id
                    ? '#FFE4C9'
                    : '',
              }}
              onClick={() => {
                setSelectedComplain({
                  ...selectedComplain,
                  complain_id: String(item.id),
                  member_id: String(item.member_id),
                });
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Complain Details ({`${item.created_by}`}){' '}
                    <span
                      style={{
                        color: 'gray',
                        display: 'flex',
                        alignContent: 'baseline',
                        justifyContent: 'space-between',
                        columnGap: 2,
                      }}
                    >
                      {moment(item.created_at).fromNow()}{' '}
                      {item.file && <PdfAndImageView file={item.file} />}
                    </span>{' '}
                    <Divider />
                  </Typography>
                  <Typography variant='body2' color='text.primary'>
                    {item.content} <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
          width: '100%',
        }}
      >
        {data?.length === 0 && <h4>No Complain Found</h4>}
      </Box>
    </Box>
  );
};

export default ComplainCardDetails;
