import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import GlobalLoader from "../../../../components/SuspenseLoader/GlobalLoader";
import { ITrainingInvoiceItem } from "src/Utils/type";
import TrainingInvoiceUpdateForm from "./TrainingInvoiceUpdateForm";

function SingleTrainingInvoiceInfo() {
  const theme = useTheme();
  const { traineeInvoiceItemId } = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [trainingInvoiceInfo, setTrainingInvoiceInfo] = useState<
    ITrainingInvoiceItem[]
  >([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/training/payment/get/single/paymentable/item/${traineeInvoiceItemId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setTrainingInvoiceInfo(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [open, traineeInvoiceItemId]);

  return !loading ? (
    <>
      <Typography
        sx={{ textDecoration: "underline" }}
        mt={8}
        mb={3}
        color="text."
        align="center"
        variant="h4"
      >
        Single Invoice Item Information
      </Typography>
      <Container>
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center">Item name</TableCell>
                  <TableCell align="center">Item BDT amount</TableCell>
                  <TableCell align="center">Item USD amount</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingInvoiceInfo?.map((details: ITrainingInvoiceItem) => (
                  <TableRow key={details.training_paymentable_item_id}>
                    <TableCell>
                      {details.training_paymentable_item_id}
                    </TableCell>
                    <TableCell align="center">
                      {details.training_paymentable_item_name}
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {details.training_paymentable_item_bdt_amount}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <AttachMoneyIcon sx={{ fontSize: "15px" }} />
                      {details.training_paymentable_item_usd_amount}
                    </TableCell>
                    <TableCell align="center">
                      <EditIcon
                        onClick={handleOpen}
                        sx={{ cursor: "pointer" }}
                      />
                    </TableCell>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title">
                        <Typography variant="h4">
                          Update Invoice Item
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          <TrainingInvoiceUpdateForm
                            details={details}
                            handleClose={handleClose}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </>
  ) : (
    <GlobalLoader />
  );
}

export default SingleTrainingInvoiceInfo;
