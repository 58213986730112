import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { url } from 'src/Helpers/utils/Constant';
import { ISingleDuplicateCertificateApplication } from 'src/Types/module/applications/applications';
import { getStatusLabel } from 'src/Utils/Combined';
import UpdateModal from './UpdateModal';
import PdfAndImageView from 'src/common/PdfAndImageView';

const SingleDuplicateCertificateApplication = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [application, setApplication] =
    useState<ISingleDuplicateCertificateApplication | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const data = await axios.get(
          `${url}/api/member/duplicate-certificate-application/${id}`
        );
        if (data.data.success) {
          setApplication(data.data.data);
        }
      } catch (err) {}
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>HAAB - Duplicate certificate application</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Card>
          <CardHeader
            title='Duplicate certificate application'
            action={
              <Button onClick={() => setShowModal(true)} variant='outlined'>
                Update
              </Button>
            }
          />
          <Divider />
          <CardContent>
            {application ? (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Application Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {moment(
                          application.duplicate_certificate_application_created_at
                        ).format('MMM Do YY')}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Company Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {application.user_member_company_name}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>Status</Typography>
                      </TableCell>
                      <TableCell>
                        {getStatusLabel(
                          application.duplicate_certificate_application_status
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Civil Aviation
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.duplicate_certificate_application_civil_aviation
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Trade License
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.duplicate_certificate_application_trade_license
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Membership Card
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.duplicate_certificate_application_memberhsip_card
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Tax Return
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.duplicate_certificate_application_tax_return
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Previous Certificate
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.duplicate_certificate_application_previous_certificate
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Duplicate Certificate Application
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={
                            application.duplicate_certificate_application_application_file
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                No Data found
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
      {application && (
        <UpdateModal
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          application={application}
          setApplication={setApplication}
        />
      )}
    </>
  );
};

export default SingleDuplicateCertificateApplication;
