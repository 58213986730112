import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { url } from "../../../../../Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { ISingleClassWithAttendance } from "src/Types/module/batch/allTypeOfBatch";
import moment from "moment";
import SuspenseLoader from "src/components/SuspenseLoader";

type IProps = {
  singleClass: ISingleClassWithAttendance;
  handleClose: Function;
  setReload: Dispatch<SetStateAction<boolean>>;
};

const AddAttendance = ({ singleClass, handleClose, setReload }: IProps) => {
  const [classStudents, setClassStudents] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await axios.get(
          `${url}/api/training/trainee/get/all/by/batch/${singleClass.training_batch_id}`
        );
        if (data.data.success) {
          setClassStudents(data.data.data);
          setAttendance(
            data.data.data.map((item) => {
              return {
                class_attendance_class: singleClass.training_class_id,
                class_attendance_student: item.user_training_trainee_id,
                class_attendance: false,
              };
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${url}/api/training/class/add/attendance`, {
        attendance,
      });

      if (res.data.success) {
        setLoading(false);
        handleClose();
        Toaster().fire({
          icon: "success",
          title: `Attendance added successfully`,
        });
        setReload(true);
      } else {
        Toaster().fire({
          icon: "error",
          title: `${res.data.message}`,
        });
        setLoading(false);
      }
    } catch (err) {
      Toaster().fire({
        icon: "error",
        title: `Attendance already taken`,
      });
      setLoading(false);
    }
  };

  const handleAttendance = ({ student_id, attend }) => {
    setAttendance(
      attendance.map((item) => {
        if (item.class_attendance_student === student_id) {
          return {
            class_attendance_class: singleClass.training_class_id,
            class_attendance_student: student_id,
            class_attendance: attend === "attend" ? true : false,
          };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Add Calss Attendance</title>
      </Helmet>

      <Card>
        <CardHeader
          title={`Add Attendance ${moment
            .tz(singleClass?.training_class_date, "Asia/Dhaka")
            .format("DD-MM-YYYY")}`}
          action={
            loading ? (
              <Button disabled variant="outlined">
                Wait...
              </Button>
            ) : (
              <Button onClick={handleSubmit} variant="outlined">
                Submit
              </Button>
            )
          }
        />
        <Divider />
        <CardContent>
          {loading ? (
            <SuspenseLoader />
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Id</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Attendance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classStudents.map((row) => (
                    <TableRow
                      key={row.user_training_trainee_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.user_training_trainee_id}
                      </TableCell>
                      <TableCell align="right">
                        {row.user_training_trainee_name}
                      </TableCell>
                      <TableCell align="right">
                        <FormControl variant="filled" size="small">
                          <InputLabel id="demo-simple-select-filled-label">
                            Attendance
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            defaultValue="absent"
                            onChange={(e) =>
                              handleAttendance({
                                attend: e.target.value,
                                student_id: row.user_training_trainee_id,
                              })
                            }
                          >
                            <MenuItem value="attend">Attend</MenuItem>
                            <MenuItem value="absent">Absent</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default AddAttendance;
