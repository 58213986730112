import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ITransactionHistory } from '../type/budgetType';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import CommonDateRange from 'src/common/CommonDateRange';
import { TableLoader } from 'src/Utils/TableLoader';
import getStatusChip from 'src/common/getStatusChip';
import TableNoDataMessage from 'src/common/TableNoDataMessage';

const TransactionHistory = () => {
  const [filter, setFilter] = useState<{
    page: string | number;
    from_date: string;
    to_date: string;
    limit: string | number;
  }>({
    from_date: '',
    to_date: '',
    page: 1,
    limit: 25,
  });

  console.log(filter);
  const { response, setRefetch, loading, total, getData } = GetDataApi<
    ITransactionHistory[]
  >(
    `/api/atab/new-report/member-ledger?from_date=${filter.from_date}&to_date=${filter.to_date}&limit=${filter.limit}&page=${filter.page}`,
    true
  );
  const handleSubmit = () => {
    getData();
  };
  useEffect(() => {
    if (filter.limit && filter.page) {
      getData();
    }
  }, [filter.limit, filter.page]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  useEffect(() => {
    const debit = response
      ?.filter((transaction) => transaction.type === 'OUT')
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);

    const credit = response
      ?.filter((transaction) => transaction.type === 'IN')
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);
    if (debit && credit) {
      setTotalDebit(debit);
      setTotalCredit(credit);
    }
  }, [response]);
  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}
              >
                Transaction History
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ textAlign: 'end' }}>
              <Grid container>
                <Grid item xs={8}>
                  <CommonDateRange
                    filter={filter}
                    setDateRange={setFilter}
                    showClearIcon
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Box>
                    <SyncAltIcon />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Button
                    sx={{ paddingY: '5px', width: '100%' }}
                    variant='outlined'
                    onClick={handleSubmit}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />

      <Box>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small' align='center'>
                  SL
                </TableCell>
                <TableCell align='center' size='small'>
                  Details
                </TableCell>
                <TableCell align='center' size='small'>
                  Date
                </TableCell>
                <TableCell align='center' size='small'>
                  Account Number
                </TableCell>
                <TableCell align='center' size='small'>
                  Voucher No
                </TableCell>
                <TableCell align='center' size='small'>
                  Transaction ID
                </TableCell>
                <TableCell align='center' size='small'>
                  Debit Amount
                </TableCell>
                <TableCell align='center' size='small'>
                  Credit Amount
                </TableCell>
                <TableCell align='center' size='small'>
                  Remaining Balance
                </TableCell>
                <TableCell align='center' size='small'>
                  Payment Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {response?.length ? (
                    <>
                      {response?.map((sPayment, index) => (
                        <TableRow
                          key={sPayment.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align='center' size='small'>
                            {index + 1}
                          </TableCell>
                          <TableCell width={300} align='center' size='small'>
                            {sPayment.details}
                          </TableCell>

                          <TableCell align='center' size='small'>
                            {moment(sPayment.ledger_date).format('DD-MMM-YYYY')}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {Number(sPayment.account_number)
                              ? sPayment.account_number
                              : ''}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {Number(sPayment.account_number)
                              ? ''
                              : sPayment.voucher_no}
                          </TableCell>

                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.trx_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.type === 'OUT' ? sPayment.amount : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.type === 'IN' ? sPayment.amount : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.type === 'OUT' ? sPayment.amount : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {getStatusChip(sPayment.status)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ background: '#FAF7F0' }}>
                        <TableCell colSpan={6} align='center'>
                          <Typography variant='h4' gutterBottom>
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell align='center'>{totalDebit}</TableCell>
                        <TableCell align='center'>{totalCredit}</TableCell>
                        <TableCell align='center'></TableCell>
                        <TableCell align='center'></TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2} display={'flex'} justifyContent={'start'}>
          <TablePagination
            component='div'
            count={total as number}
            onPageChange={(_event: any, newPage: number) =>
              setFilter({ ...filter, page: newPage + 1 })
            }
            onRowsPerPageChange={(event) =>
              setFilter({ ...filter, limit: event.target.value })
            }
            page={Number(filter.page) && Number(filter.page) - 1}
            rowsPerPage={Number(filter.limit)}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default TransactionHistory;
