import Tamplate from "./Template";

function TrainingInvoiceTemplate({ componentRef, singleInvoiceInfo }) {
  const toReturn = singleInvoiceInfo?.training_payment_invoice_grand_total ? (
    <div ref={componentRef}>
      <Tamplate singleInvoiceInfo={singleInvoiceInfo} />
      <hr
        style={{
          width: "60%",
          borderTop: "2px dashed black",
        }}
      />
      <Tamplate singleInvoiceInfo={singleInvoiceInfo} />
    </div>
  ) : null;

  return toReturn;
}

export default TrainingInvoiceTemplate;
