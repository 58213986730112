import React from 'react';
import GetDataApi from './FetchData/GetDataApi';
export type RoleName =
  | 'Admin'
  | 'Super Admin'
  | 'Member Admin'
  | 'Accounts'
  | 'support'
  | 'sochid'
  | 'President'
  | 'Secretary General'
  | 'Finance Secretary'
  | 'Secretary'
  | 'Computer Operator'
  | 'Chief Accounting';

const SpecificRoleFind = (roles_name: RoleName) => {
  const { response } = GetDataApi<
    {
      roles_id: number;
      roles_name: string;
    }[]
  >(`/api/atab/admin/get/roles`);
  if (response?.length && roles_name) {
    const foundRole = response.find((role) => role.roles_name === roles_name);

    if (foundRole) {
      const { roles_id, roles_name } = foundRole;
      return { roles_id, roles_name };
    }
  }

  return {};
};

export default SpecificRoleFind;
