import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import Toaster from '../../../../../Toaster/Toaster';
import { url } from '../../../../../Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

interface IProps {
  handleClose: Dispatch<SetStateAction<boolean>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}

const CreatePermission = ({ handleClose, setRefresh }: IProps) => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const [formFields, setFormFields] = useState([{ value: '' }]);

  const onSubmit = async (inputs: any) => {
    inputs.permissions_names = formFields.map((field) => field.value);
    inputs.permissions_created_by = user.user_admin_id;

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${url}/api/atab/admin/create/permissions`,
        inputs
      );

      if (data.success) {
        setRefresh(false);
        Toast.fire({
          icon: 'success',
          title: `Permission Created Successfully`,
        });
        reset();
        handleClose(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);

      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
    }
  };

  const handleDeleteField = (id: number) => {
    const filterData = formFields.filter((_s, index) => index !== id);
    setFormFields(filterData);
  };

  const addFormField = () => {
    setFormFields([...formFields, { value: '' }]);
  };

  const handleFieldChange = (index, event) => {
    const updatedFields = [...formFields];
    updatedFields[index].value = event.target.value;
    setFormFields(updatedFields);
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create Permission</title>
      </Helmet>

      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: 550,
          m: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField
              sx={{ mb: 2 }}
              margin='normal'
              required
              fullWidth
              id='name'
              label='Permission section'
              {...register('permissions_section')}
              type='text'
              autoFocus
              variant='outlined'
              size='small'
            />
          </Grid>

          <Grid item lg={6}>
            <form>
              {formFields.map((field, index) => (
                <Box>
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <TextField
                      type='text'
                      margin='normal'
                      fullWidth
                      id='name'
                      label='Permission Name'
                      autoFocus
                      variant='outlined'
                      value={field.value}
                      onChange={(event) => handleFieldChange(index, event)}
                      size='small'
                    />

                    {formFields.length > 1 ? (
                      <Tooltip title='Delete Field' arrow>
                        <IconButton
                          onClick={() => handleDeleteField(index)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter,
                            },
                            color: theme.palette.error.main,
                          }}
                          color='inherit'
                          size='small'
                        >
                          <DeleteTwoToneIcon fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              ))}

              <Button onClick={addFormField}>
                <AddIcon />
                Add more permission field
              </Button>
            </form>
          </Grid>
        </Grid>

        {!isLoading ? (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
            size='small'
          >
            Create Permission
          </Button>
        ) : (
          <LoadingButton
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Creating...
          </LoadingButton>
        )}
      </Box>
    </>
  );
};

export default CreatePermission;
