import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import EventInfoUpdate from "./EventInfoUpdate";
import { ISingleFairEvent } from "src/Utils/type";
import { imgUrl } from "src/Helpers/utils/Constant";
import { useNavigate } from "react-router-dom";

interface IProps {
  handleEditModeChange: Function;
  singleEvent: ISingleFairEvent;
}

const SingleFairEventInfo = ({ handleEditModeChange, singleEvent }: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Container>
        <Card>
          <CardHeader
            title={
              <Typography gutterBottom variant="h4">
                Single event information
              </Typography>
            }
            action={
              <Tooltip title="Edit">
                <IconButton onClick={handleOpen} sx={{ cursor: "pointer" }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <CardContent>
            <Box sx={{ mt: 2 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {" "}
                <Grid item lg={12} md={12}>
                  <img
                    width={250}
                    height={150}
                    style={{
                      borderRadius: "5px",
                      objectFit: "contain",
                    }}
                    src={
                      singleEvent.event_photo
                        ? `${imgUrl}/${singleEvent.event_photo}`
                        : "/static/images/avatars/avatar2.png"
                    }
                    alt=""
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event title :
                  </Typography>
                  {singleEvent?.event_title}
                </Grid>
                {singleEvent?.event_type && (
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Event type:
                    </Typography>
                    {singleEvent?.event_type}
                  </Grid>
                )}
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event date:
                  </Typography>
                  {moment(singleEvent?.event_date).format("LL")}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event venue:
                  </Typography>
                  {singleEvent?.event_venu}
                </Grid>
                {singleEvent?.event_date && (
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Event Created date:
                    </Typography>
                    {moment(singleEvent?.event_created_at).format("LL")}
                  </Grid>
                )}
                {/* <Grid item xs={2} sm={4} md={4}>
                  {moment(singleEvent., "HH:mm").format("LT")}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {moment(singleEvent.event_end_time, "HH:mm").format("LT")}
                </Grid> */}
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event total speaker:
                  </Typography>
                  {singleEvent?.event_total_speaker}
                </Grid>
                <Grid item lg={12}>
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Event description:
                    </Typography>
                    {singleEvent?.event_description}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle sx={{ p: 4 }} id="responsive-dialog-title">
            <Typography sx={{ mb: 3 }} variant="h4">
              Update Fair Event information
            </Typography>
            <Divider />
          </DialogTitle>

          <DialogContent>
            <EventInfoUpdate
              handleEditModeChange={handleEditModeChange}
              handleClose={handleClose}
              singleEvent={singleEvent}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default SingleFairEventInfo;
