import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Toaster from 'src/Toaster/Toaster';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import AddressSelect from '../../subModules/memberList/singleMember/AddressSelect/AddressSelect';

interface Props {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const OrganizationInfo = ({
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [date, setDate] = useState('');
  const [zone, setZone] = useState<number>(1);
  const [companyLogo, setCOmpanyLogo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [areaID, setAreaID] = useState<number>(0);

  const onSubmit = async (data: {
    zone: number;
    company_type: string;
    police_station: string;
    cellphone: string;
    phone: string;
    addressline1: string;
    postalcode: string;
    email: string;
    website: string;
    area_id: string;
    division_id: number;
    district_id: number;
    thana_id: number;
    date: string;
    stablished_date: string;
    company_logo: any;
  }) => {
    const { company_logo, ...all } = data;

    const formData = new FormData();
    formData.append('company_logo', data.company_logo[0]);
    Object.keys(all).forEach((key) => {
      formData.append(key, data[key]);
    });
    const result: IResponse = await HttpClient.post(
      `/api/atab/admin/member-company/member/company/${memberId}`,
      formData
    );

    if (result.success) {
      Toaster().fire({
        icon: 'success',
        title: result.message,
      });
      setRefresh(!refresh);
      setOpen(false);
      setIsLoading(false);
    } else {
      Toaster().fire({
        icon: 'error',
        title: result.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <Box mt={2}>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='demo-simple-select-label'>
                  Select zone
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={zone}
                  label='Select zone'
                  {...register('zone')}
                  onChange={(e: any) => setZone(e.target.value)}
                >
                  <MenuItem value={1}>Dhaka</MenuItem>
                  <MenuItem value={2}>Chittagong</MenuItem>
                  <MenuItem value={3}>Sylhet</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
              <label htmlFor=''>
                Organization type <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter organization type'
                required
                type='text'
                fullWidth
                {...register('company_type')}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=''>
                Organization established date{' '}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='date'
                type='date'
                required
                {...register('stablished_date')}
                onChange={(e) => setDate(e.target.value)}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=''>
                {' '}
                Which police station does the organization belong to?{' '}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter organization police station'
                type='text'
                required
                fullWidth
                {...register('police_station')}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box>
              <label htmlFor=''>
                Organization telephone number
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                placeholder='Organization telephone number'
                id='outlined-basic'
                variant='outlined'
                type='text'
                fullWidth
                {...register('cellphone')}
                required
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=''>
                Organization mobile number
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                {...register('phone', {
                  required: 'Organization mobile number is required',
                  pattern: {
                    value: /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/,
                    message: 'Invalid phone number',
                  },
                })}
                error={errors.phone && Boolean(errors.phone)}
                helperText={
                  errors.phone?.message ? `${errors.phone.message}` : ''
                }
                placeholder='Enter organization mobile number'
                type='number'
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor=''>
                Organization full address
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter organization full address'
                type='text'
                required
                fullWidth
                {...register('addressline1')}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor=''>
                Organization postal code
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter organization postal code'
                type='text'
                required
                fullWidth
                {...register('postalcode')}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box>
              <label htmlFor=''>
                Upload organization logo
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='file'
                fullWidth
                {...register('company_logo')}
                onChange={(e: any) => setCOmpanyLogo(e.target.files[0])}
                required
              />
            </Box>

            <Box mt={2}>
              <label htmlFor=''>
                Organization email address
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter organization email address'
                type='email'
                fullWidth
                {...register('email')}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=''> Organization website</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter organization website'
                type='text'
                fullWidth
                {...register('website')}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor=''>
                Enter organization area
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder=' organization area'
                type='text'
                required
                fullWidth
                {...register('area_id')}
              />
            </Box>
          </Box>
        </Grid>
        <AddressSelect setAreaID={setAreaID} register={register} />
      </Grid>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OrganizationInfo;
