import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Container,
  Grid,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { useSearchParams } from 'react-router-dom';
import EventTableRow from './EventTableRow';
import CreateEvent from './CreateEvent/CreateEvent';
import { IEvent } from 'src/Types/module/member/Event';

const EventTable = () => {
  const [allEvents, setAllEvents] = useState<IEvent[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'upcoming',
  });
  const status = searchParams.get('status');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${url}/api/member/event?status=${status}`
        );

        if (data.success) {
          setAllEvents(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [status]);

  const statusOptions = [
    {
      id: 'upcoming',
      name: 'Upcoming',
    },
    {
      id: 'ended',
      name: 'Ended',
    },

    {
      id: 'cancelled',
      name: 'Cancelled',
    },
  ];
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <FormControl sx={{ width: '150px' }} size='small'>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) =>
                        setSearchParams(`status=${e.target.value}`)
                      }
                      label='Status'
                      autoWidth
                    >
                      {statusOptions &&
                        statusOptions.map((statusOption) => (
                          <MenuItem
                            key={statusOption.id}
                            value={statusOption.id}
                          >
                            {statusOption.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={() => handleOpen()}
                    variant='contained'
                    size='small'
                  >
                    Create Event
                  </Button>
                </Box>
              }
              title='Event List'
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Creator Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : allEvents.length ? (
                    <>
                      {allEvents.map((sEvent) => {
                        return (
                          <EventTableRow
                            key={sEvent.member_event_id}
                            sEvent={sEvent}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={allEvents?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
            {/* Modal */}
            <Dialog
              fullScreen={fullScreen}
              open={open}
              maxWidth='md'
              onClose={handleClose}
              aria-labelledby='responsive-dialog-title'
            >
              <DialogContent>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 2 }}> Create Event</Typography>
                  <Divider />
                </Box>
                <CreateEvent
                  handleClose={handleClose}
                  setAllEvents={setAllEvents}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventTable;
