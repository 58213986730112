import { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Imembers } from 'src/Types/module/member/memberTypes';
import { useDebounce } from 'src/hooks/useDebounce';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/lab';
import ComplainTableRow from './ComplainTableRow';
import CreateComplain from './ViewComplain/CreateComplain';

type TDate = {
  startDate: Date | null;
  endDate: Date | null;
};
const ComplainMemberList = ({ status, title }) => {
  const [allMembers, setAllMembers] = useState<Imembers[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(25);
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');

  // Pagination
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });
  const page = parseInt(searchParams.get('page'));
  const [total, setTotal] = useState<number>(0);
  // Search by Time and Zone
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  const [date, setDate] = useState<TDate>({ startDate: null, endDate: null });

  const [zone, setZone] = useState<number>(0);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        if (!searchQuery) {
          let uri = `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?status=${status}&`;
          if (date.startDate && date.endDate) {
            uri += `from_date=${
              date.startDate.toISOString().split('T')[0]
            }&to_date=${date.endDate.toISOString().split('T')[0]}&`;
          }
          if (zone > 0) {
            uri += `zone=${zone}&`;
          }
          if (page >= 0) {
            uri += `skip=${limit * page}&limit=${limit}`;
          }
          const { data } = await axios.get(uri);

          if (data.success) {
            setAllMembers(data.data);
            setTotal(data.total);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          const { data } = await axios.get(
            `${url}/api/atab/user-member/search-member/by-company/${searchQuery}?status=${status}&zone=${zone}`
          );

          if (data.success) {
            setIsLoading(false);
            setAllMembers(data.data);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [searchQuery, page, limit, date.endDate, date.startDate, zone]);

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  From
                </Typography>

                <DatePicker
                  label='Start Date'
                  value={date.startDate}
                  onChange={(e: any) => e && setDate({ ...date, startDate: e })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label='End Date'
                  value={date.endDate}
                  onChange={(e: any) => e && setDate({ ...date, endDate: e })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <FormControl sx={{ width: '120px' }} size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Select zone
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={zone}
                    defaultValue={0}
                    label='Select zone'
                    onChange={(e: any) => setZone(e.target.value)}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>Dhaka</MenuItem>
                    <MenuItem value={2}>Chittagong</MenuItem>
                    <MenuItem value={3}>Sylhet</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignContent={'center'}
                  gap={2}
                >
                  <TextField
                    size='small'
                    id='outlined-basic'
                    label='Search Company'
                    variant='outlined'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchCompanyVal(e.target.value)
                    }
                  />
                </Box>
              }
              title={`${title} (${total})`}
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Representative name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell width={200}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : allMembers?.length ? (
                    <>
                      {allMembers?.map((allMembers: Imembers) => {
                        return (
                          <ComplainTableRow
                            key={allMembers.user_member_id}
                            allMembers={allMembers}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={(_event: any, newPage: number) =>
                  setSearchParams({ page: newPage.toString() })
                }
                onRowsPerPageChange={(event) =>
                  setLimit(parseInt(event.target.value))
                }
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ComplainMemberList;
