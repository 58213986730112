import { useState } from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import Toaster from 'src/Toaster/Toaster';
import { ICompanyTradeLicense } from 'src/Types/module/member/CompanyInformation';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';
import moment from 'moment';

interface IProps {
  tradeLicense: ICompanyTradeLicense;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}

const UpdateCompanyTradeLicense = ({
  tradeLicense,
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { register, handleSubmit } = useForm();
  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    if (date) {
      inputsData.company_trade_license_expiry_date = date;
    }

    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      setIsLoading(true);
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/license/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Enter trade license number '
                type='text'
                defaultValue={tradeLicense.company_trade_license}
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_trade_license')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='date'
                type='date'
                label='Trade license expiry date '
                defaultValue={moment(
                  tradeLicense.company_trade_license_expiry_date
                )?.format('YYYY-MM-DD')}
                onChange={(e) => setDate(e.target.value)}
                sx={{ width: '100%', pb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Upload trade license (pdf)'
                type='file'
                fullWidth
                inputProps={{
                  accept: 'application/pdf',
                }}
                sx={{ pb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('company_trade_license_file')}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyTradeLicense;
