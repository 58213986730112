import React from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { LoadingButton } from "@mui/lab";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useParams } from "react-router-dom";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

const UpdateBatch = ({
  statusValue,
  setStatusValue,
  setShowModal,
  showModal,
  loading,
  setLoading,
  batchDetails,
}: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleUpdate = () => {
    const updateInfo = {
      training_batch_id: id,
      status: statusValue,
    };

    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.put(
          `${url}/api/training/batch/update/single?user_admin_id=${user.user_admin_id}`,
          updateInfo
        );

        if (data.success) {
          setShowModal(false);
          Toaster().fire({ icon: "success", title: "Updated Successfully!" });
          setLoading(false);
        } else {
          Toaster().fire({ icon: "info", title: "Something went wrong" });
          setLoading(false);
        }
      } catch (err) {
        Toaster().fire({ icon: "info", title: "Something went wrong" });
        setLoading(false);
      }
    })();
  };
  const statusOptions = [
    {
      id: "running",
      name: "Running",
    },
    {
      id: "ended",
      name: "Ended",
    },
    {
      id: "cancelled",
      name: "Cancelled",
    },
  ];
  return (
    <ModalsWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      modalData={{ title: "Update Batch" }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ px: "50px", mb: "20px" }}
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="center"
          spacing={{ xs: 2 }}
        >
          <Grid xs={12} item>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                defaultValue={
                  statusValue
                    ? statusValue
                    : batchDetails?.training_batch_status
                }
                onChange={(e) => setStatusValue(e.target.value)}
                label="Status"
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            {!loading ? (
              <Button
                onClick={handleUpdate}
                variant="contained"
                disableElevation
                fullWidth
              >
                Update Batch
              </Button>
            ) : (
              <LoadingButton
                disabled
                loading
                loadingIndicator="Updating..."
                variant="contained"
              >
                Updating...
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </ModalsWrapper>
  );
};

export default UpdateBatch;
