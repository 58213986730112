import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import { TableLoader } from 'src/Utils/TableLoader';
import { ITrainingInvoiceList } from 'src/Utils/type';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableNotFound } from 'src/Utils/TableLoader';
import TrainingInvoiceTableRow from './TrainingInvoiceTableRow';
import CreateTrainingInvoice from './CreateTrainingInvoice';

function TrainingInvoice() {
  const [trainingInvoiceList, setTrainingInvoiceList] = useState<
    ITrainingInvoiceList[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [status, setStatus] = useState<string>('pending');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setIsLoading(true);

    if (!searchValue) {
      axios
        .get(`${url}/api/training/invoice/get/all/for-admin/status/${status}`, {
          cancelToken: source.token,
        })
        .then((data) => {
          setTrainingInvoiceList(data.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            setIsLoading(false);
          } else {
            // handle error
            setIsLoading(false);
          }
        });
      return () => {
        // cancel the request before component unmounts
        source.cancel();
      };
    } else {
      const timeoutId = setTimeout(() => {
        axios
          .get(
            `${url}/api/training/invoice/get/all/for-admin/status/trainee-name/all/${searchValue}`,
            {
              cancelToken: source.token,
            }
          )

          .then((data) => {
            setTrainingInvoiceList(data.data.data);
            setIsLoading(false);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              setIsLoading(false);
            } else {
              // handle error
              setIsLoading(false);
            }
          });
        return () => {
          // cancel the request before component unmounts
          source.cancel();
        };
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [status, searchValue]);

  const statusOptions = [
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'paid',
      name: 'Paid',
    },
    {
      id: 'cancelled',
      name: 'Cancelled',
    },
    {
      id: 'reported',
      name: 'Reported',
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedInvoice = applyPagination(trainingInvoiceList, page, limit);

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display='flex' justifyContent='space-between' gap={2}>
                  <TextField
                    id='outlined-basic'
                    label='Trainee name'
                    variant='outlined'
                    onChange={(
                      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => setSearchValue(e.target.value)}
                    size='small'
                  />

                  <FormControl sx={{ width: '150px' }} size='small'>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label='Status'
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={() => handleOpen()}
                    variant='contained'
                    size='small'
                  >
                    Create invoice
                  </Button>
                </Box>
              }
              title='Training invoice List'
            />

            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Trainee name</TableCell>
                    <TableCell>Total amount</TableCell>
                    <TableCell>Grand total</TableCell>
                    <TableCell>Discount amount</TableCell>
                    <TableCell>Payment method</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Invoice issue date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {!isLoading ? (
                  paginatedInvoice.length ? (
                    <TableBody>
                      {paginatedInvoice.map(
                        (trainingInvoiceList: ITrainingInvoiceList) => {
                          return (
                            <TrainingInvoiceTableRow
                              key={
                                trainingInvoiceList.training_payment_invoice_id
                              }
                              trainingInvoiceList={trainingInvoiceList}
                            />
                          );
                        }
                      )}
                    </TableBody>
                  ) : (
                    <TableNotFound />
                  )
                ) : (
                  <TableLoader />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={trainingInvoiceList.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}> Create a training invoice</Typography>
            <Divider />
          </Box>
          <CreateTrainingInvoice
            handleClose={handleClose}
            setTrainingInvoiceList={setTrainingInvoiceList}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TrainingInvoice;
