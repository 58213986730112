import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
  Autocomplete,
  Grid,
} from '@mui/material';
import { muiStyles } from 'src/Utils/Styles';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { IFairPaymentableItem } from 'src/Types/module/invoice/invoiceTypes';
import { ItraineeList } from 'src/Types/module/courses/trainee';

interface IPropsMember {
  user_training_trainee_id: number;
  user_training_trainee_account_status: string;
  user_training_trainee_email: string;
  user_training_trainee_name: string;
  user_training_trainee_photo: string;
}
const useStyles = muiStyles;

interface IProps {
  handleClose: any;
  setTrainingInvoiceList: any;
}

const CreateTrainingInvoice = ({
  handleClose,
  setTrainingInvoiceList,
}: IProps) => {
  const { user } = useAuthContext();
  const [allPaymentableItem, setAllPaymentableItem] = useState<
    IFairPaymentableItem[]
  >([]);
  const [userMember, setUserMember] = useState<ItraineeList[]>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IPropsMember | null>(null);
  const [paymentableId, setPaymentableId] = useState<IFairPaymentableItem[]>(
    []
  );
  const [discount, setDiscount] = useState<number>(0);
  const Toast = Toaster();

  const resetFun = () => {
    setPaymentableId([]);
    setMembersId(null);
    setDiscount(0);
    setisMsgSent(false);
  };

  useEffect(() => {
    setLoading(true);
    try {
      if (user?.user_admin_id) {
        setLoading(true);
        const urls = [
          `${url}/api/training/payment/get/all/paymentable/item`,
          `${url}/api/training/trainee/get/all/or/status/all`,
        ];
        Promise.all(
          urls.map((url) => fetch(url).then((response) => response.json()))
        ).then((data) => {
          if (data.length) {
            setAllPaymentableItem(data[0].data);
            setUserMember(data[1].data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [user.user_admin_id]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!membersId.user_training_trainee_id) {
      Toast.fire({
        icon: 'error',
        title: `Select a trainee member`,
      });
    } else if (paymentableId.length === 0) {
      Toast.fire({
        icon: 'error',
        title: `Select a trainee invoice item`,
      });
    } else {
      const selectedMember = paymentableId.map(
        (member: IFairPaymentableItem) => {
          return member.training_paymentable_item_id;
        }
      );
      let body = {
        training_payment_invoice_user_member_id:
          membersId.user_training_trainee_id,
        training_payment_invoice_paymentable_items: selectedMember,
        training_payment_invoice_discount_amount: discount,
      };
      setisMsgSent(true);
      try {
        const { data } = await axios.post(
          `${url}/api/training/invoice/create/for-admin?user_admin_id=${user.user_admin_id}`,
          body
        );

        if (data.success) {
          setTrainingInvoiceList((prev) => [
            {
              training_payment_invoice_id:
                data.data.training_payment_invoice_id,
              training_payment_invoice_issue_date: new Date().toLocaleString(),
              training_payment_invoice_total_amount:
                data.data.training_payment_invoice_total_amount,
              training_payment_invoice_discount_amount: discount,
              training_payment_invoice_grand_total:
                data.data.training_payment_invoice_grand_total,
              training_payment_invoice_status: 'pending',
              training_payment_invoice_payment_method: '',
              user_training_trainee_id: membersId.user_training_trainee_id,
              user_training_trainee_name: membersId.user_training_trainee_name,
              user_training_trainee_photo:
                membersId.user_training_trainee_photo,
            },
            ...prev,
          ]);
          Toast.fire({
            icon: 'success',
            title: `Invoice Successfully created`,
          });
          resetFun();
          handleClose();
        } else {
          setPaymentableId([]);
          setisMsgSent(false);
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: err.response?.data?.message,
        });
        resetFun();
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>HAAB - Create training trainee invoice</title>
      </Helmet>

      <Box component='form' onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={6} item>
            <Autocomplete
              placeholder='Select trainee Member'
              value={membersId}
              onChange={(event: any, newValue: any) => {
                setMembersId(newValue);
              }}
              id='controllable-states-demo'
              options={userMember}
              getOptionLabel={(option: ItraineeList) =>
                option.user_training_trainee_name
              }
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant='filled'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label='Select trainee' />
              )}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              id='outlined-password-input'
              label='Discount'
              type='number'
              onChange={(e) => setDiscount(parseFloat(e.target.value))}
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              multiple
              id='tags-standard'
              value={paymentableId}
              onChange={(e, mainValue: any) => setPaymentableId(mainValue)}
              options={allPaymentableItem}
              getOptionLabel={(option: IFairPaymentableItem) =>
                option.training_paymentable_item_name +
                ' (' +
                option.training_paymentable_item_bdt_amount +
                'tk)'
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Select invoice items'
                  placeholder='More...'
                />
              )}
            />
          </Grid>
        </Grid>
        <Box textAlign='center'>
          {!isMSgsent ? (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
                ml: 2,
              }}
              size='small'
            >
              Create a invoice
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Creating...'
              variant='contained'
              disabled
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              size='small'
            >
              <span>Creating...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateTrainingInvoice;
