import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { ISingleAddressChangeApplication } from 'src/Types/module/applications/applications';
import { getStatusLabel } from 'src/Utils/Combined';
import UpdateModal from './UpdateModal';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { fetcherGet } from 'src/Utils/fetcher';
import PdfAndImageView from 'src/common/PdfAndImageView';

const SingleAddressApplication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [application, setApplication] =
    useState<ISingleAddressChangeApplication>(
      {} as ISingleAddressChangeApplication
    );
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        // const {data} = await axios.get(
        //   `${url}/api/member/address-change-application/${id}`
        // );
        const data = await fetcherGet(
          `/member/address-change-application/${id}`
        );
        if (data.success) {
          setApplication(data.data);
          setLoading(false);
        }
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        alert('No application found');
        navigate('/update-applications/address-change-application');
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>HAAB - Address change application</title>
      </Helmet>
      <Container>
        {loading ? (
          <GlobalLoader />
        ) : (
          <Card>
            <CardHeader
              title='Address change application'
              action={
                <Button
                  size='small'
                  onClick={() => setShowModal(true)}
                  variant='outlined'
                >
                  Update
                </Button>
              }
            />
            <Divider />
            <CardContent>
              {application ? (
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>
                            Application Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(
                            application.address_change_application_created_at
                          ).format('MMM Do YY')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>
                            Company Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {application.user_member_company_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Address</Typography>
                        </TableCell>
                        <TableCell>
                          {application?.address_change_application_addressline}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Division</Typography>
                        </TableCell>
                        <TableCell>{application?.division_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>District</Typography>
                        </TableCell>
                        <TableCell>{application?.district_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Area</Typography>
                        </TableCell>
                        <TableCell>{application?.area_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Thana</Typography>
                        </TableCell>
                        <TableCell>{application?.thana_name}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>Status</Typography>
                        </TableCell>
                        <TableCell>
                          {getStatusLabel(
                            application?.address_change_application_status
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>
                            Download Civil Aviation
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <PdfAndImageView
                            file={
                              application.address_change_application_civil_aviation
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>
                            Download Trade License
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <PdfAndImageView
                            file={
                              application.address_change_application_trade_license
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant='subtitle2'>
                            Download Hajj License
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <PdfAndImageView
                            file={
                              application.address_change_application_hajj_license
                            }
                          />
                        </TableCell>
                      </TableRow>

                      {application.address_change_application_reject_reason && (
                        <TableRow>
                          <TableCell>
                            <Typography variant='subtitle2'>Note</Typography>
                          </TableCell>
                          <TableCell>
                            {
                              application.address_change_application_reject_reason
                            }
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  No Data found
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
      </Container>
      {application && (
        <UpdateModal
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          application={application}
          setApplication={setApplication}
        />
      )}
    </>
  );
};

export default SingleAddressApplication;
