import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { url } from "src/Helpers/utils/Constant";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import EditIcon from "@mui/icons-material/Edit";
import { ISingleTrainingInvoice } from "src/Utils/type";
import ItemList from "./ItemList";
import SingleTrainingInvoiceUpdateForm from "src/content/modules/Training/TrainingInvoice/SingleTrainingInvoice/SingleTrainingInvoiceUpdateForm";
import { SimCardDownload } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import TrainingInvoiceTampleate from "./TrainingInvoiceTamplate";

const SingleTrainingInvoice = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [singleInvoiceInfo, setSingleInvoiceInfo] =
    useState<ISingleTrainingInvoice>({} as ISingleTrainingInvoice);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const { singleTraineeInvoice } = useParams();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/training/invoice/get/single/${singleTraineeInvoice}`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setSingleInvoiceInfo(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [reload, singleTraineeInvoice]);

  return (
    <div>
      <Helmet>
        <title>HAAB - Single trainee Invoice information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            <Container>
              <Card title="Single Trainee Invoice">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    textAlign="center"
                    gutterBottom
                    variant="h2"
                    component="div"
                  >
                    Single Training Invoice information
                  </Typography>
                  <Box>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={handleOpen}
                        sx={{ cursor: "pointer" }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Print Invoice">
                      <IconButton onClick={handlePrint}>
                        <SimCardDownload
                          sx={{
                            cursor: "pointer",
                            color: theme.colors.primary.main,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <CardContent>
                  <Box>
                    <Box sx={{ m: 3 }}>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Trainee name:
                          </Typography>
                          {singleInvoiceInfo?.user_training_trainee_name}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Trainee email:
                          </Typography>
                          {singleInvoiceInfo?.user_training_trainee_email}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Trainee phone:
                          </Typography>
                          {singleInvoiceInfo?.user_training_trainee_phone}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Trainee address:
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Total amount:
                          </Typography>
                          {
                            singleInvoiceInfo?.training_payment_invoice_total_amount
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Grand total amount:
                          </Typography>
                          {
                            singleInvoiceInfo?.training_payment_invoice_grand_total
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Discount amount:
                          </Typography>
                          {
                            singleInvoiceInfo?.training_payment_invoice_discount_amount
                          }
                        </Grid>
                        {singleInvoiceInfo.training_payment_invoice_remark && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              display="inline"
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Invoice remark:
                            </Typography>
                            {singleInvoiceInfo.training_payment_invoice_remark}
                          </Grid>
                        )}
                        {singleInvoiceInfo.training_payment_invoice_transaction_id && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              display="inline"
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Transaction ID:
                            </Typography>
                            {
                              singleInvoiceInfo.training_payment_invoice_transaction_id
                            }
                          </Grid>
                        )}
                        {singleInvoiceInfo.training_payment_invoice_transaction_date && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              display="inline"
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Transaction date:
                            </Typography>
                            {moment(
                              singleInvoiceInfo.training_payment_invoice_transaction_date
                            ).format("LL")}
                          </Grid>
                        )}
                        {singleInvoiceInfo.training_payment_invoice_issue_date && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              display="inline"
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Issue date:
                            </Typography>
                            {moment(
                              singleInvoiceInfo.training_payment_invoice_issue_date
                            ).format("LL")}
                          </Grid>
                        )}
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice status:
                          </Typography>
                          {getStatusLabel(
                            singleInvoiceInfo?.training_payment_invoice_status
                          )}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Payment method:
                          </Typography>
                          {
                            singleInvoiceInfo?.training_payment_invoice_payment_method
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Payment invoice ID:
                          </Typography>
                          {singleInvoiceInfo?.training_payment_invoice_id}
                        </Grid>
                        {singleInvoiceInfo.training_payment_invoice_bank_name && (
                          <>
                            {singleInvoiceInfo.training_payment_invoice_bank_name && (
                              <Grid item xs={2} sm={4} md={4}>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  display="inline"
                                  sx={{ pr: 1 }}
                                  gutterBottom
                                >
                                  Bank branch:
                                </Typography>
                                {
                                  singleInvoiceInfo?.training_payment_invoice_bank_name
                                }
                              </Grid>
                            )}
                            {singleInvoiceInfo.training_payment_invoice_bank_branch && (
                              <Grid item xs={2} sm={4} md={4}>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  display="inline"
                                  sx={{ pr: 1 }}
                                  gutterBottom
                                >
                                  Bank branch:
                                </Typography>
                                {
                                  singleInvoiceInfo?.training_payment_invoice_bank_branch
                                }
                              </Grid>
                            )}
                            {singleInvoiceInfo.training_payment_invoice_instrument && (
                              <Grid item xs={2} sm={4} md={4}>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  display="inline"
                                  sx={{ pr: 1 }}
                                  gutterBottom
                                >
                                  Instrument:
                                </Typography>
                                {
                                  singleInvoiceInfo?.training_payment_invoice_instrument
                                }
                              </Grid>
                            )}
                            {singleInvoiceInfo.training_payment_invoice_instrument_date && (
                              <Grid item xs={2} sm={4} md={4}>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  display="inline"
                                  sx={{ pr: 1 }}
                                  gutterBottom
                                >
                                  Instrument date:
                                </Typography>
                                {moment(
                                  singleInvoiceInfo.training_payment_invoice_instrument_date
                                ).format("LL")}
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Box>
                    <ItemList itemDetails={singleInvoiceInfo.invoiceItems} />
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle sx={{ py: 2 }} id="responsive-dialog-title">
                        <Typography variant="h4">
                          Update Training invoice
                        </Typography>
                      </DialogTitle>
                      <DialogContent sx={{ mt: 4 }}>
                        <SingleTrainingInvoiceUpdateForm
                          singleInvoiceDetails={singleInvoiceInfo}
                          handleEditModeChange={handleClose}
                          setReload={setReload}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
        <div className="invoice_footer_form">
          {singleInvoiceInfo && (
            <TrainingInvoiceTampleate
              singleInvoiceInfo={singleInvoiceInfo}
              componentRef={componentRef}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default SingleTrainingInvoice;
