import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  user_fair_member_id: number;
  user_fair_member_contact_name: string;
}

const CreateFairInvoiceByMember = () => {
  const { user } = useAuthContext();
  const [userMember, setUserMember] = useState<IProps[]>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isFetchLoading, setFetchLoading] = useState<Boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IProps>(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const Toast = Toaster();

  const handleTotalAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setTotalAmount(e.target.value);
    }
  };

  useEffect(() => {
    setFetchLoading(true);
    try {
      if (user?.user_admin_id) {
        axios
          .get(`${url}/api/fair/member/get/all/all`)
          .then(function (res) {
            if (res.data.success) {
              setUserMember(res.data.data);
              setFetchLoading(false);
            } else {
              setFetchLoading(false);
            }
          })
          .catch(function (err) {
            setUserMember([]);
            setFetchLoading(false);
          });
      } else {
        setFetchLoading(false);
      }
    } catch (error) {
      setFetchLoading(false);
    }
  }, [user.user_admin_id]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setisMsgSent(true);
    if (!membersId) {
      Toast.fire({
        icon: "error",
        title: `Select a member`,
      });
    } else {
      const body = {
        fair_member_id: membersId.user_fair_member_id,
        total_amount: totalAmount,
      };
      try {
        const { data } = await axios.post(
          `${url}/api/atab/fair-invoice/create/for-admin?user_admin_id=${user.user_admin_id}`,
          body
        );
        if (data.success) {
          Toast.fire({
            icon: "success",
            title: `Invoice Successfully created`,
          });
          setisMsgSent(false);
          setTotalAmount(null);
          setMembersId(null);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: `Something went to wrong`,
        });
        setisMsgSent(false);
        setTotalAmount(null);
      }
    }
    // setisMsgSent(false);
  };

  return (
    <div>
      {!isFetchLoading ? (
        <>
          <Helmet>
            <title>HAAB - Create a fair invoice</title>
          </Helmet>

          <Container sx={{ mt: 4 }} maxWidth="md">
            <Card>
              <CardHeader title="Create a fair invoice by member" />
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ p: 2 }}
                  className=""
                >
                  <Autocomplete
                    sx={{ padding: "12px" }}
                    placeholder="Select member"
                    value={membersId}
                    onChange={(event: any, newValue: any) => {
                      setMembersId(newValue);
                    }}
                    id="controllable-states-demo"
                    options={userMember}
                    getOptionLabel={(option) =>
                      option.user_fair_member_contact_name
                    }
                    // sx={{ width: 300 }}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          key={index}
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select member" />
                    )}
                  />
                  <Grid sx={{ padding: "12px" }} item xs={12} md={6} lg={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Total amount"
                      required
                      id="outlined-required"
                      onChange={handleTotalAmount}
                      type="number"
                    />
                  </Grid>
                  {!isMSgsent ? (
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                        ml: 2,
                      }}
                    >
                      Create a fair invoice
                    </Button>
                  ) : (
                    <LoadingButton
                      loading
                      loadingIndicator="Creating..."
                      fullWidth
                      variant="contained"
                      disabled
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                    >
                      <span>Creating...</span>
                    </LoadingButton>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Container>
        </>
      ) : (
        <GlobalLoader />
      )}
    </div>
  );
};

export default CreateFairInvoiceByMember;
