import { useState, useEffect } from "react";
import {
  Card,
  Container,
  CardContent,
  Divider,
  CardHeader,
  Button,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SuspenseLoader from "src/components/SuspenseLoader";
import SingleBatchNoticeDetails from "./SingleNoticeDetails";
import { ISingleBatchNoticeDetails } from "src/Types/module/batch/allTypeOfBatch";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";

const SingleBatchNotice = () => {
  const [notice, setNotice] = useState<ISingleBatchNoticeDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/batch/get/notice/single/${id}`;
        const { data } = await axios.get(uri);

        if (data.success) {
          setNotice(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>HAAB - Single Notice</title>
      </Helmet>
      {isLoading ? (
        <SuspenseLoader />
      ) : (
        <Container sx={{ mt: 4 }} maxWidth="lg">
          <Card>
            <CardHeader
              action={
                <Button size="small" variant="outlined">
                  <Link
                    to={`/training-batch/notice/batch/${notice?.training_batch_id}?batch=${notice.training_batch_name}`}
                  >
                    Go to Notice
                  </Link>
                </Button>
              }
              title={`${notice.training_batch_name} Notice`}
            />
            <Divider />
            <CardContent>
              {isLoading ? (
                <SuspenseLoader />
              ) : (
                <SingleBatchNoticeDetails notice={notice} />
              )}
            </CardContent>
          </Card>
        </Container>
      )}
    </Box>
  );
};

export default SingleBatchNotice;
