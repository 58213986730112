import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  CardContent,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import { url } from '../../../../Helpers/utils/Constant';
import Toaster from '../../../../Toaster/Toaster';
import axios from 'axios';

import { ISelectOptions } from 'src/Types/module/courses/trainee';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

interface IProps {
  handleClose: Function;
  setAllBatch: Function;
}

const CreateBatch = ({ handleClose, setAllBatch }: IProps) => {
  const [values, setValues] = useState<any>({
    training_batch_start_date: new Date().toISOString().split('T')[0],
    training_batch_name: '',
    training_batch_course_id: 0,
    training_batch_teacher_id: 0,
    training_batch_details: '',
    training_batch_students: [],
  });
  const [courseDetails, setCourseDetails] = useState<any>(null);
  const [teacherDetails, setteacherDetails] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions>({
    teachers: [],
    courses: [],
    students: [],
  });

  const { user } = useAuthContext();

  useEffect(() => {
    (async () => {
      try {
        const urls = [
          `${url}/api/training/teacher/get/all/or/status/active`,
          `${url}/api/training/course/get/approved`,
        ];
        Promise.all(
          urls.map((url) => fetch(url).then((response) => response.json()))
        ).then((data) => {
          if (data.length) {
            setSelectOptions({
              ...selectOptions,
              teachers: data[0].data,
              courses: data[1].data,
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (courseDetails?.atab_training_course_id) {
      (async () => {
        const data = await axios.get(
          `${url}/api/training/course/application?training_course_application_course_id=${courseDetails?.atab_training_course_id}&training_course_application_status=pending`
        );

        setSelectOptions({ ...selectOptions, students: data.data.data || [] });
      })();
    }
  }, [courseDetails]);

  const onSubmit = async (e) => {
    e.preventDefault();

    // if (values.training_batch_students.length < 12) {
    //   Toaster().fire({
    //     icon: 'error',
    //     title: 'You must select minimum 12 student',
    //   });
    //   return;
    // }

    const data = {
      ...values,
      training_batch_teacher_id: teacherDetails?.training_teacher_id,
      training_batch_course_id: courseDetails?.atab_training_course_id,
    };

    try {
      setLoading(true);

      const res = await axios.post(
        `${url}/api/training/batch/create/batch?user_admin_id=${user.user_admin_id}`,
        data
      );

      if (res.data.success) {
        setAllBatch((prev) => [
          {
            training_batch_id: res.data.data.training_batch_id,
            training_batch_name: values.training_batch_name,
            training_batch_course_name: courseDetails.atab_training_course_name,
            training_teacher_name: teacherDetails.training_teacher_name,
            training_batch_details: values.training_batch_details,
            training_batch_status: 'running',
            training_batch_total_student: values.training_batch_students.length,
            training_batch_teacher: 'Please refresh...',
          },
          ...prev,
        ]);
        setValues({
          training_batch_start_date: new Date().toISOString().split('T')[0],
          training_batch_name: '',
          training_batch_course_id: 0,
          training_batch_teacher_id: 0,
          training_batch_details: '',
          training_batch_students: [],
        });
        handleClose();
        Toaster().fire({
          icon: 'success',
          title: 'Batch created successfully!',
        });
        setLoading(false);
      } else {
        Toaster().fire({
          icon: 'error',
          title: res.data.message,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>HAAB - Create Batch</title>
      </Helmet>
      <DialogContent>
        <Box>
          <Typography sx={{ mb: 2 }}>Create Batch</Typography>
          <Divider></Divider>
        </Box>

        <CardContent>
          <Box
            onSubmit={onSubmit}
            component='form'
            sx={{ mt: 1 }}
            maxWidth={'sm'}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id='name'
                  value={values.training_batch_name}
                  label='Batch name'
                  onChange={(newValue) => {
                    setValues({
                      ...values,
                      training_batch_name: newValue.target.value,
                    });
                  }}
                  type='text'
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={selectOptions && selectOptions.courses}
                  getOptionLabel={(option) => option.atab_training_course_name}
                  renderOption={(props, option) => (
                    <Box component='li' {...props}>
                      {option.atab_training_course_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select a course'
                      variant='outlined'
                    />
                  )}
                  onChange={(_event, value) => {
                    setCourseDetails(value);
                  }}
                  value={courseDetails}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={selectOptions && selectOptions.teachers}
                  getOptionLabel={(option) => option.training_teacher_name}
                  renderOption={(props, option) => (
                    <Box component='li' {...props}>
                      {option.training_teacher_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select a teacher'
                      variant='outlined'
                    />
                  )}
                  onChange={(_event, value) => {
                    setteacherDetails(value);
                  }}
                />
              </Grid>
              {courseDetails?.atab_training_course_id ? (
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    options={selectOptions.students}
                    getOptionLabel={(option) =>
                      option.user_training_trainee_name
                    }
                    renderOption={(props, option) => (
                      <Box component='li' {...props}>
                        {option.user_training_trainee_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Select students'
                        variant='outlined'
                      />
                    )}
                    onChange={(_event, value) => {
                      setValues({
                        ...values,
                        training_batch_students: value.map((item) => {
                          return {
                            trainee_id:
                              item.training_course_application_trainee_id,
                            application_id: item.training_course_application_id,
                          };
                        }),
                      });
                    }}
                  />
                </Grid>
              ) : (
                ''
              )}
              <Grid item lg={12}>
                <TextField
                  required
                  rows={4}
                  label='Batch details'
                  placeholder='Enter some details about this batch here..'
                  multiline
                  onChange={(event) => {
                    setValues({
                      ...values,
                      training_batch_details: event.target.value,
                    });
                  }}
                  // rows={4}
                  // maxRows={6}
                  fullWidth
                />
              </Grid>
            </Grid>

            {loading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                disabled
                sx={{
                  mt: 3,
                  py: 1,
                }}
              >
                Creating...
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  py: 1,
                }}
              >
                Create Batch
              </Button>
            )}
          </Box>
        </CardContent>
      </DialogContent>
    </div>
  );
};

export default CreateBatch;
