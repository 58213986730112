import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useForm } from "react-hook-form";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { LoadingButton } from "@mui/lab";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

function MeetingUpdateForm({ singleInvoiceDetails, handleEditModeChange }) {
  const { register, handleSubmit } = useForm();
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const { user } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [timeValue, setTimeValue] = useState(
    dayjs(singleInvoiceDetails.member_meeting_time)
  );

  const handleDateChange = (event: any) => {
    setSelectedDate(event);
  };
  const handleChange = (newValue: any) => {
    setTimeValue(newValue);
  };

  const onSubmit = async (inputs: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    if (
      (user.user_admin_id &&
        singleInvoiceDetails.member_meeting_time &&
        singleInvoiceDetails.member_meeting_id,
      singleInvoiceDetails.member_meeting_date)
    ) {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        if (typeof inputs[key] === "object") {
          if (inputs[key][0]) {
            bodyFormData.append(key, inputs[key][0]);
          }
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }
      bodyFormData.append(
        "member_meeting_time",
        timeValue?.toISOString().slice(11, 19)
      );
      bodyFormData.append(
        "member_meeting_date",
        selectedDate.toISOString().slice(0, 10)
      );

      bodyFormData.append(
        "member_meeting_id",
        singleInvoiceDetails.member_meeting_id
      );

      try {
        const { data } = await axios.put(
          `${url}/api/member/meeting/update/for-admin?user_admin_id=${user.user_admin_id}`,
          bodyFormData
        );

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: `Updated Successfully`,
          });
          handleEditModeChange();
          setSuccess(false);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });

        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        "& .MuiTextField-root": { mx: 2, mt: 4, width: "35ch" },
      }}
      noValidate
      autoComplete='off'
    >
      <Grid
        container
        sx={{ paddingLeft: "40px" }}
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <TextField
          label='Member meeting title:'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails.member_meeting_title}
          variant='outlined'
          size='medium'
          type='text'
          {...register("member_meeting_title")}
        />
        <Grid item xs={4} sm={8} md={3.3}>
          <FormControl required margin='normal' fullWidth>
            <InputLabel required id='demo-simple-select-label'>
              Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Status'
              key={singleInvoiceDetails.member_meeting_status}
              defaultValue={singleInvoiceDetails.member_meeting_status}
              {...register("member_meeting_status")}
            >
              <MenuItem value='upcoming'>Upcoming</MenuItem>
              <MenuItem value='ended'>Ended</MenuItem>
              <MenuItem value='cancelled'>Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <TextField
          label='Member meeting place:'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails.member_meeting_place}
          variant='outlined'
          size='medium'
          type='text'
          {...register("member_meeting_place")}
        />

        <TextField
          id='filled-size-small'
          variant='outlined'
          type='file'
          label='Upload a meeting image'
          InputLabelProps={{
            shrink: true,
          }}
          {...register("member_meeting_img")}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label='Update meeting date'
            inputFormat='MM/DD/YYYY'
            value={selectedDate || singleInvoiceDetails.member_meeting_date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label='Update meeting time'
            value={timeValue}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          label='Member meeting remark:'
          id='filled-size-small'
          multiline
          rows={4}
          defaultValue={singleInvoiceDetails?.member_meeting_remark}
          variant='outlined'
          size='medium'
          type='text'
          {...register("member_meeting_remark")}
        />
        <TextField
          multiline
          rows={4}
          label='Member meeting details'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails.member_meeting_details}
          variant='outlined'
          size='medium'
          type='text'
          {...register("member_meeting_details")}
        />
      </Grid>
      {!isLoading ? (
        <Button
          type='submit'
          variant='contained'
          disableElevation
          sx={{
            margin: 2,
          }}
        >
          Update meeting
        </Button>
      ) : (
        <LoadingButton
          loading
          loadingIndicator='Updating...'
          variant='outlined'
        >
          Updating...
        </LoadingButton>
      )}
    </Box>
  );
}

export default MeetingUpdateForm;
