import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Toaster from 'src/Toaster/Toaster';
import { HttpClient } from 'src/Helpers/http/http';
import { IResponse } from 'src/Types/module/common/commonTypes';

interface IProps {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const OrganizatonOtherinput = ({
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { register, handleSubmit } = useForm();
  const [ownershipStatus, setOwnershipStatus] = useState<string>('');
  const [officeAgreement, setOfficeAgreement] = useState<string>('');
  const [organizationOfficePhoto, setOrganizationOfficePhoto] = useState<any>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append('ownership_status', ownershipStatus);
    formData.append('office_agreement', officeAgreement);

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });
    organizationOfficePhoto.forEach((img: string, index: number) => {
      const name = 'office_photographs' + (index + 1);
      formData.append(name, img);
    });

    try {
      setIsLoading(true);
      const res: IResponse = await HttpClient.post(
        `/api/atab/admin/member-company/member/documents/${memberId}`,
        formData
      );

      if (res.success) {
        Toaster().fire({
          icon: 'success',
          title: res.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setOrganizationOfficePhoto([]);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });

      setIsLoading(false);
    }
  };

  const handelOfficephotoRemove = (id: number) => {
    const restPhoto = organizationOfficePhoto.filter(
      (img: string, index: number) => index !== id
    );
    setOrganizationOfficePhoto(restPhoto);
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <FormControl sx={{ width: '100%', mt: 5 }} size='small'>
              <InputLabel id='demo-simple-select-label' required>
                Select ownership status
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Select ownership status'
                sx={{ borderRadius: '10px' }}
                value={ownershipStatus}
                required
                onChange={(e: any) => setOwnershipStatus(e.target.value)}
              >
                <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                <MenuItem value={'Incorporator'}>Incorporator</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {ownershipStatus === 'Partnership' && (
            <Box mt={2}>
              <Box>
                <label htmlFor=''>
                  Upload photocopy of deed of partnership
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  required
                  variant='outlined'
                  type='file'
                  fullWidth
                  {...register('company_partnership_deed_file')}
                />
              </Box>
            </Box>
          )}
          {ownershipStatus === 'Incorporator' && (
            <Box mt={2}>
              <label htmlFor=''>
                Upload incorporation certificate{' '}
                <span style={{ color: 'red' }}>*</span>
              </label>

              <TextField
                size='small'
                id='outlined-basic'
                required
                variant='outlined'
                type='file'
                fullWidth
                {...register('company_incorporation_certificate')}
              />
            </Box>
          )}
          {ownershipStatus === 'Incorporator' && (
            <Box mt={2}>
              <label htmlFor=''>
                Upload memorandum document{' '}
                <span style={{ color: 'red' }}>*</span>
              </label>

              <TextField
                size='small'
                id='outlined-basic'
                required
                variant='outlined'
                type='file'
                fullWidth
                {...register('company_memorandum_file')}
              />
            </Box>
          )}
          {/* office agreement */}
          <Box mt={4}>
            <FormControl sx={{ width: '100%' }} size='small'>
              <InputLabel id='demo-simple-select-label' required>
                Select office agreement
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label=' Select office agreement'
                sx={{ borderRadius: '10px' }}
                value={officeAgreement}
                required
                onChange={(e: any) => setOfficeAgreement(e.target.value)}
              >
                <MenuItem value={'Self premise'}>Self premise</MenuItem>
                <MenuItem value={'Rented premise'}>Rented premise</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {officeAgreement && (
            <Box mt={2}>
              <label htmlFor=''>
                {officeAgreement === 'self premise'
                  ? 'Upload deed of agreement of self premise office space'
                  : 'Upload deed of agreement of rented premise office space'}
              </label>

              <TextField
                size='small'
                id='outlined-basic'
                required
                variant='outlined'
                type='file'
                fullWidth
                {...register('company_agreement_file')}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor=''>
              {' '}
              Upload staff list on office pad with seal and signature of owner
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              {...register('company_staff_list_file')}
            />
          </Box>
          <Box mt={2}>
            <label htmlFor=''> Upload one staff certificate </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              {...register('company_one_staff_certificate')}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {organizationOfficePhoto?.map(
                (singleImg: string | Blob, index: number) => {
                  return (
                    <Box sx={{ position: 'relative' }} key={index}>
                      <img
                        src={URL.createObjectURL(singleImg as Blob)}
                        alt=''
                        width={100}
                        height={100}
                      />
                      <HighlightOffIcon
                        onClick={() => handelOfficephotoRemove(index)}
                        sx={{
                          position: 'absolute',
                          top: -4,
                          right: -4,
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  );
                }
              )}
            </Box>
            <Box>
              <label htmlFor=''>
                Upload internal &#38; external photographs of office
              </label>

              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='file'
                required
                fullWidth
                onChange={(e: any) =>
                  setOrganizationOfficePhoto([
                    ...organizationOfficePhoto,
                    e.target.files[0],
                  ])
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OrganizatonOtherinput;
