import BudgetListTable from '../component/BudgetListTable';
import BodyContainer from 'src/common/BodyContainer';

const BudgetManagement = () => {
  return (
    <BodyContainer>
      <BudgetListTable />
    </BodyContainer>
  );
};

export default BudgetManagement;
