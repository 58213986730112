import { useState } from 'react';
import { HttpClient } from 'src/Helpers/http/http';
import Toaster from 'src/Toaster/Toaster';
import { useGlobalCache } from './useGlobalCache';
const Toast = Toaster();

interface IResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

const PostDataApis = <T,>(url: string, cacheKey?: string) => {
  const { getCache, setCache, deleteCache, clearCache, responseCache } =
    useGlobalCache();
  console.log(responseCache, 'inside post');
  const [response, setResponse] = useState<T | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const postData = async (payload: any) => {
    setError(null);

    try {
      setLoading(true);
      const res: IResponse<T> = await HttpClient.post(url, payload);
      if (res?.success) {
        if (cacheKey) {
          deleteCache(cacheKey);
        }
        setSuccess(true);
        setResponse(res?.data);
        Toast.fire({
          icon: 'success',
          title: `Successfully created!`,
        });
      } else {
        setSuccess(false);
        setError('something went wrong');
        Toast.fire({
          icon: 'error',
          title: 'something went wrong',
        });
      }
    } catch (err: any) {
      setSuccess(false);
      setError(err?.response?.data?.message);
      Toast.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Something went wrong',
      });
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, postData, success };
};

export default PostDataApis;
