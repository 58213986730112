import { useEffect } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import PostDataApis from 'src/common/FetchData/PostDataApis';

interface Props {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
}
const AddOwnerPassport = ({ memberId, setRefresh, setOpen }: Props) => {
  const {
    postData,
    loading: isLoading,
    success,
  } = PostDataApis(
    `/api/atab/admin/member-company/member/owner-passport/${memberId}`
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    await postData(formData);
  };
  useEffect(() => {
    if (success) {
      setRefresh(true);
      setOpen(false);
    }
  }, [success]);
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <label htmlFor=''>
              Passport number <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              placeholder='passport number '
              fullWidth
              {...register('passport_number', {
                required: 'passport number is required',
              })}
              error={errors.passport_number && Boolean(errors.passport_number)}
              helperText={
                Boolean(errors?.passport_number?.message)
                  ? `${errors?.passport_number?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor=''>
              Issue date <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='date'
              required
              type='date'
              {...register('issue_date', {
                required: 'issue date is required',
              })}
              error={errors.issue_date && Boolean(errors.issue_date)}
              helperText={
                Boolean(errors?.issue_date?.message)
                  ? `${errors?.issue_date?.message}`
                  : ''
              }
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor=''>
              Expiry date<span style={{ color: 'red' }}>*</span>{' '}
            </label>
            <TextField
              size='small'
              id='date'
              required
              type='date'
              {...register('expiry_date', {
                required: 'expiry date is required',
              })}
              error={errors.expiry_date && Boolean(errors.expiry_date)}
              helperText={
                Boolean(errors?.expiry_date?.message)
                  ? `${errors?.expiry_date?.message}`
                  : ''
              }
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <label htmlFor=''>
              Owner's Passport (PDF) <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              {...register('passport_file', {
                required: 'owner passport is required',
              })}
              inputProps={{
                accept: 'application/pdf',
              }}
              error={errors.passport_file && Boolean(errors.passport_file)}
              helperText={
                Boolean(errors?.passport_file?.message)
                  ? `${errors?.passport_file?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 5, textAlign: 'end', mb: 1 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading...
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddOwnerPassport;
