import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, DialogActions, Divider, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type IProps = {
  children: React.ReactNode;
  showModal: boolean;
  setShowModal: any;
  modalData: {
    title: any;
  };
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
};

const ModalsWrapper = ({
  children,
  showModal,
  setShowModal,
  modalData,
  fullWidth,
  maxWidth,
}: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={maxWidth || 'md'}
        fullWidth={
          fullWidth || false
        } /* if fullWidth is true its width will be maxWidth wise */
        open={showModal}
        onClose={() => {
          return setShowModal(!showModal);
        }}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle>
          <Typography variant='h4' sx={{ mb: 2 }}>
            {modalData.title}
          </Typography>
          <Divider />
        </DialogTitle>
        {children}
        <DialogActions>
          <Button autoFocus onClick={() => setShowModal(false)} color='error'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalsWrapper;
