import { useEffect } from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import PostDataApis from 'src/common/FetchData/PostDataApis';

interface Props {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
}
const AddTinbin = ({ memberId, setRefresh, setOpen }: Props) => {
  const {
    postData,
    loading: isLoading,
    success,
  } = PostDataApis(
    `/api/atab/admin/member-company/member/tin-bin-certificate/${memberId}`
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    await postData(formData);
  };

  useEffect(() => {
    if (success) {
      setRefresh(true);
      setOpen(false);
    }
  }, [success]);
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <label htmlFor=''>TIN number</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter tin number'
                type='number'
                required
                fullWidth
                sx={{ pb: 2 }}
                {...register('tin_number')}
                error={errors.tin_number && Boolean(errors.tin_number)}
                helperText={
                  Boolean(errors?.tin_number?.message)
                    ? `${errors?.tin_number?.message}`
                    : ''
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <label htmlFor=''>TIN Year</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter tin year'
                type='number'
                required
                fullWidth
                sx={{ pb: 2 }}
                {...register('tin_year')}
                error={errors.tin_year && Boolean(errors.tin_year)}
                helperText={
                  Boolean(errors?.tin_year?.message)
                    ? `${errors?.tin_year?.message}`
                    : ''
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <label htmlFor=''>Upload TIN pdf</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='file'
                required
                fullWidth
                inputProps={{
                  accept: 'application/pdf',
                }}
                sx={{ pb: 2 }}
                {...register('tin_file')}
                error={errors.tin_file && Boolean(errors.tin_file)}
                helperText={
                  Boolean(errors?.tin_file?.message)
                    ? `${errors?.tin_file?.message}`
                    : ''
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <label htmlFor=''>Bin number</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter bin number'
                type='text'
                fullWidth
                sx={{ pb: 2 }}
                {...register('bin_number')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <label htmlFor=''>Bin Year</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                placeholder='Enter bin year'
                type='text'
                fullWidth
                sx={{ pb: 2 }}
                {...register('bin_year')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <label htmlFor=''>Upload BIN pdf</label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='file'
                fullWidth
                inputProps={{
                  accept: 'application/pdf',
                }}
                sx={{ pb: 2 }}
                {...register('bin_file')}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {
        <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Loading...
            </Button>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Save
            </Button>
          )}
        </Box>
      }
    </Box>
  );
};

export default AddTinbin;
