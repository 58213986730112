import { TextField } from '@mui/material';
import { useCallback, useState } from 'react';
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
const CommonSearchTextFieldWithDebounce = ({
  onSearch,
  label,
}: {
  onSearch: any;
  label?: string;
}) => {
  const [query, setQuery] = useState('');
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      onSearch(searchValue); // Call the parent handler with the search value
    }, 500), // Adjust debounce delay as needed
    [onSearch]
  );
  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  };
  return (
    <TextField
      sx={{ m: 0 }}
      label={label || 'Search by License'}
      variant='outlined'
      fullWidth
      value={query}
      onChange={handleChange}
      size='small'
      margin='normal'
    />
  );
};

export default CommonSearchTextFieldWithDebounce;
