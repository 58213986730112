import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
  ButtonGroup,
  Chip,
} from '@mui/material';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IBudgetForward } from '../type/budgetType';
interface ICommentType {
  admin_id: number;
  comments: string;
  created_at: string;
  id: number;
  member_id: number;
  role_id: number;
  self: true;
  signature: string;
  status: number;
  type: string;
  user_admin_name: string;
}

interface ISelfComments {
  id: number;
  comments: string;
  created_at: string;
  forward_to: string;
  signature: string;
}
type IProps = {
  response: IBudgetForward[];
};
const ViewAllForwardingNote = ({ response }: IProps) => {
  const { user } = useAuthContext();
  const [openForwardModal, setOpenForward] = useState(false);
  // State to manage the toggle between "Incoming Comments" and "Outgoing Comments"
  const [view, setView] = useState<'incoming' | 'outgoing'>('incoming');

  return (
    <>
      <Button
        sx={{ mt: { xs: 2, md: 0, marginRight: 2, zIndex: 20 } }}
        variant='contained'
        size='small'
        onClick={() => setOpenForward(true)}
        startIcon={<VisibilityIcon fontSize='small' />}
      >
        View note
      </Button>
      <ModalsWrapper
        setShowModal={setOpenForward}
        showModal={openForwardModal}
        modalData={{ title: 'View Note Details' }}
        maxWidth='md'
        fullWidth={true}
      >
        <Box sx={{ px: 2 }}>
          {' '}
          {/* ButtonGroup for toggling between Incoming and Outgoing Comments */}
          <ButtonGroup sx={{ marginBottom: 4 }}>
            <Button
              variant={view === 'incoming' ? 'contained' : 'outlined'}
              onClick={() => setView('incoming')}
            >
              Incoming Note
            </Button>
            <Button
              variant={view === 'outgoing' ? 'contained' : 'outlined'}
              onClick={() => setView('outgoing')}
            >
              Outgoing Note
            </Button>
          </ButtonGroup>
          {view === 'incoming' && (
            <TableContainer component={Paper}>
              <Typography
                variant='h6'
                fontWeight='bold'
                color='primary'
                sx={{ padding: 2 }}
              >
                Incoming Note
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold' sx={{ ml: 1 }}>
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Forward From</Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Forward To</Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Note</Typography>
                    </TableCell>

                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Signature</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {response
                    ?.filter(
                      (item) => item.forward_from !== user?.user_admin_id
                    )
                    ?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>
                            {moment(row.created_at).format(
                              'DD-MMM-YYYY hh:mm A'
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>
                            {row.forward_from_name}
                          </Typography>
                        </TableCell>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>
                            {row.forward_to_role}{' '}
                            {row.is_forward_me === 'true' ? (
                              <Chip
                                size='small'
                                label='me'
                                color='primary'
                                variant='outlined'
                              />
                            ) : (
                              <Chip
                                size='small'
                                label='others'
                                color='success'
                                variant='outlined'
                              />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>{row.note}</Typography>
                        </TableCell>

                        <TableCell padding='none'>
                          <img
                            src={`${imgUrl}/${row.forward_from_signature}`}
                            alt='Signature'
                            width={100}
                            height={50}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {view === 'outgoing' && (
            <TableContainer component={Paper}>
              <Typography
                variant='h6'
                fontWeight='bold'
                color='primary'
                sx={{ padding: 2 }}
              >
                Outgoing Note
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold' sx={{ ml: 1 }}>
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Forward From</Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Forward To</Typography>
                    </TableCell>
                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Note</Typography>
                    </TableCell>

                    <TableCell padding='none'>
                      <Typography fontWeight='bold'>Signature</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {response
                    ?.filter(
                      (item) => item.forward_from === user?.user_admin_id
                    )
                    ?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>
                            {moment(row.created_at).format(
                              'DD-MMM-YYYY hh:mm A'
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>
                            {row.forward_from_name}
                          </Typography>
                        </TableCell>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>
                            {row.forward_to_role}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell padding='none'>
                          <Typography sx={{ ml: 1 }}>{row.note}</Typography>
                        </TableCell>

                        <TableCell padding='none'>
                          <img
                            src={`${imgUrl}/${row.forward_from_signature}`}
                            alt='Signature'
                            width={100}
                            height={50}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </ModalsWrapper>
    </>
  );
};

export default ViewAllForwardingNote;
