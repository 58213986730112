import { Button, TableCell, TableRow } from '@mui/material';
import { IAccountType } from '../type/voucherType';
import getStatusChip from 'src/common/getStatusChip';
import { Box } from '@mui/system';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import DeleteDataApi from 'src/common/FetchData/DeleteDataApi';
import { useEffect } from 'react';
type IProps = {
  index: number;
  sInvoice: IAccountType;
  setEditData: React.Dispatch<React.SetStateAction<IAccountType>>;
  refetchData: () => Promise<void>;
  filter: {
    page: string | number;
    listType: string | number;
    limit: string | number;
  };
};

const AccountTable = ({
  sInvoice: invoiceData,
  index,
  setEditData,
  refetchData,
  filter,
}: IProps) => {
  const { success, deleteData } = DeleteDataApi('/api/atab/account');
  const handleDelete = (id) => {
    deleteData(id);
  };
  useEffect(() => {
    if (success) {
      refetchData();
    }
  }, [success]);
  return (
    <>
      {invoiceData && (
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{invoiceData.name}</TableCell>
          {filter.listType === 'Bank' && (
            <TableCell>{invoiceData.bank_name}</TableCell>
          )}

          <TableCell>{getStatusChip(invoiceData.account_type)}</TableCell>
          {filter.listType === 'Mobile Banking' && (
            <>
              <TableCell>{invoiceData.wallet_type}</TableCell>
              <TableCell>{invoiceData.mobile_operator_name}</TableCell>
              <TableCell>{invoiceData.wallet_account_type}</TableCell>
              <TableCell>{invoiceData.account_no}</TableCell>
            </>
          )}

          <TableCell>{invoiceData.balance}</TableCell>
          {filter.listType !== 'Cash' && (
            <TableCell>
              {invoiceData.is_active ? (
                <Button size='small' variant='text' color='success'>
                  Active
                </Button>
              ) : (
                <Button size='small' variant='text' color='error'>
                  InActive
                </Button>
              )}
            </TableCell>
          )}

          <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => setEditData(invoiceData)}
            >
              <BorderColorSharpIcon color='primary' />
            </Box>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => handleDelete(invoiceData.id)}
            >
              <DeleteForeverSharpIcon color='error' />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AccountTable;
