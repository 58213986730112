import { DatePicker } from "@mui/lab";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { IDate } from "src/Types/module/serviceRequest/serviceRequestTypes";
import { CommonDatePicker } from "src/common/CommonDatePicker";

const ReceivePayment = () => {
  const [date, setDate] = useState<IDate>({
    startDate: null,
    endDate: null,
  });
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid item xs={12} mb={2}>
        <Card sx={{ p: 2 }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                fontWeight={"bold"}
                variant="h6"
                component="h6"
                gutterBottom
              >
                From
              </Typography>

              <CommonDatePicker
                onChange={(e: any) => setDate({ ...date, startDate: e })}
                label={"Start Date"}
                value={date.startDate}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                fontWeight={"bold"}
                variant="h6"
                component="h6"
                gutterBottom
              >
                To
              </Typography>
              <CommonDatePicker
                onChange={(e: any) => setDate({ ...date, endDate: e })}
                label={"End Date"}
                value={date.endDate}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Receive Payment " />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Receipts</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <InvoiceTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box> */}
            </CardContent>

            {/* Modal */}

            {/* manual invoice  */}
            {/* <ModalsWrapper
              modalData={{ title: "Create manual invoice" }}
              setShowModal={setOpenManual}
              showModal={openManual}
            >
              <CreateManualInvoice handleClose={() => setOpenManual(false)} />
            </ModalsWrapper> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReceivePayment;
