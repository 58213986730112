import { useState } from 'react';
import { HttpClient } from 'src/Helpers/http/http';
import Toaster from 'src/Toaster/Toaster';
const Toast = Toaster();
interface IResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

const DeleteDataApi = <T,>(url: string) => {
  const [response, setResponse] = useState<T | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteData = async (id?: string | number | {}, body?: any) => {
    setError(null);

    try {
      setLoading(true);
      const res: IResponse<T> = await HttpClient.delete(
        id ? `${url}/${id}` : `${url}`,
        body
      );
      if (res.success) {
        setSuccess(true);
        setResponse(res.data);
        Toast.fire({
          icon: 'success',
          title: `Successfully created!`,
        });
      } else {
        setSuccess(false);
        setError('something went wrong');
      }
    } catch (err: any) {
      setSuccess(false);
      setError(err?.message);
      Toast.fire({
        icon: 'error',
        title: `${
          err?.response?.data?.message
            ? err?.response?.data?.message || 'Something went wrong'
            : err?.message || 'Something went wrong'
        }`,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    response,
    loading,
    error,
    deleteData,
    success,
    setRefetch,
    setSuccess,
  };
};

export default DeleteDataApi;
